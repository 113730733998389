import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

export const useStyles = makeStyles()(theme => ({
  topContainer: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    marginTop: 0,
  },
  topMessageContainer: {
    width: '270px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Noto Sans',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '28px',
    textAlign: 'center',
  },
  qr: {
    width: 124,
    height: 124,
    display: 'block',
  },
  qrWrapper: {
    padding: '40px',
    position: 'relative',
    marginTop: 0,
  },
  qrGeminiIcon: {
    width: 17,
    height: 17,
    top: 90,
    left: 90,
    position: 'absolute',
    backgroundColor: COLORS.WHITE,
    padding: 3,
  },
  qrCryptoIcon: {
    width: 20,
    height: 20,
    top: '94px',
    left: '94px',
    position: 'absolute',
    color: '#000',
    backgroundColor: '#FFF',
    fontSize: '12px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  donationContainerWrapper: {
    '&.MuiContainer-root': {
      padding: '30px 30px 45px 30px',
    },
  },
  addressCopyButton: {
    color: theme.palette.text.primary,
    flex: 1,
    fontFamily: 'Noto Sans',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    background: 'transparent',
    outline: 0,
    padding: '14px 15px 14px 20px',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    boxShadow: '0px 3px 12px rgba(41, 27, 79, 0.05) !important',
    position: 'relative',
  },
  destinationTagLabel: {
    position: 'absolute',
    fontSize: 12,
    color: COLORS.TEXT.INSUBSTANTIAL_GREY,
    left: 20,
    top: -10,
    background: COLORS.WHITE,
  },
  iconCopyWrapper: {
    marginLeft: 5,
    color: '#86909F',
    height: 20,
    width: 20,
  },
  donationNotes: {
    color: '#FC6C2B',
    marginTop: 14,
    fontFamily: 'Noto Sans',
    fontSize: 12,
    lineHeight: '20px',
    textAlign: 'center',
  },
  tooltipIcon: {
    marginLeft: 5,
    cursor: 'pointer',
    color: '#86909F',
  },
  copyButtonWrapper: {
    display: 'flex',
    textAlign: 'center',
  },
  iconInfoWrapper: {
    position: 'relative',
    marginLeft: 8,
    height: 25,
  },
  resetButton: {
    flex: 1,
    height: 50,
    fontSize: 16,
    borderRadius: `${theme.shape.borderRadius} !important`,
    color: `${theme.palette.primary.main} !important`,
    lineHeight: '24px',
    textTransform: 'none !important' as 'none',
    background: `${theme.palette.secondary.main} !important`,
    boxShadow: 'none !important',
  },
  readyButton: {
    flex: 1,
    height: 50,
    fontSize: 16,
    fontWeight: 500,
    borderRadius: `${theme.shape.borderRadius} !important`,
    color: `${theme.palette.primary.main} !important`,
    lineHeight: '24px',
    textTransform: 'none !important' as 'none',
    background: `${theme.palette.secondary.main} !important`,
    boxShadow: 'none !important',
    padding: 0,
  },
  donationNoteTooltip: {
    width: '270px !important',
    padding: '10px 15px !important',
    fontSize: '14px !important',
    fontFamily: 'Noto Sans !important',
    backgroundColor: '#928E9C !important',
    textAlign: 'center',
    borderRadius: `${theme.shape.borderRadius} !important`,
    fontWeight: '400 !important',
  },
  metamaskButton: {
    width: '100%',
    fontFamily: 'Poppins',
    height: '50px !important',
    color: `${theme.palette.text.primary} !important`,
    backgroundColor: `${theme.palette.common.white} !important`,
    boxShadow: '0px 3px 12px rgba(41, 27, 79, 0.05) !important',
    border: `1px solid ${theme.palette.divider} !important`,
    boxSizing: 'border-box',
    borderRadius: theme.shape.borderRadius,
    marginTop: '20px !important',
  },
  walletButtonIcon: {
    marginRight: 10,
  },
  cryptoAmount: {
    wordBreak: 'break-word',
  },
  address: {
    overflow: 'auto',
  },
  autoRedirectMessage: {
    width: '270px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Noto Sans',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '28px',
    textAlign: 'center',
  },
}));

export default useStyles;
