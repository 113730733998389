import { ReactElement } from 'react';

interface OptionallyVisibleProps {
  visible: boolean;
  children: ReactElement;
}

export const OptionallyVisible = ({ visible, children }: OptionallyVisibleProps) => (visible ? children : null);

export default OptionallyVisible;
