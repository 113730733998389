import React, {
  ChangeEvent, useCallback, useEffect, useState,
} from 'react';
import { Box, debounce } from '@mui/material';

import { Button } from 'components';
import PageHeader from 'components/pageHeader/pageHeader';
import { DonationPledge } from 'types/pledge';

import Note from './note';
import LABELS, { KEYS, PLEDGE_KEYS } from './notes.keys';
import useStyles from './notes.style';
import TributeNote from './tributeNote';

interface NotesProps {
  notes: string;
  honoreeName: string | null;
  honoreeEmail: string | null;
  isTributeNote: boolean;
  notifyHonoree: boolean;
  updatePledgeNotes: (changes: Partial<DonationPledge>) => void;
  proceed: () => void;
  goBack: () => void;
}

export const Notes = ({
  notes,
  honoreeName,
  honoreeEmail,
  isTributeNote,
  notifyHonoree,
  updatePledgeNotes,
  proceed,
  goBack,
}: NotesProps) => {
  const { classes } = useStyles();
  const [hasErrors, setHasErrors] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  useEffect(() => {
    const shouldCheckEmail = notifyHonoree && isTributeNote;
    const isNotePresent = Boolean(notes.trimEnd().length);
    const isNamePresent = isTributeNote ? Boolean(honoreeName?.trimEnd().length) : true;
    const isEmailPresent = shouldCheckEmail ? Boolean(honoreeEmail?.trimEnd().length) : true;
    const isEmailOk = shouldCheckEmail ? isEmailValid : true;

    const errorsPresent = [isNotePresent, isNamePresent, isEmailPresent, isEmailOk]
      .some(condition => condition === false);

    setHasErrors(errorsPresent);
  }, [notes, isTributeNote, notifyHonoree, honoreeName, honoreeEmail, isEmailValid]);

  useEffect(() => {
    if (!notifyHonoree) {
      setIsEmailValid(true);
      return;
    }

    if (!honoreeEmail) {
      return;
    }

    setIsEmailValid(KEYS.EMAIL_REGEX.test(honoreeEmail));
  }, [notifyHonoree]);

  const debounceEmailTest = useCallback(debounce((email) => {
    setIsEmailValid(KEYS.EMAIL_REGEX.test(email));
  }, KEYS.DEBOUNCE_TIMEOUT), []);

  const handlePledgeChange = (fieldName: keyof DonationPledge) => (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;

    if (fieldName === PLEDGE_KEYS.HONOREE_EMAIL) {
      debounceEmailTest(value);
    }

    updatePledgeNotes({
      [fieldName]: value,
    });
  };

  const handlePledgeBooleanChange = (fieldName: keyof DonationPledge) => (checked: boolean) => {
    updatePledgeNotes({
      [fieldName]: checked,
    });
  };

  const title = isTributeNote ? LABELS.TRIBUTE_NOTE.PAGE_TITLE : LABELS.NOTE.PAGE_TITLE;

  const form = isTributeNote
    ? (
      <TributeNote
        honoreeEmail={honoreeEmail}
        isEmailValid={isEmailValid}
        honoreeName={honoreeName}
        notifyHonoree={notifyHonoree}
        notes={notes}
        onChange={handlePledgeChange}
        onBooleanChange={handlePledgeBooleanChange}
      />
    )
    : (
      <Note
        notes={notes}
        onChange={handlePledgeChange}
      />
    );

  return (
    <div className={classes.scrollContainer}>
      <PageHeader label={title} withBackButton onGoBack={goBack} />
      {form}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <Button
          onClick={proceed}
          disabled={hasErrors}
          className={classes.nextButton}
        >
          {LABELS.NEXT}
        </Button>
      </Box>
    </div>
  );
};

export default Notes;
