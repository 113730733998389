import React from 'react';

import { COLORS } from 'theme/colors';

import { IconProps } from './icon.types';

const path = [
  'M18.7388 16.1693C18.9515 16.222 19.1406 16.0175 19.0788',
  '15.8011L19.0424 15.6738L19.0124 15.572L18.756',
  '14.6792V14.6729L18.7578 14.6647C18.7588 14.6602',
  '18.756 14.6556 18.7533 14.6556C18.7506 14.6547',
  '18.7478 14.6529 18.7469 14.6511L17.4069 9.98915C17.3897',
  '9.92518 17.3556 9.86701 17.3081 9.82072C17.2607',
  '9.77444 17.2017 9.74174 17.1374 9.72604C17.073 9.71034',
  '17.0056 9.71222 16.9422 9.73147C16.8788 9.75073 16.8217',
  '9.78666 16.7769 9.83551L15.5406 11.191L15.4451 11.2965L14.8224',
  '11.9783L12.8633 14.1274C12.6669 14.342 12.7697 14.6947 13.0478',
  '14.7638L13.2478 14.8129C13.2593 14.816 13.27 14.8216 13.279',
  '14.8294C13.288 14.8372 13.2951 14.8469 13.2998 14.8579C13.3045',
  '14.8688 13.3067 14.8806 13.3062 14.8925C13.3057 14.9044 13.3025',
  '14.916 13.2969 14.9265C12.8581 15.7725 12.2652 16.5291 11.5488',
  '17.1575C10.263 18.2906 8.62016 18.9364 6.90694 18.9821C6.37573',
  '19.014 5.84273 18.9988 5.31421 18.9366L5.26875 18.9311C4.93966',
  '18.8866 4.89512 18.9984 5.20057 19.133C5.68832 19.3461 6.18315',
  '19.5425 6.68421 19.7221L6.72512 19.7357C10.2215 20.9866 14.4797',
  '20.9285 17.1042 17.8357C17.1098 17.8291 17.1182 17.8192 17.1292',
  '17.8063C17.3152 17.5883 18.2285 16.5174 18.316 16.1629C18.3169',
  '16.1595 18.3176 16.1563 18.3184 16.1531C18.3336 16.0899 18.3384',
  '16.0701 18.4042 16.0865L18.7388 16.1693ZM5.79449 10.5697C5.79441',
  '10.5814 5.79437 10.593 5.79437 10.6046C5.79437 11.5183 6.02801',
  '12.3747 6.43801 13.1138C6.60619 13.4165 6.80255 13.7001 7.02619',
  '13.9592C7.91983 15.0011 9.22619 15.6584 10.6789 15.6584C11.0007',
  '15.6584 11.1516 16.0538 10.8989 16.2602C9.14101 17.7158 6.91898',
  '18.492 4.6371 18.4475C4.55637 18.4453 4.47892 18.4151 4.41801',
  '18.362C3.53228 17.5958 2.78742 16.6804 2.2171 15.6575C1.35431 14.1135',
  '0.903777 12.3733 0.908918 10.6046C0.908918 10.5882 0.908956 10.5719',
  '0.909031 10.5555C0.900649 9.03983 1.2284 7.53788 1.87239 6.15908C2.78975',
  '4.18416 4.33451 2.5681 6.26603 1.56262C7.63389 0.855992 9.13581 0.49608',
  '10.6488 0.500032C10.763 0.499735 10.8773 0.501499 10.9916 0.505338C12.6544',
  '0.559884 14.2107 1.04353 15.5635 1.85264C15.5847 1.86584 15.6031 1.88329',
  '15.6173 1.90391C15.6231 1.91236 15.6282 1.92128 15.6325 1.93055C16.1269',
  '2.23627 16.5944 2.58745 17.0288 2.98083C17.546 3.44812 17.4924 4.2654',
  '16.976 4.7336L15.1515 6.38999C14.6351 6.85818 13.8542 6.79 13.2824',
  '6.39726C12.5844 5.91446 11.768 5.631 10.921 5.57735C10.2219 5.53305',
  '9.52369 5.64672 8.87754 5.90759C7.87193 6.32008 7.03061 7.06812',
  '6.48222 8.01795C6.41951 8.12901 6.36102 8.2428 6.30694 8.35912C6.03071',
  '8.95025 5.87171 9.58933 5.83875 10.241C5.83306 10.3518 5.81816 10.462 5.79449 10.5697Z',
].join(' ');

export const IconChariot = ({
  width = 20,
  height = 20,
  color = COLORS.BLACK,
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d={path} fill={color} />
  </svg>
);

export default IconChariot;
