import { APIConfig } from 'config/api';
import { Storage } from 'utils/storage';

import APIResponse from './api.response';
import BASE_URL from './api.routes';
import {
  APIErrorType,
  APIFetchParams,
  APIRequestMethods,
} from './api.types';
import {
  DonationFormError,
} from './errors/DonationFormError';
import ValidationError from './errors/validationError';

export class API {
  baseUrl: string;

  skipErrorHandling: boolean;

  constructor(baseUrl: string, skipErrorHandling?: boolean) {
    this.baseUrl = baseUrl;
    this.skipErrorHandling = Boolean(skipErrorHandling);
  }

  async fetch(fetchParams: APIFetchParams) {
    const {
      url, body, method, headers: passedHeaders,
    } = fetchParams;
    const defaultHeaders = await this.buildHeaders();
    const headers: Headers = passedHeaders || defaultHeaders;

    const shouldBuildUrl = !url.includes('http');

    const requestUrl = shouldBuildUrl ? this.buildUrl(url) : url;

    try {
      const response = await fetch(requestUrl, {
        method,
        body,
        headers,
      });

      const json = await response.json();

      if (!response.ok) {
        throw this.buildError(response, json);
      }

      return json;
    } catch (error) {
      this.handleError(error);
      return null;
    }
  }

  async fetchRaw(fetchParams: APIFetchParams): Promise<APIResponse> {
    const {
      url, body, method, headers: passedHeaders,
    } = fetchParams;

    const defaultHeaders = await this.buildHeaders();
    const headers: Headers = passedHeaders || defaultHeaders;

    const response = await fetch(this.buildUrl(url), {
      method: method as string,
      body,
      headers,
    });

    return new APIResponse(response);
  }

  buildError(response, data: any) {
    if (this.skipErrorHandling) {
      return data;
    }

    if (!data) {
      return new DonationFormError(response.statusText);
    }

    if (data.errorType === APIErrorType.Validation) {
      return new ValidationError(data);
    }

    return new DonationFormError().fromAPIError(data);
  }

  buildUrl(url: string) {
    const ensuredSlash = this.baseUrl.slice(-1) === '/'
      ? ''
      : '/';

    return `${this.baseUrl}${ensuredSlash}${url}`;
  }

  getDefaultHeaders() {
    const headers = new Headers();
    headers.append('Content-type', 'application/json');
    return headers;
  }

  async buildHeaders() {
    const headers = this.getDefaultHeaders();
    const token = Storage.get(APIConfig.tokenKey);
    if (token) {
      headers.append('Authorization', `bearer ${token}`);
    }

    return headers;
  }

  async post(url: string, data?: object, headers?: Headers) {
    return this.fetch({
      url,
      body: this.prepareBody(data),
      headers,
      method: APIRequestMethods.POST,
    });
  }

  async put(url: string, data?: object) {
    return this.fetch({
      url,
      body: this.prepareBody(data),
      method: APIRequestMethods.PUT,
    });
  }

  async get(url: string, data?: object, headers?: Headers) {
    const searchParams = new URLSearchParams();
    if (data) {
      Object.entries(data).forEach(([key, value]) => searchParams.set(key, value));
    }

    return this.fetch({
      url: `${url}?${searchParams}`,
      headers,
      method: APIRequestMethods.GET,
    });
  }

  async delete(url: string, data?: object) {
    return this.fetch({
      url,
      body: this.prepareBody(data),
      method: APIRequestMethods.DELETE,
    });
  }

  prepareQueryString(data: any) {
    return '';
  }

  prepareBody(data?: object) {
    const body = {
      ...(data || {}),
    };

    return JSON.stringify(body);
  }

  handleError(error) {
    throw error;
  }
}

const instance = new API(BASE_URL);

export default instance;
