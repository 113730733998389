import React from 'react';

import { IconProps } from './icon.types';

const path = [
  'M13.7867 2.77603H2.21333C1.54444 2.77603 1 3.32048 1 3.98936V11.5665C1 12.2354 1.54444',
  '12.7798 2.21333 12.7798H13.7867C14.4556 12.7798 15 12.2354 15 11.5665V3.99092C15 3.32048',
  '14.4556 2.77603 13.7867 2.77603ZM14.5473 3.99092V11.568C14.5473 11.7065 14.5069 11.8356',
  '14.4416 11.9476L9.87911 7.7787L14.4416 3.61136C14.5084 3.72336 14.5473 3.85092 14.5473',
  '3.99092ZM13.7867 3.2287C13.9033 3.2287 14.0122 3.2567 14.1102 3.30336L8.51333 8.41492C8.22089',
  '8.68248 7.77911 8.68248 7.48511 8.41492L1.88978 3.30336C1.98778 3.2567 2.09667 3.2287',
  '2.21333 3.2287H13.7867ZM1.55689 11.946C1.49156 11.834 1.45111 11.7049 1.45111',
  '11.5665V3.99092C1.45111 3.85248 1.49156 3.72336 1.55689 3.61136L6.12089 7.7787L1.55689',
  '11.946ZM2.21333 12.3287C2.09667 12.3287 1.98778 12.3007 1.88978 12.254L6.45533',
  '8.08359L7.18178 8.74781C7.41511 8.96092 7.70756 9.06825 8 9.06825C8.29244 9.06825 8.58489',
  '8.96248 8.81822 8.74781L9.54467 8.08359L14.1102 12.254C14.0122 12.3007 13.9033 12.3287',
  '13.7867 12.3287H2.21333Z',
].join(' ');

export const IconMail = ({
  width = 16,
  height = 15,
  color = 'black',
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d={path} fill={color} />
  </svg>
);

export default IconMail;
