import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

const useStyles = makeStyles()({
  scrollContainer: {
    padding: '30px 30px 45px 30px',
  },
  note: {
    fontSize: 14,
    padding: '10px 15px',
    background: COLORS.TEXT.NOTE_BACKGROUND,
    borderRadius: 5,
    marginBottom: 15,
  },
  tooltip: {
    backgroundColor: '#333!important',
    border: '0!important',
    fontSize: '13px!important',
    fontFamily: 'Roboto!important',
    fontWeight: '500!important',
    lineHeight: '16px!important',
    marginTop: '6px!important',
    marginLeft: '15px!important',
  },
  tooltipArrow: {
    left: '5px!important',
    '&::before': {
      backgroundColor: 'transparent!important',
      borderLeft: '8px solid #333',
      borderBottom: '8px solid #333',
      borderTop: '8px solid transparent',
      borderRight: '8px solid transparent',
      transform: 'none!important',
      width: '0px!important',
      height: '0px!important',
    },
  },
  checkboxes: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
    gap: 15,
    marginTop: 40,
  },
  checkboxLabel: {
    fontSize: '14px',
    marginLeft: 15,
    fontFamily: 'Noto Sans',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkboxLabelTooltip: {
    color: '#888',
    marginLeft: 5,
    marginTop: 5,
  },
  checkboxContainer: {
    '& .MuiCheckbox-root': {
      color: '#DADFE5',
      borderRadius: '3px',
      padding: 0,
      marginRight: 0,
    },
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,

      '& .MuiTypography-root': {
        lineHeight: '20px',
      },
    },
    border: `1px solid ${COLORS.INPUT.BORDER}`,
    padding: '14px 19px',
    boxSizing: 'border-box',
    borderRadius: 5,
    gridColumn: '1 / span 2',
  },
  proceedButton: {
    marginTop: 45,
    width: '100%',
  },
  loadingContainer: {
    padding: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default useStyles;
