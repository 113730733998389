import React, { useEffect, useRef, useState } from 'react';

import { useStyles } from './style';
import { SelectOption } from './types';

export interface FundsDesignationItemContentProps {
  option: SelectOption;
}

export const FundsDesignationItemContent = ({
  option,
}: FundsDesignationItemContentProps) => {
  const { classes } = useStyles();
  const [isEllipsisActive, setIsEllipsisActive] = useState<boolean>(false);
  const designationContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!designationContainerRef.current) {
      return;
    }
    const { offsetWidth, scrollWidth } = designationContainerRef.current;
    const hasEllipses = offsetWidth < scrollWidth;
    setIsEllipsisActive(hasEllipses);
  }, []);

  return (
    <div
      className={classes.designationMenuItemContent}
      ref={designationContainerRef}
      title={isEllipsisActive ? option.label : undefined}
    >
      {option.label}
    </div>
  );
};

export default FundsDesignationItemContent;
