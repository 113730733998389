import React, { useCallback } from 'react';
import {
  Error as ErrorIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import {
  InputAdornment,
  TextField,
} from '@mui/material';
import { useField } from 'formik';

import OptionallyVisible from 'components/optionallyVisible';

import { FormFieldDescription } from './fields.types';

const InvalidStateIcons = {
  Required: <ErrorIcon color="error" fontSize="small" />,
  Invalid: <WarningIcon color="error" fontSize="small" />,
};

interface CustomTextFieldProps {
  item: FormFieldDescription;
  required: boolean;
  disabled: boolean;
  isSubmitting: boolean;
  name: string;
  className: string;
  setFieldValue: (field: string, value: any) => void;
  errorText?: string;
  onBlur?: () => void;
}

export const CustomTextField = ({
  item,
  disabled,
  isSubmitting,
  setFieldValue,
  errorText,
  onBlur,
  ...props
}: CustomTextFieldProps) => {
  const [field, meta] = useField(props);
  const hasMetaError = Boolean((isSubmitting || meta.touched) && meta.error);
  const hasError = !disabled && (hasMetaError || Boolean(errorText));
  const errorCode: string = meta.error || '';

  const handleChange = useCallback(({ target }) => setFieldValue(item.name, target.value), [item]);

  return (
    <div>
      <TextField
        {...field}
        {...props}
        placeholder={item.placeholder}
        name={item.name}
        title={item.title}
        margin="dense"
        error={hasError}
        helperText={errorText}
        disabled={disabled}
        fullWidth
        variant="outlined"
        onChange={handleChange}
        onBlur={onBlur}
        InputProps={{
          endAdornment: (
            <OptionallyVisible visible={hasError}>
              <InputAdornment position="end">
                {InvalidStateIcons[errorCode]}
              </InputAdornment>
            </OptionallyVisible>
          ),
        }}
      />
    </div>
  );
};

export default CustomTextField;
