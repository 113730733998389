import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  header: {
    fontWeight: 'bold ',
  },
  flowButton: {
    height: 45,
    borderRadius: '10px',
    textTransform: 'none',
    backgroundColor: '#E6EDF2',
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: 14,
    '&:hover': {
      backgroundColor: '#E6EDF2',
    },
  },
  outstandingFlowButton: {
    height: 55,
    fontSize: 16,
    backgroundColor: '#F6D553',
    '&:hover': {
      backgroundColor: '#F6D553',
    },
  },
  gridContainer: {
    width: '220px',
    padding: '0px',
    marginTop: '20px',
  },
  icon: {
    marginRight: 10,
  },
});

export default useStyles;
