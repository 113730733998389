import React from 'react';

import MainLogo from 'assets/images/simple-main-logo.png';

interface LogoProps {
  className?: string;
  size?: number;
}

export const Logo = ({
  className,
  size = 150,
}: LogoProps) => (
  <img
    src={MainLogo}
    alt="loading"
    className={className}
    style={{
      maxWidth: size,
    }}
  />
);

export default Logo;
