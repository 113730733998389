/* eslint-disable react/no-array-index-key */
import React from 'react';

import { COLORS } from 'theme/colors';

import { IconProps } from './icon.types';

const path = [
  [
    'M11.1117 0C8.10982 0.02 5.59826 2.28 5.25805 5.26C2.01604 5.61 -0.315404',
    '8.51 0.0348128 11.74C0.355011 14.73 2.87657 17 5.88844 17C8.8903 16.98 11.4119 14.72',
    '11.7421 11.74C14.9741 11.39 17.3155 8.49 16.9653 5.25C16.6451 2.27 14.1235 0 11.1117',
    '0ZM15.6245 6.55C15.3243 8.54 13.7633 10.09 11.7821 10.4V6.55H15.6245ZM1.37564',
    '10.45C1.67583 8.46 3.2368 6.9 5.21803 6.6V10.44L1.37564 10.45ZM10.4012 11.78C10.031',
    '14.27 7.71958 15.99 5.22803 15.63C3.2368 15.34 1.67583 13.77 1.37564 11.78H10.4012ZM10.4513',
    '6.55V10.44H6.54885V6.55H10.4513ZM15.6245 5.22H6.59888C6.96911 2.73 9.28055 1.01 11.7721',
    '1.37C13.7633 1.67 15.3243 3.23 15.6245 5.22Z',
  ].join(' '),
];

export const IconGemini = ({
  width = 17,
  height = 17,
  color = COLORS.BLACK,
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {path.map((item, key) => <path d={item} fill={color} key={key} />)}
  </svg>
);

export default IconGemini;
