import { AnyAction, Reducer } from '../state.types';
import { DonationReduxState } from './state';

export const actionTypeName = 'DONATION__SET_ACH_ERROR';

export type ActionSetAchError = AnyAction & {
  errorMessage: string;
}

export const createAction = (errorMessage): ActionSetAchError => ({
  type: actionTypeName,
  errorMessage,
});

export const reducer: Reducer<DonationReduxState, ActionSetAchError> = (
  state: DonationReduxState,
  action: ActionSetAchError,
): DonationReduxState => {
  const nextState = {
    ...state,
    ach: {
      ...state.ach,
      error: {
        errorMessage: action.errorMessage,
      },
    },
  };

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
