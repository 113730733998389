import React from 'react';

import DonationSuccessLayout from 'pages/common/donationSuccess/components/donationSuccessLayout';

export interface CardDonationSuccessProps {
  resetFlowWithDesiredDonationType: () => void;
  organizationName: string;
  onShare: (platform: string) => void;
}

export const CardDonationSuccess = ({
  resetFlowWithDesiredDonationType,
  organizationName,
  onShare,
}: CardDonationSuccessProps) => (
  <DonationSuccessLayout
    restart={resetFlowWithDesiredDonationType}
    onShare={onShare}
    organizationName={organizationName}
    additionalContent={null}
    withSocialLinks
  />
);

export default CardDonationSuccess;
