import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

const useStyles = makeStyles()({
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100% - 72px)',
    padding: '0 34px',
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 28,
  },
  header: {
  },
  headerTitle: {
    fontSize: 16,
    textAlign: 'center',
    fontWeight: 500,
    margin: 0,
    marginBottom: 5,
    color: COLORS.TEXT.PRIMARY,
  },
  note: {
    fontSize: 14,
    padding: '10px 15px',
    background: COLORS.TEXT.NOTE_BACKGROUND,
    borderRadius: 5,
    width: '100%',
    boxSizing: 'border-box',
    marginBottom: 15,
  },
  donationAmountContainer: {
    width: '100%',
  },
  donationAmountTitle: {
    color: COLORS.TEXT.PRIMARY,
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 500,
    margin: 0,
    marginBottom: 5,
  },
  donationAmount: {
    border: `1px solid ${COLORS.INPUT.BORDER}`,
    borderRadius: 5,
    boxSizing: 'border-box',
    width: '100%',
    padding: '12px 20px',
    fontSize: 16,
    textAlign: 'center',
    fontWeight: 500,
  },
  tooltip: {
    backgroundColor: '#333!important',
    border: '0!important',
    fontSize: '13px!important',
    fontFamily: 'Roboto!important',
    fontWeight: '500!important',
    lineHeight: '16px!important',
    marginTop: '6px!important',
    marginLeft: '15px!important',
  },
  tooltipArrow: {
    left: '5px!important',
    '&::before': {
      backgroundColor: 'transparent!important',
      borderLeft: '8px solid #333',
      borderBottom: '8px solid #333',
      borderTop: '8px solid transparent',
      borderRight: '8px solid transparent',
      transform: 'none!important',
      width: '0px!important',
      height: '0px!important',
    },
  },
  checkboxLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: COLORS.TEXT.PRIMARY,
  },
  checkboxLabelTooltip: {
    color: '#888',
    marginLeft: 5,
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 10,
    width: '100%',
    paddingLeft: 15,

    '& label': {
      '& .MuiCheckbox-root': {
        padding: 0,
        marginRight: 14,
      },
    },
  },
});

export default useStyles;
