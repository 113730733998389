/* eslint-disable react/no-array-index-key */
import React from 'react';

import useStyles from './styles';

export type SpinnerProps = {
  scale?: number;
  color?: string;
  className?: string;
};

export const Spinner = ({
  scale = 1,
  color,
  className,
  ...otherProps
}: SpinnerProps) => {
  const { classes, cx, theme } = useStyles();

  /* eslint-disable max-len */
  const paths = [
    'M5.40492 4.61636C5.17601 4.38439 4.80462 4.38439 4.575 4.61636C4.34609 4.84879 4.34609 5.22514 4.575 5.45734L7.06453 7.98005C7.17945 8.09651 7.32964 8.15426 7.4796 8.15426C7.62956 8.15426 7.77976 8.09651 7.89421 7.98005C8.12359 7.74809 8.12359 7.37174 7.89421 7.13907L5.40492 4.61636Z',
    'M11.9998 6.25808C12.3236 6.25808 12.5863 5.99132 12.5863 5.66303V2.09458C12.5863 1.76628 12.3236 1.5 11.9998 1.5C11.6754 1.5 11.4126 1.76628 11.4126 2.09458V5.66303C11.4126 5.99132 11.6754 6.25808 11.9998 6.25808Z',
    'M19.4229 4.61636C19.194 4.38439 18.8221 4.38439 18.593 4.61636L16.1039 7.13907C15.875 7.37151 15.875 7.74785 16.1039 7.98005C16.2184 8.09651 16.3686 8.15426 16.519 8.15426C16.6692 8.15426 16.8192 8.09651 16.9336 7.98005L19.4227 5.45734C19.6523 5.22538 19.6523 4.84903 19.4229 4.61636Z',
    'M21.9129 11.5449H18.3904C18.066 11.5449 17.8032 11.8112 17.8032 12.1395C17.8032 12.4678 18.066 12.7341 18.3904 12.7341H21.9129C22.2369 12.7341 22.4996 12.4678 22.4996 12.1395C22.4996 11.8112 22.2369 11.5449 21.9129 11.5449Z',
    'M16.9339 16.299C16.7049 16.067 16.3335 16.067 16.1039 16.299C15.875 16.5309 15.875 16.9078 16.1039 17.14L18.593 19.6631C18.7074 19.7796 18.8581 19.8374 19.0081 19.8374C19.158 19.8374 19.3082 19.7796 19.4227 19.6631C19.6521 19.4314 19.6521 19.0548 19.4231 18.8222L16.9339 16.299Z',
    'M11.4126 22.185C11.4126 22.5133 11.6754 22.7796 11.9998 22.7796C12.3236 22.7796 12.5863 22.5133 12.5863 22.185V18.617C12.5863 18.2887 12.3236 18.0225 11.9998 18.0225C11.6754 18.0225 11.4126 18.2887 11.4126 18.617V22.185Z',
    'M7.8952 16.299C7.66629 16.067 7.2949 16.067 7.06528 16.299L4.5767 18.8222C4.34732 19.0546 4.34732 19.4314 4.5767 19.6631C4.69115 19.7796 4.84135 19.8374 4.99131 19.8374C5.14197 19.8374 5.29193 19.7796 5.40638 19.6631L7.89544 17.14C8.12481 16.9078 8.12481 16.5309 7.8952 16.299Z',
    'M1.5 12.1395C1.5 12.4678 1.76278 12.7341 2.08676 12.7341H5.60872C5.93316 12.7341 6.19548 12.4678 6.19548 12.1395C6.19548 11.8112 5.93316 11.5449 5.60872 11.5449H2.08676C1.76278 11.5452 1.5 11.8114 1.5 12.1395Z',
  ];
  /* eslint-enable max-len */

  return (
    <svg
      className={cx(classes.spinner, className)}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...otherProps}
    >
      {paths.map((path, i) => (
        <path key={i} d={path} fill={color ?? theme.palette.secondary.main} strokeWidth="0.5" />
      ))}
    </svg>
  );
};

export default Spinner;
