import { Logger } from 'utils/logger';

export class Storage {
  static set(key: string, value: any) {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      Logger.error(e);
    }
  }

  static get(key: string) {
    try {
      let value = window.localStorage.getItem(key);
      if (value !== null) {
        value = JSON.parse(value);
      }

      return value;
    } catch (e) {
      Logger.error(e);
    }

    return null;
  }

  static remove(key: string) {
    try {
      window.localStorage.removeItem(key);
    } catch (e) {
      Logger.error(e);
    }
  }
}

export default Storage;
