import Frequency from 'types/frequency';

import { AnyAction } from '../state.types';
import { FrequencyReduxState } from './state';

export const actionTypeName = 'FREQUENCY__SET_FREQUENCY';

export type ActionSetAnonymous = AnyAction & {
  frequency: Frequency;
}

export const createAction = (frequency: Frequency) => ({
  type: actionTypeName,
  frequency,
});

export const reducer = (
  state: FrequencyReduxState,
  { frequency }: ActionSetAnonymous,
): FrequencyReduxState => ({
  ...state,
  frequency,
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
