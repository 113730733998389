import { AnyAction } from '../state.types';
import { CreditCardReduxState, initialState } from './state';

export const actionTypeName = 'CREDIT_CARD__CLEAR_CREDIT_CARD_INFO_FIELDS';

export type ActionClearCreditCardInfoValue = AnyAction & {
  initialCardInformation?: Partial<CreditCardReduxState>;
};

export const createAction = (initialCardInformation?: Partial<CreditCardReduxState>) => ({
  type: actionTypeName,
  initialCardInformation,
});

export const reducer = (
  _: CreditCardReduxState,
  action: ActionClearCreditCardInfoValue,
): CreditCardReduxState => {
  const { initialCardInformation } = action;
  const initalState = initialState();
  const nextState = {
    ...initalState,
    ...(initialCardInformation || {}),
  };

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
