import React from 'react';

import { Broker } from 'types/broker';

import { FormFieldDescription } from '../../fields/field.types';
import { KEYS } from '../brokerInfo.keys';
import { CustomSelect } from './customSelect';
import { CustomTextField } from './customTextField';

export interface TextFieldProps {
  brokers: Broker[];
  item: FormFieldDescription;
  isSubmitting: boolean;
  setFieldValue: (field: string, value: any) => void;
  errorText?: string;
}

export const TextField = ({
  brokers,
  item,
  isSubmitting,
  setFieldValue,
  errorText,
}: TextFieldProps) => {
  const componentMap = {
    [KEYS.BROKER_NAME]: CustomSelect,
    default: CustomTextField,
  };

  const SelectedTextField = componentMap[item.name] || componentMap.default;

  return (
    <SelectedTextField
      brokers={brokers}
      item={item}
      required={Boolean(item.isRequired)}
      name={item.name}
      isSubmitting={isSubmitting}
      disabled={Boolean(item.isDisabled)}
      setFieldValue={setFieldValue}
      errorText={errorText}
    />
  );
};

export default TextField;
