import React, { useCallback, useState } from 'react';
import InputMask from 'react-input-mask';
import {
  Error as ErrorIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import {
  InputAdornment,
  TextField,
} from '@mui/material';
import { useField } from 'formik';

import OptionallyVisible from 'components/optionallyVisible';

import { FormFieldDescription } from './fields.types';
import { useStyles } from './styles';

const InvalidStateIcons = {
  Required: <ErrorIcon color="error" fontSize="small" />,
  Invalid: <WarningIcon color="error" fontSize="small" />,
};

interface MaskedTextFieldProps {
  item: FormFieldDescription;
  required: boolean;
  disabled: boolean;
  isSubmitting: boolean;
  name: string;
  className: string;
  setFieldValue: (field: string, value: any) => void;
  errorText?: string;
}

export const MaskedTextField = ({
  item,
  disabled,
  isSubmitting,
  setFieldValue,
  errorText,
  ...props
}: MaskedTextFieldProps) => {
  const { classes } = useStyles();
  const [field, meta] = useField(props);
  const [visibleValue, setVisibleValue] = useState<string>('');
  const hasMetaError = Boolean((isSubmitting || meta.touched) && meta.error);
  const hasError = !disabled && (hasMetaError || Boolean(errorText));
  const errorCode: string = meta.error || '';

  const handleChange = useCallback(({ target }) => {
    const maskHideSymbol = item?.maskHideSymbol;

    if (maskHideSymbol) {
      const trimmedValue = target.value.replace(/\s/g, '');
      const nextVisibleValue = maskHideSymbol.repeat(trimmedValue.length);
      setVisibleValue(nextVisibleValue);

      const previousValue = field.value;
      const begginingOfValue = previousValue.substring(0, trimmedValue.length);
      const endOfValue = trimmedValue.substring(
        trimmedValue.length - (trimmedValue.length - previousValue.length),
        trimmedValue.length,
      );

      const nextFieldValue = `${begginingOfValue}${endOfValue}`;
      setFieldValue(item.name, nextFieldValue);
      return;
    }

    setVisibleValue(target.value);
    setFieldValue(item.name, target.value);
  }, [field.value, item]);

  return (
    <div>
      <InputMask
        {...props}
        {...field}
        alwaysShowMask
        maskChar={null}
        mask={item?.mask}
        formatChars={item?.formatChars}
        disabled={disabled}
        value={visibleValue}
        onChange={handleChange}
      >
        {() => (
          <TextField
            className={classes.defaultInput}
            placeholder={item.placeholder}
            name={item.name}
            title={item.title}
            margin="dense"
            error={hasError}
            helperText={errorText}
            fullWidth
            variant="outlined"
            InputProps={{
              endAdornment: (
                <OptionallyVisible visible={hasError}>
                  <InputAdornment position="end">
                    {InvalidStateIcons[errorCode]}
                  </InputAdornment>
                </OptionallyVisible>
              ),
            }}
          />
        )}
      </InputMask>
    </div>
  );
};

export default MaskedTextField;
