import { AnyAction } from '../state.types';
import { CreditCardReduxState } from './state';

export const actionTypeName = 'CREDIT_CARD__SET_ERROR';

export type ActionSetError = AnyAction & {
  error: Record<string, string>;
}

export const createAction = (error: Record<string, string>) => ({
  type: actionTypeName,
  error,
});

export const reducer = (
  state: CreditCardReduxState,
  action: ActionSetError,
): CreditCardReduxState => ({
  ...state,
  error: action.error,
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
