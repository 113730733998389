import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';

import useStyles, { styles } from './styles';

const SkipButton = withStyles(Button, {
  root: {
    color: 'white',
    backgroundColor: '#291B4F',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#291B4F',
      boxShadow: 'none',
    },
    borderRadius: 0,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    width: '100%',
    height: '100%',
  },
});

const PaperComponent = (props) => {
  const { classes } = useStyles();
  return <Paper {...props} className={classes.dialogPaper} />;
};

const KEYS = {
  DIALOG_TITLE: 'Temporary Maintenance',
  DIALOG_TEXT: `
    We are currently undergoing maintenance and a receipt is not available for this transaction.
    If you require a receipt, please try again later.
    If not, you may continue with your donation.
  `,
  SKIP: 'GOT IT',
  MODAL_CONTAINER_ID: 'warningModalContainer',
};

interface FallbackWarningDialogProps {
  open: boolean;
  toggleOpen: () => void;
  disable: boolean;
}

export const FallbackWarningDialog = ({ open, toggleOpen, disable }: FallbackWarningDialogProps) => {
  const { classes } = useStyles();

  if (disable) {
    return null;
  }

  return (
    <div className={classes.modalContainer} id={KEYS.MODAL_CONTAINER_ID}>
      <Dialog
        PaperComponent={PaperComponent}
        container={() => document.getElementById(KEYS.MODAL_CONTAINER_ID)}
        open={open}
        onClose={toggleOpen}
        style={styles.absoluteContainer as React.CSSProperties}
        BackdropProps={{ style: styles.absoluteContainer as React.CSSProperties }}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle
          id="alert-dialog-title"
          className={classes.dialogTitle}
        >
          {KEYS.DIALOG_TITLE}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className={classes.dialogText}>
            {KEYS.DIALOG_TEXT}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={styles.dialogActions}>
          <SkipButton type="button" onClick={toggleOpen}>
            {KEYS.SKIP}
          </SkipButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default React.memo(FallbackWarningDialog);
