import React from 'react';

import { COLORS } from 'theme/colors';

import { IconProps } from './icon.types';

const path = [
  'M10 1.5L1 6.34615V8.42308H19V6.34615L10 1.5Z',
  'M19 19.5017H1L2.38462 16.7324H17.6154L19 19.5017Z',
  'M3.76917 8.42188V16.7296',
  'M7.9231 8.42188V16.7296',
  'M12.0769 8.42188V16.7296',
  'M16.2308 8.42188V16.7296',
].join(' ');

export const IconAch = ({
  width = 20,
  height = 20,
  color = COLORS.PRIMARY,
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d={path}
      stroke={color}
      strokeWidth="1.38462"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconAch;
