import { createReducer } from '../createReducer';
import { buildReducerItem } from '../reducerItem';
import { Reducer } from '../state.types';
import setDonationData from './setDonationData';
import { DonationDataReduxState, initialState } from './state';

// Reducers
const reducers: Map<string, Reducer<DonationDataReduxState, any>> = new Map([
  buildReducerItem(setDonationData),
]);

export const donationDataReducer = createReducer(initialState, reducers);

export default donationDataReducer;
