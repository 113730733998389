import Frequency from 'types/frequency';

import { AnyReduxState } from '../state.types';

export type FrequencyReduxState = AnyReduxState & {
  frequency: Frequency;
}

export const initialState = (): FrequencyReduxState => ({
  frequency: Frequency.Once,
});
