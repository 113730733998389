import { Dispatch } from 'redux';

import { API, Endpoints } from 'api';
import ValidationError from 'api/errors/validationError';
import setPledgeError from 'state/pledge/setError';
import changeStep from 'state/workflow/changeStep';
import setError from 'state/workflow/setError';
import { StockDonationWorkflowStep } from 'types/workflow';

import signDonationPledgeFail from '../signDonationPledge.fail';
import signDonationPledgeStart from '../signDonationPledge.start';
import signDonationPledgeSuccess from '../signDonationPledge.success';

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again later.';

export const signStockDonationPledge = (
  donationUuid: string,
  signature: string,
) => async (dispatch: Dispatch<any>) => {
  dispatch(signDonationPledgeStart.createAction());

  try {
    const response = await API.post(Endpoints.signStockDonation, {
      donationUuid,
      signature,
      date: new Date().toString(),
    });

    if (response?.data?.isSuccess) {
      dispatch(signDonationPledgeSuccess.createAction());
      dispatch(changeStep.createAction(StockDonationWorkflowStep.Success));
      return;
    }

    dispatch(signDonationPledgeFail.createAction({
      errorMessage: DEFAULT_ERROR_MESSAGE,
    }));

    dispatch(changeStep.createAction(StockDonationWorkflowStep.Error));
    dispatch(setError.createAction(DEFAULT_ERROR_MESSAGE));
  } catch (error: any) {
    const { message } = error;
    if (error instanceof ValidationError) {
      dispatch(signDonationPledgeFail.createAction({
        errorMessage: message,
      }));
      dispatch(setPledgeError.createAction(error.getValidationErrorDetails()));
      return;
    }

    const errorMessage = message || DEFAULT_ERROR_MESSAGE;
    dispatch(signDonationPledgeFail.createAction({
      errorMessage,
    }));

    dispatch(changeStep.createAction(StockDonationWorkflowStep.Error));
    dispatch(setError.createAction(errorMessage));
  }
};

export default signStockDonationPledge;
