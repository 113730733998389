import React from 'react';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import useStyles from './styles';

const ColorButton = withStyles(Button, {
  root: {
    color: 'white',
    backgroundColor: '#291B4F',
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
    '&:hover': {
      backgroundColor: '#291B4F',
      '& svg': {
        color: '#FCD42B',
      },
    },
    borderBottomRightRadius: 5,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 5,
  },
});

export interface BottomButtonsNextProps {
  rightButtonText: string;
  onClickRight: (event: React.MouseEvent<HTMLElement>) => unknown;
  isDisabledSubmit?: boolean;
  disabledSubmitReason?: string;
  rightIcon?: [IconPrefix, IconName];
}

export const BottomButtonsNext = ({
  rightButtonText,
  onClickRight,
  isDisabledSubmit = false,
  disabledSubmitReason,
  rightIcon,
}: BottomButtonsNextProps) => {
  const { classes, cx } = useStyles();

  return (
    <div className={classes.container}>
      <ButtonGroup
        className={classes.ButtonGroup}
        fullWidth
        variant="contained"
        size="large"
        aria-label="full width large contained button group"
      >
        <ColorButton
          type="submit"
          disabled={isDisabledSubmit}
          title={isDisabledSubmit ? disabledSubmitReason : undefined}
          onClick={onClickRight}
          className={classes.rightButton}
        >
          {rightButtonText}
          <span className={classes.iconWrapRight}>
            <FontAwesomeIcon
              icon={rightIcon || ['fas', 'chevron-circle-right']}
              className={cx({
                [classes.iconDisabled]: isDisabledSubmit,
              })}
            />
          </span>
        </ColorButton>
      </ButtonGroup>
    </div>
  );
};

export default BottomButtonsNext;
