import { FiatPaymentMethod, ThreeDSecureToken } from 'types/card';

import { AnyAction, Reducer } from '../state.types';
import { DonationReduxState } from './state';

export const actionTypeName = 'DONATION__GET_CARD_TOKEN__SUCCESS';

export type ActionGetCardTokenSuccess = AnyAction & {
  token: ThreeDSecureToken;
  method: FiatPaymentMethod;
};

export const createAction = ({ token, method }): ActionGetCardTokenSuccess => ({
  type: actionTypeName,
  token,
  method,
});

export const reducer: Reducer<DonationReduxState, ActionGetCardTokenSuccess> = (
  state: DonationReduxState,
  {
    token,
    method,
  }: ActionGetCardTokenSuccess,
): DonationReduxState => {
  const cardToken = method === FiatPaymentMethod.ApplePay ? token : token.id;
  const nextState = {
    ...state,
    card: {
      ...state.card,
      cardToken,
      cardType: token.brand,
      method,
      threeDSecure: {
        ...state.card.threeDSecure,
        isLoading: false,
        token,
      },
    },
  };

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
