import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export function initSentry() {
  Sentry.init({
    ...(window?.donationFormConfig?.sentry || {}),
    integrations: [new Integrations.BrowserTracing()],
  });
}

export default initSentry;
