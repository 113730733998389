import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';
import FONT_FAMILIES from 'theme/fontFamilies';

export const useStyles = makeStyles()({
  panel: {
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 3,
    gap: 8,
  },
  instructionsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    color: COLORS.TEXT.PRIMARY,
    gap: 10,
    width: '100%',
  },
  instructionIcon: {
    minWidth: 24,
    minHeight: 24,
    backgroundColor: COLORS.SECONDARY,
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  instructionText: {
    color: COLORS.PRIMARY,
    fontFamily: FONT_FAMILIES.ROBOTO,
    fontSize: 14,
    fontWeight: 400,
    margin: 0,
    textIndent: 'unset',
  },
  textSemiBold: {
    fontWeight: 500,
  },
  textBold: {
    fontWeight: 600,
  },
  delimeter: {
    border: 0,
    margin: 0,
    backgroundColor: COLORS.INPUT.BORDER,
    width: '100%',
    height: 1,
    marginTop: -1,
  },
  gratitude: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    color: COLORS.TEXT.PRIMARY,
  },
  gratitudeImage: {
    marginTop: 5,
  },
  withSpaceAbove: {
    marginTop: 10,
  },
});

export default useStyles;
