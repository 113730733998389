import React, { useCallback } from 'react';
import {
  FormControl,
  MenuItem,
  TextField,
} from '@mui/material';
import { useField } from 'formik';

import { Broker } from 'types/broker';

import { useStyles } from '../styles';
import { FormFieldDescription } from './field.types';

interface CustomSelectProps {
  item: FormFieldDescription;
  required: boolean;
  disabled: boolean;
  isSubmitting: boolean;
  name: string;
  setFieldValue: (field: string, value: any) => void;
  errorText?: string;
  brokers: Broker[];
}

export const CustomSelect = ({
  item,
  disabled,
  isSubmitting,
  setFieldValue,
  errorText,
  brokers,
  ...props
}: CustomSelectProps) => {
  const { classes } = useStyles();
  const [field, meta] = useField(props);
  const hasMetaError = Boolean((isSubmitting || meta.touched) && meta.error);
  const hasError = !disabled && (hasMetaError || Boolean(errorText));

  const handleChange = useCallback(({ target }) => setFieldValue(item.name, target.value), [item]);

  return (
    <div className={classes.inputWrapper}>
      <FormControl fullWidth>
        <TextField
          {...field}
          {...props}
          type="text"
          name={item.name}
          label={item.label}
          margin="dense"
          select
          className={classes.select}
          variant="outlined"
          onChange={handleChange}
          title={item?.title}
          error={hasError}
          disabled={disabled}
        >
          {brokers.map(broker => (
            <MenuItem value={broker.name} key={broker.name} className={classes.menuItem}>
              {broker.label}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </div>
  );
};

export default CustomSelect;
