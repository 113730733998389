/* eslint-disable react/no-array-index-key */
import React from 'react';

import { COLORS } from 'theme/colors';

import { IconProps } from './icon.types';

const path = [
  [
    'M7.99993 0C3.58178 0 6.10352e-05 3.58178 6.10352e-05 7.99992C6.10352e-05',
    '12.4179 3.58173 16 7.99982 16C12.4179 16 16 12.4179 16 7.99992C16 3.58178',
    '12.418 0 7.99993 0ZM11.0177 11.049C10.6983 11.3682 10.1809 11.3682 9.86172',
    '11.049L8.04137 9.22865L6.13813 11.1319C5.81888 11.451 5.30139 11.451',
    '4.9823 11.1319C4.66289 10.8124 4.66289 10.295 4.9823 9.97563L6.88538',
    '8.07256L5.11345 6.30074C4.79431 5.98122 4.79431 5.46389 5.11345',
    '5.14459C5.4327 4.82534 5.95029 4.82534 6.2696 5.14459L8.04142',
    '6.91641L9.73052 5.2272C10.0498 4.90806 10.5675 4.90795 10.8865',
    '5.2272C11.2059 5.54672 11.2059 6.06437 10.8865 6.38335L9.19757',
    '8.07245L11.0178 9.89281C11.337 10.2119 11.3371 10.7297 11.0177 11.049Z',
  ].join(' '),
];

export const IconClear = ({
  width = 24,
  height = 24,
  color = COLORS.SECONDARY,
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {path.map((item, index) => <path d={item} fill={color} key={index} />)}
  </svg>
);

export default IconClear;
