export const KEYS = {
  APPLE_PAY_ABORT_ERROR_NAME: 'AbortError',
};

export const CARD_DETAILS_SCREEN_ERRORS = {
  INVALID_NUMBER: 'invalid_number',
  INVALD_EXPIRY_MONTH: 'invalid_expiry_month',
  INVALD_EXPIRY_YEAR: 'invalid_expiry_year',
  INVALD_CVC: 'invalid_cvc',
  INVALD_ZIP: 'incorrect_zip',
};

export const LABELS = {
  ERRORS: {
    [CARD_DETAILS_SCREEN_ERRORS.INVALID_NUMBER]: 'Card number not valid',
    [CARD_DETAILS_SCREEN_ERRORS.INVALD_EXPIRY_MONTH]: 'Expiry month invalid',
    [CARD_DETAILS_SCREEN_ERRORS.INVALD_EXPIRY_YEAR]: 'Expiry year invalid',
    [CARD_DETAILS_SCREEN_ERRORS.INVALD_CVC]: 'Security code invalid',
    [CARD_DETAILS_SCREEN_ERRORS.INVALD_ZIP]: 'Zip code invalid',
  },
};

export const CARD_ERROR_TO_FIELD_MAPPING = {
  [CARD_DETAILS_SCREEN_ERRORS.INVALID_NUMBER]: 'cardNumber',
  [CARD_DETAILS_SCREEN_ERRORS.INVALD_EXPIRY_MONTH]: 'cardExpirationDate',
  [CARD_DETAILS_SCREEN_ERRORS.INVALD_EXPIRY_YEAR]: 'cardExpirationDate',
  [CARD_DETAILS_SCREEN_ERRORS.INVALD_CVC]: 'cardCVV',
  [CARD_DETAILS_SCREEN_ERRORS.INVALD_ZIP]: 'cardHolderZipCode',
};
