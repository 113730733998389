import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

const useStyles = makeStyles()({
  amountText: {
    marginTop: 15,
    paddingTop: 2,
    fontSize: 12,
    lineHeight: '16px',
    marginBottom: 4,
    textAlign: 'center',
  },
  separated: {
    marginRight: '1px',
  },
  smallFontSizeAddressContainer: {
    fontSize: '8px !important',
  },
  donationContainerWrapper: {
    '&.MuiContainer-root': {
      padding: 30,
    },
  },
  contentContainer: {
    overflowY: 'visible',
    padding: '0 !important',
    textAlign: 'center',
    marginBottom: 20,
    fontFamily: 'Noto Sans',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '28px',
    color: COLORS.PRIMARY,
  },
  contentText: {
    color: COLORS.PRIMARY,
  },
  bottomContainer: {
    marginTop: 0,
    justifyContent: 'center',
    display: 'flex',
    '& .MuiFormControl-marginDense': {
      marginTop: 0,
    },
  },
  tooltipIcon: {
    marginLeft: 5,
    cursor: 'pointer',
    color: '#86909F',
  },
  dialogTitle: {
    '& h2': {
      fontSize: '1.15rem',
    },
  },
  defaultInput: {
    marginTop: 0,
    '& .MuiInputBase-input': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    '& .MuiOutlinedInput-input': {
      padding: '13px 20px',
      fontFamily: 'Noto Sans',
      fontSize: 16,
      lineHeight: '23px',
      fontWeight: 400,
      color: COLORS.PRIMARY,
    },
    '& fieldset': {
      borderColor: COLORS.INPUT.BORDER,
      borderRadius: 5,
    },
    '& .Mui-error fieldset': {
      borderColor: `${COLORS.SECONDARY} !important`,
      borderWidth: 2,
    },
    '& ~ .MuiFormHelperText-root': {
      fontFamily: 'Poppins',
      fontWeight: 600,
      color: COLORS.PRIMARY,
      margin: 0,
      marginTop: 5,
      fontSize: 14,
      lineHeight: '18px',
    },
  },
  dialogActions: {
    padding: '0 !important',
    margin: 0,
    height: 48,
  },
});

export default useStyles;
