import React from 'react';

import { Button, ButtonDisplayVariant } from 'components/button/button';
import OptionallyVisible from 'components/optionallyVisible';
import runtimeLocalStorage, { RUNTIME_LOCAL_STORAGE_KEYS } from 'utils/runtimeLocalStore';

import { KEYS, LABELS } from '../keys';
import { PoweredByBrand, PoweredByBrandType } from '../types';
import useStyles from './styles';

export interface FooterProps {
  brand: PoweredByBrandType;
  openSupportDialog: () => void;
}

export const Footer = ({
  brand = PoweredByBrand.Crypto,
  openSupportDialog,
}: FooterProps) => {
  const { cx, classes } = useStyles({ isOverlay: true });

  const alt = LABELS.ALT[brand];
  const imageSrc = KEYS.LOGO[brand];
  const link = KEYS.LINK[brand];
  const footerClassMap = {
    [PoweredByBrand.Crypto]: classes.cryptoFooter,
    [PoweredByBrand.Card]: classes.shift4Footer,
    [PoweredByBrand.Gemini]: classes.geminiFooter,
  };

  const isPCDEnabled = runtimeLocalStorage.get(RUNTIME_LOCAL_STORAGE_KEYS.IS_PCD_ENABLED);

  const supportButtonClicked = (event) => {
    event.preventDefault();
    event.stopPropagation();
    openSupportDialog();
  };

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className={cx(classes.footer, footerClassMap[brand])}
      href={link}
    >
      <span>
        {LABELS.POWERED_BY}
      </span>
      <img src={imageSrc} alt={alt} />
      <OptionallyVisible visible={!isPCDEnabled}>
        <Button
          display={ButtonDisplayVariant.text}
          className={classes.helpButton}
          onClick={supportButtonClicked}
        >
          {LABELS.NEED_HELP}
        </Button>
      </OptionallyVisible>
    </a>
  );
};

export default Footer;
