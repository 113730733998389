import { FunctionComponent } from 'react';
import {
  connect, ConnectedComponent, MapDispatchToPropsParam, MapStateToPropsParam,
} from 'react-redux';
import { Dispatch } from 'redux';

import setFrequency from 'state/frequency/setFrequency';
import { AppReduxState } from 'state/state';
import changeStep from 'state/workflow/changeStep';
import setType from 'state/workflow/setType';
import Frequency from 'types/frequency';
import { CreditCardDonationWorkflowStep } from 'types/workflow';
import hasMultipleFlowsEnabled from 'utils/organization';

import FrequencyScreen from './frequency';

const mapStateToProps: MapStateToPropsParam<any, any, AppReduxState> = (state) => {
  const { organization } = state.organization;

  return ({
    isPreviousStepAvailable: hasMultipleFlowsEnabled(organization),
  });
};

const mapDispatchToProps: MapDispatchToPropsParam<any, any> = (dispatch: Dispatch<any>) => ({
  setFrequency: (frequency: Frequency) => {
    dispatch(setFrequency.createAction(frequency));
    dispatch(changeStep.createAction(CreditCardDonationWorkflowStep.Pledge));
  },
  resetFlow: () => dispatch(setType.createAction(null)),
});

export const FrequencyConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FrequencyScreen) as ConnectedComponent<FunctionComponent, {}>;

export default FrequencyConnect;
