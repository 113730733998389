import { Dispatch } from 'redux';

import changeStep from 'state/workflow/changeStep';
import setError from 'state/workflow/setError';
import { FiatPaymentMethod, Shift4Utils } from 'types/card';
import { CreditCardDonationWorkflowStep } from 'types/workflow';

import getCardTokenFail from '../getCardToken.fail';
import getCardTokenStart from '../getCardToken.start';
import getCardTokenSuccess from '../getCardToken.success';
import {
  CARD_DETAILS_SCREEN_ERRORS, CARD_ERROR_TO_FIELD_MAPPING, KEYS,
  LABELS,
} from './getCardToken.keys';
import { payWithApplePay } from './methods/applePay';
import { payWithCard } from './methods/card';

export const DEFAULT_ERROR_MESSGAE = 'Something went wrong. Please try again later.';

interface Shift4CardTokenError {
  type: string;
  code: string;
  message: string;
}

const fiatPaymentMethods: Record<FiatPaymentMethod, (params: GetCardTokenParams) => Promise<Record<string, string> | null>> = {
  [FiatPaymentMethod.ApplePay]: payWithApplePay,
  [FiatPaymentMethod.Card]: payWithCard,
  // TODO: Wait for S4 to support Google Pay
  [FiatPaymentMethod.GooglePay]: () => null as any,
};

const mapErrorToTheCarDetailsField = ({ code }: Shift4CardTokenError) => {
  const field = CARD_ERROR_TO_FIELD_MAPPING[code];

  if (!field) {
    return null;
  }

  return ({
    [field]: LABELS.ERRORS[code],
  });
};

const shouldErrorBeHandledOnTheCardDetailsPage = (errorCode: string) => Object.values(CARD_DETAILS_SCREEN_ERRORS).includes(errorCode);

export interface GetCardTokenParams {
  method: FiatPaymentMethod;
  countryCode: string;
  merchantName: string;
  shift4: Shift4Utils;
  donationAmount: number;
  components: unknown;
  additionalFields?: unknown;
  shift4PublicKey?: string;
  widgetId?: string;
}

export const getCardToken = (params: GetCardTokenParams) => async (dispatch: Dispatch<any>) => {
  dispatch(getCardTokenStart.createAction());

  try {
    const token = await fiatPaymentMethods[params.method]?.(params);
    if (token?.error) {
      throw token.error;
    }

    dispatch(getCardTokenSuccess.createAction({ token, method: params.method }));
    dispatch(changeStep.createAction(CreditCardDonationWorkflowStep.Donate));
  } catch (error: any) {
    const shouldStayOnCurrentScreen = params.method === FiatPaymentMethod.ApplePay && error.name === KEYS.APPLE_PAY_ABORT_ERROR_NAME;
    if (shouldStayOnCurrentScreen) {
      // Set the error but dont redirect to the error screen.
      // It happens when user closes Apple Pay modal.
      // This also sets loading to false.
      dispatch(getCardTokenFail.createAction({
        errorMessage: error.message,
      }));
      return;
    }

    const shouldHandleCardErrorWithoutRedirect = error.code && shouldErrorBeHandledOnTheCardDetailsPage(error.code);
    if (shouldHandleCardErrorWithoutRedirect) {
      const mappedTokenError = mapErrorToTheCarDetailsField(error);
      if (mappedTokenError !== null) {
        dispatch(getCardTokenFail.createAction(mappedTokenError));
        return;
      }
    }
    const { message, details } = error;
    const errorMessage = details?.errorMessage || message || DEFAULT_ERROR_MESSGAE;
    dispatch(getCardTokenFail.createAction({
      errorMessage,
    }));
    dispatch(setError.createAction(errorMessage));
  }
};

export default getCardToken;
