import { appConfig } from '../config';

// For reference - providing
export const QALocator = (name: string) => {
  if (!appConfig.enableQALocators) {
    return {};
  }

  return {
    'data-cy': name,
  };
};

export const locators = {
  components: {
    crypto: {
      amountInput: 'crypto-amount-input',
      usdAmountInput: 'usd-amount-input',
      optionsInput: 'crypto-options-input',
      optionButton: (code: string) => `crypto-option-button-${code}`,
    },
    fiat: {
      feesCheckbox: 'fees-checkbox-container',
      amountInput: 'fiat-amount-input',
      amountWithFeesInput: 'fiat-amount-input-with-fees',
      amountButton: (value: number) => `fiat-amount-button-${value}`,
      frequencyButton: (value: string) => `fiat-frequency-button-${value}`,
    },
    stock: {
      amountInput: 'stock-amount-input',
      usdAmountInput: 'usd-amount-input',
      tickerInputContainer: 'stock-ticker-input-container',
      nameInputContainer: 'stock-name-input-container',
    },
    daf: {

    },
    common: {
      button: 'button',
      pledgeForm: 'pledge-form',
      checkbox: 'checkbox',
      input: 'input',
      combobox: 'combobox',
      comboboxInput: 'combobox-input',
    },
  },
  page: {
    crypto: {
    },
    fiat: {
    },
    stock: {
    },
    daf: {
    },
    common: {
      components: {
        notesCheckbox: 'checkbox-notes-container',
        tributesCheckbox: 'checkbox-tributes-container',
        fundsDesignations: 'funds-designations-input',
        donationTypeButton: (type: string) => `donation-type-button-${type}`,
        nextButton: 'button-next',
      },
      start: 'page-start',
      pledge: 'page-pledge',
      donorInfo: 'page-donor-info',
      error: 'page-error',
    },
    donate: 'page-donate',
  },
};
