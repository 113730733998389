import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { AppReduxState } from 'state/state';
import changeStep from 'state/workflow/changeStep';
import setType from 'state/workflow/setType';
import { DafDonationWorkflowStep, DesignVersion, DonationWorkflowType } from 'types/workflow';
import componentSwitch, { getUsedDesignVersion } from 'utils/componentSwitch';
import { getAvailableFlows } from 'utils/organization';

import ErrorBlock from '../errorBlock';
import OverlayErrorBlock from '../overlayVariant/errorBlock';

const mapStateToProps = (state: AppReduxState) => {
  const { organization } = state.organization;

  const availableFlows = getAvailableFlows(organization);
  const dafErrorMessage = state.chariot.donation.error
    ? Object.values(state.chariot.donation.error).filter(e => e !== null).join(', ')
    : null;

  return ({
    availableFlows,
    error: state.workflow.error.message || dafErrorMessage,
    title: state.workflow.error.title,
    hasBackButton: state.organization?.organization?.id,
  });
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  goBack: (availableFlows: DonationWorkflowType[]) => {
    dispatch(changeStep.createAction(DafDonationWorkflowStep.FillDonorInfo));
    const isV2Design = getUsedDesignVersion() === DesignVersion.V2;
    const shouldSelectDifferentFlow = isV2Design && availableFlows[0];
    if (shouldSelectDifferentFlow) {
      dispatch(setType.createAction(availableFlows[0]));
    }
  },
});

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
) => ({
  ...stateProps,
  ...dispatchProps,
  goBack: () => dispatchProps.goBack(stateProps.availableFlows),
});

export const ErrorConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(componentSwitch(ErrorBlock, OverlayErrorBlock));

export default ErrorConnect;
