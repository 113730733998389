import { Broker } from 'types/broker';

import { AnyReduxState } from '../state.types';

export type BrokersReduxState = AnyReduxState & {
  brokers: Broker[];
  loading: boolean;
  error: Record<string, string>;
}

export const initialState = (): BrokersReduxState => ({
  brokers: [],
  loading: false,
  error: {},
});
