import { connect, MapStateToPropsParam } from 'react-redux';

import { AppReduxState } from 'state/state';

import Router from './router';

const mapStateToProps: MapStateToPropsParam<any, any, AppReduxState> = state => ({
  step: state.workflow.step,
  type: state.workflow.type,
});

export const RouterConnect = connect(
  mapStateToProps,
)(Router);

export default RouterConnect;
