import React from 'react';
import {
  Error as ErrorIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { useField } from 'formik';

import OptionallyVisible from 'components/optionallyVisible';

import { FormFieldDescription } from './fields.types';
import { SetFieldValueFunction } from './types';

const InvalidStateIcons = {
  Required: <ErrorIcon color="error" fontSize="small" />,
  Invalid: <WarningIcon color="error" fontSize="small" />,
};

interface CustomTextFieldProps {
  item: FormFieldDescription;
  required: boolean;
  disabled: boolean;
  isSubmitting: boolean;
  name: string;
  className: string;
  setFieldValue: SetFieldValueFunction;
  hasPledgeError?: boolean;
}

export const CustomTextField = ({
  item,
  disabled,
  isSubmitting,
  setFieldValue,
  hasPledgeError,
  ...props
}: CustomTextFieldProps) => {
  const [field, meta] = useField(props);
  const serverSideError = Boolean(meta.error && hasPledgeError);
  const clientSideError = Boolean(((isSubmitting || meta.touched) && meta.error));
  const hasError = !disabled && (clientSideError || serverSideError);
  const shouldShowError = !disabled && hasError;
  const errorCode: string = meta.error || '';

  return (
    <TextField
      {...field}
      {...props}
      label={item.label}
      type={item.type}
      name={item.name}
      title={meta.error ?? item.title}
      margin="dense"
      error={shouldShowError}
      disabled={disabled}
      fullWidth
      variant="outlined"
      onChange={({ target }) => setFieldValue(item.name, target.value)}
      InputProps={{
        endAdornment: (
          <OptionallyVisible visible={hasError}>
            <InputAdornment position="end">
              {InvalidStateIcons[errorCode]}
            </InputAdornment>
          </OptionallyVisible>
        ),
      }}
    />
  );
};

export default CustomTextField;
