import React from 'react';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as Sentry from '@sentry/react';
import WebFont from 'webfontloader';

import Captcha from 'components/captcha/captcha';
import { CaptchaContext, initialValues as captchaInitialValues } from 'components/captcha/captchaContext';
import { initialValues as shift4UtilsInitialValues, Shift4UtilsContext } from 'components/shift4Utils/context';
import { ErrorPage } from 'pages/common/error/errorPage';
import { ThemeProviderConnect } from 'theme/themeProvider.connect';

import './theme/initIcons';
import { RouterConnect } from './router/router.connect';
import { store } from './state/store';

WebFont.load({
  google: { families: ['Roboto:300,400,500', 'Poppins:300,400,500'] },
});

function App() {
  return (
    <Sentry.ErrorBoundary fallback={ErrorPage} showDialog>
      <Provider store={store}>
        <ThemeProviderConnect>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Shift4UtilsContext.Provider value={shift4UtilsInitialValues}>
              <CaptchaContext.Provider value={captchaInitialValues}>
                <RouterConnect />
                <Captcha />
              </CaptchaContext.Provider>
            </Shift4UtilsContext.Provider>
          </LocalizationProvider>
        </ThemeProviderConnect>
      </Provider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
