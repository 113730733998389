import { connect, MapDispatchToPropsParam, MapStateToPropsParam } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchStockBrokers } from 'state/dictionaries/thunk/fetchBrokers.thunk';
import { AppReduxState } from 'state/state';
import { submitBrokerInfo } from 'state/stock/thunk/submitBrokerInfo.thunk';
import updateBrokerInfo from 'state/stock/updateBrokerInfo';
import changeStep from 'state/workflow/changeStep';
import { BrokerInfo } from 'types/broker';
import { DonationWorkflowType, StockDonationWorkflowStep } from 'types/workflow';
import { brokerInformationIsValid } from 'utils/broker';
import componentSwitch from 'utils/componentSwitch';
import { pushDataLayerEvent } from 'utils/gtm';
import GTMEventType from 'utils/gtm/types';
import eventDataFactory from 'utils/gtm/util';

import BrokerInfoScreen from './brokerInfo';
import OverlayBrokerInfoScreen from './overlayVariant/brokerInfo';

const mapStateToProps: MapStateToPropsParam<any, any, AppReduxState> = (state) => {
  const { brokers } = state.dictionaries.brokers;

  const {
    brokerName,
    brokerageAccountNumber,
    brokerContactName,
    brokerEmail,
    brokerPhone,
    customBrokerName,
    submitting: isSubmitting,
    error,
  } = state.stock;
  const brokerInfo = {
    brokerName,
    brokerageAccountNumber,
    brokerContactName,
    brokerEmail,
    brokerPhone,
    customBrokerName,
  };

  const isDisabledSubmit = !brokerInformationIsValid(brokerInfo);
  const { donationUuid } = state.donation.stocks;
  const GTMEventData = eventDataFactory(GTMEventType.BrokerInfo, DonationWorkflowType.Stock, { state });

  return {
    GTMEventData,
    brokers,
    brokerInfo,
    donationUuid,
    isSubmitting,
    isDisabledSubmit,
    error,
  };
};

const mapDispatchToProps: MapDispatchToPropsParam<any, any> = (dispatch: Dispatch<any>) => ({
  goBack: () => dispatch(changeStep.createAction(StockDonationWorkflowStep.FillDonorInfo)),
  updateBrokerInfo: (changes: Partial<BrokerInfo>) => dispatch(updateBrokerInfo.createAction(changes)),
  fetchStockBrokers: () => dispatch(fetchStockBrokers()),
  submitBrokerInfo: (donationUuid: string, brokerInfo: BrokerInfo) => dispatch(submitBrokerInfo(donationUuid, brokerInfo)),
});

const mergeProps = (stateProps, displatchProps) => ({
  ...stateProps,
  ...displatchProps,
  submitBrokerInfo: () => {
    pushDataLayerEvent(stateProps.GTMEventData);
    return displatchProps.submitBrokerInfo(stateProps.donationUuid, stateProps.brokerInfo);
  },
});

export const BrokerInfoConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(componentSwitch(BrokerInfoScreen, OverlayBrokerInfoScreen));

export default BrokerInfoConnect;
