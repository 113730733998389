import { MINIMAL_USD_VALUES } from 'config/workflow';

export const LABELS = {
  DONATE: 'DONATE',
  PREVIOUS: 'PREVIOUS',
  ASSET_SYMBOL_LABEL: 'Stock Ticker Symbol',
  ASSET_SYMBOL_PLACEHOLDER: 'Enter Stock Ticker',
  ASSET_DESCRIPTION_LABEL: 'Stock Name',
  ASSET_DESCRIPTION_PLACEHOLDER: 'Enter Stock Name',
  QUANTITY_LABEL: 'Quantity',
  SHARES: 'Shares',
  SHARE: 'Share',
  FUNDS_DESIGNATIONS_TITLE: 'Select Impact Area',
  STOCK_DONATION: 'Stock Donation',
  MINIMAL_USD_VALUE_ERROR: `Minimum value for stock donations is $${MINIMAL_USD_VALUES.STOCK}`,
  NOTES_CHECKBOX_LABEL: 'Add a note',
  TRIBUTE_CHECKBOX_LABEL: 'Dedicate this donation',
  EXCHANGE_RATE_UNAVAILABLE: [
    'Price unavailable for approximate donation value. ',
    'You can continue to make your donation using quantity, please note minimum ',
    'value for stock donations is $500.',
  ].join(''),
};

export const KEYS = {
  ARE_NOTES_ENABLED: 'areNotesEnabled',
  ARE_TRIBUTES_ENABLED: 'areTributesEnabled',
  ASSET: 'asset',
  QUANTITY: 'quantity',
  FUNDS_DESIGNATION: 'fundsDesignation',
  TOOLTIP_TOUCH_DELAY: 50,
};

export default LABELS;
