import React, { useMemo } from 'react';

import { DonorInfoConnect } from 'pages/ach/donorInfo/donorInfo.connect';
import { MandateConnect } from 'pages/ach/mandate/mandate.connect';
import { PlaidConnect } from 'pages/ach/plaid/plaid.connect';
import { DonationSuccessConnect } from 'pages/common/donationSuccess/donationSuccess.connect';
import { ErrorConnect } from 'pages/common/error/ach/error.connect';
import { NotesConnect } from 'pages/common/notes/notes.connect';
import { PledgeConnect } from 'pages/common/pledge/pledge.connect';
import { AchDonationWorkflowStep } from 'types/workflow';

const componentMap = {
  [AchDonationWorkflowStep.Notes]: NotesConnect,
  [AchDonationWorkflowStep.Pledge]: PledgeConnect,
  [AchDonationWorkflowStep.FillDonorInfo]: DonorInfoConnect,
  [AchDonationWorkflowStep.Mandate]: MandateConnect,
  [AchDonationWorkflowStep.Plaid]: PlaidConnect,
  [AchDonationWorkflowStep.Error]: ErrorConnect,
  [AchDonationWorkflowStep.Success]: DonationSuccessConnect,
};

interface AchRouterProps {
  step: AchDonationWorkflowStep;
}

export const AchRouter = ({ step }: AchRouterProps) => {
  const Component = useMemo(() => componentMap[step], [step]);

  return (
    <Component />
  );
};

export default AchRouter;
