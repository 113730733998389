export const BASIC_CURRENCIES = {
  BTC: 'btc',
  ETH: 'eth',
  USD: 'USD',
} as const;

export const CRYPTO_CURRENIES = {
  BTC: 'btc',
  ETH: 'eth',
  USDC: 'usdc',
  CUBE: 'cube',
  CVC: 'cvc',
  GALA: 'gala',
  GRT: 'grt',
  GUSD: 'gusd',
  QRDO: 'qrdo',
  STORJ: 'storj',
  USDT: 'usdt',
  SLP: 'slp',
  XRP: 'xrp',
};
