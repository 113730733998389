import { Dispatch } from 'redux';

import { API, Endpoints } from 'api';
import ValidationError from 'api/errors/validationError';
import CryptoDonationStatus from 'types/crypto';

import fetchCryptoDonationStatusFail from '../fetchCryptoDonationStatus.fail';
import fetchCryptoDonationStatusStart from '../fetchCryptoDonationStatus.start';
import fetchCryptoDonationStatusSuccess from '../fetchCryptoDonationStatus.success';

export const DEFAULT_ERROR_MESSGAE = 'Something went wrong. Please try again later.';

export const fetchCryptoDonationStatus = () => async (dispatch: Dispatch<any>, getState) => {
  dispatch(fetchCryptoDonationStatusStart.createAction());

  try {
    const state = getState();
    const donationUuid = state.donation.crypto?.donationUuid;
    const response = await API.get(Endpoints.crypto.cryptoDonationStatus(donationUuid));

    if (response?.data) {
      const donationStatus = response?.data?.status;
      const donationReceived = donationStatus === CryptoDonationStatus.Synced;
      dispatch(fetchCryptoDonationStatusSuccess.createAction(donationReceived));
      return donationReceived;
    }

    throw DEFAULT_ERROR_MESSGAE;
  } catch (error: any) {
    const { message, details } = error;
    if (error instanceof ValidationError) {
      dispatch(fetchCryptoDonationStatusFail.createAction({
        errorMessage: message,
      }));
      const donationReceived = false;
      return donationReceived;
    }

    const errorMessage = details?.errorMessage || message || DEFAULT_ERROR_MESSGAE;
    dispatch(fetchCryptoDonationStatusFail.createAction({
      errorMessage,
    }));
    const donationReceived = false;
    return donationReceived;
  }
};

export default fetchCryptoDonationStatus;
