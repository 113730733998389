import { AnyAction } from 'state/state.types';
import { ChariotSession } from 'types/chariot';

import { ChariotReduxState } from './state';

export const actionTypeName = 'CHARIOT__SET_SESSION';

type ActionSetChariotSession = AnyAction & {
  session: ChariotSession;
}

export const createAction = (session: ChariotSession) => ({
  type: actionTypeName,
  session,
});

export const reducer = (
  state: ChariotReduxState,
  { session }: ActionSetChariotSession,
): ChariotReduxState => ({
  ...state,
  donation: {
    ...state.donation,
    session,
  },
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
