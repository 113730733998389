import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

const useStyles = makeStyles()({
  iconButton: {
    width: 40,
    height: 40,
    boxSizing: 'border-box',
    borderRadius: 5,
    boxShadow: 'none',
  },
  primaryButton: {
    backgroundColor: COLORS.PRIMARY,
    border: `1px solid ${COLORS.PRIMARY}`,
    color: COLORS.SECONDARY,
    '&:hover': {
      backgroundColor: COLORS.SECONDARY,
      border: `1px solid ${COLORS.SECONDARY}`,
      color: '#fff',
    },
  },
  secondaryButton: {
    border: `1px solid ${COLORS.INPUT.BORDER}`,
    color: COLORS.SECONDARY,
    backgroundColor: '#fff',
    '&:hover': {
      color: COLORS.SECONDARY,
      backgroundColor: COLORS.PRIMARY,
      border: `1px solid ${COLORS.PRIMARY}`,
    },
  },
  defaultButton: {
    border: `2px solid ${COLORS.SECONDARY}`,
    color: COLORS.SECONDARY,
    backgroundColor: '#fff',
    '&:hover': {
      color: '#fff',
      backgroundColor: COLORS.SECONDARY,
      border: `2px solid ${COLORS.INPUT.BORDER}`,
    },
  },
  disabledButton: {
    border: '1px solid transparent',
    background: 'linear-gradient(180deg, #F0F4F8 0%, rgba(240, 244, 248, 0) 100%) !important',
    color: `${COLORS.SECONDARY} !important`,
  },
});

export default useStyles;
