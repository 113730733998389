export const environmentConfig = {
  isPCDEnabled: window?.donationFormConfig?.isPCDEnabled ?? false,
  scriptPrefix: window?.donationFormConfig?.scriptPrefix ?? '',
  supportDomain: window?.donationFormConfig?.supportDomain ?? '',
  twitterHandle: window?.donationFormConfig?.twitterHandle ?? '',
  marketingWebsite: window?.donationFormConfig?.marketingWebsite ?? '',
  projectOrganizationName: window?.donationFormConfig?.projectOrganizationName ?? '',
};

export default environmentConfig;
