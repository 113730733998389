import React, {
  useState,
} from 'react';

import { SearchableDropdownWithFetch } from 'components/inputs/searchableDropdown';
import { StockTickerSymbol } from 'types/pledge';

import KEYS from './keys';
import { fetchStockAssets } from './stockAssets.http';
import useStyles from './styles';

export interface StockNameInputProps {
  selected?: StockTickerSymbol;
  onChange: (selected: StockTickerSymbol[]) => void;
  disabled?: boolean;
}

const StockNameInput = ({
  selected,
  onChange,
  disabled = false,
}: StockNameInputProps) => {
  const { classes } = useStyles();
  const [items, setItems] = useState<StockTickerSymbol[]>([]);

  const fetchTickers = async ({ page, search }: { page: number; search: string }) => {
    const searchQuery = {
      name: search,
    };

    try {
      const data = await fetchStockAssets({ page, search: searchQuery });
      setItems(data.tickers);
      return data.tickers;
    } catch (e: any) {
      setItems([]);
      return [];
    }
  };

  const renderItem = (item: StockTickerSymbol) => (
    <div>
      <span className={classes.mainText}>{item.name}</span>
      <span className={classes.secondaryText}>{item.ticker}</span>
    </div>
  );

  const getOptionSelected = (option: StockTickerSymbol, value: StockTickerSymbol) => option.ticker === value.ticker;

  return (
    <SearchableDropdownWithFetch<StockTickerSymbol>
      onChange={onChange}
      selected={selected}
      getOptionLabel={item => item.name}
      items={items as StockTickerSymbol[]}
      disabled={disabled}
      fetchData={fetchTickers}
      pageSize={KEYS.PAGE_SIZE}
      ComboboxProps={{
        getOptionSelected,
        renderItem,
      }}
    />
  );
};

export default StockNameInput;
