import React, { useContext, useEffect } from 'react';
import { Container } from '@mui/material';

import { CaptchaContext } from 'components/captcha/captchaContext';
import Loading from 'components/loading';
import { FiatPaymentMethod } from 'types/card';

import { KEYS } from '../keys';
import { useStyles } from './styles';

interface CardDonationProps {
  isProcessing: boolean;
  method: FiatPaymentMethod;
  pay: (captchaToken: string | null) => void;
}

export const CardDonation = ({
  isProcessing,
  method,
  pay,
}: CardDonationProps) => {
  const { classes } = useStyles();
  const { execute } = useContext(CaptchaContext);

  useEffect(() => {
    verifyCaptchaAndProceed();
  }, []);

  const verifyCaptchaAndProceed = async () => {
    if (method === FiatPaymentMethod.ApplePay) {
      pay(null);
      return;
    }

    const captchaToken = await execute();

    pay(captchaToken);
  };

  if (isProcessing) {
    return (
      <Container className={classes.donationContainerWrapper}>
        <Loading text={KEYS.PROCESSING} />
      </Container>
    );
  }

  return (
    <Container className={classes.donationContainerWrapper}>
      <Loading text={KEYS.PROCESSING} />
    </Container>
  );
};

export default CardDonation;
