import qs from 'query-string';

import { BASIC_CURRENCIES } from 'constants/currencies';
import { StockTickerSymbol } from 'types/pledge';

import { AnyReduxState } from '../state.types';

export type PledgeReduxState = AnyReduxState & {
  asset?: StockTickerSymbol;
  quantity: number | null;
  currency: string;
  amount: number;
  usdAmount: number;
  exchangeRate: number;
  calculateFromFiat: boolean;
  cryptoDropdownTouched: boolean;
  fundsDesignation: string | null;
  coverTransactionFees: boolean;
  error: Record<string, string> | null;
  exchangeRateLoading: boolean;
  campaignId?: string;
  areNotesEnabled: boolean;
  areTributesEnabled: boolean;
  notes: string;
  honoreeName: string | null;
  honoreeEmail: string | null;
  notifyHonoree: boolean;
}

export const initialState = (coverTransactionFees: boolean = false): PledgeReduxState => {
  const { search } = window.location;
  const { campaignId: searchCampaignId } = qs.parse(search);
  const campaignId = searchCampaignId?.toString();

  return {
    asset: undefined,
    quantity: null,
    currency: BASIC_CURRENCIES.BTC,
    amount: 0.25,
    notes: '',
    exchangeRate: 0,
    usdAmount: 0,
    calculateFromFiat: false,
    cryptoDropdownTouched: false,
    error: null,
    exchangeRateLoading: false,
    fundsDesignation: null,
    coverTransactionFees,
    campaignId,
    areNotesEnabled: false,
    areTributesEnabled: false,
    honoreeEmail: null,
    honoreeName: null,
    notifyHonoree: false,
  };
};
