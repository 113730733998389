import React from 'react';

import { COLORS } from 'theme/colors';

import { IconProps } from './icon.types';

const path = [
  [
    'M5.99996 7.42017C5.7849 7.42017 5.56986 7.33805 5.40589 7.17416L0.246169',
    '2.01438C-0.0820565 1.68616 -0.0820565 1.154 0.246169 0.825904C0.574263',
    '0.497811 1.10632 0.497811 1.43457 0.825904L5.99996 5.39156L10.5654',
    '0.826063C10.8936 0.49797 11.4256 0.49797 11.7537 0.826063C12.0821 1.15416',
    '12.0821 1.68632 11.7537 2.01454L6.59403 7.17432C6.42998 7.33824 6.21495',
    '7.42017 5.99996 7.42017Z',
  ].join(' '),
];

export const IconChevron = ({
  width = 24,
  height = 14,
  color = COLORS.SECONDARY,
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {path.map(item => <path d={item} fill={color} />)}
  </svg>
);

export default IconChevron;
