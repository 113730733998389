import { AnyAction } from '../state.types';
import { DonationReduxState } from './state';

export const actionTypeName = 'WORKFLOW__SUBMIT_PLEDGE__START';

export type ActionSubmitPledgeStart = AnyAction & {
}

export const createAction = () => ({
  type: actionTypeName,
});

export const reducer = (
  state: DonationReduxState,
  action: ActionSubmitPledgeStart,
): DonationReduxState => ({
  ...state,
  isSubmitting: true,
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
