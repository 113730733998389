export const LABELS = {
  RIGHT_BUTTON_TEXT: 'Next',
  LEFT_BUTTON_TEXT: 'Previous',
  SUBMITTING_TEXT: 'Preparing Pledge',
  VIEW_LOADING: 'Processing...',
  PAGE_TITLE: 'Make a Donation',
  LIST_MARKER: '',
  INFO: 'Grant funds directly from your DAF to charity in just a few clicks!',
  MARKER_SYMBOL: '*',
};

export default LABELS;
