import { AnyAction } from '../state.types';
import { StockReduxState } from './state';

export const actionTypeName = 'STOCK__UPDATE_SIGNATURE';

export type ActionUpdateSignature = AnyAction & {
  signature: string;
}

export const createAction = (signature: string) => ({
  type: actionTypeName,
  signature,
});

export const reducer = (
  state: StockReduxState,
  action: ActionUpdateSignature,
): StockReduxState => ({
  ...state,
  signature: action.signature,
  error: {},
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
