import { connect, MapDispatchToPropsParam, MapStateToPropsParam } from 'react-redux';
import { Dispatch } from 'redux';

import { CRYPTO_CURRENIES } from 'constants/currencies';
import fetchCryptoDonationStatus from 'state/donation/thunk/fetchCryptoDonationStatus.thunk';
import { AppReduxState } from 'state/state';
import changeStep from 'state/workflow/changeStep';
import { CryptoDonationWorkflowStep, DonationWorkflowType } from 'types/workflow';
import componentSwitch from 'utils/componentSwitch';
import { pushDataLayerEvent } from 'utils/gtm';
import GTMEventType from 'utils/gtm/types';
import eventDataFactory from 'utils/gtm/util';

import { DonateScreen } from './donate';
import { DonateScreen as OverlayDonateScreen } from './overlayVariant/donate';

const mapStateToProps: MapStateToPropsParam<any, any, AppReduxState> = (state) => {
  const {
    metamaskInstalled,
  } = state.wallet.metamask;

  const {
    isSubmitting,
    crypto,
  } = state.donation;

  const {
    returnUrl,
  } = state.donationData;

  const {
    donationAddress,
    fallbackAddressUsed,
    destinationTag,
    qrValue,
    donationReceived,
  } = crypto;

  const {
    amount,
    currency,
  } = state.pledge;

  const {
    currencies,
  } = state.currencies;

  const donationSentEventData = eventDataFactory(GTMEventType.DonationSent, DonationWorkflowType.Crypto, { state });

  return {
    donationSentEventData,
    pledge: {
      amount,
      currency,
    },
    isSubmitting,
    donation: {
      donationAddress,
      fallbackAddressUsed,
      destinationTag,
      qrValue,
    },
    currencies,
    metamaskInstalled,
    isRipple: currency === CRYPTO_CURRENIES.XRP,
    returnUrl,
    donationReceived,
  };
};

const mapDispatchToProps: MapDispatchToPropsParam<any, any> = (dispatch: Dispatch<any>) => ({
  goBack: () => dispatch(changeStep.createAction(CryptoDonationWorkflowStep.FillDonorInfo)),
  resetWidget: () => window.location.reload(),
  fetchCryptoDonationStatus: () => dispatch(fetchCryptoDonationStatus()),
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onInit: () => {
    pushDataLayerEvent(stateProps.donationSentEventData);
  },
});

export const DonateConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(componentSwitch(DonateScreen, OverlayDonateScreen));

export default DonateConnect;
