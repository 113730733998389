import React from 'react';

import { IconProps } from './icon.types';

export const IconGenericCheck = ({
  width = 79,
  height = 66,
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 79 66"
    fill="none"
  >
    <path d="M8.25 34L31.25 57.5L70.75 8.5" stroke="black" strokeWidth="11" strokeLinecap="square" />
  </svg>
);

export default IconGenericCheck;
