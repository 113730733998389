import React from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { locators, QALocator } from 'utils/tests/QA';

import { LABELS } from '../pledge.keys';
import { FundsDesignationItemContent } from './fundsDesignationItemContent';
import { useStyles } from './style';
import { SelectOption } from './types';

export interface FundsDesignationsProps {
  onChange: (value: string) => void;
  options: SelectOption[];
  value: string;
}

export const FundsDesignations = ({
  onChange,
  options = [],
  value = '',
}: FundsDesignationsProps) => {
  const { classes } = useStyles();

  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value);
  };

  return (
    <FormControl fullWidth className={classes.fundsDesignationWrapper} size="small">
      <Select
        multiple={false}
        onChange={handleChange}
        value={value}
        displayEmpty
        variant="outlined"
        className={classes.root}
        {...QALocator(locators.page.common.components.fundsDesignations)}
      >
        <MenuItem value="">
          {LABELS.FUNDS_DESIGNATIONS_TITLE}
        </MenuItem>
        {options.map(option => (
          <MenuItem value={option.id} key={option.id}>
            <FundsDesignationItemContent option={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FundsDesignations;
