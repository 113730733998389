import { AnyReduxState } from '../state.types';

export type DonationDataReduxState = AnyReduxState & {
  returnUrl: string | null;
  externalId: string | null;
  defaultAmount: number | null;
  defaultCryptocurrency: string | null;
  personalInfoDefaults: {
    country?: string;
    countryDisabled?: boolean;
  } | null;
}

export const initialState = (): DonationDataReduxState => ({
  returnUrl: null,
  externalId: null,
  defaultAmount: null,
  defaultCryptocurrency: null,
  personalInfoDefaults: null,
});
