export const KEYS = {
  COVER_TRANSACTION_FEES: 'coverTransactionFees',
  ARE_NOTES_ENABLED: 'areNotesEnabled',
  ARE_TRIBUTES_ENABLED: 'areTributesEnabled',
};

export const LABELS = {
  COVER_TRANSACTION_FEES: 'Cover transaction fees',
  DONATION_AMOUNT_TITLE: 'Your Donation',
  ENABLE_NOTES: 'Add a note',
  ENABLE_TRIBUTE: 'Dedicate this donation',
  TRANSACTION_FEES_TOOLTIP: 'Make your impact go even further by covering the processing fees of this donation',
  DAF_LINKED: 'Your DAF account has been linked',
  RIGHT_BUTTON_TEXT: 'DONATE',
  LEFT_BUTTON_TEXT: 'PREVIOUS',
  PAGE_TITLE: 'Donation Options',
};
