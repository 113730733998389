import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

const appearAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const waitingAnimation = keyframes`
  20%, 80%{
    opacity: 1;
  }
  30%, 70% {
    opacity: 0.5;
  }
`;

const fadeOutAnimation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const useStyles = makeStyles()(theme => ({
  page: {
    padding: '30px 30px 45px 30px',
  },
  progressContainer: {
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 360,
  },
  pageContainer: {
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 300,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 120,
    marginBottom: 10,
  },
  animatedRow: {
    animation: `${waitingAnimation} 1.5s linear infinite`,
  },
  header: {
    fontSize: 25,
    fontFamily: 'Poppins',
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
  subHeader: {
    fontSize: 16,
    fontFamily: 'Noto Sans',
    color: theme.palette.text.primary,
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: 23,
  },
  message: {
    fontSize: 16,
    fontFamily: 'Poppins',
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
  icon: {
    marginBottom: 12,
  },
  successCheck: {
    opacity: 1,
    animation: `${appearAnimation} 1s ease-in`,
  },
  hidden: {
    display: 'none',
  },
  fadeOut: {
    animation: `${fadeOutAnimation} 0.5s ease-in`,
    opacity: 0,
  },
  successMessage: {
    marginTop: -24,
  },
  invisible: {
    opacity: 0,
  },
  fadeIn: {
    animation: `${fadeInAnimation} 1s ease-in`,
    opacity: 1,
  },
  readyButton: {
    height: 50,
    fontSize: 16,
    fontWeight: 500,
    borderRadius: `${theme.shape.borderRadius} !important`,
    color: `${theme.palette.primary.main} !important`,
    lineHeight: '24px',
    textTransform: 'none !important' as 'none',
    backgroundColor: `${theme.palette.secondary.main} !important`,
    boxShadow: 'none !important',
    paddingLeft: 0,
    paddingRight: 0,
    width: 370,
  },
  actionButton: {
    borderRadius: theme.shape.borderRadius,
    color: `${theme.palette.primary.main} !important`,
    backgroundColor: `${theme.palette.secondary.main} !important`,
  },
}));

export default useStyles;
