import { AnyAction } from '../state.types';
import { StockReduxState } from './state';

export const actionTypeName = 'STOCK__SIGN_DONATION_PLEDGE__FAIL';

export type ActionSignDonationPledgeFail = AnyAction & {
  error: Record<string, string>;
};

export const createAction = (error: Record<string, string>): ActionSignDonationPledgeFail => ({
  type: actionTypeName,
  error,
});

export const reducer = (
  state: StockReduxState,
  action: ActionSignDonationPledgeFail,
): StockReduxState => ({
  ...state,
  submitting: false,
  error: action.error,
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
