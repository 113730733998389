import { AnyAction } from '../state.types';
import { DonationReduxState } from './state';

export const actionTypeName = 'WORKFLOW__SUBMIT_STOCKS_DONATION_PLEDGE__START';

export type ActionSubmitStockDonationPledgeStart = AnyAction & {
}

export const createAction = (): ActionSubmitStockDonationPledgeStart => ({
  type: actionTypeName,
});

export const reducer = (
  state: DonationReduxState,
): DonationReduxState => ({
  ...state,
  isSubmitting: true,
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
