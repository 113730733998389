import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import updatePledge from 'state/pledge/updatePledge';
import { AppReduxState } from 'state/state';
import changeStep from 'state/workflow/changeStep';
import setType from 'state/workflow/setType';
import { DonationPledge } from 'types/pledge';
import DonationWorkflowType, {
  AchDonationWorkflowStep,
  CreditCardDonationWorkflowStep,
  CryptoDonationWorkflowStep,
  DafDonationWorkflowStep,
  StockDonationWorkflowStep,
  WorkflowStep,
} from 'types/workflow';
import componentSwitch from 'utils/componentSwitch';

import Notes from './notes';
import OverlayNotes from './overlayVariant/notes';

const mapStateToProps = (state: AppReduxState) => {
  const { pledge } = state;
  const {
    notes,
    areTributesEnabled,
    honoreeName,
    honoreeEmail,
    notifyHonoree,
    usdAmount,
    coverTransactionFees,
  } = pledge;

  const { step, type } = state.workflow;

  return ({
    notes,
    honoreeName,
    honoreeEmail,
    notifyHonoree,
    usdAmount,
    step,
    type,
    isTributeNote: areTributesEnabled,
    areFeesCovered: coverTransactionFees,
    pledge,
  });
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  resetFlow: () => dispatch(setType.createAction(null)),
  changeStep: (step: WorkflowStep) => dispatch(changeStep.createAction(step)),
  updatePledgeNotes: (changes: Partial<DonationPledge>) => dispatch(updatePledge.createAction(changes)),
});

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
) => ({
  ...stateProps,
  ...dispatchProps,
  proceed: async () => {
    if (stateProps.type === null) {
      return;
    }

    const workflowType = stateProps.type;

    const nextStepMap = {
      [DonationWorkflowType.Crypto]: CryptoDonationWorkflowStep.FillDonorInfo,
      [DonationWorkflowType.CreditCard]: CreditCardDonationWorkflowStep.FillDonorInfo,
      [DonationWorkflowType.Daf]: DafDonationWorkflowStep.Success,
      [DonationWorkflowType.Stock]: StockDonationWorkflowStep.FillDonorInfo,
      [DonationWorkflowType.Ach]: AchDonationWorkflowStep.FillDonorInfo,
    };

    const nextStep = nextStepMap[workflowType];

    dispatchProps.changeStep(nextStep);
  },
  goBack: () => {
    if (stateProps.type === null) {
      return;
    }

    const workflowType = stateProps.type;

    const previousStepMap = {
      [DonationWorkflowType.Crypto]: CryptoDonationWorkflowStep.Pledge,
      [DonationWorkflowType.CreditCard]: CreditCardDonationWorkflowStep.Pledge,
      [DonationWorkflowType.Daf]: DafDonationWorkflowStep.Donate,
      [DonationWorkflowType.Stock]: StockDonationWorkflowStep.Pledge,
      [DonationWorkflowType.Ach]: AchDonationWorkflowStep.Pledge,
    };

    const previousStep = previousStepMap[workflowType];

    dispatchProps.changeStep(previousStep);
  },
});

export const NotesConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(componentSwitch(Notes, OverlayNotes));

export default NotesConnect;
