import { AnyAction, Reducer } from '../state.types';
import { DonationReduxState } from './state';

export const actionTypeName = 'DONATION__CREATE_PAYMENT_INTENT_AND_SUBMIT_PLEDGE__START';

export type ActionCreatePaymentIntentAndSubmitPledgeStart = AnyAction;

export const createAction = (): ActionCreatePaymentIntentAndSubmitPledgeStart => ({
  type: actionTypeName,
});

export const reducer: Reducer<DonationReduxState, ActionCreatePaymentIntentAndSubmitPledgeStart> = (
  state: DonationReduxState,
): DonationReduxState => {
  const nextState = {
    ...state,
    isSubmitting: true,
  };

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
