import React, { useCallback, useState } from 'react';
import InputMask from 'react-input-mask';
import {
  FormControl, FormHelperText,
  TextField,
} from '@mui/material';
import { useField } from 'formik';

import { FormFieldDescription } from 'pages/creditCard/cardDetails/overlayVariant/fields/fields.types';
import { useStyles } from 'pages/creditCard/cardDetails/overlayVariant/fields/styles';

interface MaskedTextFieldProps {
  item: FormFieldDescription;
  required: boolean;
  disabled: boolean;
  isSubmitting: boolean;
  name: string;
  className: string;
  setFieldValue: (field: string, value: any) => void;
  errorText?: string;
}

export const MaskedTextField = ({
  item,
  disabled,
  isSubmitting,
  setFieldValue,
  errorText,
  ...props
}: MaskedTextFieldProps) => {
  const { classes } = useStyles();
  const [field, meta] = useField(props);
  const [visibleValue, setVisibleValue] = useState<string>('');
  const hasMetaError = Boolean((isSubmitting || meta.touched) && meta.error);
  const hasError = !disabled && (hasMetaError || Boolean(errorText));
  const errorCode: string = meta.error || '';

  const handleChange = useCallback(({ target }) => {
    const maskHideSymbol = item?.maskHideSymbol;

    if (maskHideSymbol) {
      const trimmedValue = target.value.replace(/\s/g, '');
      const nextVisibleValue = maskHideSymbol.repeat(trimmedValue.length);
      setVisibleValue(nextVisibleValue);

      const previousValue = field.value;
      const begginingOfValue = previousValue.substring(0, trimmedValue.length);
      const endOfValue = trimmedValue.substring(
        trimmedValue.length - (trimmedValue.length - previousValue.length),
        trimmedValue.length,
      );

      const nextFieldValue = `${begginingOfValue}${endOfValue}`;
      setFieldValue(item.name, nextFieldValue);
      return;
    }

    setVisibleValue(target.value);
    setFieldValue(item.name, target.value);
  }, [field.value, item]);

  return (
    <div className={classes.textFieldContainer}>
      <InputMask
        {...props}
        {...field}
        alwaysShowMask
        maskChar={null}
        mask={item?.mask}
        formatChars={item?.formatChars}
        disabled={disabled}
        value={visibleValue}
        onChange={handleChange}
      >
        {() => (
          <FormControl fullWidth>
            <TextField
              className={classes.defaultInput}
              placeholder={item.placeholder}
              name={item.name}
              title={item.title}
              margin="dense"
              error={hasError}
              helperText={errorText}
              fullWidth
              variant="outlined"
            />
            <FormHelperText>{errorCode}</FormHelperText>
          </FormControl>
        )}
      </InputMask>
    </div>
  );
};

export default MaskedTextField;
