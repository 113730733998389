import checkIsFiatDonationType from 'utils/workflow';

import { DonationWorkflowType } from '../types/workflow';
import { PoweredByBrand, PoweredByBrandType } from './types';

export interface GetBrandParams {
  workflowType: DonationWorkflowType;
  isPCDEnabled: boolean;
}

export const getBrand = ({
  workflowType,
  isPCDEnabled,
}: GetBrandParams): PoweredByBrandType => {
  if (isPCDEnabled) {
    return PoweredByBrand.Gemini;
  }

  const isFiatDonation = checkIsFiatDonationType(workflowType);

  if (isFiatDonation) {
    return PoweredByBrand.Card;
  }

  return PoweredByBrand.Crypto;
};

export default getBrand;
