import React from 'react';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import { useField } from 'formik';

import { SetFieldValueFunction } from '../types';
import { KEYS } from './fields.keys';
import { FormFieldDescription } from './fields.types';
import useStyles from './styles';

interface CustomTextFieldProps {
  item: FormFieldDescription;
  required: boolean;
  disabled: boolean;
  isSubmitting: boolean;
  name: string;
  className: string;
  setFieldValue: SetFieldValueFunction;
  hasPledgeError?: boolean;
}

export const CustomTextField = ({
  item,
  disabled,
  isSubmitting,
  setFieldValue,
  hasPledgeError,
  ...props
}: CustomTextFieldProps) => {
  const { classes } = useStyles({});
  const [field, meta] = useField(props);
  const serverSideError = Boolean(meta.error && hasPledgeError);
  const clientSideError = Boolean(((isSubmitting || meta.touched) && meta.error));
  const hasError = !disabled && (clientSideError || serverSideError);
  const shouldShowError = !disabled && hasError;
  const errorCode: string = meta.error || '';
  const label = `${item.label}${item.isRequired ? KEYS.REQUIRED_ASTERISK : ''}`;

  return (
    <FormControl fullWidth>
      <TextField
        {...field}
        {...props}
        placeholder={label}
        type={item.type}
        name={item.name}
        title={item.title}
        margin="dense"
        error={shouldShowError}
        disabled={disabled}
        fullWidth
        variant="outlined"
        onChange={({ target }) => setFieldValue(item.name, target.value)}
      />
      <FormHelperText className={classes.errorHelperText}>{shouldShowError && errorCode}</FormHelperText>
    </FormControl>
  );
};

export default CustomTextField;
