import { AnyAction } from '../state.types';
import { StockReduxState } from './state';

export const actionTypeName = 'STOCK__SIGN_DONATION_PLEDGE__START';

export type ActionSignDonationPledgeStart = AnyAction;

export const createAction = () => ({
  type: actionTypeName,
});

export const reducer = (
  state: StockReduxState,
): StockReduxState => ({
  ...state,
  submitting: true,
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
