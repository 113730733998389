import { AppReduxState } from 'state/state';
import DonationWorkflowType from 'types/workflow';

import KEYS from './keys';
import GTMEventType from './types';

export const taxReceiptPayloadFactory = (
  flow: DonationWorkflowType,
  { email }: { state: AppReduxState } & Record<string, any>,
) => {
  const canHaveReceipt = [DonationWorkflowType.CreditCard, DonationWorkflowType.Crypto].includes(flow);
  if (!canHaveReceipt) {
    return {};
  }

  const receiptRequested = email ? KEYS.YES : KEYS.NO;

  return {
    event: GTMEventType.TaxReceipt,
    donationType: flow,
    receiptRequested,
  };
};

export default taxReceiptPayloadFactory;
