import { BASIC_CURRENCIES } from 'constants/currencies';
import { CardPaymentToken, Shift4Utils } from 'types/card';

import { GetCardTokenParams } from '../getCardToken.thunk';

const verify3DS = async (
  shift4: Shift4Utils,
  token: CardPaymentToken,
  donationAmount: number,
) => {
  const threeDSecureToken = await shift4.verifyThreeDSecure({
    // Charge amount in minor units of a given currency. For example 10€ is represented as "1000" and 10¥ is represented as "10".
    // https://dev.shift4.com/docs/api#charge-object
    amount: Math.round(donationAmount * 100),
    currency: BASIC_CURRENCIES.USD,
    card: token.id,
  });

  return threeDSecureToken;
};

export const payWithCard = async ({
  shift4,
  donationAmount,
  components,
  additionalFields,
}: GetCardTokenParams) => {
  const token = await shift4.createToken(components, additionalFields);
  if (token.error) {
    return token;
  }

  const threeDSVerifiedToken = await verify3DS(shift4, token, donationAmount);

  return threeDSVerifiedToken;
};

export default payWithCard;
