import React from 'react';

import { COLORS } from 'theme/colors';

import { IconProps } from './icon.types';

const path = [
  [
    'M10.8327 16.6667C11.9373 16.6654 12.9964 16.2259 13.7775 15.4448C14.5586 14.6637 14.998',
    '13.6047 14.9993 12.5V5.20251C15.0006 4.76448 14.915 4.33055 14.7473 3.92586C14.5797 3.52118',
    '14.3334 3.1538 14.0227 2.84501L12.1543 0.976681C11.8456 0.665993 11.4782 0.419684 11.0735',
    '0.252031C10.6688 0.0843791 10.2349 -0.00128163 9.79685 1.44913e-05H5.83268C4.72802 0.00133771',
    '3.66898 0.440749 2.88787 1.22186C2.10675 2.00298 1.66734 3.06202 1.66602 4.16668V12.5C1.66734',
    '13.6047 2.10675 14.6637 2.88787 15.4448C3.66898 16.2259 4.72802 16.6654 5.83268',
    '16.6667H10.8327ZM3.33268 12.5V4.16668C3.33268 3.50364 3.59607 2.86776 4.06492 2.39891C4.53376',
    '1.93007 5.16964 1.66668 5.83268 1.66668C5.83268 1.66668 9.93185 1.67835 9.99935',
    '1.68668V3.33335C9.99935 3.77538 10.1749 4.1993 10.4875 4.51186C10.8001 4.82442 11.224 5.00001',
    '11.666 5.00001H13.3127C13.321 5.06751 13.3327 12.5 13.3327 12.5C13.3327 13.1631 13.0693',
    '13.7989 12.6004 14.2678C12.1316 14.7366 11.4957 15 10.8327 15H5.83268C5.16964 15 4.53376',
    '14.7366 4.06492 14.2678C3.59607 13.7989 3.33268 13.1631 3.33268 12.5ZM18.3327',
    '6.66668V15.8333C18.3314 16.938 17.8919 17.9971 17.1108 18.7782C16.3297 19.5593 15.2707',
    '19.9987 14.166 20H6.66602C6.445 20 6.23304 19.9122 6.07676 19.7559C5.92048 19.5997 5.83268',
    '19.3877 5.83268 19.1667C5.83268 18.9457 5.92048 18.7337 6.07676 18.5774C6.23304 18.4211 6.445',
    '18.3333 6.66602 18.3333H14.166C14.8291 18.3333 15.4649 18.07 15.9338 17.6011C16.4026 17.1323',
    '16.666 16.4964 16.666 15.8333V6.66668C16.666 6.44567 16.7538 6.23371 16.9101 6.07743C17.0664',
    '5.92115 17.2783 5.83335 17.4993 5.83335C17.7204 5.83335 17.9323 5.92115 18.0886 6.07743C18.2449',
    '6.23371 18.3327 6.44567 18.3327 6.66668Z',
  ].join(' '),
];

export const IconCopy = ({
  width = 24,
  height = 24,
  color = COLORS.GREY,
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {path.map(item => <path d={item} fill={color} />)}
  </svg>
);

export default IconCopy;
