import { AnyAction } from '../state.types';
import { StockReduxState } from './state';

export const actionTypeName = 'STOCK__SIGN_DONATION_PLEDGE__SUCCESS';

export type ActionSignDonationPledgeSuccess = AnyAction;

export const createAction = () => ({
  type: actionTypeName,
});

export const reducer = (
  state: StockReduxState,
): StockReduxState => ({
  ...state,
  submitting: false,
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
