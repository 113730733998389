import React, { useCallback } from 'react';
import {
  Error as ErrorIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import {
  InputAdornment,
  TextField,
} from '@mui/material';
import { useField } from 'formik';

import OptionallyVisible from 'components/optionallyVisible';
import { Broker } from 'types/broker';

import { useStyles } from '../styles';
import { FormFieldDescription } from './field.types';

const InvalidStateIcons = {
  Required: <ErrorIcon color="error" fontSize="small" />,
  Invalid: <WarningIcon color="error" fontSize="small" />,
};

export interface CustomTextFieldProps {
  brokers: Broker[];
  item: FormFieldDescription;
  required: boolean;
  disabled: boolean;
  isSubmitting: boolean;
  name: string;
  setFieldValue: (field: string, value: any) => void;
  errorText?: string;
}

export const CustomTextField = ({
  item,
  disabled,
  isSubmitting,
  setFieldValue,
  errorText,
  ...props
}: CustomTextFieldProps) => {
  const { classes } = useStyles();
  const [field, meta] = useField(props);
  const hasMetaError = Boolean((isSubmitting || meta.touched) && meta.error);
  const hasError = !disabled && (hasMetaError || Boolean(errorText));
  const errorCode: string = meta.error || '';

  const handleChange = useCallback(({ target }) => setFieldValue(item.name, target.value), [item]);

  const additionalInputProps = {
    InputProps: {
      endAdornment: (
        <OptionallyVisible visible={hasError}>
          <InputAdornment position="end">
            {InvalidStateIcons[errorCode]}
          </InputAdornment>
        </OptionallyVisible>
      ),
      className: classes.mainInput,
    },
    inputProps: item.maxLength ? {
      maxLength: item.maxLength,
    } : undefined,
  };

  const textFieldPassedProps = {
    ...props,
    ...additionalInputProps,
  };

  return (
    <div className={classes.inputWrapper}>
      <TextField
        {...field}
        {...textFieldPassedProps}
        label={item.label}
        className={classes.input}
        onChange={handleChange}
        name={item.name}
        title={item?.title || errorText}
        type={item.type}
        margin="dense"
        variant="outlined"
        error={hasError}
        disabled={disabled}
      />
    </div>
  );
};

export default CustomTextField;
