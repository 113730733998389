import { USDFormatter } from 'utils/currency';

export const KEYS = {
  COVER_TRANSACTION_FEES: 'coverTransactionFees',
  ARE_NOTES_ENABLED: 'areNotesEnabled',
  ARE_TRIBUTES_ENABLED: 'areTributesEnabled',
};

export const LABELS = {
  COVER_TRANSACTION_FEES: 'Cover transaction fees',
  ENABLE_NOTES: 'Add a note to your donation',
  ENABLE_TRIBUTE: 'Dedicate this donation to a friend or loved one',
  TRANSACTION_FEES_TOOLTIP: 'Make your impact go even further by covering the processing fees of this donation',
  DAF_LINKED: 'Your DAF account has been linked',
  PAGE_TITLE: 'Donation Options',
};

export const donateLabel = (amount: number) => {
  const formattedAmount = USDFormatter().format(amount);

  return `Donate ${formattedAmount}`;
};
