import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

export const useStyles = makeStyles()(theme => ({
  mainText: {
    marginRight: '0.5rem',
  },
  secondaryText: {
    color: COLORS.GREY,
    fontSize: 13,
  },
}));

export default useStyles;
