import React, {
  ChangeEvent,
  FocusEvent,
  useEffect,
  useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Input, Tooltip } from '@mui/material';

import OptionallyVisible from 'components/optionallyVisible';
import { BASIC_CURRENCIES } from 'constants/currencies';
import NumberFormatCustom from 'pages/common/pledge/numberFormatCustom';
import { StocksDonationPledge } from 'types/pledge';
import { toDecimalFormat, USDFormatter } from 'utils/currency';
import { locators, QALocator } from 'utils/tests/QA';

import { KEYS, LABELS } from '../../stockDonationPledge.keys';
import useStyles from './styles';

export interface StockCurrencyAmountBoxProps {
  onAmountChange: (value: number) => void;
  pledge: StocksDonationPledge;
  error?: string;
  isExchangeRateUnavailable: boolean;
}

export const StockCurrencyAmountBox = ({
  onAmountChange,
  pledge,
  error = '',
  isExchangeRateUnavailable,
}: StockCurrencyAmountBoxProps) => {
  const { classes, cx } = useStyles();
  const formattedValue = pledge.quantity ? toDecimalFormat(pledge.quantity, 0) : '';
  const [inputValue, setInputValue] = useState<string>(formattedValue);
  const [focused, setFocused] = useState<boolean>(false);

  const handleUpdateAmount = (value: string) => {
    setInputValue(value);
  };

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const processedValue = value.replace(/[^0-9-]/ig, '');
    onAmountChange(Number(processedValue));
    handleUpdateAmount(processedValue);
  };

  const handleInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onAmountChange(Number(value));
    setInputValue(formattedValue);
    setFocused(false);
  };

  const handleInputFocus = (event: FocusEvent<HTMLInputElement>) => {
    setFocused(true);
  };

  useEffect(() => {
    setInputValue(formattedValue);
  }, [pledge]);

  const changingCurrency = pledge.quantity === 1 ? LABELS.SHARE : LABELS.SHARES;
  const calculatedCurrency = BASIC_CURRENCIES.USD;
  const subPriceInputVisible = Boolean(pledge.exchangeRate);
  const subPrice = `~${USDFormatter(2).format(pledge.usdAmount || 0)}`;

  return (
    <div className={classes.inputWrapper}>
      <h6 className={classes.fieldHeader}>{LABELS.QUANTITY_LABEL}</h6>
      <Tooltip
        classes={{
          arrow: classes.tooltipArrow,
          tooltip: classes.tooltip,
        }}
        open={Boolean(error && !focused)}
        arrow
        disableTouchListener
        placement="bottom-start"
        title={error}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
          className={cx({
            [classes.amountBox]: true,
            error: Boolean(error),
          })}
        >
          <Box>
            <Input
              className={classes.input}
              disableUnderline
              inputProps={{ className: classes.mainCurrencyInput }}
              value={inputValue}
              onChange={handleAmountChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              inputComponent={NumberFormatCustom}
              {...QALocator(locators.components.stock.amountInput)}
            />
            <OptionallyVisible
              visible={subPriceInputVisible}
            >
              <div>
                <div className={classes.subPrice}>
                  {subPrice}
                  &nbsp;
                </div>
              </div>
            </OptionallyVisible>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className={classes.swapBox}
          >
            <div className={classes.activeCurrency}>
              {changingCurrency}
              <OptionallyVisible visible={isExchangeRateUnavailable}>
                <Tooltip
                  title={LABELS.EXCHANGE_RATE_UNAVAILABLE}
                  classes={{
                    tooltip: classes.donationTooltip,
                  }}
                  enterTouchDelay={KEYS.TOOLTIP_TOUCH_DELAY}
                >
                  <span className={classes.iconEyeTooltip}>
                    <FontAwesomeIcon icon="exclamation-circle" />
                  </span>
                </Tooltip>
              </OptionallyVisible>
            </div>
            <OptionallyVisible visible={Boolean(pledge.exchangeRate)}>
              <>
                <span
                  role="button"
                  tabIndex={0}
                  className={classes.iconExchangeWrapper}
                >
                  <FontAwesomeIcon icon={['fas', 'exchange-alt']} className={classes.iconExchange} />
                </span>
                <div className={classes.calculatedCurrency}>
                  {calculatedCurrency}
                </div>
              </>
            </OptionallyVisible>
          </Box>
        </Box>
      </Tooltip>
    </div>
  );
};

export default StockCurrencyAmountBox;
