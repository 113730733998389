import React, { useCallback } from 'react';
import {
  FormControl, FormHelperText,
  TextField,
} from '@mui/material';
import { useField } from 'formik';

import { FormFieldDescription } from 'pages/creditCard/cardDetails/overlayVariant/fields/fields.types';
import { useStyles } from 'pages/creditCard/cardDetails/overlayVariant/fields/styles';

interface CustomTextFieldProps {
  item: FormFieldDescription;
  required: boolean;
  disabled: boolean;
  isSubmitting: boolean;
  name: string;
  className: string;
  setFieldValue: (field: string, value: any) => void;
  errorText?: string;
}

export const CustomTextField = ({
  item,
  disabled,
  isSubmitting,
  setFieldValue,
  errorText,
  ...props
}: CustomTextFieldProps) => {
  const { classes } = useStyles();
  const [field, meta] = useField(props);
  const hasMetaError = Boolean((isSubmitting || meta.touched) && meta.error);
  const hasError = !disabled && (hasMetaError || Boolean(errorText));
  const errorCode: string = meta.error || '';

  const handleChange = useCallback(({ target }) => setFieldValue(item.name, target.value), [item]);

  return (
    <div className={classes.textFieldContainer}>
      <FormControl fullWidth>
        <TextField
          {...field}
          {...props}
          placeholder={item.placeholder}
          name={item.name}
          title={item.title}
          margin="dense"
          error={hasError}
          helperText={errorText}
          disabled={disabled}
          fullWidth
          variant="outlined"
          onChange={handleChange}
        />
        <FormHelperText>{errorCode}</FormHelperText>
      </FormControl>
    </div>
  );
};

export default CustomTextField;
