import { AnyAction } from '../state.types';
import { OrganizationReduxState } from './state';

export const actionTypeName = 'ORGANIZATION__FETCH_ORGANIZATION_INFO__START';

export type ActionFetchOrganizationInfoStart = AnyAction & {
}

export const createAction = () => ({
  type: actionTypeName,
});

export const reducer = (
  state: OrganizationReduxState,
  action: ActionFetchOrganizationInfoStart,
): OrganizationReduxState => ({
  ...state,
  isFetching: true,
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
