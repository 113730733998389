import { createReducer } from '../createReducer';
import { buildReducerItem } from '../reducerItem';
import { Reducer } from '../state.types';
import changeCryptoAmount from './changeCryptoAmount';
import changeFiatAmount from './changeFiatAmount';
import fetchAssetExchangeRateFail from './fetchAssetExchangeRate.fail';
import fetchAssetExchangeRateStart from './fetchAssetExchangeRate.start';
import fetchAssetExchangeRateSuccess from './fetchAssetExchangeRate.success';
import fetchCurrencyExchangeRateFail from './fetchCurrencyExchangeRate.fail';
import fetchCurrencyExchangeRateStart from './fetchCurrencyExchangeRate.start';
import fetchCurrencyExchangeRateSuccess from './fetchCurrencyExchangeRate.success';
import setCalculateFromFiat from './setCalculateFromFiat';
import setDropdownTouched from './setDropdownTouched';
import setError from './setError';
import { initialState, PledgeReduxState } from './state';
import updatePledge from './updatePledge';

// Reducers
const reducers: Map<string, Reducer<PledgeReduxState, any>> = new Map([
  buildReducerItem(fetchAssetExchangeRateStart),
  buildReducerItem(fetchAssetExchangeRateSuccess),
  buildReducerItem(fetchAssetExchangeRateFail),
  buildReducerItem(fetchCurrencyExchangeRateStart),
  buildReducerItem(fetchCurrencyExchangeRateSuccess),
  buildReducerItem(fetchCurrencyExchangeRateFail),
  buildReducerItem(changeCryptoAmount),
  buildReducerItem(changeFiatAmount),
  buildReducerItem(setCalculateFromFiat),
  buildReducerItem(updatePledge),
  buildReducerItem(setDropdownTouched),
  buildReducerItem(setError),
]);

export const dashboardReducer = createReducer(initialState, reducers);

export default dashboardReducer;
