import { AnyReduxState } from '../state.types';
import {
  BrokersReduxState,
  initialState as brokersInitialState,
} from './brokers.state';

export type DictionariesReduxState = AnyReduxState & {
  brokers: BrokersReduxState;
}

export const initialState = (): DictionariesReduxState => ({
  brokers: brokersInitialState(),
});
