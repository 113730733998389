import { AnyAction } from '../state.types';
import { PledgeReduxState } from './state';

export const actionTypeName = 'PLEDGE__FETCH_CURRENCY_EXCHANGE_RATE__FAIL';

export type ActionFetchCurrencyExchangeRateFail = AnyAction & {
  error: Record<string, string> | null;
}

export const createAction = (error: Record<string, string>) => ({
  type: actionTypeName,
  error,
});

export const reducer = (
  state: PledgeReduxState,
  action: ActionFetchCurrencyExchangeRateFail,
): PledgeReduxState => ({
  ...state,
  exchangeRate: 0,
  usdAmount: 0,
  error: action.error,
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
