import React, { ChangeEvent, ReactNode } from 'react';
import {
  OutlinedTextFieldProps,
  TextField,
} from '@mui/material';

import { REQUIRED_MARK } from '../keys';
import useStyles from './styles';

export interface TextInputProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  disabled?: boolean;
  name?: string;
  placeholder?: string;
  value?: string;
  title?: string;
  onChange?: (value: any) => void;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  className?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  label?: string;
}

export const TextInput = ({
  className,
  startAdornment: passedStartAdornment,
  endAdornment: passedEndAdornment,
  onChange,
  required = false,
  label,
  placeholder = label,
  inputProps,
  ...props
}: TextInputProps) => {
  const { classes, cx } = useStyles();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value);
  };

  const startAdornment = passedStartAdornment ?? props?.InputProps?.startAdornment;
  const endAdornment = passedEndAdornment ?? props?.InputProps?.endAdornment;

  const preparedPlaceholder = (required && placeholder)
    ? [placeholder, REQUIRED_MARK].join(' ')
    : placeholder;

  const usedStartAdornment = typeof startAdornment === 'string'
    ? <span className={classes.startAdornment}>{startAdornment}</span>
    : startAdornment;

  const usedEndAdornment = typeof endAdornment === 'string'
    ? <span className={classes.endAdornment}>{endAdornment}</span>
    : endAdornment;

  /* eslint-disable */
  return (
    <TextField
      {...props}
      label={label}
      required={required}
      onChange={handleChange}
      variant="outlined"
      className={cx([classes.input, className])}
      placeholder={preparedPlaceholder}
      FormHelperTextProps={{
        classes: {
          error: classes.helperTextError,
          root: classes.helperTextRoot,
        },
      }}
      InputProps={{
        ...props.InputProps,
        startAdornment: usedStartAdornment,
        endAdornment: usedEndAdornment,
      }}
      inputProps={{
        ...inputProps,
        placeholder: preparedPlaceholder,
      }}
    />
  );
};

export default TextInput;
