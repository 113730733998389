import DonationWorkflowType, {
  AchDonationWorkflowStep,
  CreditCardDonationWorkflowStep,
  CryptoDonationWorkflowStep,
  DafDonationWorkflowStep,
  GenericWorkflowStep,
  StockDonationWorkflowStep,
} from '../../types/workflow';
import { AnyAction } from '../state.types';
import { WorkflowReduxState } from './state';

export const actionTypeName = 'WORKFLOW__SET_ERROR';

export type ActionSetError = AnyAction & {
  error: string;
  title?: string;
}

export const createAction = (
  error: string,
  title?: string,
): ActionSetError => ({
  type: actionTypeName,
  error,
  title,
});

export const reducer = (
  state: WorkflowReduxState,
  action: ActionSetError,
): WorkflowReduxState => {
  const { error } = action;

  if (!error) {
    return {
      ...state,
      error: {
        message: '',
        title: undefined,
      },
    };
  }

  const errorStepMap = {
    [DonationWorkflowType.Crypto]: CryptoDonationWorkflowStep.Error,
    [DonationWorkflowType.CreditCard]: CreditCardDonationWorkflowStep.Error,
    [DonationWorkflowType.Stock]: StockDonationWorkflowStep.Error,
    [DonationWorkflowType.Daf]: DafDonationWorkflowStep.Error,
    [DonationWorkflowType.Ach]: AchDonationWorkflowStep.Error,
    [DonationWorkflowType.Generic]: GenericWorkflowStep.Error,
    default: GenericWorkflowStep.Error,
  };

  const { type } = state;
  const errorStep = type ? errorStepMap[type] : errorStepMap.default;

  return {
    ...state,
    error: {
      message: action.error,
      title: action.title,
    },
    step: errorStep,
  };
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
