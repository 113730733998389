import {
  Button,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';

export const ColorButtonRight = withStyles(Button, {
  root: {
    color: 'white',
    backgroundColor: '#291B4F',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#291B4F',
      boxShadow: 'none',
    },
    borderRadius: 0,
    borderBottomLeftRadius: 4,
    width: '50%',
    height: '100%',
  },
});

export const ColorButtonLeft = withStyles(Button, {
  root: {
    color: 'white',
    backgroundColor: '#291B4F',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#291B4F',
      boxShadow: 'none',
    },
    borderRadius: 0,
    borderBottomRightRadius: 4,
    width: '50%',
    marginLeft: '0 !important',
    height: '100%',
  },
});
