import { AnyAction, Reducer } from '../state.types';
import { DonationReduxState } from './state';

export const actionTypeName = 'DONATION__FETCH_CRYPTO_DONATION_STATUS__START';

export const createAction = (): AnyAction => ({
  type: actionTypeName,
});

export const reducer: Reducer<DonationReduxState, AnyAction> = (
  state: DonationReduxState,
): DonationReduxState => {
  const nextState = {
    ...state,
    crypto: {
      ...state.crypto,
      statusError: {},
      isFetchingStatus: true,
    },
  };

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
