import { AnyAction } from '../state.types';
import { DictionariesReduxState } from './state';

export const actionTypeName = 'DICTIONARIES__FETCH_BROKERS__FAIL';

export type ActionFetchBrokersFail = AnyAction & {
  error: Record<string, string>;
}

export const createAction = (error: Record<string, string>): ActionFetchBrokersFail => ({
  type: actionTypeName,
  error,
});

export const reducer = (
  state: DictionariesReduxState,
  action: ActionFetchBrokersFail,
): DictionariesReduxState => ({
  ...state,
  brokers: {
    ...state.brokers,
    loading: false,
    error: action.error,
  },
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
