import React, { ReactElement, ReactNode } from 'react';
import {
  Box,
  Button,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import IconAch from 'components/icon/ach';
import IconBitcoin from 'components/icon/bitcoin';
import IconChariot from 'components/icon/chariot';
import IconCreditCard from 'components/icon/creditCard';
import IconStocks from 'components/icon/stocks';
import OptionallyVisible from 'components/optionallyVisible';
import { KEYS } from 'layout/keys';
import COLORS from 'theme/colors';
import { DonationWorkflowType } from 'types/workflow';
import { locators, QALocator } from 'utils/tests/QA';

import { LABELS, MAX_TYPES_PER_ROW } from './donationTypeSwitch.keys';
import useStyles from './styles';

interface DonationTypeSwitchProps {
  type: DonationWorkflowType;
  setType: (type: DonationWorkflowType, showFrequencyStep?: boolean) => void;
  availableFlows: DonationWorkflowType[];
}

const iconByType: (color: string) => Record<DonationWorkflowType, ReactNode> = (color: string) => ({
  [DonationWorkflowType.Crypto]: <IconBitcoin width={20} height={20} color={color} />,
  [DonationWorkflowType.CreditCard]: <IconCreditCard width={20} height={20} color={color} />,
  [DonationWorkflowType.Stock]: <IconStocks width={20} height={20} color={color} />,
  [DonationWorkflowType.Daf]: <IconChariot width={20} height={20} color={color} />,
  [DonationWorkflowType.Ach]: <IconAch width={20} height={20} color={color} />,
  [DonationWorkflowType.Generic]: null,
});

export const DonationTypeSwitch = ({
  type,
  setType,
  availableFlows = [],
}: DonationTypeSwitchProps) => {
  const { classes, cx } = useStyles();
  const displayAsGrid = availableFlows.length > MAX_TYPES_PER_ROW;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleButtonClick = (type: DonationWorkflowType) => () => {
    setType(type);
  };

  const isActive = (buttonType: DonationWorkflowType) => buttonType === type;
  const shouldDisplayIconForActiveItem = (donationType: DonationWorkflowType) => !isActive(donationType) || displayAsGrid;
  const iconColor = (donationType: DonationWorkflowType) => (isActive(donationType) ? COLORS.PRIMARY : COLORS.GREY);

  const getMethodButton = (typeItem: DonationWorkflowType) => (
    <Button
      key={typeItem}
      className={cx(classes.donationTypeButton, {
        [classes.gridDonationTypeButton]: displayAsGrid,
        [classes.donationTypeButtonActive]: isActive(typeItem),
        [classes.gridDonationTypeButtonActive]: displayAsGrid && isActive(typeItem),
      })}
      onClick={handleButtonClick(typeItem)}
      {...QALocator(locators.page.common.components.donationTypeButton(typeItem))}
    >
      <OptionallyVisible visible={shouldDisplayIconForActiveItem(typeItem)}>
        {iconByType(iconColor(typeItem))[typeItem] as ReactElement}
      </OptionallyVisible>
      {LABELS[typeItem.toString().toUpperCase()]}
    </Button>
  );

  if (availableFlows.length <= 1) {
    return null;
  }

  if (!displayAsGrid) {
    return (
      <Box
        paddingBottom="30px"
        className={cx({
          [classes.donationTypeButtonsContainer]: !displayAsGrid,
          [classes.donationTypeButtonsContainerGrid]: displayAsGrid,
        })}
      >
        {availableFlows.map(typeItem => (
          getMethodButton(typeItem)
        ))}
      </Box>
    );
  }

  const areShortItemsUsed = availableFlows.length >= KEYS.SHORT_ITEMS_REQURED_LENGTH;
  const elementsPerRow = areShortItemsUsed
    ? KEYS.ELEMENTS_PER_ROW_SHORT : KEYS.ELEMENTS_PER_ROW_LONG;

  const itemLength = areShortItemsUsed ? KEYS.ROW_ITEM_SHORT : KEYS.ROW_ITEM_LONG;
  const availableButtonRows = availableFlows.reduce<Array<Array<DonationWorkflowType>>>((acc, type, index) => {
    const isFirstItemOnRow = index % elementsPerRow === 0;
    if (isFirstItemOnRow) {
      acc.push([]);
    }

    const lastArrayAdded = acc[acc.length - 1];
    lastArrayAdded.push(type);
    return acc;
  }, []);

  const columnSpacing = isSmallScreen ? 1 : 2;

  return (
    <Box
      paddingBottom="30px"
      className={cx({
        [classes.donationTypeButtonsContainer]: !displayAsGrid,
        [classes.donationTypeButtonsContainerGrid]: displayAsGrid,
      })}
    >
      {availableButtonRows.map((rowItems, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid container className={classes.row} key={index} rowSpacing={2} columnSpacing={columnSpacing}>
          {rowItems.map(workflowType => (
            <Grid item md={itemLength} lg={itemLength} xs={itemLength} sm={itemLength}>
              {getMethodButton(workflowType)}
            </Grid>
          ))}
        </Grid>
      ))}
    </Box>
  );
};

export default DonationTypeSwitch;
