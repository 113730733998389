import { SortOrder } from 'types/sort';

import {
  booleanComparator,
  defaultComparator,
  numberComparator,
  stringComparator,
} from './descendingComparator';

const comparators = {
  string: stringComparator,
  date: defaultComparator,
  number: numberComparator,
  boolean: booleanComparator,
  default: defaultComparator,
};

export enum ComparatorType {
  String = 'String',
  Date = 'Date',
  Number = 'Number',
  Boolean = 'Boolean',
  Default = 'Default',
}

export interface ComparatorOptions {
  type?: ComparatorType;
}

export const getComparator = (order: SortOrder, orderBy: string, options: ComparatorOptions = {}) => {
  const { type = ComparatorType.Default } = options;
  const comparator = comparators[type] || comparators.default;

  return order === SortOrder.Desc
    ? (a, b) => comparator(a, b, orderBy)
    : (a, b) => -comparator(a, b, orderBy);
};

export default getComparator;
