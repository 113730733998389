import React, {
  useState,
} from 'react';

import { SearchableDropdownWithFetch } from 'components/inputs/searchableDropdown';
import { StockTickerSymbol } from 'types/pledge';
import { locators, QALocator } from 'utils/tests/QA';

import KEYS, { LABELS } from './keys';
import { fetchStockAssets } from './stockAssets.http';
import useStyles from './styles';

export interface StockTickerInputProps {
  selected?: StockTickerSymbol;
  onChange: (selected: StockTickerSymbol[]) => void;
  disabled?: boolean;
}

const StockTickerInput = ({
  selected,
  onChange,
  disabled = false,
}: StockTickerInputProps) => {
  const { classes, cx } = useStyles();
  const [items, setItems] = useState<StockTickerSymbol[]>([]);

  const fetchTickers = async ({ page, search }: { page: number; search: string }) => {
    const searchQuery = {
      ticker: search,
    };

    try {
      const data = await fetchStockAssets({ page, search: searchQuery });
      setItems(data.tickers);
      return data.tickers;
    } catch (e: any) {
      setItems([]);
      return [];
    }
  };

  const renderItem = (item: StockTickerSymbol) => (
    <div className={classes.item}>
      <div className={cx([classes.tickerSection, classes.mainSection])}>{item.ticker}</div>
      <div className={cx([classes.nameSection, classes.secondarySection])}>{item.name}</div>
    </div>
  );

  const getOptionSelected = (option: StockTickerSymbol, value: StockTickerSymbol) => option.ticker === value.ticker;

  return (
    <div
      className={classes.container}
      {...QALocator(locators.components.stock.tickerInputContainer)}
    >
      <SearchableDropdownWithFetch<StockTickerSymbol>
        onChange={onChange}
        selected={selected}
        getOptionLabel={item => item.ticker}
        items={items as StockTickerSymbol[]}
        disabled={disabled}
        fetchData={fetchTickers}
        pageSize={KEYS.PAGE_SIZE}
        ComboboxProps={{
          placeholder: LABELS.ASSET_SYMBOL_LABEL,
          getOptionSelected,
          renderItem,
        }}
      />
    </div>
  );
};

export default StockTickerInput;
