import { FormFieldDescription, InputViewType } from 'components/formFields//fields.types';
import { LOCATION_FIELD_NAMES } from 'components/formFields/fields.keys';
import DonationWorkflowType from 'types/workflow';

export const LABELS = {
  RIGHT_BUTTON_TEXT: 'Continue',
  LEFT_BUTTON_TEXT: 'Previous',
  INVALID_EMAIL: 'Invalid email',
  REQUIRED: 'Required',
  PROCESSING: 'Processing...',
  LOADING_SCREEN: 'Preparing Pledge',
};

export const KEYS = {
  EMAIL_REGEX: /\S+@\S+\.\S+/,
  ANONYMOUS_FIELD_NAME: 'isAnonymous',
  MAX_LENGTH_PHONE_NUMBER: 20,
  COMMUNICATION_ALLOWED_KEY: 'isCharityCommunicationAllowed',
};

export const donorFormFields: FormFieldDescription[] = [
  {
    label: 'First name',
    type: 'text',
    view: {
      display: InputViewType.Short,
      appliesTo: [
        DonationWorkflowType.CreditCard,
        DonationWorkflowType.Crypto,
        DonationWorkflowType.Stock,
        DonationWorkflowType.Daf,
      ],
    },
    isRequired: true,
    name: 'firstName',
  },
  {
    label: 'Last name',
    type: 'text',
    view: {
      display: InputViewType.Short,
      appliesTo: [
        DonationWorkflowType.CreditCard,
        DonationWorkflowType.Crypto,
        DonationWorkflowType.Stock,
        DonationWorkflowType.Daf,
      ],
    },
    isRequired: true,
    name: 'lastName',
  },
  {
    label: 'Email',
    type: 'email',
    isRequired: true,
    name: 'email',
  },
  {
    label: 'Phone',
    type: 'tel',
    isRequired: true,
    name: 'phoneNumber',
    hiddenFor: [
      DonationWorkflowType.CreditCard,
      DonationWorkflowType.Crypto,
      DonationWorkflowType.Daf,
    ],
    formatter: (value: string) => value.replace(/[^\d+]/g, '').substring(0, KEYS.MAX_LENGTH_PHONE_NUMBER),
  },
  {
    label: 'Country',
    type: 'autocomplete',
    isRequired: true,
    name: LOCATION_FIELD_NAMES.COUNTRY,
    options: [],
    view: {
      display: InputViewType.Short,
      appliesTo: [
        DonationWorkflowType.Daf,
      ],
    },
  },
  {
    label: 'State/Province/Region',
    type: 'autocomplete',
    isRequired: true,
    name: LOCATION_FIELD_NAMES.STATE,
    options: [],
    view: {
      display: InputViewType.Short,
      appliesTo: [
        DonationWorkflowType.Daf,
      ],
    },
  },
  {
    label: 'Address 1',
    type: 'text',
    isRequired: true,
    name: 'address1',
  },
  {
    label: 'Address 2',
    type: 'text',
    isRequired: false,
    name: 'address2',
  },
  {
    label: 'City',
    type: 'text',
    isRequired: true,
    name: 'city',
    view: {
      display: InputViewType.Short,
      appliesTo: [
        DonationWorkflowType.Crypto,
        DonationWorkflowType.Daf,
      ],
    },
  },
  {
    label: 'ZIP Code',
    type: 'text',
    isRequired: true,
    name: 'zipCode',
    view: {
      display: InputViewType.Short,
      appliesTo: [
        DonationWorkflowType.Crypto,
        DonationWorkflowType.Daf,
      ],
    },
  },
  {
    label: 'Join our mailing list for news and updates',
    type: 'checkbox',
    isRequired: false,
    view: {
      display: InputViewType.Clean,
      appliesTo: [
        DonationWorkflowType.Crypto,
        DonationWorkflowType.Stock,
        DonationWorkflowType.CreditCard,
        DonationWorkflowType.Daf,
      ],
    },
    name: KEYS.COMMUNICATION_ALLOWED_KEY,
  },
];

export const anonymousDonorField = {
  label: 'Donate Anonymously',
  type: 'checkbox',
  name: KEYS.ANONYMOUS_FIELD_NAME,
};
