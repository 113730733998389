export const LINKS = {
  PRIVACY_POLICY: 'https://policies.google.com/privacy',
  TERMS_AND_CONDITIONS: 'https://policies.google.com/terms',
};

export const LABELS = {
  TERMS: {
    START: 'This site is protected by reCAPTCHA and the Google',
    PRIVACY_POLICY: 'Privacy Policy',
    CONJUNCTIVE: 'and',
    TERMS_AND_CONDITIONS: 'Terms and Conditions',
    END: 'apply.',
  },
};
