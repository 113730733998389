import { Currency } from 'types/currency';

import { AnyReduxState } from '../state.types';

export type CurrenciesReduxState = AnyReduxState & {
  currencies: Currency[];
  isLoading: boolean;
  error: Record<string, any> | null;
}

export const initialState: () => CurrenciesReduxState = () => ({
  currencies: [],
  isLoading: false,
  error: null,
});
