import React, {
  ChangeEvent,
  FocusEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  FormHelperText,
  Input,
  Tooltip,
} from '@mui/material';

import IconInfo from 'components/icon/info';
import OptionallyVisible from 'components/optionallyVisible';
import { FiatFormatCustom, NumberFormatCustom } from 'pages/common/pledge/numberFormatCustom';
import COLORS from 'theme/colors';
import { StocksDonationPledge } from 'types/pledge';
import { toDecimalFormat, USDFormatter } from 'utils/currency';
import { locators, QALocator } from 'utils/tests/QA';

import { KEYS, LABELS } from '../stockDonationPledge.keys';
import useStyles from './styles';

export interface StockCurrencyAmountBoxProps {
  onMainAmountChange: (value: number) => void;
  onSecondaryAmountChange?: (value: number) => void;
  pledge: StocksDonationPledge;
  error?: string;
  isExchangeRateUnavailable: boolean;
}

export const StockCurrencyAmountBox = ({
  onMainAmountChange,
  onSecondaryAmountChange,
  pledge,
  error = '',
  isExchangeRateUnavailable,
}: StockCurrencyAmountBoxProps) => {
  const formattedUsdValue = useMemo(
    () => {
      if (pledge.usdAmount === 0) {
        return '';
      }

      return USDFormatter(2).format(Number(pledge.usdAmount));
    },
    [pledge.usdAmount],
  );
  const formattedCryptoValue = useMemo(() => {
    const formattedValue = toDecimalFormat(pledge.quantity) || '';
    return formattedValue;
  }, [pledge.quantity]);
  const [amountInputValue, setAmountInputValue] = useState<string>(formattedCryptoValue);
  const [USDInputValue, setUSDInputValue] = useState<string>(formattedUsdValue);
  const [amountInputFocused, setAmountInputFocused] = useState<boolean>(false);
  const [USDInputFocused, setUSDInputFocused] = useState<boolean>(false);
  const { classes, cx } = useStyles({ isFocused: amountInputFocused || USDInputFocused });

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const processedValue = value.replace(/[^0-9-]/ig, '');
    const inputValue = toDecimalFormat(value, 0);
    if (amountInputFocused) {
      onMainAmountChange(Number(processedValue));
    }

    setAmountInputValue(inputValue);
  };

  const handleUSDAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (USDInputFocused) {
      onSecondaryAmountChange?.(Number(value));
    }

    const nextUSDInputValue = value
      ? USDFormatter(2).format(Number(value))
      : '';

    setUSDInputValue(nextUSDInputValue);
  };

  const handleAmountInputFocus = (event: FocusEvent<HTMLInputElement>) => {
    setAmountInputFocused(true);
  };

  const handleUSDInputFocus = (event: FocusEvent<HTMLInputElement>) => {
    setUSDInputFocused(true);
  };

  const handleInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onMainAmountChange(Number(value));
    setAmountInputValue(formattedCryptoValue);
    setAmountInputFocused(false);
  };

  const handleUSDInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onSecondaryAmountChange?.(Number(value));
    const nextUSDInputValue = value
      ? USDFormatter(2).format(Number(value))
      : '';

    setUSDInputValue(nextUSDInputValue);
    setUSDInputFocused(false);
  };

  useEffect(() => {
    setAmountInputValue(formattedCryptoValue);
  }, [pledge.quantity]);

  useEffect(() => {
    setUSDInputValue(formattedUsdValue);
  }, [pledge.usdAmount]);

  const endAdorment = isExchangeRateUnavailable ? (
    <Tooltip
      title={LABELS.EXCHANGE_RATE_UNAVAILABLE}
      classes={{
        tooltip: classes.donationTooltip,
      }}
      enterTouchDelay={KEYS.TOOLTIP_TOUCH_DELAY}
    >
      <span className={classes.iconEyeTooltip}>
        <IconInfo className={classes.icon} color={COLORS.GREY} />
      </span>
    </Tooltip>
  ) : null;

  return (
    <div className={classes.amountBoxWrapper}>
      <Box
        className={cx({
          [classes.amountBox]: true,
          [classes.error]: Boolean(error) && !amountInputFocused,
        })}
      >
        <Box className={classes.mainInputContainer}>
          <Input
            disableUnderline
            inputProps={{ className: classes.mainCurrencyInput }}
            value={amountInputValue}
            onChange={handleAmountChange}
            onFocus={handleAmountInputFocus}
            onBlur={handleInputBlur}
            placeholder={LABELS.QUANTITY_LABEL}
            inputComponent={NumberFormatCustom}
            {...QALocator(locators.components.stock.amountInput)}
          />
        </Box>
        <Box className={classes.secondaryInputContainer}>
          <span className={classes.inputDecoration}>
            {LABELS.APPROXIMATE_USD_VALUE_PREFIX}
          </span>
          <Input
            disableUnderline
            inputProps={{
              className: classes.secondaryCurrencyInput,
              prefix: LABELS.USD_VALUE_PREFIX,
            }}
            value={USDInputValue}
            disabled
            onChange={handleUSDAmountChange}
            onFocus={handleUSDInputFocus}
            onBlur={handleUSDInputBlur}
            placeholder={LABELS.USD_INPUT_PLACEHOLDER}
            inputComponent={FiatFormatCustom}
            {...QALocator(locators.components.stock.usdAmountInput)}
            endAdornment={endAdorment}
          />
        </Box>
      </Box>
      <OptionallyVisible visible={!amountInputFocused}>
        <FormHelperText className={classes.errorText}>{error}</FormHelperText>
      </OptionallyVisible>
    </div>
  );
};

export default StockCurrencyAmountBox;
