import { AnyAction } from '../state.types';
import { PledgeReduxState } from './state';

export const actionTypeName = 'PLEDGE__FETCH_CURRENCY_EXCHANGE_RATE__SUCCESS';

export type ActionFetchCurrencyExchangeRateSuccess = AnyAction & {
  exchangeRate: number;
}

export const createAction = (exchangeRate: number) => ({
  type: actionTypeName,
  exchangeRate,
});

export const reducer = (
  state: PledgeReduxState,
  action: ActionFetchCurrencyExchangeRateSuccess,
): PledgeReduxState => {
  const hasUsdAmountSet = Boolean(state.usdAmount);
  if (hasUsdAmountSet) {
    return {
      ...state,
      amount: state.usdAmount / action.exchangeRate,
      exchangeRate: action.exchangeRate,
    };
  }

  return {
    ...state,
    exchangeRate: action.exchangeRate,
    usdAmount: state.amount * action.exchangeRate,
  };
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
