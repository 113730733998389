import React, { ReactNode } from 'react';
import { ThemeProvider } from '@mui/material';

import { DesignVersion } from 'types/workflow';

import getTheme from './theme';

export interface CustomThemeProviderProps {
  children: ReactNode | ReactNode[];
  designVersion: DesignVersion;
  isPCDEnabled: boolean;
}

export const CustomThemeProvider = ({
  children,
  designVersion,
  isPCDEnabled,
}: CustomThemeProviderProps) => {
  const theme = getTheme({
    isPCDEnabled,
    designVersion,
  });

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

export default CustomThemeProvider;
