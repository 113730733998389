import React, { useEffect } from 'react';
import { Container, Typography } from '@mui/material';

import loadingLogo from 'assets/images/simple-main-logo.png';
import BottomButtons from 'components/bottomButtons';
import { Broker, BrokerInfo } from 'types/broker';

import { LABELS } from './brokerInfo.keys';
import { BrokerInforForm } from './brokerInfoForm';
import { useStyles } from './styles';

interface BrokerInfoScreenProps {
  brokerInfo: BrokerInfo;
  updateBrokerInfo: (changes: Partial<BrokerInfo>) => void;
  fetchStockBrokers: () => void;
  brokers: Broker[];
  submitBrokerInfo: () => void;
  isDisabledSubmit: boolean;
  goBack: () => void;
  isSubmitting: boolean;
  error: Record<string, string>;
}

const BrokerInfoScreen = ({
  brokerInfo,
  updateBrokerInfo,
  fetchStockBrokers,
  brokers,
  submitBrokerInfo,
  isDisabledSubmit,
  goBack,
  isSubmitting,
  error,
}: BrokerInfoScreenProps) => {
  const { classes } = useStyles();
  useEffect(() => {
    fetchStockBrokers();
  }, []);

  const handleSubmit = () => {
    submitBrokerInfo();
  };

  if (isSubmitting) {
    return (
      <Container className={classes.submissionLoadingContainerWrapper}>
        <div className={classes.loadingContainer}>
          <div className={classes.imageLoadingContainer}>
            <img src={loadingLogo} alt="processing" className={classes.loadingImage} />
          </div>
          <Typography variant="h6" className={classes.loadingText}>
            {LABELS.PROCESSING}
          </Typography>
        </div>
      </Container>
    );
  }

  return (
    <>
      <BrokerInforForm
        brokerInfo={brokerInfo}
        onChange={updateBrokerInfo}
        brokers={brokers}
        error={error}
      />
      <BottomButtons
        isDisabledSubmit={isDisabledSubmit}
        onClickRight={handleSubmit}
        onClickLeft={goBack}
        leftButtonText={LABELS.BACK_BUTTON_LABEL}
        rightButtonText={LABELS.CONTINUE_BUTTON_LABEL}
        rightButtonLoading={isSubmitting}
      />
    </>
  );
};

export default BrokerInfoScreen;
