import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  container: {
    width: 100,
  },
  pageHeader: {
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'Poppins !important',
    margin: 'unset',
    color: '#291B4F',
  },
  dialogPaper: {
    width: 260,
    background: 'non',
  },
  modalContainer: {
    overflow: 'hidden',
    bottomLeftBorderRadius: 8,
    bottomRightBorderRadius: 8,
  },
  formHelperText: {
    marginLeft: 74,
  },
  iconExchangeWrapper: {
    width: '16px',
    height: '16px',
    background: '#FFFFFF',
    border: '1px solid #DADFE5',
    boxSizing: 'border-box',
    borderRadius: '50%',
    display: 'flex',
    color: '#86909F',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    cursor: 'pointer',
    zIndex: '999',
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
    // Progress bar on top is 24px fixed height
    // Bottom actions buttons are 48px
    // Parent container has fixed height
    // Height of the content between is 100% minus buttons and progress
    height: 'calc(100% - 72px)',
  },
  fieldHeader: {
    fontSize: 13,
    fontWeight: 500,
    marginBottom: 4,
    marginTop: 0,
    width: '100%',
    textAlign: 'left',
    color: '#aaa',
  },
  stockBox: {
    padding: '0px 8px',
    height: '100%',
    cursor: 'pointer',
  },
  activeCurrency: {
    fontSize: 12,
  },
  stocksLabel: {
    fontSize: 12,
    color: '#86909F',
  },
  inputWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  input: {
    textAlign: 'left',
    paddingLeft: 8,
    width: '100%',
    margin: '0px !important',
  },
  mainInput: {
    fontSize: '13px !important',
  },
  absolute: {
    position: 'absolute',
  },
  stockQuantityInput: {
    textAlign: 'left',
    fontWeight: 600,
    fontSize: '22px',
    padding: '0!important',
    paddingLeft: '4px !important',
    display: 'inline',
  },
  subPrice: {
    color: '#86909F',
    paddingRight: 0,
    fontSize: 14,
    maxWidth: 152,
    marginRight: '8px',
    overflow: 'hidden',
    textAlign: 'left',
    width: '100%',
    paddingLeft: 8,
  },
  currency: {
    position: 'absolute',
    right: -30,
    top: -5,
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '15px',
    fontFamily: 'Roboto',
    lineHeight: '48px',
    textTransform: 'uppercase',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    color: '#000',
    display: 'inline',
    '> .MuiTypographyColorTextSecondary': {
      color: '#000',
    },
  },
  iconExchange: {
    transform: 'rotate(90deg)',
    fontSize: 8,
  },
  quantityBox: {
    border: '1px solid rgba(0, 0, 0, 0.27)',
    borderRadius: 4,
    width: '100%',
    boxSizing: 'border-box',
    padding: 8,
    '&.error': {
      border: '1px solid #F02B2B',
    },
  },
  amountBox: {
    border: '1px solid rgba(0, 0, 0, 0.27)',
    borderRadius: 4,
    width: '100%',
    boxSizing: 'border-box',
    height: 40,
    padding: 8,
    '&.error': {
      border: '1px solid #F02B2B',
    },
  },
  inputButtonsWrapper: {
    width: '100%',
  },
  checkboxLabel: {
    fontSize: '14px',
  },
  checkboxContainer: {
    maxHeight: 20,
    '& .MuiSvgIcon-root path': {
      fill: '#291B4F',
    },
    '& .MuiCheckbox-root': {
      padding: 0,
      marginRight: 5,
    },
    '& .MuiFormControlLabel-root': {
      margin: 'unset',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    maxWidth: 220,
    gap: 12,
  },
});

export default useStyles;
