import { AnyAction } from '../state.types';
import { DictionariesReduxState } from './state';

export const actionTypeName = 'DICTIONARIES__FETCH_BROKERS__START';

export type ActionFetchBrokersStart = AnyAction;

export const createAction = (): ActionFetchBrokersStart => ({
  type: actionTypeName,
});

export const reducer = (
  state: DictionariesReduxState,
): DictionariesReduxState => ({
  ...state,
  brokers: {
    ...state.brokers,
    error: {},
    loading: true,
  },
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
