import { AnyAction, AnyReduxState, Reducer } from './state.types';

export const createReducer = (
  initialState: () => AnyReduxState,
  reducers: Map<string, Reducer<any, any>>,
) => (
  state: AnyReduxState = initialState(),
  action: AnyAction,
) => {
  const reducer: Reducer<any, any> = reducers.get(action.type) || (() => state);

  return reducer(state, action);
};

export default createReducer;
