import { Broker } from 'types/broker';

import { AnyAction } from '../state.types';
import { DictionariesReduxState } from './state';

export const actionTypeName = 'DICTIONARIES__FETCH_BROKERS__SUCCESS';

export type ActionFetchBrokersSuccess = AnyAction & {
  brokers: Broker[];
}

export const createAction = (brokers: Broker[]): ActionFetchBrokersSuccess => ({
  type: actionTypeName,
  brokers,
});

export const reducer = (
  state: DictionariesReduxState,
  action: ActionFetchBrokersSuccess,
): DictionariesReduxState => {
  const nextState = {
    ...state,
    brokers: {
      ...state.brokers,
      loading: false,
      brokers: action.brokers,
    },
  };

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
