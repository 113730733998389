import { BASIC_CURRENCIES } from 'constants/currencies';
import { AppReduxState } from 'state/state';
import DonationWorkflowType from 'types/workflow';

import { calculateWithFeesBeforeGross } from '../currency';
import { KEYS } from './keys';
import GTMEventType from './types';

const makeBasicPayload = (state: AppReduxState) => {
  const { name, id } = state.organization.organization;
  return {
    event: GTMEventType.DonationStart,
    nonprofitName: name,
    nonprofitId: id,
  };
};

const makeCryptoPayload = (state: AppReduxState) => {
  const {
    currency,
    amount,
    coverTransactionFees,
    usdAmount,
  } = state.pledge;
  const { currencies } = state.currencies;

  const selectionType = currencies.find(currencyItem => currencyItem.code === currency.toUpperCase())?.name || '';

  return {
    donationType: DonationWorkflowType.Crypto,
    donationAmount: amount,
    symbol: currency,
    areFeesCovered: coverTransactionFees,
    selectionType,
    usdAmount,
  };
};

const makeCardPayload = (state: AppReduxState) => {
  const { amount, coverTransactionFees } = state.pledge;
  const { frequency } = state.frequency;
  const { fiatFee } = state.organization.organization;
  let feeAmount = 0;
  if (fiatFee) {
    feeAmount = calculateWithFeesBeforeGross(amount, fiatFee) - amount;
  }

  return {
    donationType: DonationWorkflowType.CreditCard,
    donationAmount: amount,
    donationFrequency: frequency,
    symbol: BASIC_CURRENCIES.USD,
    areFeesCovered: coverTransactionFees,
    selectionType: KEYS.CREDIT,
    feeAmount,
  };
};

const makeStockPayload = (state: AppReduxState) => {
  const { asset, quantity, usdAmount } = state.pledge;

  return {
    donationType: DonationWorkflowType.Stock,
    donationAmount: quantity,
    symbol: asset?.ticker,
    selectionType: asset?.name,
    usdAmount,
  };
};

const makeDAFPayload = (state: AppReduxState) => {
  const { isAnonymous } = state.donor;

  return {
    donationType: DonationWorkflowType.Daf,
    isAnonymous: isAnonymous ? KEYS.YES : KEYS.NO,
  };
};

const flowPayloadGetterMap = {
  [DonationWorkflowType.Crypto]: makeCryptoPayload,
  [DonationWorkflowType.CreditCard]: makeCardPayload,
  [DonationWorkflowType.Stock]: makeStockPayload,
  [DonationWorkflowType.Daf]: makeDAFPayload,
};

export const donationStartPayloadFactory = (
  flow: DonationWorkflowType,
  { state }: { state: AppReduxState },
) => {
  const makeSpecificPayload = flowPayloadGetterMap[flow];

  return {
    ...makeBasicPayload(state),
    ...(makeSpecificPayload?.(state) || {}),
  };
};

export default donationStartPayloadFactory;
