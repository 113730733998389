import React, {
  useEffect,
} from 'react';
import { Box } from '@mui/material';

import ChariotConnect from 'components/chariot';
import Loading from 'components/loading';
import OptionallyVisible from 'components/optionallyVisible';
import PageHeader from 'components/pageHeader/pageHeader';
import DonationTypeSwitch from 'pages/common/pledge/overlayVariant/donationTypeSwitch/donationTypeSwitch';
import { ChariotSession } from 'types/chariot';
import { DonorInfo } from 'types/donor';
import DonationWorkflowType from 'types/workflow';

import { LABELS } from '../keys';
import { useStyles } from './styles';

interface DAFPledgeScreenProps {
  connection: {
    id: string | null;
    isLoading: boolean;
    error: Record<string, any> | null;
  };
  donation: {
    isLoading: boolean;
    error: Record<string, any> | null;
  };
  donor: DonorInfo;
  workflowType: DonationWorkflowType;
  availableFlows: DonationWorkflowType[];
  fetchConnectionId: () => void;
  setType: (type: DonationWorkflowType) => void;
  resetConnectionId: () => void;
  onDonationStart: () => void;
  onDonationSuccess: (data: ChariotSession) => void;
}

export const DAFPledgeScreen = ({
  connection,
  donation,
  donor,
  workflowType,
  availableFlows,
  setType,
  fetchConnectionId,
  resetConnectionId,
  onDonationStart,
  onDonationSuccess,
}: DAFPledgeScreenProps) => {
  const { classes } = useStyles();

  useEffect(() => {
    fetchConnectionId();
  }, []);

  const onSuccess = ({ detail }: { detail: ChariotSession }) => {
    onDonationSuccess(detail);
  };

  const onDonationRequest = () => {
    onDonationStart();
    return {
      firstName: donor.firstName,
      lastName: donor.lastName,
      email: donor.email,
    };
  };

  const onChariotButtonWrapperClick = async (e) => {
    e.preventDefault();
  };

  if (donation.isLoading) {
    return <Loading text={LABELS.VIEW_LOADING} />;
  }

  return (
    <div className={classes.scrollContainer}>
      <PageHeader label={LABELS.PAGE_TITLE} />
      <DonationTypeSwitch
        type={workflowType}
        setType={setType}
        availableFlows={availableFlows}
      />
      <OptionallyVisible visible={connection.isLoading}>
        <Loading text={LABELS.SUBMITTING_TEXT} />
      </OptionallyVisible>
      <OptionallyVisible visible={!connection.isLoading}>
        <div className={classes.infoPanel}>
          <div className={classes.infoItem}>
            {LABELS.INFO}
          </div>
        </div>
      </OptionallyVisible>
      <OptionallyVisible visible={Boolean(connection.id)}>
        <Box
          onClick={onChariotButtonWrapperClick}
        >
          <ChariotConnect
            dependecies={[donor]}
            onDonationRequest={onDonationRequest}
            cid={connection.id!}
            theme={{ width: 'w-full' }}
            onSuccess={onSuccess}
            onUnmount={resetConnectionId}
          />
        </Box>
      </OptionallyVisible>
    </div>
  );
};

export default DAFPledgeScreen;
