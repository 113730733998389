import { FunctionComponent } from 'react';
import {
  connect,
  ConnectedComponent,
} from 'react-redux';
import { Dispatch } from 'redux';

import clearCreditCardInfoFields from 'state/creditCard/clearCreditCardInfoFields';
import { CreditCardReduxState, initialState } from 'state/creditCard/state';
import updateCreditCardInfoValue from 'state/creditCard/updateCreditCardInfoValue';
import set3DSError from 'state/donation/set3DSError';
import getCardToken, { GetCardTokenParams } from 'state/donation/thunk/getCardToken.thunk';
import { AppReduxState } from 'state/state';
import changeStep from 'state/workflow/changeStep';
import { FiatPaymentMethod, Shift4Utils } from 'types/card';
import Frequency from 'types/frequency';
import { CreditCardDonationWorkflowStep } from 'types/workflow';
import componentSwitch from 'utils/componentSwitch';
import { calculateWithFeesBeforeGross } from 'utils/currency';

import CreditCardDetails from './creditCardDetails';
import { KEYS } from './creditCardDetails.keys';
import OverlayCreditCardDetails from './overlayVariant/creditCardDetails';

const mapStateToProps = (state: AppReduxState) => {
  const {
    card,
    donation,
    donor,
    pledge,
    organization,
  } = state;

  const {
    shift4PublicKey,
    name: organizationName,
    countryCodeIso2,
  } = organization.organization;

  const {
    firstName,
    lastName,
    zipCode,
    isAnonymous,
  } = donor;

  const {
    frequency,
  } = state.frequency;

  const {
    widgetId,
  } = state.widget;

  const initalCardInformationState = initialState();

  const hasBothNamesFilled = Boolean(firstName && lastName);

  const shouldUseDonorInfoToPrefilFields = !isAnonymous;
  const initialCardHolderName = hasBothNamesFilled ? `${firstName} ${lastName}` : initalCardInformationState.cardHolderName;
  const initialZipCode = zipCode || initalCardInformationState.cardHolderZipCode;

  const initialCreditCardInformation = shouldUseDonorInfoToPrefilFields ? {
    ...initalCardInformationState,
    [KEYS.CARD_HOLDER_NAME]: initialCardHolderName,
    [KEYS.CARD_HOLDER_ZIP_CODE]: initialZipCode,
  } : initalCardInformationState;

  const donationAmount = pledge.coverTransactionFees
    ? calculateWithFeesBeforeGross(pledge.usdAmount, organization.organization.fiatFee || undefined)
    : pledge.usdAmount;

  return {
    donationAmount,
    countryCode: countryCodeIso2,
    creditCardInformation: card,
    initialCreditCardInformation,
    isSubmitting: donation.isSubmitting || donation.card.threeDSecure?.isLoading,
    error: donation.card.threeDSecure?.error || card.error,
    organizationName,
    shift4PublicKey,
    isRecurringDonation: frequency !== Frequency.Once,
    widgetId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  goBack: () => dispatch(changeStep.createAction(CreditCardDonationWorkflowStep.FillDonorInfo)),
  updateValue: (field: string, value: string) => dispatch(updateCreditCardInfoValue.createAction(field, value)),
  clearFields: (initialCreditCardInformation: Partial<CreditCardReduxState>) => (
    dispatch(clearCreditCardInfoFields.createAction(initialCreditCardInformation))
  ),
  set3DSError: (error: Record<string, string> | undefined) => {
    dispatch(set3DSError.createAction(error));
  },
  getCardToken: (params: GetCardTokenParams) => dispatch(getCardToken(params)),
});

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
) => ({
  ...stateProps,
  ...dispatchProps,
  getCardToken: (
    method: FiatPaymentMethod,
    options: {
      shift4Utils: Shift4Utils;
      components: unknown;
      additionalFields?: object;
    },
  ) => dispatchProps.getCardToken({
    method,
    merchantName: stateProps.organizationName,
    countryCode: stateProps.countryCode,
    shift4: options.shift4Utils,
    donationAmount: stateProps.donationAmount,
    components: options.components,
    shift4PublicKey: stateProps.shift4PublicKey ?? '',
    additionalFields: {
      ...options.additionalFields,
      cardholderName: stateProps.creditCardInformation.cardHolderName,
      addressZip: stateProps.creditCardInformation.cardHolderZipCode,
    },
    widgetId: stateProps.widgetId,
  }),
  clearFields: () => dispatchProps.clearFields(stateProps.initialCreditCardInformation),
});

export const CreditCardDetailsConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(componentSwitch(CreditCardDetails, OverlayCreditCardDetails)) as ConnectedComponent<FunctionComponent, {}>;

export default CreditCardDetailsConnect;
