export enum AchDonationStatus {
  Chargeable = 'chargeable',
  Pending = 'pending',
  Failed = 'failed',
  Used = 'used',
}

export enum VerificationProviders {
  Plaid = 'plaid',
}

export interface PlaidOnError {
  error_type: string;
  error_code: string;
  error_message: string;
  display_message?: string;
}

export interface PlaidInstance {
  open: () => void;
}

export interface PlaidOnSuccessMetadata {
  institution: {
    name: string;
    institution_id: string;
  };
  account: {
    id: string;
    name: string;
    mask: string;
    type: string;
    subtype: string;
  };
}

export interface PlaidOnExitMetadata {
  institution: {
    name: string;
    institution_id: string;
  };
}

export interface PlaidCreateOptions {
  token: string;
  onSuccess?: (publicToken: string, metadata: PlaidOnSuccessMetadata) => void;
  onLoad?: () => void;
  onExit?: (error: PlaidOnError, metadata: PlaidOnExitMetadata) => void;
  onEvent?: (eventName: string, metadata: PlaidOnEventMetadata) => void;
}

export interface PlaidOnEventMetadata {
  eventName: string;
}

export interface PlaidUtils {
  create: (options: PlaidCreateOptions) => PlaidInstance;
}

export default AchDonationStatus;
