import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

export const useStyles = makeStyles()({
  longView: {
    gridColumn: '1 / span 2',
  },
  subscriptionText: {
    display: 'inline-block',
    color: '#2D2E31',
    lineHeight: '12px',
    fontSize: 12,
    cursor: 'pointer',
  },
  shortInput: {
    width: '50%',
    '&:first-of-type': {
      width: 'calc(50% - 5px)',
      marginRight: 5,
    },
  },
  inputLabel: {
    fontSize: '14px !important',
  },
  defaultInput: {
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 9px) scale(1)',
      fontSize: 14,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& fieldset': {
      borderColor: '#DADFE5',
      borderRadius: '3px',
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: 14,
      height: 28,
    },
    '& .MuiFormControl-marginDense': {
      marginTop: 1,
      marginBottom: 1,
    },
  },
  formItem: {
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 9px) scale(1)',
      fontSize: 14,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& fieldset': {
      borderColor: '#DADFE5',
      borderRadius: '3px',
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: 14,
    },
    '& .MuiFormControl-marginDense': {
      marginTop: 5,
      marginBottom: 1,
    },
  },
  shift4InputWrapper: {
    position: 'relative',
    borderRadius: 4,
    border: '1px solid #DADFE5',
    marginTop: 5,
    padding: '10px 14px',
    paddingRight: 30,
    '&:hover': {
      border: '1px solid #000',
    },
  },
  shift4InputWrapperError: {
    borderColor: COLORS.ERROR,
  },
  shift4InputWrapperAdornment: {
    position: 'absolute',
    right: 14,
    top: 'calc(50% - 10px)',
  },
  hidden: {
    display: 'none',
  },
  errorWrapper: {
    color: COLORS.ERROR,
    marginLeft: 14,
    marginRight: 14,
    marginTop: 4,
    marginBottom: 0,
    fontSize: '0.75rem',
  },
});

export default useStyles;
