import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';
import FONT_FAMILIES from 'theme/fontFamilies';

export const useStyles = makeStyles()({
  scrollContainer: {
    padding: '30px 30px 45px 30px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 12,
  },
  inputTitle: {
    color: COLORS.PRIMARY,
    fontSize: 16,
    fontWeight: 400,
    margin: 'unset',
    textAlign: 'center',
    padding: '0 30px',
  },
  checkboxContainer: {
    width: '100%',
    marginLeft: 4,

    '& .MuiFormControlLabel-root': {
      marginRight: 'unset',
    },
    '& .MuiSvgIcon-root path': {
      fill: 'transparent',
    },
  },
  checkboxLabel: {
    fontSize: 14,
  },
  nextButton: {
    marginTop: 95,
    '&:disabled, &.Mui-disabled': {
      border: 'unset',
    },
  },
  error: {
    color: COLORS.PRIMARY,
    fontWeight: 500,
    fontSize: 14,
    marginLeft: 10,
    marginTop: -5,
    fontFamily: FONT_FAMILIES.POPPINS,
  },
  textInput: {
    boxShadow: '0px 3px 12px 0px rgba(41, 27, 79, 0.05)',
    '& .MuiInputBase-root': {
      padding: '13px 20px !important',
    },
    '& .MuiInputLabel-root': {
      fontSize: '16px !important',
      color: COLORS.GREY,
      transform: 'translate(20px, 13px) scale(1)',

    },
    '& .MuiInputBase-input': {
      fontSize: 16,
      lineHeight: '23px',
      color: COLORS.PRIMARY,
      fontFamily: 'Noto Sans',
      padding: 0,
      '&.Mui-disabled': {
        background: COLORS.INPUT.DISABLED.BACKGROUND,
      },
    },
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${COLORS.INPUT.BORDER}`,
      borderColor: `${COLORS.INPUT.BORDER} !important`,
    },
    [`& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
    & .MuiOutlinedInput-root:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline,
    & .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline`]: {
      border: `2px solid ${COLORS.SECONDARY}`,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 9px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& fieldset': {
      borderRadius: 5,
      borderColor: COLORS.LIGHT_GREY,
    },
    '& .MuiOutlinedInput-input': {
      fontSize: 16,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& .MuiFormControl-marginDense': {
      marginTop: 1,
      marginBottom: 1,
    },
  },
});

export default useStyles;
