// @ts-ignore
import { Dispatch } from 'redux';

import { API, Endpoints } from 'api';
import { FiatCurrency } from 'types/currency';
import DonationWorkflowType from 'types/workflow';

import { AppReduxState } from '../../state';
import fetchCurrencyExchangeRateFail from '../fetchCurrencyExchangeRate.fail';
import fetchCurrencyExchangeRateStart from '../fetchCurrencyExchangeRate.start';
import fetchCurrencyExchangeRateSuccess from '../fetchCurrencyExchangeRate.success';

interface FetchCurrencyResponse {
  data: {
    rate: number;
  };
  status: string;
}

const DEFAULT_ERROR = {
  errorCode: 'UNKNOWN_ERROR',
};

export const fetchCurrencyExchangeRate = (cryptoCurrency: string) => async (
  dispatch: Dispatch,
  getState: () => AppReduxState,
) => {
  const currentState = getState();
  dispatch(fetchCurrencyExchangeRateStart.createAction());
  try {
    const payoutCurrency = currentState.organization?.organization?.payoutCurrency || FiatCurrency.USD;
    const response: FetchCurrencyResponse = await API.get(Endpoints.exchangeRate, {
      currency: cryptoCurrency.toUpperCase(),
      toCurrency: payoutCurrency,
    });
    if (response?.data?.rate) {
      if (currentState.workflow.type === DonationWorkflowType.Crypto) {
        dispatch(fetchCurrencyExchangeRateSuccess.createAction(response.data.rate));
      }
      return response.data;
    }

    dispatch(fetchCurrencyExchangeRateFail.createAction(DEFAULT_ERROR));
    return DEFAULT_ERROR;
  } catch (e: any) {
    dispatch(fetchCurrencyExchangeRateFail.createAction(e));
    return e;
  }
};

export default fetchCurrencyExchangeRate;
