import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  container: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 48,
  },
  ButtonGroup: {
    height: '100%',
    background: '#291B4F',
    bottomRightBorderRadius: 8,
    topRightBorderRadius: 0,
    topleftBorderRadius: 0,
    bottomLeftBorderRadius: 8,
    '& .MuiButton-contained.Mui-disabled': {
      color: '#5F4C94',
    },
    '& .MuiButton-contained:hover': {
      boxShadow: 'none',
      backgroundColor: 'inherit',
      '& svg': {
        color: '#FCD42B',
      },
    },
  },
  leftButton: {
    bottomLeftBorderRadius: 8,
  },
  iconWrapRight: {
    marginLeft: '10px',
    color: '#5F4C94',
  },
  rightButton: {
    fontSize: 13,
  },
  iconDisabled: {
    color: '#5F4C94 !important',
  },
});

export default useStyles;
