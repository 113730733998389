import React from 'react';

import { COLORS } from 'theme/colors';

import { IconProps } from './icon.types';

const path = [
  [
    'M4.58325 14.6667C5.27361 14.6667 5.83325 14.107 5.83325 13.4167C5.83325 12.7263 5.27361',
    '12.1667 4.58325 12.1667C3.8929 12.1667 3.33325 12.7263 3.33325 13.4167C3.33325 14.107 3.8929',
    '14.6667 4.58325 14.6667Z',
  ].join(' '),
  [
    'M15.8333 3H4.16667C3.062 3.00132 2.00296 3.44073 1.22185 4.22185C0.440735 5.00296 0.00132321',
    '6.062 0 7.16667L0 13.8333C0.00132321 14.938 0.440735 15.997 1.22185 16.7782C2.00296 17.5593',
    '3.062 17.9987 4.16667 18H15.8333C16.938 17.9987 17.997 17.5593 18.7782 16.7782C19.5593 15.997',
    '19.9987 14.938 20 13.8333V7.16667C19.9987 6.062 19.5593 5.00296 18.7782 4.22185C17.997 3.44073',
    '16.938 3.00132 15.8333 3ZM4.16667 4.66667H15.8333C16.4964 4.66667 17.1323 4.93006 17.6011',
    '5.3989C18.0699 5.86774 18.3333 6.50363 18.3333 7.16667H1.66667C1.66667 6.50363 1.93006 5.86774',
    '2.3989 5.3989C2.86774 4.93006 3.50363 4.66667 4.16667 4.66667ZM15.8333 16.3333H4.16667C3.50363',
    '16.3333 2.86774 16.0699 2.3989 15.6011C1.93006 15.1323 1.66667 14.4964 1.66667',
    '13.8333V8.83333H18.3333V13.8333C18.3333 14.4964 18.0699 15.1323 17.6011 15.6011C17.1323',
    '16.0699 16.4964 16.3333 15.8333 16.3333Z',
  ].join(' '),
];

export const IconCreditCard = ({
  width = 24,
  height = 24,
  color = COLORS.GREY,
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {path.map(item => <path d={item} fill={color} />)}
  </svg>
);

export default IconCreditCard;
