import { CreditCardDonationWorkflowStep } from 'types/workflow';

import { AnyAction } from '../state.types';
import { WorkflowReduxState } from './state';

export const actionTypeName = 'CREDIT_CARD__PAY__FAIL';

export type ActionPayFail = AnyAction & {
  error: string;
};

export const createAction = (error: string) => ({
  type: actionTypeName,
  error,
});

export const reducer = (
  state: WorkflowReduxState,
  action: ActionPayFail,
): WorkflowReduxState => {
  const nextState = {
    ...state,
    isSubmitting: false,
    error: { message: action.error },
    step: CreditCardDonationWorkflowStep.Error,
  };

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
