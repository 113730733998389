import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  icon: {
    width: 150,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '24px',
    justifyContent: 'center',
    paddingTop: 0,
    paddingBottom: 0,
  },
  stage: {
    cursor: 'default',
    pointerEvents: 'none',
    minWidth: 70,
    backgroundColor: '#DADFE5',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '24px',
    color: '#fff',
    flexBasis: '300px',
    textAlign: 'center',
  },
  active: {
    backgroundColor: '#5F4C94',
  },
  triangleLeft: {
    width: '0',
    height: '0',
    borderTop: '22px solid transparent',
    borderBottom: '22px solid transparent',
    borderLeft: '22px solid white',
    position: 'relative',
    '&:after': {
      content: "''",
      width: '0',
      height: '0',
      borderTop: '21px solid transparent',
      borderBottom: '21px solid transparent',
      borderLeft: '21px solid #dddddd',
      position: 'absolute',
      top: '-21px',
      right: '1px',
    },
  },
});

export default useStyles;
