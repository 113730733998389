export const KEYS = {
  DIALOG_TITLE: 'Email Confirmation?',
  DIALOG_TEXT: [
    'If you would like to receive a confirmation of ',
    'your grant while remaining anonymous, enter ',
    'your email below. This email will only be used ',
    'for the purpose of sending the confirmation.',
  ].join(''),
  SKIP: 'Skip',
  GET_RECEIPT: 'Confirm',
  EMAIL_FIELD_LABEL: 'Email for grant confirmation',
  MODAL_CONTAINER_ID: 'modalSubmitContainer',
  EMAIL_FIELD_KEY: 'receiptEmail',
};

export default KEYS;
