/* eslint-disable react/no-array-index-key */
import React from 'react';

import { IconProps } from './icon.types';

const path = [
  [
    'M11.9003 12.0905C11.561 13.4532 9.26766 12.7166 8.52393 12.5317L9.12605 10.1166C9.86979 10.302 12.2548 10.6698 11.9003 12.0905Z',
  ].join(' '),
  [
    'M12.2395 8.55575C11.9303 9.79464 10.0211 9.165 9.40161 9.01065L9.9477 6.82019C10.5677 6.97454 12.562 7.26287 12.2395 8.55575Z',
  ].join(' '),
  [
    'M12.4176 0.299231C7.06162 -1.03644 1.63537',
    '2.22429 0.300719 7.58176C-1.03546 12.9382',
    '2.22477 18.3644 7.58019 19.7006C12.9377 21.0368',
    '18.3639 17.7761 19.6991 12.4191C21.0352 7.06165 17.775',
    '1.6349 12.4176 0.299231ZM7.94187 6.74633C7.95562 6.51302',
    '7.87514 6.21909 7.43042 6.10804C7.44774 6.09683 6.63371',
    '5.90987 6.63371 5.90987L6.92611 4.73823L8.43905 5.11621L8.43804',
    '5.12233C8.66523 5.17836 8.89956 5.23236 9.13899 5.28687L9.58879',
    '3.48508L10.685 3.75864L10.2444 5.52527C10.5383 5.59251 10.8348',
    '5.66026 11.1231 5.73209L11.5607 3.97717L12.658 4.25073L12.2087',
    '6.05302C13.5938 6.53034 14.607 7.24555 14.4078 8.57613C14.2637',
    '9.55012 13.7237 10.0218 13.0069 10.1869C13.9911 10.6993 14.4919',
    '11.4854 14.0146 12.848C13.4226 14.5403 12.0156 14.6829 10.1446',
    '14.3289L9.69067 16.1485L8.59341 15.875L9.04169 14.0803C8.75744',
    '14.0095 8.46657 13.9346 8.16754 13.8531L7.71773 15.6564L6.62148',
    '15.3829L7.07587 13.5597C6.81964 13.494 6.55934 13.4247 6.29342',
    '13.358L4.86554 13.0019L5.4101 11.7462C5.4101 11.7462 6.21854',
    '11.9612 6.20784 11.9454C6.51858 12.0218 6.65612 11.8195 6.71063',
    '11.6845L7.94187 6.74633Z',
  ].join(' '),
];

export const IconCrypto = ({
  width = 20,
  height = 20,
  color = 'black',
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {path.map((item, key) => <path d={item} fill={color} key={key} />)}
  </svg>
);

export default IconCrypto;
