import qs from 'query-string';

import { DesignVersion, WidgetVariant } from 'types/workflow';

import runtimeLocalStorage, { RUNTIME_LOCAL_STORAGE_KEYS } from './runtimeLocalStore';

export const getUsedDisplayVariant = (): WidgetVariant => {
  const { search } = window.location;
  const { display } = qs.parse(search);
  const isValidDisplayParam = display
    && Object.values(WidgetVariant).includes(display as WidgetVariant);
  if (isValidDisplayParam) {
    return display as WidgetVariant;
  }

  return WidgetVariant.Embedded;
};

export const getUsedDesignVersion = (): DesignVersion => {
  const { search } = window.location;
  const { display, version } = qs.parse(search);
  const isPCDEnabled = runtimeLocalStorage.get(RUNTIME_LOCAL_STORAGE_KEYS.IS_PCD_ENABLED);

  if (isPCDEnabled) {
    return DesignVersion.V2;
  }

  const isValidVersionParam = version && Object.values(DesignVersion).includes(version as DesignVersion);
  if (isValidVersionParam) {
    return version as DesignVersion;
  }

  return display === WidgetVariant.Overlay
    ? DesignVersion.V2
    : DesignVersion.V1;
};

export function componentSwitch(
  generalComponent: any,
  newDesignComponent?: any,
) {
  if (!newDesignComponent) {
    return generalComponent;
  }

  const designVersion = getUsedDesignVersion();
  if (designVersion === DesignVersion.V2) {
    return newDesignComponent;
  }

  return generalComponent;
}

export default componentSwitch;
