import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { AppReduxState } from 'state/state';
import changeStep from 'state/workflow/changeStep';
import { AchDonationWorkflowStep } from 'types/workflow';
import componentSwitch from 'utils/componentSwitch';

import MandateScreen from './mandate';
import OverlayMandateScreen from './overlayVariant/mandate';

const mapStateToProps = (state: AppReduxState) => {
  const {
    organization,
  } = state.organization;

  return {
    organizationName: organization?.name ?? '',
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  proceed: () => {
    dispatch(changeStep.createAction(AchDonationWorkflowStep.Plaid));
  },
  goBack: () => {
    dispatch(changeStep.createAction(AchDonationWorkflowStep.FillDonorInfo));
  },
});

export const MandateConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(componentSwitch(MandateScreen, OverlayMandateScreen));

export default MandateConnect;
