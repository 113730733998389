import React from 'react';

import { COLORS } from 'theme/colors';

import { IconProps } from './icon.types';

const path = [
  [
    'M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209',
    '6.17317C0.00433286 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041',
    '15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937',
    '11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135',
    '13.9112 20 11.9778 20 10C19.9971 7.34872 18.9426 4.80684 17.0679 2.9321C15.1932 1.05736',
    '12.6513 0.00286757 10 0V0ZM10 18.3333C8.35183 18.3333 6.74066 17.8446 5.37025 16.9289C3.99984',
    '16.0132 2.93174 14.7117 2.30101 13.189C1.67028 11.6663 1.50525 9.99076 1.82679 8.37425C2.14834',
    '6.75774 2.94201 5.27288 4.10745 4.10744C5.27289 2.94201 6.75774 2.14833 8.37425 1.82679C9.99076',
    '1.50525 11.6663 1.67027 13.189 2.301C14.7118 2.93173 16.0132 3.99984 16.9289 5.37025C17.8446',
    '6.74066 18.3333 8.35182 18.3333 10C18.3309 12.2094 17.4522 14.3276 15.8899 15.8899C14.3276',
    '17.4522 12.2094 18.3309 10 18.3333Z',
  ].join(' '),
  [
    'M10 8.33337H9.16671C8.94569 8.33337 8.73373 8.42117 8.57745 8.57745C8.42117 8.73373 8.33337',
    '8.94569 8.33337 9.16671C8.33337 9.38772 8.42117 9.59968 8.57745 9.75596C8.73373 9.91224 8.94569',
    '10 9.16671 10H10V15C10 15.2211 10.0878 15.433 10.2441 15.5893C10.4004 15.7456 10.6124 15.8334',
    '10.8334 15.8334C11.0544 15.8334 11.2663 15.7456 11.4226 15.5893C11.5789 15.433 11.6667 15.2211',
    '11.6667 15V10C11.6667 9.55801 11.4911 9.13409 11.1786 8.82153C10.866 8.50897 10.4421 8.33337 10 8.33337Z',
  ].join(' '),
  [
    'M10 6.66663C10.6904 6.66663 11.25 6.10698 11.25 5.41663C11.25 4.72627 10.6904 4.16663 10',
    '4.16663C9.30964 4.16663 8.75 4.72627 8.75 5.41663C8.75 6.10698 9.30964 6.66663 10 6.66663Z',
  ].join(' '),
];

export const IconInfo = ({
  width = 24,
  height = 24,
  color = COLORS.GREY,
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {path.map(item => <path d={item} fill={color} />)}
  </svg>
);

export default IconInfo;
