import React from 'react';

import { COLORS } from 'theme/colors';

import { IconProps } from './icon.types';

const path = [
  [
    'M0.879313 9.08999L4.74931 13C4.84228 13.0937 4.95288 13.1681 5.07474 13.2189C5.1966 13.2697',
    '5.3273 13.2958 5.45931 13.2958C5.59133 13.2958 5.72203 13.2697 5.84389 13.2189C5.96575',
    '13.1681 6.07635 13.0937 6.16931 13C6.26304 12.907 6.33744 12.7964 6.3882 12.6746C6.43897',
    '12.5527 6.46511 12.422 6.46511 12.29C6.46511 12.158 6.43897 12.0273 6.3882 11.9054C6.33744',
    '11.7836 6.26304 11.673 6.16931 11.58L2.60931 7.99999H22.9993C23.2645 7.99999 23.5189 7.89464',
    '23.7064 7.7071C23.894 7.51956 23.9993 7.26521 23.9993 6.99999C23.9993 6.73478 23.894 6.48042',
    '23.7064 6.29289C23.5189 6.10535 23.2645 5.99999 22.9993 5.99999H2.54931L6.16931 2.37999C6.3466',
    '2.19402 6.4455 1.94693 6.4455 1.68999C6.4455 1.43305 6.3466 1.18597 6.16931 0.999991C6.07635',
    '0.906263 5.96575 0.831868 5.84389 0.7811C5.72203 0.730331 5.59133 0.704193 5.45931',
    '0.704193C5.3273 0.704193 5.1966 0.730331 5.07474 0.7811C4.95288 0.831868 4.84228 0.906263',
    '4.74931 0.999991L0.879313 4.84999C0.317511 5.41249 0.00195313 6.17499 0.00195312',
    '6.96999C0.00195312 7.765 0.317511 8.52749 0.879313 9.08999Z',
  ].join(' '),
];

export const IconBackArrow = ({
  width = 24,
  height = 14,
  color = COLORS.GREY,
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {path.map(item => <path d={item} fill={color} />)}
  </svg>
);

export default IconBackArrow;
