import { AnyAction, Reducer } from '../state.types';
import { DonationReduxState } from './state';

export const actionTypeName = 'DONATION__FINALIZE_ACH__FAIL';

export type ActionFinalizeAchFail = AnyAction & {
  error: Record<string, string>;
}

export const createAction = (error): ActionFinalizeAchFail => ({
  type: actionTypeName,
  error,
});

export const reducer: Reducer<DonationReduxState, ActionFinalizeAchFail> = (
  state: DonationReduxState,
  action: ActionFinalizeAchFail,
): DonationReduxState => {
  const nextState = {
    ...state,
    ach: {
      ...state.ach,
      error: action.error,
      isFinalizing: false,
    },
  };

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
