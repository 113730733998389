/* eslint-disable react/no-array-index-key */
import React from 'react';

import { COLORS } from 'theme/colors';

import { IconProps } from './icon.types';

const path = [
  [
    'M9.28542 1.46458C9.28542 1.05036 8.94964 0.714577 8.53542 0.714577L1.78542',
    '0.714577C1.37121 0.714577 1.03542 1.05036 1.03542 1.46458C1.03542 1.87879',
    '1.37121 2.21458 1.78542 2.21458H7.78542V8.21458C7.78542 8.62879 8.12121',
    '8.96458 8.53542 8.96458C8.94964 8.96458 9.28542 8.62879 9.28542 8.21458L9.28542',
    '1.46458ZM1.99469 9.06597L9.06575 1.99491L8.00509 0.934247L0.934025 8.00531L1.99469 9.06597Z',
  ].join(' '),
];

export const IconLinkArrow = ({
  width = 10,
  height = 10,
  color = COLORS.PRIMARY,
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {path.map((item, index) => <path d={item} fill={color} key={index} />)}
  </svg>
);

export default IconLinkArrow;
