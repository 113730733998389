export const FORM_SIZE = {
  VERSION_1: {
    WIDTH: 300,
    HEIGHT: 600,
  },
  VERSION_2: {
    WIDTH: 430,
    HEIGHT: 820,
  },
};

export default FORM_SIZE;
