export class APIResponse {
  public isResolved: boolean = false;

  public parsedResponse: any;

  private response: Response;

  constructor(response: Response) {
    this.response = response;
  }

  async asJson() {
    if (this.isResolved) {
      throw new Error('Response was already parsed');
    }

    this.parsedResponse = await this.response.json();
    this.isResolved = true;
    return this.parsedResponse;
  }

  async asText() {
    if (this.isResolved) {
      throw new Error('Response was already parsed');
    }

    this.parsedResponse = await this.response.text();
    this.isResolved = true;
    return this.parsedResponse;
  }

  async asBlob() {
    if (this.isResolved) {
      throw new Error('Response was already parsed');
    }

    this.parsedResponse = await this.response.blob();
    this.isResolved = true;
    return this.parsedResponse;
  }

  isResponseOk() {
    return this.response.ok;
  }
}

export default APIResponse;
