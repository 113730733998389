/* eslint-disable react/no-array-index-key */
import React from 'react';

import { COLORS } from 'theme/colors';

import { IconProps } from './icon.types';

const path = [
  [
    'M8 0.5C6.41775 0.5 4.87104 0.969192 3.55544 1.84824C2.23985 2.72729 1.21447 3.97672 0.608967',
    '5.43853C0.00346629 6.90034 -0.15496 8.50887 0.153721 10.0607C0.462403 11.6126 1.22433 13.038',
    '2.34315 14.1569C3.46197 15.2757 4.88743 16.0376 6.43928 16.3463C7.99113 16.655 9.59966 16.4965',
    '11.0615 15.891C12.5233 15.2855 13.7727 14.2602 14.6518 12.9446C15.5308 11.629 16 10.0823 16',
    '8.5C15.9977 6.37897 15.1541 4.34547 13.6543 2.84568C12.1545 1.34589 10.121 0.502294 8 0.5V0.5ZM8',
    '15.1667C6.68146 15.1667 5.39253 14.7757 4.2962 14.0431C3.19987 13.3106 2.34539 12.2694 1.84081',
    '11.0512C1.33622 9.83305 1.2042 8.49261 1.46144 7.1994C1.71867 5.90619 2.35361 4.71831 3.28596',
    '3.78596C4.21831 2.85361 5.4062 2.21867 6.6994 1.96143C7.99261 1.7042 9.33305 1.83622 10.5512',
    '2.3408C11.7694 2.84539 12.8106 3.69987 13.5431 4.7962C14.2757 5.89253 14.6667 7.18146 14.6667',
    '8.5C14.6647 10.2675 13.9617 11.9621 12.7119 13.2119C11.4621 14.4617 9.76752 15.1647 8 15.1667Z',
  ].join(' '),
  [
    'M8.47865 3.87534C8.0942 3.8053 7.69906 3.8206 7.32118 3.92017C6.9433 4.01974 6.59192 4.20115',
    '6.29191 4.45155C5.9919 4.70195 5.75059 5.01522 5.58506 5.36921C5.41952 5.72319 5.33381 6.10923',
    '5.33398 6.50001C5.33398 6.67682 5.40422 6.84639 5.52925 6.97142C5.65427 7.09644 5.82384 7.16668',
    '6.00065 7.16668C6.17746 7.16668 6.34703 7.09644 6.47206 6.97142C6.59708 6.84639 6.66732 6.67682',
    '6.66732 6.50001C6.66715 6.30386 6.71026 6.11009 6.79358 5.93252C6.8769 5.75494 6.99837 5.59794',
    '7.14933 5.4727C7.30029 5.34746 7.47703 5.25707 7.66693 5.20797C7.85684 5.15888 8.05524 5.15229',
    '8.24798 5.18868C8.51134 5.2398 8.75349 5.36827 8.94348 5.55767C9.13348 5.74707 9.2627 5.98881',
    '9.31465 6.25201C9.36712 6.52828 9.33089 6.81406 9.21115 7.0685C9.09142 7.32294 8.8943 7.53301',
    '8.64798 7.66868C8.2401 7.90499 7.90304 8.24635 7.67193 8.65721C7.44083 9.06807 7.32412 9.53338',
    '7.33398 10.0047V10.5C7.33398 10.6768 7.40422 10.8464 7.52925 10.9714C7.65427 11.0964 7.82384',
    '11.1667 8.00065 11.1667C8.17746 11.1667 8.34703 11.0964 8.47206 10.9714C8.59708 10.8464 8.66732',
    '10.6768 8.66732 10.5V10.0047C8.65895 9.77269 8.71185 9.54258 8.8207 9.33754C8.92954 9.13249',
    '9.09048 8.95974 9.28732 8.83668C9.77033 8.57139 10.1593 8.16298 10.4007 7.66761C10.6421 7.17225',
    '10.7242 6.61425 10.6355 6.07036C10.5469 5.52647 10.2919 5.0234 9.90565 4.63034C9.51942 4.23728',
    '9.0209 3.97352 8.47865 3.87534Z',
  ].join(' '),
  [
    'M8.66732 12.5C8.66732 12.1319 8.36884 11.8334 8.00065 11.8334C7.63246 11.8334 7.33398 12.1319',
    '7.33398 12.5C7.33398 12.8682 7.63246 13.1667 8.00065 13.1667C8.36884 13.1667 8.66732 12.8682 8.66732 12.5Z',
  ].join(' '),
];

export const IconQuestionMark = ({
  width = 24,
  height = 24,
  color = COLORS.GREY,
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {path.map((item, key) => <path key={key} d={item} fill={color} />)}
  </svg>
);

export default IconQuestionMark;
