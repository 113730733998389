import { AnyAction } from '../state.types';
import { PledgeReduxState } from './state';

export const actionTypeName = 'PLEDGE__FETCH_ASSET_EXCHANGE_RATE__SUCCESS';

export type ActionFetchCurrencyExchangeRateSuccess = AnyAction & {
  exchangeRate: number;
}

export const createAction = (exchangeRate: number) => ({
  type: actionTypeName,
  exchangeRate,
});

export const reducer = (
  state: PledgeReduxState,
  action: ActionFetchCurrencyExchangeRateSuccess,
): PledgeReduxState => ({
  ...state,
  exchangeRate: action.exchangeRate,
  usdAmount: (state.quantity || 0) * action.exchangeRate,
  exchangeRateLoading: false,
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
