import { createReducer } from '../createReducer';
import { buildReducerItem } from '../reducerItem';
import { Reducer } from '../state.types';
import setFundraiserId from './setFundraiserId';
import { FundraiserReduxState, initialState } from './state';

const reducers: Map<string, Reducer<FundraiserReduxState, any>> = new Map([
  buildReducerItem(setFundraiserId),
]);

export const fundraiserReducer = createReducer(initialState, reducers);

export default fundraiserReducer;
