import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  container: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 48,
    left: 0,
  },
  ButtonGroup: {
    height: '100%',
    flexDirection: 'row-reverse',
    borderBottomRightRadius: 5,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 5,
    background: '#291B4F',
    '& .MuiButton-contained.Mui-disabled': {
      color: '#5F4C94',
    },
    '& .MuiButton-contained:hover': {
      boxShadow: 'none',
      backgroundColor: 'inherit',
      '& svg': {
        color: '#FCD42B',
      },
    },
  },
  leftButton: {
    '&.MuiButton-contained': {
      borderTopLeftRadius: '5px !important',
      borderBottomLeftRadius: '5px !important',
      borderTopRightRadius: '0px !important',
      borderBottomRightRadius: '0px !important',
      background: '#291B4F',
      color: '#fff',
      justifyContent: 'left',
      paddingLeft: 20,
      borderRight: '1px solid #bdbdbd',
    },
  },
  leftButtonOnly: {
    justifyContent: 'center !important',
  },
  rightButton: {
    '&.MuiButton-contained': {
      color: '#fff',
      borderTopRightRadius: '5px !important',
      borderBottomRightRadius: '5px !important',
      borderTopLeftRadius: '0px !important',
      borderBottomLeftRadius: '0px !important',
      paddingLeft: 6,
      paddingRight: 20,
      justifyContent: 'flex-end',
      borderRightWidth: 0,
    },
  },
  iconWrapRight: {
    marginLeft: '10px',
    color: '#5F4C94',
  },
  iconWrapLeft: {
    marginRight: '10px',
    color: '#5F4C94',
  },
  iconDisabled: {
    color: '#5F4C94 !important',
  },
});

export default useStyles;
