import { AnyAction } from '../state.types';
import { WorkflowReduxState } from './state';

export const actionTypeName = 'CREDIT_CARD__PAY__START';

export type ActionPayStart = AnyAction;

export const createAction = () => ({
  type: actionTypeName,
});

export const reducer = (
  state: WorkflowReduxState,
): WorkflowReduxState => {
  const nextState = {
    ...state,
    isSubmitting: true,
  };

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
