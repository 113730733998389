import { AnyAction } from '../../state.types';
import { WalletReduxState } from '../state';

export const actionTypeName = 'WALLET__SET_ADDRESS';

export type ActionSetAddress = AnyAction & {
  selectedAddress: string | null;
}

export const createAction = (selectedAddress: string | null): ActionSetAddress => ({
  type: actionTypeName,
  selectedAddress,
});

export const reducer = (
  state: WalletReduxState,
  action: ActionSetAddress,
): WalletReduxState => {
  const { selectedAddress } = action;

  return {
    ...state,
    metamask: {
      ...state.metamask,
      selectedAddress,
    },
  };
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
