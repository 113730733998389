import React from 'react';

import { COLORS } from 'theme/colors';

import { IconProps } from './icon.types';

const path = [
  [
    'M10.0077 0.499912C4.48453 0.495264 0.00465206 4.96897 3.61921e-06 10.4922C-0.00464482 16.0154',
    '4.46907 20.4953 9.99227 20.4999C15.5147 20.4953 19.9915 16.0224 20 10.4999C20 4.97979 15.5279',
    '0.504561 10.0077 0.499912ZM9.99227 18.8358C5.38856 18.8358 1.65637 15.1036 1.65637 10.4999C1.65637',
    '5.8962 5.38856 2.16401 9.99227 2.16401C14.5944 2.16866 18.3235 5.89776 18.3282 10.4999C18.3282',
    '15.1036 14.596 18.8358 9.99227 18.8358Z',
  ].join(' '),
  [
    'M12.6939 10.0124C13.3627 9.63542 13.7241 8.88081 13.5995 8.12382C13.4447 6.95511 12.0515',
    '6.5139 10.9524 6.42105V4.66409H9.86883V6.40558H9.14129V4.66409H8.0577V6.40558H5.875V7.52788H6.6877C7.05148',
    '7.52788 7.22949 7.63624 7.22949 7.93034V12.7523C7.22949 13.1548 7.02824 13.2787 6.81152',
    '13.2787H5.98336V14.4242H8.08863V16.2044H9.17996V14.4242H9.9075V16.2044H10.9679V14.4242H11.2001C13.4292',
    '14.4242 14.1722 13.3328 14.1722 11.8468C14.1544 10.9706 13.5468 10.2167 12.6939 10.0124ZM9.14125',
    '7.59753H9.91524C10.457 7.59753 11.5252 7.69042 11.5252 8.57277C11.5623 9.14862 11.1451 9.65327',
    '10.5731 9.72601H9.14125V7.59753ZM10.3409 13.1703V13.1625H9.14125V10.8405H10.5345C10.8595 10.8405',
    '12.0824 10.9489 12.0824 11.8158C12.0824 12.6826 11.502 13.1703 10.3409 13.1703Z',
  ].join(' '),
];

export const IconBitcoin = ({
  width = 24,
  height = 24,
  color = COLORS.GREY,
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {path.map(item => <path key={item} d={item} fill={color} />)}
  </svg>
);

export default IconBitcoin;
