export type CardDetails = {
  donationUuid: string;
  mid: string;
  method: FiatPaymentMethod;
}

export enum FiatPaymentMethod {
  ApplePay = 'Apple Pay',
  Card = 'Card',
  GooglePay = 'Google Pay',
}

export interface CardPaymentToken {
  id: string;
  created: number;
  objectType: string;
  first6: string;
  last4: string;
  fingerprint: string;
  expMonth: string;
  expYear: string;
  brand: string;
  type: string;
  country: string;
  cardholderName: string;
  used: boolean;
  fraudCheckData: FraudCheckData;
}

export interface PaymentTokenCardDetails {
  number: string;
  expMonth: string;
  expYear: string;
  cvc: string;
  cardholderName: string;
}

interface FraudCheckData {
  ipAddress: string;
  ipCountry: string;
  userAgent: string;
  acceptLanguage: string;
}

export interface ThreeDSecure {
  amount: number;
  currency: string;
  enrolled: boolean;
  liabilityShift: string;
  version: string;
  authenticationFlow: unknown | null;
}

export interface ThreeDSecureToken extends CardPaymentToken {
  threeDSecureInfo: ThreeDSecure;
}

export interface PlaidOptions {
  publicToken: string;
  publicTokenMetadata: string;
}

export interface UpdatePaymentOptions {
  plaid: PlaidOptions;
}

export interface Shift4Utils {
  createToken: (components: unknown, additionalFields?: unknown) => any;
  createComponentGroup: (options?: unknown) => any;
  verifyThreeDSecure: (request: {
    amount: number;
    currency: string;
    card: string;
    merchantAccountId?: string;
  }) => Promise<ThreeDSecureToken & { error?: Record<string, string> }>;
  handlePaymentMethodNextAction: (clientObjectId: string) => any;
  updatePaymentMethod: (clientObjectId: string, options: UpdatePaymentOptions) => any;
}

export enum CardNetwork {
  Visa = 'visa',
  AmEx = 'amex',
  Discovery = 'discovery',
  Mastercard = 'mastercard',
}
