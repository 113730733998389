import { Dispatch } from 'redux';

import { API, Endpoints } from 'api';
import changeStep from 'state/workflow/changeStep';
import setError from 'state/workflow/setError';
import { DafDonationWorkflowStep } from 'types/workflow';

import fetchDonationAmountFail from '../fetchDonationAmount.fail';
import fetchDonationAmountStart from '../fetchDonationAmount.start';
import fetchDonationAmountSuccess from '../fetchDonationAmount.success';

export const DEFAULT_ERROR_CODE = 'Something went wrong.';

export interface FetchDonationAmountParams {
  organizationId: number;
  amountInCents: number;
  coverTransactionFees: boolean;
}

export const fetchDonationAmount = ({
  organizationId,
  amountInCents,
  coverTransactionFees,
}: FetchDonationAmountParams) => async (dispatch: Dispatch<any>) => {
  dispatch(fetchDonationAmountStart.createAction());

  try {
    const body = {
      amountInCents,
      coverTransactionFees,
    };

    const response = await API.post(Endpoints.chariot.donationAmount(organizationId), body);

    if (!response?.data?.finalAmountInCents) {
      throw DEFAULT_ERROR_CODE;
    }

    dispatch(fetchDonationAmountSuccess.createAction(response.data.finalAmountInCents));
  } catch (e: any) {
    const errorMessage = e.message || DEFAULT_ERROR_CODE;
    dispatch(fetchDonationAmountFail.createAction({ errorMessage }));
    dispatch(changeStep.createAction(DafDonationWorkflowStep.Error));
    dispatch(setError.createAction(errorMessage));
  }
};

export default fetchDonationAmount;
