import { AnyReduxState } from 'state/state.types';

import { initialState as metamaskInitialState, MetamaskReduxState } from './metamask/state';

export type WalletReduxState = AnyReduxState & {
  metamask: MetamaskReduxState;
}

export const initialState = (): WalletReduxState => ({
  metamask: metamaskInitialState(),
});
