import { AnyReduxState } from '../state.types';

export type CreditCardReduxState = AnyReduxState & {
  cardNumber: string;
  cardHolderName: string;
  cardExpirationDate: string;
  cardCVV: string;
  cardHolderZipCode: string;
  error: Record<string, string>;
}

export const initialState = (): CreditCardReduxState => ({
  cardNumber: '',
  cardHolderName: '',
  cardExpirationDate: '',
  cardCVV: '',
  cardHolderZipCode: '',
  error: {},
});
