import { environmentConfig } from 'environment/environment.config';

const cryptoPoweredBy: string = environmentConfig.projectOrganizationName.split(' ').join('').toLowerCase();

export const PoweredByBrand = {
  Crypto: cryptoPoweredBy,
  Card: 'Shift4',
  Gemini: 'Gemini',
} as const;

export type PoweredByBrandType = typeof PoweredByBrand[keyof typeof PoweredByBrand];

export default PoweredByBrand;
