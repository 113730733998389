import React from 'react';
import { createRoot } from 'react-dom/client';

import 'assets/fonts/poppins/index.css';
import 'assets/fonts/noto sans/index.css';
import 'assets/fonts/inter/index.css';
import './index.css';

import App from './app';
import { initSentry } from './sentryConfig';
import * as serviceWorker from './serviceWorker';
import { initializeGTM } from './utils/gtm';

initSentry();
initializeGTM();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
