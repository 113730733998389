import { Dispatch } from 'redux';

import { API, Endpoints } from 'api';
import changeStep from 'state/workflow/changeStep';
import setError from 'state/workflow/setError';
import AchDonationStatus from 'types/ach';
import { AchDonationWorkflowStep } from 'types/workflow';

import finalizeAchFail from '../finalizeAch.fail';
import finalizeAchStart from '../finalizeAch.start';
import finalizeAchSuccess from '../finalizeAch.success';

export const DEFAULT_ERROR_MESSGAE = 'Something went wrong. Please try again later.';

export const finalizeAch = (donationUuid: string) => async (dispatch: Dispatch<any>) => {
  dispatch(finalizeAchStart.createAction());

  try {
    const response = await API.post(Endpoints.donationAchFinalize, {
      donationUuid,
    });

    if (response?.data?.paymentMethodStatus) {
      const { paymentMethodStatus } = response.data;
      const isSuccessStatus = [
        AchDonationStatus.Chargeable,
        AchDonationStatus.Pending,
        AchDonationStatus.Used,
      ].includes(paymentMethodStatus);

      if (!isSuccessStatus) {
        throw Error(DEFAULT_ERROR_MESSGAE);
      }

      dispatch(finalizeAchSuccess.createAction(paymentMethodStatus));
      dispatch(changeStep.createAction(AchDonationWorkflowStep.Success));
      return { paymentMethodStatus };
    }

    throw Error(DEFAULT_ERROR_MESSGAE);
  } catch (error: any) {
    const { message, details } = error;

    const errorMessage = details?.errorMessage || message || DEFAULT_ERROR_MESSGAE;
    dispatch(finalizeAchFail.createAction({
      errorMessage,
    }));
    dispatch(setError.createAction(errorMessage));
    return { errorMessage };
  }
};

export default finalizeAch;
