import { AnyAction } from '../state.types';
import { PledgeReduxState } from './state';

export const actionTypeName = 'PLEDGE__SET_DROPDOWN_TOUCHED';

export type ActionSetDropdownTouched = AnyAction & {
  value: boolean;
}

export const createAction = (value: boolean) => ({
  type: actionTypeName,
  value,
});

export const reducer = (
  state: PledgeReduxState,
  action: ActionSetDropdownTouched,
): PledgeReduxState => {
  const { value } = action;

  return {
    ...state,
    cryptoDropdownTouched: value,
  };
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
