// @ts-ignore
import { environmentConfig } from 'environment/environment.config';
import { Dispatch } from 'redux';

import { API, Endpoints } from 'api';
import { DEFAULT_USD_VALUES } from 'config/workflow';
import { BASIC_CURRENCIES } from 'constants/currencies';
import setDonorInfo from 'state/donor/setDonorInfo';
import { DonorInfoFieldsState } from 'state/donor/state';
import setError from 'state/workflow/setError';
import { WidgetInitializationParameters } from 'types/init';
import { OrganizationInfo } from 'types/organization';
import { getCountryNameFromISOCode } from 'utils/countries';
import runtimeLocalStorage, { RUNTIME_LOCAL_STORAGE_KEYS } from 'utils/runtimeLocalStore';

import setDonationData from '../../donationData/setDonationData';
import fetchOrganizationInfoFail from '../fetchOrganizationInfo.fail';
import fetchOrganizationInfoStart from '../fetchOrganizationInfo.start';
import fetchOrganizationInfoSuccess from '../fetchOrganizationInfo.success';
import { InitializationDataResponse } from './fetchInitializationData.types';

interface FetchOrganizationResponse {
  data: InitializationDataResponse;
}

export interface FetchOrganizationInfoResult {
  data: InitializationDataResponse | null;
  error: { errorMessage: string } | null;
}

const DEFAULT_ERROR_MESSAGE = 'Something went wrong.';

export const fetchOrganizationInfo = (
  params: WidgetInitializationParameters,
) => async (dispatch: Dispatch): Promise<FetchOrganizationInfoResult> => {
  try {
    dispatch(fetchOrganizationInfoStart.createAction());

    const response: FetchOrganizationResponse = await API.post(Endpoints.initialize, params);

    if (response?.data) {
      const { donationData, isPCDEnabled: isPCDEnabledProvided, ...organizationInfoProperties } = response.data;
      const isPCDEnabled = isPCDEnabledProvided ?? environmentConfig.isPCDEnabled;

      const organizationInfo: OrganizationInfo = {
        ...organizationInfoProperties,
        isPCDEnabled,
      };

      runtimeLocalStorage.set(RUNTIME_LOCAL_STORAGE_KEYS.IS_PCD_ENABLED, isPCDEnabled);

      const defaultAmount = donationData?.defaultAmount ?? DEFAULT_USD_VALUES.CRYPTO;
      const defaultCryptocurrency = donationData?.defaultCryptocurrency?.toLowerCase() ?? BASIC_CURRENCIES.BTC;
      const nextDonationData = { ...donationData, defaultAmount, defaultCryptocurrency };
      dispatch(setDonationData.createAction(nextDonationData));
      dispatch(fetchOrganizationInfoSuccess.createAction(organizationInfo));
      if (donationData?.personalInfoDefaults?.country) {
        const presetDefaultCountry = donationData?.personalInfoDefaults?.country ?? '';
        const presetValues: Partial<DonorInfoFieldsState> = {
          countryId: presetDefaultCountry,
          country: getCountryNameFromISOCode(presetDefaultCountry),
        };
        runtimeLocalStorage.set(RUNTIME_LOCAL_STORAGE_KEYS.COUNTRY, presetValues.country);
        runtimeLocalStorage.set(RUNTIME_LOCAL_STORAGE_KEYS.COUNTRY_ID, presetValues.countryId);
        dispatch(setDonorInfo.createAction(presetValues));
      }
      return {
        data: response.data,
        error: null,
      };
    }

    throw Error(DEFAULT_ERROR_MESSAGE);
  } catch (e: any) {
    dispatch(fetchOrganizationInfoFail.createAction(e));
    dispatch(setError.createAction(DEFAULT_ERROR_MESSAGE));
    return {
      data: null,
      error: {
        errorMessage: DEFAULT_ERROR_MESSAGE,
      },
    };
  }
};

export default fetchOrganizationInfo;
