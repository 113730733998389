import { AnyAction } from '../state.types';
import { OrganizationReduxState } from './state';

export const actionTypeName = 'ORGANIZATION__FETCH_ORGANIZATION_INFO__FAIL';

export type ActionFetchOrganizationInfoFail = AnyAction & {
  error: Record<string, string> | null;
}

export const createAction = (error: Record<string, string>) => ({
  type: actionTypeName,
  error,
});

export const reducer = (
  state: OrganizationReduxState,
  action: ActionFetchOrganizationInfoFail,
): OrganizationReduxState => ({
  ...state,
  isFetching: false,
  error: action.error,
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
