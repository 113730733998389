import { AnyAction, Reducer } from '../state.types';
import { DonationReduxState } from './state';

export const actionTypeName = 'DONATION__FINALIZE_ACH__START';

export type ActionFinalizeAchStart = AnyAction;

export const createAction = (): ActionFinalizeAchStart => ({
  type: actionTypeName,
});

export const reducer: Reducer<DonationReduxState, ActionFinalizeAchStart> = (
  state: DonationReduxState,
): DonationReduxState => {
  const nextState = {
    ...state,
    ach: {
      ...state.ach,
      isFinalizing: true,
    },
  };

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
