import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';
import FONT_FAMILIES from 'theme/fontFamilies';

export const useStyles = makeStyles()({
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100% - 72px)',
    padding: '0 34px 30px',
    boxSizing: 'border-box',
  },
  infoPanelHeader: {
    textAlign: 'center',
    margin: 0,
    color: COLORS.TEXT.PRIMARY,
    fontFamily: FONT_FAMILIES.ROBOTO,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 'normal',
    marginBottom: 5,
  },
  infoPanel: {
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 5,
    marginBottom: 32,
  },
  infoItem: {
    color: COLORS.TEXT.PRIMARY,
    textAlign: 'center',
    fontFamily: FONT_FAMILIES.ROBOTO,
    fontSize: 13,
    fontWeight: 400,
    lineHeight: '18px',
  },
  chariotButtonContainer: {
    maxWidth: '100%',
  },
});

export default useStyles;
