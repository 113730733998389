import { makeStyles } from 'tss-react/mui';

import { COLOR_SCHEMA } from 'theme/colors';

const useStyles = makeStyles()({
  fakeButton: {
    width: '100%',
    background: `${COLOR_SCHEMA.LIGHT_GREY} !important`,
  },
});

export default useStyles;
