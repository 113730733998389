import React from 'react';

import { SetFieldValueFunction } from '../types';
import { CustomTextField } from './customTextField';
import { FormFieldDescription } from './fields.types';
import { useStyles } from './styles';

interface TextFieldProps {
  item: FormFieldDescription;
  isSubmitting: boolean;
  setFieldValue: SetFieldValueFunction;
  hasPledgeError?: boolean;
}

export const TextField = ({
  item,
  isSubmitting,
  setFieldValue,
  hasPledgeError,
}: TextFieldProps) => {
  const { classes, cx } = useStyles({});
  return (
    <div
      key={item.label}
      className={cx([
        item.view === 'short' ? '' : classes.longView,
        classes.formItem,
      ])}
    >
      <CustomTextField
        item={item}
        required={Boolean(item.isRequired)}
        name={item.name}
        isSubmitting={isSubmitting}
        disabled={Boolean(item.isDisabled)}
        className={classes.defaultInput}
        setFieldValue={setFieldValue}
        hasPledgeError={hasPledgeError}
      />
    </div>
  );
};

export default TextField;
