import buildReducerItem from 'state/reducerItem';

import { createReducer } from '../createReducer';
import { Reducer } from '../state.types';
import signDonationPledgeFail from './signDonationPledge.fail';
import signDonationPledgeStart from './signDonationPledge.start';
import signDonationPledgeSuccess from './signDonationPledge.success';
import { initialState, StockReduxState } from './state';
import submitBrokerInfoFail from './submitBrokerInfo.fail';
import submitBrokerInfoStart from './submitBrokerInfo.start';
import submitBrokerInfoSuccess from './submitBrokerInfo.success';
import updateBrokerInfo from './updateBrokerInfo';
import updateSignature from './updateSignature';

// Reducers
const reducers: Map<string, Reducer<StockReduxState, any>> = new Map([
  buildReducerItem(updateBrokerInfo),
  buildReducerItem(submitBrokerInfoStart),
  buildReducerItem(submitBrokerInfoSuccess),
  buildReducerItem(submitBrokerInfoFail),
  buildReducerItem(updateSignature),
  buildReducerItem(signDonationPledgeStart),
  buildReducerItem(signDonationPledgeSuccess),
  buildReducerItem(signDonationPledgeFail),
]);

export const stockReducer = createReducer(initialState, reducers);

export default stockReducer;
