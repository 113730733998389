import { AnyAction } from '../state.types';
import { DonationReduxState } from './state';

export const actionTypeName = 'WORKFLOW__SUBMIT_PLEDGE__FAIL';

export type ActionSubmitPledgeFail = AnyAction & {
}

export const createAction = () => ({
  type: actionTypeName,
});

export const reducer = (
  state: DonationReduxState,
  action: ActionSubmitPledgeFail,
): DonationReduxState => ({
  ...state,
  isSubmitting: false,
  crypto: {
    ...state.crypto,
    donationAddress: '',
    fallbackAddressUsed: false,
  },
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
