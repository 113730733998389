import DonationWorkflowType from 'types/workflow';

export enum InputViewType {
  Short = 'short',
  Clean = 'clean'
}

export type InputViewSettings = {
  display: InputViewType;
  appliesTo: DonationWorkflowType[];
}

export interface SelectOption {
  id: string | number;
  label: string;
}

export interface FormFieldDescription {
  label: string;
  type: string;
  name: string;
  options?: SelectOption[];
  title?: string;
  view?: InputViewSettings;
  isRequired?: boolean;
  isDisabled?: boolean;
  hiddenFor?: DonationWorkflowType[];
  formatter?: (rawValue: string) => string;
}
