import React, {
  ChangeEvent,
  FocusEvent,
  useEffect,
  useMemo,
} from 'react';
import { Box, Input, Tooltip } from '@mui/material';

import OptionallyVisible from 'components/optionallyVisible';
import { DonationPledge } from 'types/pledge';
import { calculateWithFeesBeforeGross, USDFormatter } from 'utils/currency';
import { locators, QALocator } from 'utils/tests/QA';

import { FiatFormatCustom } from './numberFormatCustom';
import { BASIC_CURRENCIES, LABELS } from './pledge.keys';
import SelectAmountButtons from './selectAmountButtons';
import useStyles from './styles';

export interface FiatCurrencyAmountBoxProps {
  onAmountChange: (value: number) => void;
  pledge: DonationPledge;
  showSubPrice: boolean;
  error?: string;
  fees: {
    percent?: number;
    usd: number;
  } | null;
}

export const FiatCurrencyAmountBox = ({
  onAmountChange,
  fees,
  showSubPrice,
  pledge,
  error = '',
}: FiatCurrencyAmountBoxProps) => {
  const { classes, cx } = useStyles();
  const formattedUsdValue = useMemo(() => USDFormatter().format(pledge.usdAmount), [pledge.usdAmount]);
  const formattedGrossValue = useMemo(() => {
    if (!fees) {
      return USDFormatter(2).format(pledge.usdAmount);
    }

    const amount = calculateWithFeesBeforeGross(pledge.usdAmount, fees);
    return USDFormatter(2).format(amount);
  }, [fees, pledge.usdAmount]);

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onAmountChange(Number(value));
  };

  const handleInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onAmountChange(Number(value));
  };

  useEffect(() => {
    onAmountChange(pledge.usdAmount);
  }, [pledge.usdAmount]);

  return (
    <>
      <SelectAmountButtons onSelect={onAmountChange} usdAmount={formattedUsdValue} />
      <div className={classes.inputWrapper}>
        <h6 className={classes.fieldHeader}>{LABELS.ENTER_AMOUNT_TITLE}</h6>
        <Tooltip
          classes={{
            arrow: classes.tooltipArrow,
            tooltip: classes.tooltip,
          }}
          open={Boolean(error)}
          arrow
          disableTouchListener
          placement="bottom-start"
          title={error}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="flex-start"
            className={cx({
              [classes.amountBox]: true,
              error: Boolean(error),
            })}
          >
            <Box>
              <Input
                className={classes.input}
                disableUnderline
                inputProps={{
                  className: classes.mainCurrencyInput,
                  prefix: LABELS.USD_VALUE_PREFIX,
                }}
                value={pledge.usdAmount}
                onChange={handleAmountChange}
                onBlur={handleInputBlur}
                inputComponent={FiatFormatCustom}
                {...QALocator(locators.components.fiat.amountInput)}
              />
              <OptionallyVisible
                visible={showSubPrice}
              >
                <div>
                  <div className={classes.subPrice}>
                    {`${LABELS.INCLUDING_FEES_PREFIX} ${formattedGrossValue}`}
                  </div>
                </div>
              </OptionallyVisible>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              className={classes.swapBox}
            >
              <div className={classes.calculatedCurrency}>
                {BASIC_CURRENCIES.USD}
              </div>
            </Box>
          </Box>
        </Tooltip>
      </div>
    </>
  );
};

export default FiatCurrencyAmountBox;
