import Shift4UtilsContextValues from 'components/shift4Utils/context';
import { OrganizationInfo } from 'types/organization';

export const shouldInitializeShift4Utils = (
  shift4UtilsCtx: Shift4UtilsContextValues,
  organization: OrganizationInfo,
) => {
  const areShift4UtilsInitialized = Boolean(shift4UtilsCtx?.shift4Utils?.verifyThreeDSecure);
  const hasPublicKey = organization?.shift4PublicKey;

  return !areShift4UtilsInitialized && hasPublicKey && Boolean(window.Shift4);
};

export default shouldInitializeShift4Utils;
