import { AnyAction } from 'state/state.types';

import { ChariotReduxState } from './state';

export const actionTypeName = 'CHARIOT__SET_CONNECTION_ID';

type ActionSetConnectionId = AnyAction & {
  id: string | null;
}

export const createAction = (id: string | null) => ({
  type: actionTypeName,
  id,
});

export const reducer = (
  state: ChariotReduxState,
  { id }: ActionSetConnectionId,
): ChariotReduxState => ({
  ...state,
  connection: {
    ...state.connection,
    id,
  },
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
