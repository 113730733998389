import React, {
  FocusEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { NumericFormat } from 'react-number-format';
import { InputBaseComponentProps } from '@mui/material';

const KEYS = {
  SEPARATOR: '.',
  SCALE: 8,
  USD_PREFIX: '$',
  GROUP_SEPARATOR: ',',
  NUMBER_VALUE_CLEANING_EXP: /[^\d.]/ig,
  SEPARATOR_REPLACE_VALUE: '0.',
};

export const NumberFormatCustom = ({ onChange, ...props }: InputBaseComponentProps) => {
  const onValueChange = useCallback(
    (values) => {
      const rawValue = values.value.replace(KEYS.NUMBER_VALUE_CLEANING_EXP, '');
      const nextValue = rawValue === KEYS.SEPARATOR ? KEYS.SEPARATOR_REPLACE_VALUE : rawValue;
      const newEvent = { target: { value: nextValue } };
      if (onChange) {
        // TODO: figure out
        // @ts-ignore
        onChange(newEvent);
      }
    },

    [onChange],
  );

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const nextValue = value.replace(KEYS.NUMBER_VALUE_CLEANING_EXP, '');
    const nextEvent = {
      ...event,
      target: {
        ...event.target,
        value: nextValue,
      },
    };

    props?.onBlur?.(nextEvent);
  };

  return (
    <NumericFormat
      {...props}
      onBlur={handleBlur}
      defaultValue={props.defaultValue as number}
      onValueChange={onValueChange}
      thousandsGroupStyle="thousand"
      thousandSeparator={KEYS.GROUP_SEPARATOR}
      decimalSeparator={KEYS.SEPARATOR}
      decimalScale={KEYS.SCALE}
    />
  );
};

export const FiatFormatCustom = ({ onChange, ...props }: InputBaseComponentProps) => {
  const [isFocused, setFocused] = useState<boolean>(false);
  const [localValue, setLocalValue] = useState<string>(props.defaultValue as string);

  useEffect(() => {
    if (!isFocused) {
      setLocalValue(props.value);
    }
  }, [props.value, isFocused]);

  const onValueChange = useCallback(
    (values) => {
      setLocalValue(values.formattedValue);
      const nextValue = values.value.replace(KEYS.NUMBER_VALUE_CLEANING_EXP, '');
      onChange?.({
        target: {
          value: nextValue,
        },
      } as unknown as FormEvent<HTMLInputElement>);
    },

    [onChange],
  );

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    props?.onFocus?.(event);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const nextValue = value.replace(KEYS.NUMBER_VALUE_CLEANING_EXP, '');
    const nextEvent = {
      ...event,
      target: {
        ...event.target,
        value: nextValue,
      },
    };
    setFocused(false);

    onChange?.(nextEvent);

    props?.onBlur?.(nextEvent);
  };

  return (
    <NumericFormat
      {...props}
      value={localValue}
      onFocus={handleFocus}
      onBlur={handleBlur}
      defaultValue={props.defaultValue as number}
      prefix={props.prefix}
      onValueChange={onValueChange}
      thousandsGroupStyle="thousand"
      thousandSeparator={KEYS.GROUP_SEPARATOR}
      decimalSeparator={KEYS.SEPARATOR}
      decimalScale={KEYS.SCALE}
    />
  );
};

export default NumberFormatCustom;
