import {
  AchDonationWorkflowStep,
  CreditCardDonationWorkflowStep,
  CryptoDonationWorkflowStep,
  DafDonationWorkflowStep,
  DesignVersion,
  DonationWorkflowType,
  GenericWorkflowStep,
  StockDonationWorkflowStep,
  WorkflowStep,
} from 'types/workflow';

import { getUsedDesignVersion } from '../../utils/componentSwitch';
import { AnyAction } from '../state.types';
import { WorkflowReduxState } from './state';

export const actionTypeName = 'WORKFLOW__SET_TYPE';

export type ActionSetType = AnyAction & {
  workflowType: DonationWorkflowType | null;
  areRecurringDonationsEnabled?: boolean;
}

export const createAction = (
  workflowType: DonationWorkflowType | null,
  areRecurringDonationsEnabled?: boolean,
) => ({
  type: actionTypeName,
  workflowType,
  areRecurringDonationsEnabled,
});

export const reducer = (
  state: WorkflowReduxState,
  action: ActionSetType,
): WorkflowReduxState => {
  const { areRecurringDonationsEnabled, workflowType } = action;
  const designVersion: DesignVersion = getUsedDesignVersion();

  const initialStepForDefaultWidget = areRecurringDonationsEnabled
    ? CreditCardDonationWorkflowStep.Frequency
    : CreditCardDonationWorkflowStep.Pledge;
  const inintialCardStep = {
    [DesignVersion.V2]: CreditCardDonationWorkflowStep.Pledge,
    [DesignVersion.V1]: initialStepForDefaultWidget,
  };

  const initialStepMap: Record<DonationWorkflowType, WorkflowStep> = {
    [DonationWorkflowType.Crypto]: CryptoDonationWorkflowStep.Pledge,
    [DonationWorkflowType.CreditCard]: inintialCardStep[designVersion] ?? inintialCardStep[DesignVersion.V1],
    [DonationWorkflowType.Stock]: StockDonationWorkflowStep.Pledge,
    [DonationWorkflowType.Daf]: DafDonationWorkflowStep.Pledge,
    [DonationWorkflowType.Ach]: AchDonationWorkflowStep.Pledge,
    [DonationWorkflowType.Generic]: GenericWorkflowStep.Error,
  };

  const step = workflowType === null ? null : initialStepMap[workflowType];

  const nextState = {
    ...state,
    type: workflowType,
    error: {
      message: '',
      title: undefined,
    },
    step,
  };

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
