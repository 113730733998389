export const descendingComparator = (itemA: any, itemB: any) => {
  if (itemB < itemA) {
    return -1;
  }

  if (itemB > itemA) {
    return 1;
  }

  return 0;
};

export const defaultComparator = (a: any, b: any, orderBy: string) => {
  const itemA = a[orderBy];
  const itemB = b[orderBy];
  return descendingComparator(itemA, itemB);
};

export const stringComparator = (a: any, b: any, orderBy: string) => {
  const itemA = (a[orderBy] ?? '').toString().toLowerCase();
  const itemB = (b[orderBy] ?? '').toString().toLowerCase();
  return descendingComparator(itemA, itemB);
};

export const booleanComparator = (a: any, b: any, orderBy: string) => {
  const itemA = a[orderBy] ? 1 : 0;
  const itemB = b[orderBy] ? 1 : 0;
  return descendingComparator(itemA, itemB);
};

export const numberComparator = (a: any, b: any, orderBy: string) => {
  const itemA = Number(a[orderBy]);
  const itemB = Number(b[orderBy]);
  return descendingComparator(itemA, itemB);
};

export default descendingComparator;
