import React from 'react';

import useStyles from './styles';

export const ErrorPage = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <p className={classes.error}>Something went wrong. Please try again later.</p>
    </div>
  );
};

export default ErrorPage;
