import React from 'react';

import { DonorInfo } from 'types/donor';
import DonationWorkflowType from 'types/workflow';

import { AutocompleteField } from './autocompleteField';
import { CheckboxField } from './checkbox';
import { FormFieldDescription } from './fields.types';
import { TextField } from './textField';
import { SetFieldValueFunction } from './types';

const typeComponentMap = {
  checkbox: CheckboxField,
  autocomplete: AutocompleteField,
  default: TextField,
};

interface FormFieldProps {
  item: FormFieldDescription;
  isSubmitting: boolean;
  values: DonorInfo;
  workflowType: DonationWorkflowType;
  setFieldValue: SetFieldValueFunction;
  setFieldTouched: (field: string, isTouched: boolean) => void;
  hasPledgeError?: boolean;
}

export const FormField = ({ item, ...props }: FormFieldProps) => {
  const Component = typeComponentMap[item.type] || typeComponentMap.default;

  return <Component {...props} item={item} />;
};

export default FormField;
