import { DonationPledge, StocksDonationPledge } from '../../types/pledge';
import { AnyAction } from '../state.types';
import { PledgeReduxState } from './state';

export const actionTypeName = 'PLEDGE__UPDATE_PLEDGE';

export type ActionUpdatePledge = AnyAction & {
  changes: Partial<DonationPledge & StocksDonationPledge>;
}

export const createAction = (changes: Partial<DonationPledge>) => ({
  type: actionTypeName,
  changes,
});

export const reducer = (
  state: PledgeReduxState,
  action: ActionUpdatePledge,
): PledgeReduxState => {
  const { changes } = action;
  const nextState = {
    ...state,
    ...changes,
  };

  if ('quantity' in changes) {
    nextState.usdAmount = (nextState.quantity || 0) * nextState.exchangeRate;
  }

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
