import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

const blinkAnimation = keyframes`
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;

const useStyles = makeStyles<{ isSelected: boolean; isOpen: boolean }>()((theme, { isSelected, isOpen }) => ({
  selectWrapper: {
    display: 'flex !important',
    alignItems: 'center',
    padding: '3px 9px 3px 0px !important',
    lineHeight: '21px !important',
  },
  optionContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minWidth: '0px',
  },
  option: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
    fontFamily: 'Noto Sans',
    textOverflow: 'ellipsis',
    fontSize: '16px !important',
    lineHeight: '23px',
    minHeight: '0px !important',
  },
  icon: {
    maxWidth: 22,
    maxHeight: 22,
    marginRight: 8,
  },
  optionWrapper: {
    '&.MuiFormControl-root': {
      display: 'flex',
      margin: '0 auto 0',
      '& .MuiInputBase-input': {
        fontWeight: 400,
        fontFamily: 'Noto Sans',
        fontSize: '16px',
        lineHeight: '23px',
        paddingLeft: 1,
        paddingTop: 0,
        paddingBottom: 0,
        '&::focused': {
          background: 'transparent',
        },
      },
    },
  },
  optionsContainer: {
    maxHeight: '280px!important',
    left: 'calc(50vw - 114px)!important',
  },
  inputRoot: {
    height: 50,
    padding: '13px 20px !important',
    [`& .MuiOutlinedInput-notchedOutline,
    &:hover .MuiOutlinedInput-notchedOutline,
    &.Mui-focused .MuiOutlinedInput-notchedOutline`]: {
      border: isOpen
        ? `2px solid ${theme.palette.secondary.main} !important`
        : `1px solid ${theme.palette.divider} !important`,
    },
    '& .MuiInputBase-input': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      marginRight: 40,
    },
  },
  labelRoot: {
    fontSize: '0.8125rem',
  },
  labelShrink: {
    fontSize: '1rem',
  },
  listPopper: {
    '& .MuiAutocomplete-paper': {
      marginTop: 0,
      borderRadius: '0 0 4px 4px',
    },
  },
  listbox: {
    border: `2px solid ${theme.palette.secondary.main}`,
    maxHeight: 200,
    boxShadow: '0px -20px 10px -10px rgba(0,0,0,0.1) inset',
    '-webkit-box-shadow': '0px -20px 10px -10px rgba(0,0,0,0.1) inset',
    '-moz-box-shadow': '0px -20px 10px -10px rgba(0,0,0,0.1) inset',
    '&::after': {
      content: "''",
      width: '0',
      height: '0',
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderTop: '10px solid #000000',
      position: 'absolute',
      bottom: '5px',
      right: 'calc(50% - 10px)',
      opacity: 1,
      animation: `${blinkAnimation} 2s linear infinite`,
    },
  },
  listOption: {
    height: 40,
    padding: '8px 16px !important',
  },
}));

export const styles = {
  iconWrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 5,
  },
  root: {
    color: 'white',
    backgroundColor: '#4c4953',
    '&:hover': {
      backgroundColor: '#68656f',
    },
  },
};

export default useStyles;
