import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import setAchError from 'state/donation/setAchError';
import finalizeAch from 'state/donation/thunk/finalizeAch.thunk';
import { AppReduxState } from 'state/state';
import changeStep from 'state/workflow/changeStep';
import { AchDonationWorkflowStep } from 'types/workflow';
import componentSwitch from 'utils/componentSwitch';

import OverlayPlaidScreen from './ovelayVariant/plaid';
import PlaidScreen from './plaid';

const mapStateToProps = (state: AppReduxState) => {
  const {
    donationUuid,
    isLoading,
    isFinalizing,
    clientObjectId,
  } = state.donation.ach;

  return {
    donationUuid,
    isLoading,
    isFinalizing,
    clientObjectId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  finalizeAch: (donationUuid: string) => {
    const thunk = finalizeAch(donationUuid);
    return dispatch(thunk);
  },
  setAchError: (errorMessage) => {
    dispatch(setAchError.createAction(errorMessage));
    dispatch(changeStep.createAction(AchDonationWorkflowStep.Error));
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  finalizeAch: () => dispatchProps.finalizeAch(stateProps.donationUuid),
});

export const PlaidConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(componentSwitch(PlaidScreen, OverlayPlaidScreen));

export default PlaidConnect;
