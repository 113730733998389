import { AnyAction, Reducer } from '../state.types';
import { DonationReduxState } from './state';

export const actionTypeName = 'DONATION__GET_CARD_TOKEN__FAIL';

export type ActionGetCardTokenFail = AnyAction & {
  error: Record<string, string>;
};

export const createAction = (error: Record<string, string>): ActionGetCardTokenFail => ({
  type: actionTypeName,
  error,
});

export const reducer: Reducer<DonationReduxState, ActionGetCardTokenFail> = (
  state: DonationReduxState,
  { error }: ActionGetCardTokenFail,
): DonationReduxState => {
  const nextState = {
    ...state,
    card: {
      ...state.card,
      threeDSecure: {
        ...state.card.threeDSecure,
        isLoading: false,
        error,
        token: null,
      },
    },
  };

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
