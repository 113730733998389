import { createReducer } from '../createReducer';
import { buildReducerItem } from '../reducerItem';
import { Reducer } from '../state.types';
import setAddress from './metamask/setAddress';
import { initialState, WalletReduxState } from './state';

const reducers: Map<string, Reducer<WalletReduxState, any>> = new Map([
  buildReducerItem(setAddress),
]);

export const fundraiserReducer = createReducer(initialState, reducers);

export default fundraiserReducer;
