import React from 'react';

import DonationWorkflowType from 'types/workflow';

import { CustomTextField } from './customTextField';
import { FormFieldDescription, InputViewType } from './fields.types';
import { useStyles } from './styles';
import { SetFieldValueFunction } from './types';

interface TextFieldProps {
  item: FormFieldDescription;
  isSubmitting: boolean;
  workflowType: DonationWorkflowType;
  setFieldValue: SetFieldValueFunction;
  hasPledgeError?: boolean;
}

export const TextField = ({
  item,
  isSubmitting,
  workflowType,
  setFieldValue,
  hasPledgeError,
}: TextFieldProps) => {
  const { classes, cx } = useStyles();
  const hasShortDisplayType = item.view?.display === InputViewType.Short;
  const isViewOptionApplicable = item.view?.appliesTo.includes(workflowType);
  const isShortField = hasShortDisplayType && isViewOptionApplicable;

  return (
    <div
      key={item.label}
      className={cx({
        [classes.formItem]: true,
        [classes.longView]: !isShortField,
      })}
    >
      <CustomTextField
        item={item}
        required={Boolean(item.isRequired)}
        name={item.name}
        isSubmitting={isSubmitting}
        disabled={Boolean(item.isDisabled)}
        className={classes.defaultInput}
        setFieldValue={setFieldValue}
        hasPledgeError={hasPledgeError}
      />
    </div>
  );
};

export default TextField;
