import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

const useStyles = makeStyles()(theme => ({
  button: {
    flex: 1,
    height: 50,
    textTransform: 'unset !important' as 'unset',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Poppins',
    borderRadius: theme.shape.borderRadius,
    lineHeight: '24px',
    boxShadow: 'none',
    border: 0,
    padding: '13px 20px 11px',
    '&:hover': {
      border: 0,
    },
    '&:disabled': {
      background: `${COLORS.BUTTON.BACKGROUND.DISABLED} !important`,
    },
  },
  primary: {
    color: `${COLORS.BUTTON.COLOR.PRIMARY} !important`,
    background: `${COLORS.BUTTON.BACKGROUND.PRIMARY} !important`,
  },
  secondary: {
    color: `${COLORS.BUTTON.COLOR.PRIMARY} !important`,
    background: `${COLORS.BUTTON.BACKGROUND.SECONDARY} !important`,
  },
  text: {
    color: `${COLORS.BUTTON.COLOR.PRIMARY} !important`,
    background: 'transparent !important',
  },
}));

export default useStyles;
