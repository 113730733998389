/* eslint-disable react/no-array-index-key */
import React from 'react';

import { COLORS } from 'theme/colors';

import { IconProps } from './icon.types';

const path = [
  [
    'M3.73773 4.89982C5.88044 5.51864 6.36546 6.00592 6.98141',
    '8.15859C6.99824 8.21742 7.05188 8.25811 7.1129 8.25811C7.17392',
    '8.25811 7.22755 8.21756 7.24438 8.15859C7.86033 6.00592',
    '8.34535 5.51864 10.4881 4.89982C10.5468 4.88291 10.5871',
    '4.82903 10.5871 4.76773C10.5871 4.70642 10.5468 4.65254',
    '10.4881 4.63563C8.34535 4.01695 7.86033 3.52967 7.24438',
    '1.37686C7.22755 1.31803 7.17392 1.27734 7.1129 1.27734C7.05188',
    '1.27734 6.99824 1.31789 6.98141 1.37686C6.36546 3.52967 5.88044',
    '4.01695 3.73773 4.63563C3.67903 4.65254 3.63867 4.70642 3.63867',
    '4.76773C3.63867 4.82903 3.67903 4.88291 3.73773 4.89982Z',
    'M2.52 10.9927C2.53683 11.0515 2.59047 11.0922 2.65149',
    '11.0922C2.71251 11.0922 2.76614 11.0516 2.78297',
    '10.9927C3.06454 10.0092 3.2699 9.80273 4.24884 9.51999C4.30753',
    '9.50308 4.3479 9.4492 4.3479 9.38789C4.3479 9.32659 4.30753',
    '9.27271 4.24884 9.2558C3.2699 8.97305 3.06454 8.7666 2.78297',
    '7.78311C2.76614 7.72428 2.71251 7.68359 2.65149 7.68359C2.59047',
    '7.68359 2.53683 7.72414 2.52 7.78311C2.23857 8.7666 2.03321 8.97305',
    '1.05413 9.2558C0.995439 9.27271 0.955078 9.32659 0.955078',
    '9.38789C0.955078 9.4492 0.995439 9.50308 1.05413 9.51999C2.03321',
    '9.80273 2.23857 10.0092 2.52 10.9927Z',
    'M13.1712 10.7092C12.0415 10.383 11.8044 10.1448 11.4796',
    '9.00967C11.4627 8.95084 11.4091 8.91016 11.3481 8.91016C11.2871',
    '8.91016 11.2334 8.95071 11.2166 9.00967C10.8918 10.1448 10.6547',
    '10.383 9.52484 10.7092C9.46614 10.7261 9.42578 10.78 9.42578',
    '10.8413C9.42578 10.9026 9.46614 10.9564 9.52484 10.9733C10.6547',
    '11.2997 10.8918 11.5379 11.2166 12.673C11.2334 12.7318 11.2871',
    '12.7725 11.3481 12.7725C11.4091 12.7725 11.4627 12.7319 11.4796',
    '12.673C11.8044 11.5379 12.0413 11.2997 13.1712 10.9733C13.2299',
    '10.9564 13.2702 10.9026 13.2702 10.8413C13.2702 10.78 13.2299',
    '10.7261 13.1712 10.7092Z',
  ].join(' '),
];

export const IconStars = ({
  width = 14,
  height = 14,
  color = COLORS.WARNING,
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {path.map((item, index) => <path d={item} fill={color} key={index} />)}
  </svg>
);

export default IconStars;
