import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';
import FONT_FAMILIES from 'theme/fontFamilies';

export const useStyles = makeStyles()({
  scrollContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.GREY_BACKGROUND,
    padding: 0,
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 30px',
    backgroundColor: COLORS.WHITE,
    borderBottom: `1px solid ${COLORS.INPUT.BORDER}`,
    width: '100%',
    boxSizing: 'border-box',
    gap: 20,
    marginBottom: -1,
  },
  section: {
    width: '100%',
    minWidth: 370,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  proceedButton: {
    zIndex: 4,
    padding: '14px 20px 12px 20px',
    backgroundColor: COLORS.BUTTON.BACKGROUND.PRIMARY,
    border: 0,
    width: 'calc(100% + 20px)',
    marginTop: 10,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    textTransform: 'unset',
    fontFamily: FONT_FAMILIES.POPPINS,
    fontSize: 16,
    fontWidth: 500,
    lineHeight: '24px',
    '&:hover': {
      backgroundColor: COLORS.BUTTON.BACKGROUND.PRIMARY,
      border: 0,
      padding: '14px 20px 12px 20px',
    },
    '&.Mui-disabled': {
      border: 0,
    },
  },
  headerSection: {
    gap: 15,
    zIndex: 4,
  },
  contentSection: {
    gap: 15,
    background: COLORS.TRANSPARENT,
  },
  contentBackgroundOuterContainer: {
    width: 'calc(100% + 60px)',
  },
  contentBackgroundContainer: {
    width: '100%',
    position: 'absolute',
    overflow: 'hidden',
    height: 190,
  },
  contentBackgroundOverlay: {
    background: `linear-gradient(180deg, ${COLORS.TRANSPARENT}, ${COLORS.WHITE})`,
    zIndex: 2,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  contentBackground: {
    zIndex: 1,
    opacity: 0.3,
  },
  headerImage: {
    width: 66,
    height: 33,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 0,
  },
  title: {
    textAlign: 'center',
    fontFamily: FONT_FAMILIES.POPPINS,
    color: COLORS.TEXT.PRIMARY,
    fontSize: 25,
    lineHeight: 'normal',
    fontWeight: 400,
    margin: 0,
    width: '100%',
  },
  subtitle: {
    textAlign: 'center',
    fontFamily: FONT_FAMILIES.POPPINS,
    color: COLORS.TEXT.PRIMARY,
    fontSize: 20,
    lineHeight: 'normal',
    fontWeight: 600,
    margin: 0,
    width: '100%',
  },
  instructionsTitle: {
    textAlign: 'left',
    fontFamily: FONT_FAMILIES.POPPINS,
    color: COLORS.TEXT.PRIMARY,
    fontSize: 18,
    lineHeight: 'normal',
    fontWeight: 600,
    margin: 0,
    width: '100%',
  },
  instructionsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    color: COLORS.TEXT.PRIMARY,
    gap: 10,
  },
  instructionIcon: {
    minWidth: 24,
    minHeight: 24,
    backgroundColor: COLORS.SECONDARY,
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  instructionText: {
    fontFamily: FONT_FAMILIES.POPPINS,
    fontSize: 14,
    lineHeight: 'normal',
    fontWeight: 400,
    margin: 0,
    textIndent: 'unset',
  },
  textBold: {
    fontWeight: 600,
  },
  socialButton: {
    borderRadius: 5,
    width: 50,
    height: 50,
    borderWidth: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer !important',
  },
  socialSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 4,
  },
  socialButtonsRow: {
    display: 'flex',
    flexDirection: 'row',
    width: 250,
    justifyContent: 'space-between',
  },
  icon: {},
  shareHeader: {
    color: COLORS.PRIMARY,
    textAlign: 'center',
    fontFamily: FONT_FAMILIES.INTER,
    fontSize: 16,
    fontWeight: 500,
  },
  shareMessage: {
    color: COLORS.PRIMARY,
    textAlign: 'center',
    fontFamily: FONT_FAMILIES.INTER,
    fontSize: 12,
    fontWeight: 400,
    backgroundColor: COLORS.GREY_BACKGROUND,
    borderRadius: 5,
    padding: '2px 5px',
    marginTop: 10,
    marginBottom: 20,
  },
  iconStars: {
    marginLeft: 5,
  },
});

export default useStyles;
