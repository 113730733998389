import { AnyAction, Reducer } from '../state.types';
import { DonationReduxState } from './state';

export const actionTypeName = 'DONATION__CREATE_PAYMENT_INTENT__SUCCESS';

export type ActionCreatePaymentIntentAndSubmitPledgeSuccess = AnyAction & {
  payload: {
    donationUuid: string;
  };
}

export const createAction = (payload): ActionCreatePaymentIntentAndSubmitPledgeSuccess => ({
  type: actionTypeName,
  payload,
});

export const reducer: Reducer<DonationReduxState, ActionCreatePaymentIntentAndSubmitPledgeSuccess> = (
  state: DonationReduxState,
  action: ActionCreatePaymentIntentAndSubmitPledgeSuccess,
): DonationReduxState => {
  const nextState = {
    ...state,
    card: {
      ...state.card,
      ...action.payload,
    },
    isSubmitting: false,
  };

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
