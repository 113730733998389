import TagManager from 'react-gtm-module';

import { appConfig } from '../config';

export const pushDataLayerEvent = (data: Record<string, any>) => {
  if (!data.event) {
    return;
  }

  window.dataLayer?.push(data);
};

export const initializeGTM = () => {
  TagManager.initialize(appConfig.gtm);
  window.dataLayer = window.dataLayer || [];
};
