export interface Currency {
  code: string;
  id: string;
  imageUrl: string;
  name: string;
  isErc20: boolean;
  network: string;
  contractAddress: string | null;
}

export enum FiatCurrency {
  USD = 'USD',
  GBP = 'GBP',
}

export default Currency;
