import { AnyAction } from 'state/state.types';

import { ChariotReduxState } from './state';

export const actionTypeName = 'CHARIOT__POST_DONATION_INFO__FAIL';

type ActionPostDonationInfoFail = AnyAction & {
  error: Record<string, any>;
}

export const createAction = (error: Record<string, any>) => ({
  type: actionTypeName,
  error,
});

export const reducer = (
  state: ChariotReduxState,
  { error }: ActionPostDonationInfoFail,
): ChariotReduxState => ({
  ...state,
  donation: {
    ...state.donation,
    isLoading: false,
    error,
  },
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
