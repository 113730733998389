export const KEYS = {
  PLACEHOLDERS: {
    ORGANIZATION_NAME: '{{organizationName}}',
  },
};

export const LABELS = {
  MANDATE: 'Mandate',
  PLAID_BUTTON_TEXT: 'Continue with',
  LEFT_BUTTON_TEXT: 'PREVIOUS',
  MANDATE_HEADER: 'ACH Donation',
  MANDATE_SHORT: [
    'By continuing you hereby authorize each of ',
    KEYS.PLACEHOLDERS.ORGANIZATION_NAME,
    ', its processor, and any financial institution working ',
    'in connection with either of them, to debit your bank ',
    'account up to the full amount of your purchase, including ',
    'for any applicable fees, taxes, and other costs, including shipping costs.',
  ].join(''),
  PRESS_PLAID_LABEL: 'Press the button below to continue',
};

export default LABELS;
