import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';
import FONT_FAMILIES from 'theme/fontFamilies';

const useStyles = makeStyles()({
  section: {
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleCaption: {
    color: COLORS.TEXT.PRIMARY,
    fontFamily: FONT_FAMILIES.POPPINS,
    fontSize: 14,
    lineHeight: '24px',
    marginTop: 4,
  },
  headerSection: {
    '& > div:nth-of-type(1)': {
      margin: 0,
    },
    marginBottom: 30,
  },
  dialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
  },
  dialogHeaderRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingTop: 10,
    paddingBottom: 5,
  },
  closeButtonContainer: {
    width: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 20,
  },
  dialogHeader: {
    color: COLORS.DARK_PRIMARY,
    fontFamily: FONT_FAMILIES.ROBOTO,
    fontSize: 16,
    fontWeight: 500,
    marginLeft: 50,
    width: '100%',
    textAlign: 'center',
  },
  closeButton: {
    width: 30,
    minWidth: 30,
    height: 30,
    minHeight: 30,
    padding: 0,
    flex: 0,
    position: 'absolute',
    right: 10,
    top: 28,
  },
  dialogContent: {
    color: COLORS.DARK_PRIMARY,
    fontFamily: FONT_FAMILIES.ROBOTO,
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'left',
    paddingLeft: 10,
    paddingRight: 10,
  },
  link: {
    color: COLORS.BRIGHT_BLUE,
    fontFamily: FONT_FAMILIES.ROBOTO,
    fontSize: 14,
    fontWeight: 400,
  },
  contentParagraph: {
    paddingTop: 3,
    paddingBottom: 3,
  },
});

export default useStyles;
