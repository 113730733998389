import { AnyAction, Reducer } from '../state.types';
import { DonationReduxState } from './state';

export const actionTypeName = 'DONATION__FETCH_CRYPTO_DONATION_STATUS__FAIL';

export type ActionFetchCryptoDonationStatusFail = AnyAction & {
  error: Record<string, string>;
}

export const createAction = (error: Record<string, string>): ActionFetchCryptoDonationStatusFail => ({
  type: actionTypeName,
  error,
});

export const reducer: Reducer<DonationReduxState, ActionFetchCryptoDonationStatusFail> = (
  state: DonationReduxState,
  action,
): DonationReduxState => {
  const nextState = {
    ...state,
    crypto: {
      ...state.crypto,
      statusError: action.error,
      isFetchingStatus: false,
    },
  };

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
