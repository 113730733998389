import omit from 'lodash/omit';

import { AnyAction } from '../state.types';
import { PledgeReduxState } from './state';

export const actionTypeName = 'PLEDGE__CHANGE_CRYPTO_AMOUNT';

export type ActionChangeCryptoAmount = AnyAction & {
  value: number;
}

export const createAction = (value: number) => ({
  type: actionTypeName,
  value,
});

export const reducer = (
  state: PledgeReduxState,
  action: ActionChangeCryptoAmount,
): PledgeReduxState => {
  const { exchangeRate } = state;
  const { value } = action;

  if (!exchangeRate) {
    return {
      ...state,
      amount: value,
    };
  }

  const nextError = state.error
    ? omit(state.error, 'pledgeAmount', 'amount')
    : null;

  const nextState = {
    ...state,
    amount: value,
    usdAmount: value * state.exchangeRate,
    error: nextError,
  };

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
