import { AnyAction, Reducer } from './state.types';

export interface ReducerItem {
  actionTypeName: string;
  createAction: (...args: any[]) => AnyAction;
  reducer: Reducer<any, any>;
}

export const buildReducerItem = (item: ReducerItem): [string, Reducer<any, any>] => [item.actionTypeName, item.reducer];

export default buildReducerItem;
