import { WorkflowStep } from 'types/workflow';

import { AnyAction } from '../state.types';
import { WorkflowReduxState } from './state';

export const actionTypeName = 'WORKFLOW__CHANGE_STEP';

export type ActionChangeStep = AnyAction & {
  step: WorkflowStep;
}

export const createAction = (step: WorkflowStep) => ({
  type: actionTypeName,
  step,
});

export const reducer = (
  state: WorkflowReduxState,
  action: ActionChangeStep,
): WorkflowReduxState => {
  const { step } = action;

  return {
    ...state,
    step,
    error: {
      title: undefined,
      message: '',
    },
  };
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
