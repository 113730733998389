/* eslint-disable react/no-array-index-key */
import React from 'react';

import COLORS from 'theme/colors';

import { IconProps } from './icon.types';

const path = [
  [
    'M10.5791 0C10.6164 0 10.6536 0 10.6929 0C10.7843 1.12835 10.3536 1.97146',
    '9.83016 2.582C9.31657 3.18834 8.61328 3.7764 7.4758 3.68717C7.39992 2.57498',
    '7.83131 1.79441 8.35403 1.18526C8.83881 0.617573 9.72759 0.112414 10.5791 0Z',
  ].join(' '),
  [
    'M14.0225 11.7445C14.0225 11.7557 14.0225 11.7655 14.0225 11.7761C13.7028 12.7442',
    '13.2468 13.574 12.6904 14.344C12.1824 15.0431 11.5599 15.9839 10.4484 15.9839C9.488',
    '15.9839 8.85005 15.3663 7.86573 15.3494C6.82449 15.3326 6.25188 15.8658 5.29988',
    '16C5.19098 16 5.08208 16 4.97529 16C4.27621 15.8989 3.71203 15.3452 3.30102',
    '14.8464C2.08906 13.3724 1.15251 11.4683 0.978271 9.03177C0.978271 8.79289',
    '0.978271 8.55472 0.978271 8.31584C1.05204 6.57202 1.89936 5.1542 3.02561 4.46707C3.62',
    '4.10172 4.4371 3.79048 5.34695 3.92959C5.73689 3.99001 6.13526 4.1235 6.48444',
    '4.25559C6.81536 4.38276 7.22918 4.60829 7.62123 4.59634C7.8868 4.58862 8.15098',
    '4.45021 8.41866 4.35255C9.20275 4.06941 9.97138 3.74481 10.9845 3.89727C12.2021',
    '4.08135 13.0663 4.62234 13.6002 5.45701C12.5702 6.11253 11.7559 7.10036 11.8951',
    '8.78727C12.0187 10.3196 12.9096 11.2161 14.0225 11.7445Z',
  ].join(' '),
];

export const IconApple = ({
  width = 15,
  height = 16,
  color = COLORS.WHITE,
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {path.map((item, key) => <path key={key} d={item} fill={color} />)}
  </svg>
);

export default IconApple;
