import React from 'react';
import {
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
  CircularProgress,
} from '@mui/material';

import COLORS from 'theme/colors';
import { QALocator } from 'utils/tests/QA';

import useStyles from './styles';

export enum ButtonDisplayVariant {
  primary = 'primary',
  secondary = 'secondary',
  text = 'text',
}

interface ButtonProps extends MUIButtonProps {
  display?: ButtonDisplayVariant;
  loading?: boolean;
}

export const Button = ({
  className,
  display = ButtonDisplayVariant.primary,
  variant,
  classes: passedClasses,
  ...props
}: ButtonProps) => {
  const { classes, cx } = useStyles();

  const spinnerSize = {
    small: 22,
    medium: 24,
    large: 26,
  };

  const content = props.loading
    ? <CircularProgress style={{ color: COLORS.GREY }} size={spinnerSize[props.size ?? 'medium']} />
    : props.children;

  return (
    <MUIButton
      className={className}
      classes={{
        root: cx(classes.button, classes[display]),
        ...passedClasses,
      }}
      variant="outlined"
      disabled={props.loading || props.disabled}
      {...QALocator('button')}
      {...props}
    >
      {content}
    </MUIButton>
  );
};

export default Button;
