import React from 'react';
import { Button } from '@mui/material';

import { USDFormatter } from 'utils/currency';
import { locators, QALocator } from 'utils/tests/QA';

import { KEYS } from '../../pledge.keys';
import useStyles from './styles';

export interface SelectAmountButtonsProps {
  onSelect: (value: number) => void;
  usdAmount: string;
}

export const SelectAmountButtons = ({
  onSelect,
  usdAmount,
}: SelectAmountButtonsProps) => {
  const { classes, cx } = useStyles();
  const handleSelect = (amount: number) => () => onSelect(amount);
  const {
    FIRST_ROW_PLEDGE_AMOUNTS,
    SECOND_ROW_PLEDGE_AMOUNTS,
  } = KEYS;

  const isSelected = (amount: number) => usdAmount === USDFormatter().format(amount);

  return (
    <div className={classes.amountButtonContainer}>
      <div className={classes.row}>
        {
          FIRST_ROW_PLEDGE_AMOUNTS.map(amount => (
            <Button
              onClick={handleSelect(amount)}
              className={cx(classes.amountButton, {
                [classes.selectedAmountButton]: isSelected(amount),
              })}
              key={amount}
              {...QALocator(locators.components.fiat.amountButton(amount))}
            >
              {USDFormatter().format(amount)}
            </Button>
          ))
        }
      </div>
      <div className={classes.row}>
        {
          SECOND_ROW_PLEDGE_AMOUNTS.map(amount => (
            <Button
              onClick={handleSelect(amount)}
              className={cx(classes.amountButton, {
                [classes.selectedAmountButton]: isSelected(amount),
              })}
              key={amount}
              {...QALocator(locators.components.fiat.amountButton(amount))}
            >
              {USDFormatter().format(amount)}
            </Button>
          ))
        }
      </div>
    </div>
  );
};

export default SelectAmountButtons;
