import AchDonationStatus from 'types/ach';
import { CardPaymentToken, FiatPaymentMethod } from 'types/card';

import { AnyReduxState } from '../state.types';
import { ThreeDSState } from './threeDS.state';

export type CryptoDonationReduxState = AnyReduxState & {
  QR: string;
  donationAddress: string;
  fallbackAddressUsed: boolean;
  destinationTag?: string;
  qrValue?: string;
  donationReceived: boolean;
  donationUuid: string | null;
  isFetchingStatus: boolean;
  statusError: Record<string, string>;
}

export type CardDonationReduxState = AnyReduxState & {
  cardToken: string | CardPaymentToken | null;
  brand: string | null;
  donationUuid: string | null;
  mid: string | null;
  method: FiatPaymentMethod | null;
  threeDSecure: ThreeDSState | null;
}

export type StocksDonationReduxState = AnyReduxState & {
  donationUuid: string | null;
}

export type AchDonationReduxState = AnyReduxState & {
  donationUuid: string | null;
  clientObjectId: string | null;
  customerId: string | null;
  paymentMethodStatus: AchDonationStatus | null;
  plaidFlowData: Record<string, any>;
  isLoading: boolean;
  isFinalizing: boolean;
  error: Record<string, string>;
}

export type DonationReduxState = AnyReduxState & {
  crypto: CryptoDonationReduxState;
  card: CardDonationReduxState;
  stocks: StocksDonationReduxState;
  ach: AchDonationReduxState;
  isSubmitting: boolean;
  error: Record<string, string>;
}

export const initialState = (): DonationReduxState => ({
  crypto: {
    QR: '',
    donationAddress: '',
    fallbackAddressUsed: false,
    isFetchingStatus: false,
    statusError: {},
    donationUuid: null,
    donationReceived: false,
  },
  card: {
    cardToken: null,
    brand: null,
    donationUuid: null,
    mid: null,
    method: null,
    threeDSecure: null,
  },
  stocks: {
    donationUuid: null,
  },
  ach: {
    donationUuid: null,
    clientObjectId: null,
    paymentMethodStatus: null,
    customerId: null,
    plaidFlowData: {},
    isLoading: false,
    isFinalizing: false,
    error: {},
  },
  isSubmitting: false,
  error: {},
});
