export const KEYS = {
  PLACEHOLDERS: {
    ORGANIZATION_NAME: '{{organizationName}}',
  },
};

export const LABELS = {
  MANDATE_HEADER: 'Mandate',
  MANDATE: [
    [
      'By continuing you hereby authorize each of ',
      KEYS.PLACEHOLDERS.ORGANIZATION_NAME,
      ' (the “Company”), its ',
      'processor, and any financial institution (the “Suppliers”) working in connection ',
      'with either of them, to debit your bank account up to the full amount of your purchase, ',
      'including for any applicable fees, taxes, and other costs, including shipping costs.',
    ].join(''),
    [
      'Additionally, you authorize ',
      KEYS.PLACEHOLDERS.ORGANIZATION_NAME,
      ' to debit or credit your bank account to correct any erroneous debit, ',
      'to make necessary adjustments to your payment, to issue a refund back to your ',
      'bank account, or to facilitate any recurring and/or sequential debits initiated by you. ',
      'This authority remains in full force and effect until ',
      KEYS.PLACEHOLDERS.ORGANIZATION_NAME,
      ' has received written notification from you of ',
      'its termination in such time and manner as to afford ',
      KEYS.PLACEHOLDERS.ORGANIZATION_NAME,
      ' and the Suppliers to act on it.',
    ].join(''),
    [
      'If you wish ',
      KEYS.PLACEHOLDERS.ORGANIZATION_NAME,
      ' to charge you for services or purchases that occur on a regular basis, ',
      'you are authorizing ',
      KEYS.PLACEHOLDERS.ORGANIZATION_NAME,
      ' to debit your bank account periodically.',
    ].join(''),
  ],
};

export default LABELS;
