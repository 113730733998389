import React, { useCallback } from 'react';
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from '@mui/material';
import { useField } from 'formik';

import { Broker } from 'types/broker';

import { FormFieldDescription } from '../../fields/field.types';
import { KEYS } from '../brokerInfo.keys';
import { useStyles } from '../styles';

interface CustomSelectProps {
  item: FormFieldDescription;
  required: boolean;
  disabled: boolean;
  isSubmitting: boolean;
  name: string;
  setFieldValue: (field: string, value: any) => void;
  errorText?: string;
  brokers: Broker[];
}

export const CustomSelect = ({
  item,
  disabled,
  isSubmitting,
  setFieldValue,
  errorText,
  brokers,
  ...props
}: CustomSelectProps) => {
  const { classes } = useStyles();
  const [field, meta] = useField(props);
  const hasMetaError = Boolean((isSubmitting || meta.touched) && meta.error);
  const hasError = !disabled && (hasMetaError || Boolean(errorText));
  const label = `${item.label}${item.isRequired ? KEYS.REQUIRED_ASTERISK : ''}`;
  const errorCode: string = meta.error || '';

  const handleChange = useCallback(({ target }) => setFieldValue(item.name, target.value), [item]);

  return (
    <FormControl fullWidth className={classes.customSelectWrapper} size="small">
      <Select
        {...field}
        {...props}
        multiple={false}
        onChange={handleChange}
        displayEmpty
        variant="outlined"
        className={classes.root}
        error={hasError}
      >
        <MenuItem value="" disabled>
          {label}
        </MenuItem>
        {brokers.map(broker => (
          <MenuItem value={broker.name} key={broker.name}>
            {broker.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{hasError && errorCode}</FormHelperText>
    </FormControl>
  );
};

export default CustomSelect;
