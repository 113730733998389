export const LABELS = {
  ANONYMOUS_DONATION_LABEL: 'Donate Anonymously',
};

export const AUTOCOMPLETE_ACTIONS = {
  CLEAR: 'clear',
};

export enum LOCATION_FIELD_NAMES {
  COUNTRY = 'country',
  STATE = 'state',
  STATE_ID = 'stateId',
  COUNTRY_ID = 'countryId',
}

export default {
  LABELS,
  AUTOCOMPLETE_ACTIONS,
};
