import {
  Button,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

export const SubmitButton = withStyles(Button, {
  root: {
    fontFamily: 'Poppins',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600,
    textTransform: 'none',
    '&, &:hover': {
      display: 'flex',
      flex: 1,
      background: COLORS.SECONDARY,
      padding: '13px 20px',
    },
  },
});

export const SkipButton = withStyles(Button, {
  root: {
    fontFamily: 'Poppins',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600,
    textTransform: 'none',
    display: 'flex',
    flex: 1,
    background: 'linear-gradient(180deg, #F0F4F8 0%, rgba(240, 244, 248, 0) 100%)',
    padding: '13px 20px',
  },
});
