import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

export const useStyles = makeStyles()({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 32px',
    // Progress bar on top is 24px fixed height
    // Bottom actions buttons are 48px
    // Parent container has fixed height
    // Height of the content between is 100% minus buttons and progress
    height: 'calc(100% - 72px)',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 223,
    width: '100%',
    gap: 12,
  },
  nameInput: {
    '& .MuiInputBase-input': {
      height: '10px !important',
    },
  },
  textInput: {
    marginTop: 7,
    width: '100%',
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .MuiInputBase-input': {
      fontSize: 13,
    },
    '& .MuiInputLabel-root': {
      fontSize: '13px !important',
      color: COLORS.GREY,
    },
  },
  inputTitle: {
    color: COLORS.BLACK,
    fontSize: 13,
    fontWeight: 400,
    margin: 'unset',
  },
  checkboxContainer: {
    '& .MuiSvgIcon-root path': {
      fill: '#291B4F',
    },
    '& .MuiCheckbox-root': {
      maxHeight: 25,
      padding: 0,
      marginRight: 5,
      alignItems: 'unset',
    },
    '& .MuiFormControlLabel-root': {
      margin: 'unset',
      alignItems: 'unset',
    },
  },
  checkboxLabel: {
    fontSize: 13,
  },
});

export default useStyles;
