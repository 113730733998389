import React from 'react';

import COLORS from 'theme/colors';

import { IconProps } from './icon.types';

export const IconCross = ({
  width = 58,
  height = 58,
  className,
  color = COLORS.BLACK,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 58 58"
    fill="none"
  >
    <line x1="4.71565" y1="53.6109" x2="53.506" y2="4.82055" stroke={color} strokeWidth="11" />
    <line x1="4.49382" y1="4.61091" x2="53.2842" y2="53.4013" stroke={color} strokeWidth="11" />
  </svg>
);

export default IconCross;
