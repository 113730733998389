import { AppReduxState } from 'state/state';
import DonationWorkflowType from 'types/workflow';

import { getOrigin } from '../detectDocumentOrigin';
import brokerInfoPayloadFactory from './brokerInfoPayloadFactory';
import donationSentPayloadFactory from './donationSentPayloadFactory';
import donationSharePayloadFactory from './donationSharePayloadFactory';
import donationStartPayloadFactory from './donationStartPayloadFactory';
import personalInfoPayloadFactory from './personalInfoPayloadFactory';
import taxReceiptPayloadFactory from './taxReceiptPayloadFactory';
import GTMEventType from './types';

const payloadFactoryMap = {
  [GTMEventType.DonationStart]: donationStartPayloadFactory,
  [GTMEventType.PersonalInfo]: personalInfoPayloadFactory,
  [GTMEventType.BrokerInfo]: brokerInfoPayloadFactory,
  [GTMEventType.TaxReceipt]: taxReceiptPayloadFactory,
  [GTMEventType.DonationSent]: donationSentPayloadFactory,
  [GTMEventType.DonationShare]: donationSharePayloadFactory,
};

export const eventDataFactory = (
  event: GTMEventType,
  flow: DonationWorkflowType,
  data: {
    state: AppReduxState;
  } & Record<string, any>,
) => {
  const eventPayloadFactory = payloadFactoryMap[event];
  const payload = eventPayloadFactory?.(flow, data) || {};
  return {
    ...payload,
    parentOrigin: getOrigin(false),
  };
};

export default eventDataFactory;
