import { DonorInfo } from 'types/donor';
import runtimeLocalStorage, { RUNTIME_LOCAL_STORAGE_KEYS } from 'utils/runtimeLocalStore';

export type DonorInfoFieldsState = DonorInfo & {
  countryId?: string;
  stateId?: string;
}

export type DonorReduxState = DonorInfoFieldsState & {
  countryId?: string;
  stateId?: string;
}

export const initialState = (): DonorReduxState => ({
  isAnonymous: false,
  firstName: '',
  lastName: '',
  email: '',
  country: runtimeLocalStorage.get(RUNTIME_LOCAL_STORAGE_KEYS.COUNTRY),
  countryId: runtimeLocalStorage.get(RUNTIME_LOCAL_STORAGE_KEYS.COUNTRY_ID),
  state: '',
  address1: '',
  address2: '',
  city: '',
  zipCode: '',
  phoneNumber: '',
  birthDate: undefined,
  employer: '',
  occupation: '',
  isDonorRetired: false,
  isCharityCommunicationAllowed: false,
});
