export const KEYS = {
  DIALOG_TITLE: 'Email Confirmation?',
  DIALOG_TEXT: [
    'If you would like to receive a confirmation of ',
    'your grant while remaining anonymous, enter your ',
    'email below. This email will only be used for the ',
    'purpose of sending the confirmation.',
  ].join(''),
  SKIP: 'Skip',
  CONFIRM: 'Confirm',
  CONFIRM_EMAIL_LABEL: 'Enter email for grant confirmation',
  EMAIL_FIELD_KEY: 'receiptEmail',
  EMAIL_VALIDATION_MESSAGE: 'Please provide a valid email address',
  REQUIRED_VALIDATION_MESSAGE: 'Email address is required',
};

export const defaultFormValue = {
  [KEYS.EMAIL_FIELD_KEY]: '',
};

export default KEYS;
