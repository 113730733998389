import { AppReduxState } from 'state/state';
import DonationWorkflowType from 'types/workflow';

import GTMEventType from './types';

const makeBasicPayload = (state: AppReduxState) => ({
  event: GTMEventType.DonationSent,
});

const makeCryptoPayload = (state: AppReduxState) => {
  const { donationAddress } = state.donation.crypto;

  return {
    donationType: DonationWorkflowType.Crypto,
    walletId: donationAddress,
  };
};

const makeCardPayload = (state: AppReduxState) => {
  const { donationUuid } = state.donation.card;

  return {
    donationType: DonationWorkflowType.CreditCard,
    transactionId: donationUuid,
  };
};

const makeStockPayload = (state: AppReduxState) => {
  const { donationUuid } = state.donation.stocks;

  return {
    donationType: DonationWorkflowType.Stock,
    transactionId: donationUuid,
  };
};

const makeDAFPayload = (state: AppReduxState) => {
  const { donation } = state.chariot;
  const {
    coverTransactionFees: areFeesCovered,
  } = state.pledge;
  const intentAmount = donation.session?.grantIntent.amount ?? 0;
  const amountWithFees = state.chariot?.details?.amount ?? 0;

  const selectedAmount = (areFeesCovered ? amountWithFees : intentAmount);
  const usdAmount = selectedAmount / 100;

  return {
    donationType: DonationWorkflowType.Daf,
    areFeesCovered,
    usdAmount,
  };
};

const flowPayloadGetterMap = {
  [DonationWorkflowType.Crypto]: makeCryptoPayload,
  [DonationWorkflowType.CreditCard]: makeCardPayload,
  [DonationWorkflowType.Stock]: makeStockPayload,
  [DonationWorkflowType.Daf]: makeDAFPayload,
};

export const donationSentPayloadFactory = (
  flow: DonationWorkflowType,
  { state, data }: { state: AppReduxState } & Record<string, any>,
) => {
  const makeSpecificPayload = flowPayloadGetterMap[flow];

  return {
    ...makeBasicPayload(state),
    ...(makeSpecificPayload?.(state) || {}),
  };
};

export default donationSentPayloadFactory;
