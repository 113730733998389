import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    marginTop: 8,
    marginBottom: 20,
    position: 'relative',
  },
  backButton: {
    flex: 0,
    padding: '12px 12px 12px 0 !important',
  },
  pageTitle: {
    flex: 1,
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 25,
    lineHeight: '35px',
    textAlign: 'center',
    color: theme.palette.text.primary,
    margin: 0,
  },
  pageTitleShifted: {
    padding: '0 38px',
  },
  absolute: {
    position: 'absolute',
  },
}));

export default useStyles;
