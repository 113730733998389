import React, {
  useEffect,
} from 'react';
import { Box } from '@mui/material';

import { BottomButtons } from 'components';
import ChariotConnect from 'components/chariot';
import Loading from 'components/loading';
import OptionallyVisible from 'components/optionallyVisible';
import { ChariotSession } from 'types/chariot';
import { DonorInfo } from 'types/donor';

import { LABELS } from './keys';
import { useStyles } from './styles';

interface DAFPledgeSceenProps {
  connection: {
    id: string | null;
    isLoading: boolean;
    error: Record<string, string> | null;
  };
  donation: {
    isLoading: boolean;
    error: Record<string, string> | null;
  };
  donor: DonorInfo;
  resetFlow: () => void;
  fetchConnectionId: () => void;
  resetConnectionId: () => void;
  isPreviousStepAvailable: boolean;
  onDonationStart: () => void;
  onDonationSuccess: (data: ChariotSession) => void;
}

export const DAFPledgeScreen = ({
  connection,
  donation,
  donor,
  resetFlow,
  fetchConnectionId,
  isPreviousStepAvailable,
  resetConnectionId,
  onDonationStart,
  onDonationSuccess,
}: DAFPledgeSceenProps) => {
  const { classes } = useStyles();

  useEffect(() => {
    fetchConnectionId();
  }, []);

  const onSuccess = ({ detail }: { detail: ChariotSession }) => {
    onDonationSuccess(detail);
  };

  const onDonationRequest = () => {
    onDonationStart();
    return ({
      firstName: donor.firstName,
      lastName: donor.lastName,
      email: donor.email,
    });
  };

  const onChariotButtonWrapperClick = async (e) => {
    e.preventDefault();
  };

  if (connection.isLoading) {
    return <Loading text={LABELS.VIEW_LOADING} />;
  }

  if (donation.isLoading) {
    return <Loading text={LABELS.VIEW_LOADING} />;
  }

  return (
    <div className={classes.pageContainer}>
      <h3 className={classes.infoPanelHeader}>{LABELS.PAGE_TITLE}</h3>
      <div className={classes.infoPanel}>
        <div className={classes.infoItem}>
          {LABELS.INFO}
        </div>
      </div>
      <OptionallyVisible visible={Boolean(connection.id)}>
        <Box
          className={classes.chariotButtonContainer}
          onClick={onChariotButtonWrapperClick}
        >
          <ChariotConnect
            dependecies={[donor]}
            onDonationRequest={onDonationRequest}
            cid={connection.id!}
            theme={{ width: 'w-full' }}
            onSuccess={onSuccess}
            onUnmount={resetConnectionId}
          />
        </Box>
      </OptionallyVisible>
      <OptionallyVisible visible={isPreviousStepAvailable}>
        <BottomButtons
          leftButtonText={LABELS.LEFT_BUTTON_TEXT}
          onClickLeft={resetFlow}
        />
      </OptionallyVisible>
    </div>
  );
};

export default DAFPledgeScreen;
