import buildReducerItem from 'state/reducerItem';

import { createReducer } from '../createReducer';
import { Reducer } from '../state.types';
import fetchBrokersFail from './fetchBrokers.fail';
import fetchBrokersStart from './fetchBrokers.start';
import fetchBrokersSuccess from './fetchBrokers.success';
import { DictionariesReduxState, initialState } from './state';

// Reducers
const reducers: Map<string, Reducer<DictionariesReduxState, any>> = new Map([
  buildReducerItem(fetchBrokersStart),
  buildReducerItem(fetchBrokersSuccess),
  buildReducerItem(fetchBrokersFail),
]);

export const dictionariesReducer = createReducer(initialState, reducers);

export default dictionariesReducer;
