export const LABELS = {
  ANONYMOUS_DONATION_LABEL: 'Make donation anonymous',
};

export const KEYS = {
  REQUIRED_ASTERISK: ' *',
  CLEAN_VIEW: 'clean',
};

export const AUTOCOMPLETE_ACTIONS = {
  CLEAR: 'clear',
};

export enum LOCATION_FIELD_NAMES {
  COUNTRY = 'country',
  STATE = 'state',
  STATE_ID = 'stateId',
  COUNTRY_ID = 'countryId',
}

export default {
  LABELS,
  AUTOCOMPLETE_ACTIONS,
  KEYS,
};
