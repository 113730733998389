import { AnyAction, Reducer } from '../state.types';
import { CurrenciesReduxState } from './state';

export const actionTypeName = 'CURRENCIES__FETCH_CURRENCIES__FAIL';

export type ActionFetchCurrencyFail = AnyAction & {
  error: Record<string, any>;
}

export const createAction = (error: Record<string, any>): ActionFetchCurrencyFail => ({
  type: actionTypeName,
  error,
});

export const reducer: Reducer<CurrenciesReduxState, ActionFetchCurrencyFail> = (
  state: CurrenciesReduxState,
  action: ActionFetchCurrencyFail,
): CurrenciesReduxState => {
  const nextState: CurrenciesReduxState = {
    ...state,
    isLoading: false,
    error: action.error,
  };

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
