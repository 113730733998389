export const WIDGET_CHANNEL = 'WIDGET_CHANNEL';

export enum ApplePayEventAction {
  isApplePayAvailable = 'IS_APPLE_PAY_AVAILABLE',
  createApplePayPaymentRequest = 'CREATE_APPLE_PAY_PAYMENT_REQUEST',
  applePayChargeResponse = 'APPLE_PAY_CHARGE_RESPONSE'
}

export enum ApplePayTransactionStatus {
  Success = 'success',
  Fail = 'fail'
}
