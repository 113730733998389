import { createReducer } from '../createReducer';
import { buildReducerItem } from '../reducerItem';
import { Reducer } from '../state.types';
import setWidgetId from './setWidgetId';
import { initialState, WidgetReduxState } from './state';

const reducers: Map<string, Reducer<WidgetReduxState, any>> = new Map([
  buildReducerItem(setWidgetId),
]);

export const widgetReducer = createReducer(initialState, reducers);

export default widgetReducer;
