import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
  fundsDesignationWrapper: {
    '&.MuiFormControl-root': {
      display: 'flex',
      margin: '0 auto 0',
      '& .MuiSelect-select': {
        fontSize: '13px',
        lineHeight: '16px',
        minHeight: 16,
        padding: '10.5px 32px 10.5px 10px',
        fontWeight: '500',
        '&::focused': {
          background: 'transparent',
        },
      },
    },
  },
  root: {
    [`& .MuiOutlinedInput-notchedOutline,
    &:hover .MuiOutlinedInput-notchedOutline,
    &.Mui-focused .MuiOutlinedInput-notchedOutline`]: {
      borderColor: '#FCD42B',
      borderRadius: '4px',
      paddingLeft: 5,
    },
  },
  designationMenuItemContent: {
    display: 'block !important',
    whiteSpace: 'nowrap !important' as 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
  },
});

export default useStyles;
