import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

export const useStyles = makeStyles()(theme => ({
  item: {
    width: '100%',
    fontFamily: 'Noto Sans',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  mainSection: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '23px',
    color: COLORS.TEXT.PRIMARY,
    display: 'inline-flex',
    paddinRight: 10,
  },
  secondarySection: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    color: COLORS.TEXT.SECONDARY,
  },
  tickerSection: {
    display: 'flex',
    flex: 1,
  },
  nameSection: {
    display: 'flex',
    flex: 3,
  },
  container: {
    marginBottom: 20,
  },
}));

export default useStyles;
