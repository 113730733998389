import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

export const useStyles = makeStyles()(theme => ({
  longView: {
    gridColumn: '1 / span 2',
  },
  subscriptionText: {
    display: 'inline-block',
    color: '#2D2E31',
    lineHeight: '12px',
    fontSize: 12,
    cursor: 'pointer',
  },
  textFieldContainer: {
    marginTop: 10,
  },
  shortInput: {
    width: '50%',
    '&:first-of-type': {
      width: 'calc(50% - 5px)',
      marginRight: 5,
    },
  },
  inputLabel: {
    fontSize: 16,
  },
  defaultInput: {
    '& .MuiInputBase-input': {
      fontSize: 16,
      lineHeight: '23px',
      color: COLORS.PRIMARY,
      fontFamily: 'Noto Sans',
      padding: '13px 20px !important',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '&.Mui-disabled': {
        background: COLORS.INPUT.DISABLED.BACKGROUND,
      },
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: COLORS.PRIMARY,
    },
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: `${COLORS.INPUT.BORDER} !important`,
    },
    [`& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
    & .MuiOutlinedInput-root:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline,
    & .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline`]: {
      border: `2px solid ${COLORS.SECONDARY}`,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 9px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& fieldset': {
      borderRadius: theme.shape.borderRadius,
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: 16,
    },
    '& .MuiFormControl-marginDense': {
      marginTop: 1,
      marginBottom: 1,
    },
  },
  formItem: {
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 9px) scale(1)',
      fontSize: 16,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& fieldset': {
      borderColor: COLORS.INPUT.BORDER,
      borderRadius: '3px',
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: 16,
    },
    '& .MuiFormControl-marginDense': {
      marginTop: 5,
      marginBottom: 1,
    },
    '& .MuiFormHelperText-root': {
      fontFamily: 'Poppins',
      fontWeight: 600,
      color: COLORS.PRIMARY,
      margin: 0,
      marginTop: 5,
      fontSize: 14,
      lineHeight: '18px',
    },
  },
  shift4InputWrapper: {
    position: 'relative',
    borderRadius: 4,
    border: '1px solid #DADFE5',
    marginTop: '5px',
    padding: '10px 14px',
    paddingRight: 30,
    '&:hover': {
      border: `2px solid ${COLORS.SECONDARY}`,
      margin: -1,
      marginTop: 4,
    },
  },
  shift4InputWrapperError: {
    border: `2px solid ${COLORS.SECONDARY}`,
  },
  shift4InputWrapperAdornment: {
    position: 'absolute',
    right: 14,
    top: 'calc(50% - 10px)',
  },
  hidden: {
    display: 'none',
  },
}));

export default useStyles;
