import React, {
  useEffect,
  useMemo,
} from 'react';
import {
  Box,
} from '@mui/material';

import Checkbox from 'components/checkbox';
import { OptionallyVisible } from 'components/optionallyVisible';
import FundsDesignations from 'pages/common/pledge/fundsDesignations';
import { SelectOption } from 'pages/common/pledge/fundsDesignations/types';
import { OrganizationInfo } from 'types/organization';
import { StocksDonationPledge, StockTickerSymbol } from 'types/pledge';
import { locators, QALocator } from 'utils/tests/QA';

import { StockCurrencyAmountBox } from './components/amountBox/currencyAmountBox.stock';
import StockNameInput from './components/stockTickerInput/stockNameInput';
import StockTickerInput from './components/stockTickerInput/stockTickerInput';
import { KEYS, LABELS } from './stockDonationPledge.keys';
import useStyles from './styles';

export interface PledgeFormProps {
  pledge: StocksDonationPledge;
  onChange: (pledge: Partial<StocksDonationPledge>) => void;
  updateExchangeRate: () => void;
  organization: OrganizationInfo;
  error: Record<string, string> | null;
  isExchangeRateUnavailable: boolean;
}

export const PledgeForm = ({
  pledge,
  onChange,
  organization,
  updateExchangeRate,
  error,
  isExchangeRateUnavailable,
}: PledgeFormProps) => {
  const { classes } = useStyles();
  const shouldShowTributesControl = organization.areNotesEnabled && organization.areTributesEnabled;

  useEffect(() => {
    updateExchangeRate();
  }, [pledge.asset]);

  const fundsDesignationOptions = useMemo(() => {
    const fundsDesignations = organization.fundsDesignations || [];

    return fundsDesignations.map(({ value, uuid }) => ({
      label: value,
      id: uuid,
    }));
  }, [organization.fundsDesignations]);

  const handleChange = (field: string) => (value: any) => {
    onChange({ [field]: value });
  };

  const handleAssetChange = (assets: StockTickerSymbol[]) => {
    const [asset] = assets;
    handleChange(KEYS.ASSET)(asset);
  };

  const handleQuantityChange = (value: number) => {
    handleChange(KEYS.QUANTITY)(Number(value) || null);
  };

  const hasDesignationOptions = fundsDesignationOptions.length > 0;
  const areDesignationsEnabled = Boolean(organization.fundsDesignations) && hasDesignationOptions;
  const shouldHideHeader = areDesignationsEnabled && organization.areNotesEnabled;

  return (
    <div className={classes.contentContainer}>
      <div className={classes.form}>
        <OptionallyVisible visible={!shouldHideHeader}>
          <h6 className={classes.pageHeader}>{LABELS.STOCK_DONATION}</h6>
        </OptionallyVisible>
        <div
          className={classes.inputWrapper}
          {...QALocator(locators.components.stock.tickerInputContainer)}
        >
          <h6 className={classes.fieldHeader}>{LABELS.ASSET_SYMBOL_LABEL}</h6>
          <StockTickerInput selected={pledge.asset} onChange={handleAssetChange} />
        </div>
        <div
          className={classes.inputWrapper}
          {...QALocator(locators.components.stock.nameInputContainer)}
        >
          <h6 className={classes.fieldHeader}>{LABELS.ASSET_DESCRIPTION_LABEL}</h6>
          <StockNameInput selected={pledge.asset} onChange={handleAssetChange} />
        </div>
        <div className={classes.inputWrapper}>
          <StockCurrencyAmountBox
            pledge={pledge}
            onAmountChange={handleQuantityChange}
            error={error?.quantity}
            isExchangeRateUnavailable={isExchangeRateUnavailable}
          />
        </div>
        <OptionallyVisible visible={areDesignationsEnabled}>
          <div className={classes.inputButtonsWrapper}>
            <h6 className={classes.fieldHeader}>{LABELS.FUNDS_DESIGNATIONS_TITLE}</h6>
            <FundsDesignations
              options={fundsDesignationOptions as SelectOption[]}
              onChange={handleChange(KEYS.FUNDS_DESIGNATION)}
              value={pledge.fundsDesignation || ''}
            />
          </div>
        </OptionallyVisible>
        <OptionallyVisible visible={organization.areNotesEnabled}>
          <Box
            className={classes.checkboxContainer}
            {...QALocator(locators.page.common.components.notesCheckbox)}
          >
            <Checkbox
              onChange={handleChange(KEYS.ARE_NOTES_ENABLED)}
              value={pledge.areNotesEnabled}
              name={KEYS.ARE_NOTES_ENABLED}
              label={(
                <div
                  className={classes.checkboxLabel}
                >
                  {LABELS.NOTES_CHECKBOX_LABEL}
                </div>
            )}
            />
          </Box>
        </OptionallyVisible>
        <OptionallyVisible visible={shouldShowTributesControl}>
          <Box
            className={classes.checkboxContainer}
            {...QALocator(locators.page.common.components.tributesCheckbox)}
          >
            <Checkbox
              onChange={handleChange(KEYS.ARE_TRIBUTES_ENABLED)}
              value={pledge.areTributesEnabled}
              name={KEYS.ARE_TRIBUTES_ENABLED}
              label={(
                <div
                  className={classes.checkboxLabel}
                >
                  {LABELS.TRIBUTE_CHECKBOX_LABEL}
                </div>
            )}
            />
          </Box>
        </OptionallyVisible>
      </div>
    </div>
  );
};

export default PledgeForm;
