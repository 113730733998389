import chariot from './chariot/reducer';
import card from './creditCard/reducer';
import currencies from './currencies/reducer';
import dictionaries from './dictionaries/reducer';
import donation from './donation/reducer';
import donationData from './donationData/reducer';
import donor from './donor/reducer';
import frequency from './frequency/reducer';
import fundraiser from './fundraiser/reducer';
import organization from './organization/reducer';
import pledge from './pledge/reducer';
import stock from './stock/reducer';
import wallet from './wallet/reducer';
import widget from './widget/reducer';
import workflow from './workflow/reducer';

export default {
  donation,
  donor,
  organization,
  pledge,
  workflow,
  currencies,
  card,
  stock,
  dictionaries,
  fundraiser,
  wallet,
  frequency,
  chariot,
  widget,
  donationData,
};
