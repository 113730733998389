import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Button, ButtonDisplayVariant } from 'components/button/button';
import IconCopy from 'components/icon/copy';
import IconCross from 'components/icon/cross';
import IconLinkArrow from 'components/icon/linkArrow';
import OptionallyVisible from 'components/optionallyVisible';
import COLORS from 'theme/colors';
import DonationWorkflowType, {
} from 'types/workflow';

import { KEYS, LABELS } from '../keys';
import useStyles from './styles';

interface SupportDialogProps {
  workflowType: DonationWorkflowType;
  isSupportDialogOpen?: boolean;
  closeSupportDialog: () => void;
}

export const SupportDialog = ({
  workflowType,
  isSupportDialogOpen = false,
  closeSupportDialog,
}: SupportDialogProps) => {
  const { classes } = useStyles({});
  const [copied, setCopied] = useState<boolean>(false);
  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const getLinkContent = () => {
    if (workflowType === DonationWorkflowType.Stock) {
      return (
        <CopyToClipboard
          text={KEYS.SUPPORT_EMAIL}
          onCopy={handleCopy}
        >
          <button type="button" className={classes.emailCopyButton}>
            <OptionallyVisible visible={copied}>
              <span className={classes.emailContainer}>{LABELS.COPIED}</span>
            </OptionallyVisible>
            <OptionallyVisible visible={!copied}>
              <span className={classes.emailContainer}>{KEYS.SUPPORT_EMAIL}</span>
            </OptionallyVisible>
            <span className={classes.iconCopyWrapper}>
              <IconCopy width={20} height={20} color={COLORS.PRIMARY} />
            </span>
          </button>
        </CopyToClipboard>
      );
    }

    return (
      <a href={KEYS.LINK_SUPPORT_FROM_DONORS} target="_blank" className={classes.supportLink}>
        {LABELS.CLICK_HERE}
        <IconLinkArrow className={classes.arrowIcon} />
      </a>
    );
  };

  if (!isSupportDialogOpen) {
    return null;
  }

  return (
    <div className={classes.dialogOverlay}>
      <div className={classes.dialog}>
        <div className={classes.dialogHeader}>
          <div className={classes.dialogHeaderTextContainer}>
            {LABELS.CONTACT_US}
          </div>
          <div className={classes.closeButtonContainer}>
            <Button
              onClick={closeSupportDialog}
              display={ButtonDisplayVariant.text}
              className={classes.closeButton}
            >
              <IconCross color={COLORS.PRIMARY} width={12} />
            </Button>
          </div>
        </div>
        <div className={classes.dialogContent}>
          <div className={classes.dialogContentHeader}>{LABELS.DONOR_SUPPORT}</div>
          <div>{getLinkContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default SupportDialog;
