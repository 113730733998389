import React from 'react';
import { FormControl, FormHelperText } from '@mui/material';

import { FormFieldDescription } from 'pages/creditCard/cardDetails/overlayVariant/fields/fields.types';
import useStyles from 'pages/creditCard/cardDetails/overlayVariant/fields/styles';

interface Shift4FieldProps {
  item: FormFieldDescription;
  required: boolean;
  disabled: boolean;
  isSubmitting: boolean;
  name: string;
  className: string;
  setFieldValue: (field: string, value: any) => void;
  errorText?: string;
  dataAttributes?: Record<string, string>;
}

export const Shift4Field = ({
  dataAttributes,
  errorText,
}: Shift4FieldProps) => {
  const { classes, cx } = useStyles();

  return (

    <div className={classes.textFieldContainer}>
      <FormControl fullWidth>
        <div
          className={cx(classes.shift4InputWrapper, {
            [classes.shift4InputWrapperError]: Boolean(errorText),
          })}
          {...dataAttributes}
        />
        <FormHelperText>{errorText}</FormHelperText>
      </FormControl>
    </div>

  );
};

export default Shift4Field;
