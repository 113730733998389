import { LOCATION_FIELD_NAMES } from 'components/formFields//fields.keys';
import { FormFieldDescription, SelectOption } from 'components/formFields//fields.types';
import { DonorInfo } from 'types/donor';
import DonationWorkflowType from 'types/workflow';
import { getCountries, getStates } from 'utils/countries';

import { anonymousDonorField, donorFormFields, KEYS } from './keys';

interface BuildFormFieldParams {
  anonymousAllowed: boolean;
  formValues: DonorInfo;
  workflowType: DonationWorkflowType;
}
export const buildFormFields = ({
  anonymousAllowed,
  formValues,
  workflowType,
}: BuildFormFieldParams): FormFieldDescription[] => {
  const preparedFields = donorFormFields
    .filter((field) => {
      const isCommunicationField = field.name === KEYS.COMMUNICATION_ALLOWED_KEY;
      if (isCommunicationField && formValues.isAnonymous) {
        return false;
      }

      const isHiddenForField = field.hiddenFor?.includes(workflowType);

      if (isHiddenForField) {
        return false;
      }

      return true;
    })
    .map((field: FormFieldDescription) => {
      if (field.name === LOCATION_FIELD_NAMES.COUNTRY) {
        return { ...field, options: getCountries() as SelectOption[] };
      }

      if (field.name === LOCATION_FIELD_NAMES.STATE) {
        return { ...field, options: getStates(formValues[LOCATION_FIELD_NAMES.COUNTRY_ID]) as SelectOption[] };
      }

      return field;
    });

  if (!anonymousAllowed) {
    return preparedFields;
  }

  if (formValues.isAnonymous) {
    const changedFields = preparedFields.map(field => ({
      ...field,
      isRequired: false,
      isDisabled: true,
    }));

    return [
      anonymousDonorField,
      ...changedFields,
    ];
  }

  return [
    anonymousDonorField,
    ...preparedFields,
  ];
};

export default buildFormFields;
