import { useContext, useEffect } from 'react';

import { Shift4UtilsContext } from 'components/shift4Utils/context';
import AchDonationStatus, { VerificationProviders } from 'types/ach';

import { ACH_ERROR_LABELS, KEYS } from './keys';

interface UsePlaidParams {
  clientObjectId: string;
  finalizeAch: () => Promise<{
    errorMessage?: string;
    paymentMethodStatus?: AchDonationStatus;
  }>;
  setAchError: (errorMessage: string) => void;
}

export const usePlaid = ({
  clientObjectId,
  finalizeAch,
  setAchError,
}: UsePlaidParams) => {
  const { shift4Utils } = useContext(Shift4UtilsContext);

  const handleFinalizeAch = () => {
    finalizeAch();
  };

  const handlePaymentMethodState = async (clientObjectId: string) => {
    if (!shift4Utils) {
      return;
    }

    let paymentMethod;
    try {
      paymentMethod = await shift4Utils.handlePaymentMethodNextAction(clientObjectId);
    } catch (err: any) {
      setAchError(ACH_ERROR_LABELS.GENERIC_ERROR);
      return;
    }

    const isMethodPending = paymentMethod.status === AchDonationStatus.Pending;
    const hasFlow = Boolean(paymentMethod.flow);
    const isNextActionPlaid = paymentMethod.flow.nextAction === VerificationProviders.Plaid;
    const shouldOpenPlaidDialog = isMethodPending && hasFlow && isNextActionPlaid;
    if (shouldOpenPlaidDialog) {
      processPlaid(clientObjectId, paymentMethod.flow.plaid.linkOptions);
      return;
    }

    setAchError(ACH_ERROR_LABELS.UNEXPECTED_PAYMENT_STATUS);
  };

  const scalePlaidWidgetDownIfSmallDimensions = (plaidIframe: HTMLIFrameElement) => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const isDisplayedInSmallDimensions = windowWidth < (KEYS.MIN_PLAID_INNER_WIDTH + KEYS.DIALOG_OFFSET_ON_THE_SIDES);
    if (isDisplayedInSmallDimensions) {
      const plaidWidgetScale = windowWidth / (KEYS.MIN_PLAID_INNER_WIDTH + KEYS.DIALOG_OFFSET_ON_THE_SIDES);
      const plaidWidgetWidth = Math.round(windowWidth * (1 / plaidWidgetScale));
      const plaidWidgetHeight = Math.round(windowHeight * (1 / plaidWidgetScale));
      const plaidWidgetLeft = `calc(50% - (${plaidWidgetWidth}px / 2))`;
      const plaidWidgetTop = `calc(50% - (${plaidWidgetHeight}px / 2))`;
      plaidIframe.style.setProperty(KEYS.SCALE_CSS_PROPERTY, plaidWidgetScale.toString());
      plaidIframe.style.setProperty(KEYS.WIDTH_CSS_PROPERTY, `${plaidWidgetWidth}px`);
      plaidIframe.style.setProperty(KEYS.HEIGHT_CSS_PROPERTY, `${plaidWidgetHeight}px`);
      plaidIframe.style.setProperty(KEYS.LEFT_CSS_PROPERTY, plaidWidgetLeft);
      plaidIframe.style.setProperty(KEYS.TOP_CSS_PROPERTY, plaidWidgetTop);
    }
  };

  const processPlaid = (clientObjectId, plaidOptions) => {
    if (!shift4Utils || !window.Plaid) {
      return;
    }
    const plaidLinkOptions = {
      ...plaidOptions,
      onSuccess: async (publicToken, metadata) => {
        try {
          await shift4Utils.updatePaymentMethod(clientObjectId, {
            plaid: {
              publicToken,
              publicTokenMetadata: metadata,
            },
          });
          handleFinalizeAch();
        } catch (err: any) {
          setAchError(ACH_ERROR_LABELS.GENERIC_ERROR);
        }
      },
      onExit: () => {
        setAchError(ACH_ERROR_LABELS.USER_CANCELED);
      },
      onLoad: () => {
        const iframes = document.querySelectorAll(KEYS.PLAID_IFRAME_SELECTOR);
        if (iframes.length === 0) {
          return;
        }

        const lastIframe = iframes[iframes.length - 1];
        scalePlaidWidgetDownIfSmallDimensions(lastIframe as HTMLIFrameElement);
      },
    };

    window.Plaid.create(plaidLinkOptions).open();
  };

  useEffect(() => {
    handlePaymentMethodState(clientObjectId);
  }, [clientObjectId]);
};

export default usePlaid;
