import { useEffect, useRef } from 'react';

import KEYS from './keys';

interface UseReturnUrlParams {
  returnUrl: string | null;
  fetchCryptoDonationStatus: () => Promise<Boolean>;
}

export const useReturnUrl = ({
  returnUrl,
  fetchCryptoDonationStatus,
}: UseReturnUrlParams) => {
  const intervalRef = useRef<ReturnType<typeof setInterval>>();
  useEffect(() => {
    if (returnUrl) {
      startPollingForStatus();
    }
    return clearPollingInterval;
  }, [returnUrl]);

  const navigateToReturnUrl = () => {
    if (!returnUrl) {
      return;
    }

    window.location.href = returnUrl;
  };

  const startPollingForStatus = async () => {
    intervalRef.current = setInterval(async () => {
      const donationReceived = await fetchCryptoDonationStatus();
      if (!donationReceived) {
        return;
      }

      clearPollingInterval();
      navigateToReturnUrl();
    }, KEYS.STATUS_POLLING_INTERVAL);
  };

  const clearPollingInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  return navigateToReturnUrl;
};

export default useReturnUrl;
