import React, { useMemo } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';

import { BASIC_CURRENCIES } from 'constants/currencies';
import Currency from 'types/currency';
import { locators, QALocator } from 'utils/tests/QA';

import { KEYS, LABELS } from './keys';
import useStyles, { styles } from './styles';

interface OptionsCryptoProps {
  handleChange: (value: string) => void;
  selectedCurrency: string;
  isTouched: boolean;
  setTouched: (value: boolean) => void;
  currencies: Currency[];
  isOnlyInput: boolean;
}

export const OptionsCrypto = ({
  currencies,
  handleChange,
  selectedCurrency,
  isTouched,
  isOnlyInput,
  setTouched,
}: OptionsCryptoProps) => {
  const cryptoOptions = useMemo(() => currencies.map(c => c.code.toLowerCase()), [currencies]);
  const labels = useMemo(() => (
    currencies.reduce((acc, currency) => {
      const ercTokenText = currency.isErc20 ? LABELS.ERC20 : '';
      const splTokenText = currency.network === KEYS.SPL_NETWORK ? LABELS.SPL_TOKEN : '';
      const label = [currency.name, `(${currency.code})`, ercTokenText, splTokenText].filter(Boolean).join(' ');
      return {
        ...acc,
        [currency.code.toLowerCase()]: label,
      };
    }, [])
  ), [currencies]);

  const isSelected = (isOnlyInput || isTouched) && cryptoOptions.includes(selectedCurrency);
  const { classes } = useStyles({ isSelected });
  const iconMap = useMemo(() => (
    currencies.reduce((acc, cur) => ({
      ...acc,
      [cur.code.toLowerCase()]: cur.imageUrl,
    }), {})
  ), [currencies]);

  const renderOption = (props: Object, crypto: string) => (
    <div className={classes.optionContainer} {...props}>
      {!!iconMap[crypto] && <img src={iconMap[crypto]} alt={crypto} className={classes.icon} />}
      <div className={classes.option}>
        {labels[crypto]}
      </div>
    </div>
  );

  const getTextFieldParams = params => ({
    ...params,
    InputProps: {
      ...params.InputProps,
      startAdornment: isSelected ? (
        <div style={styles.iconWrapper}>
          {!!iconMap[selectedCurrency] && (
          <img
            src={iconMap[selectedCurrency]}
            alt={selectedCurrency}
            className={classes.icon}
          />
          )}
        </div>
      ) : null,
    },
    InputLabelProps: {
      ...params.InputLabelProps,
      classes: {
        root: classes.labelRoot,
        shrink: classes.labelShrink,
      },
    },
  });

  const filterOptions = (options: string[], { inputValue }) => (
    options.filter(option => labels[option]
      && labels[option].toLowerCase()
        .includes(inputValue.toLowerCase()))
  );

  const onChange = (_, selectedCrypto) => {
    setTouched(Boolean(selectedCrypto));
    handleChange(selectedCrypto || BASIC_CURRENCIES.BTC);
  };

  return (
    <FormControl fullWidth className={classes.optionWrapper}>
      <Autocomplete
        openOnFocus
        classes={{
          inputRoot: classes.inputRoot,
          listbox: classes.listbox,
        }}
        onChange={onChange}
        value={isSelected ? selectedCurrency : ''}
        options={['', ...cryptoOptions]}
        filterOptions={filterOptions}
        getOptionLabel={(crypto: string) => labels[crypto] || ''}
        renderInput={params => (
          <TextField
            {...getTextFieldParams(params)}
            placeholder={!isOnlyInput ? LABELS.OTHER_CRYPTO_PLACEHOLDER : null}
            size="small"
            label={!isOnlyInput ? LABELS.OTHER_CRYPTO_LABEL : null}
            variant="outlined"
          />
        )}
        renderOption={renderOption}
        {...QALocator(locators.components.crypto.optionsInput)}
      />
    </FormControl>
  );
};

export default OptionsCrypto;
