import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';
import FONT_FAMILIES from 'theme/fontFamilies';

export const useStyles = makeStyles()({
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 0,
  },
  title: {
    textAlign: 'center',
    fontFamily: FONT_FAMILIES.POPPINS,
    color: COLORS.TEXT.PRIMARY,
    fontSize: 25,
    lineHeight: 'normal',
    fontWeight: 400,
    margin: 0,
    width: '100%',
  },
  subtitle: {
    textAlign: 'center',
    fontFamily: FONT_FAMILIES.POPPINS,
    color: COLORS.TEXT.PRIMARY,
    fontSize: 20,
    lineHeight: 'normal',
    fontWeight: 600,
    margin: 0,
    width: '100%',
  },
  panel: {
    position: 'relative',
    background: COLORS.WHITE,
    padding: 20,
    minWidth: 330,
    marginLeft: -1,
    marginTop: -1,
    borderRadius: 5,
    boxSizing: 'border-box',
    border: `1px solid ${COLORS.INPUT.BORDER}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 3,
    gap: 15,
    boxShadow: '0px 3px 12px 0px rgba(41, 27, 79, 0.05)',
  },
  instructionsTitle: {
    textAlign: 'left',
    fontFamily: FONT_FAMILIES.POPPINS,
    color: COLORS.TEXT.PRIMARY,
    fontSize: 18,
    lineHeight: 'normal',
    fontWeight: 600,
    margin: 0,
    width: '100%',
  },
  instructionsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    color: COLORS.TEXT.PRIMARY,
    gap: 10,
  },
  instructionIcon: {
    minWidth: 24,
    minHeight: 24,
    backgroundColor: COLORS.SECONDARY,
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  instructionText: {
    fontFamily: FONT_FAMILIES.POPPINS,
    fontSize: 14,
    lineHeight: 'normal',
    fontWeight: 400,
    margin: 0,
    textIndent: 'unset',
  },
  textBold: {
    fontWeight: 600,
  },
  delimeter: {
    border: 0,
    margin: 0,
    backgroundColor: COLORS.INPUT.BORDER,
    width: '100%',
    height: 1,
    marginTop: -1,
  },
  list: {
    alignSelf: 'stretch',
    fontFamily: FONT_FAMILIES.POPPINS,
    fontSize: 14,
    color: COLORS.TEXT.PRIMARY,
    margin: 0,
    paddingLeft: 21,
  },
  listTitle: {
    fontFamily: FONT_FAMILIES.POPPINS,
    fontSize: 14,
    color: COLORS.TEXT.PRIMARY,
    fontWeight: 400,
    margin: 0,
    marginLeft: -21,
    marginBottom: 10,
  },
  listItem: {
    '&:not(:last-of-type)': {
      marginBottom: 10,
      padding: 0,
    },
  },
});

export default useStyles;
