import { Dispatch } from 'redux';

import { API, Endpoints } from 'api';
import submitStocksDonationPledgeFail from 'state/donation/submitStocksDonationPledge.fail';
import submitStocksDonationPledgeStart from 'state/donation/submitStocksDonationPledge.start';
import submitStocksDonationPledgeSuccess from 'state/donation/submitStocksDonationPledge.success';
import { DonorInfo } from 'types/donor';
import { FundraiserId } from 'types/fundraiser';
import { OrganizationInfo } from 'types/organization';
import { StocksDonationPledge } from 'types/pledge';
import { StockDonationWorkflowStep } from 'types/workflow';
import detectDocumentOrigin from 'utils/detectDocumentOrigin';
import removeNullValues from 'utils/objects/removeNullValues';
import getApiUserUuid from 'utils/queryParams';

import setDonorInfo from '../../donor/setDonorInfo';
import changeStep from '../changeStep';
import setError from '../setError';

interface SubmitStockDonationPledgeParams {
  pledge: StocksDonationPledge;
  organization: OrganizationInfo;
  donor: DonorInfo;
  fundraiserId: FundraiserId;
  externalId?: string | null;
}

const preparePledge = ({
  pledge,
  organization,
  donor,
  documentOrigin,
  fundraiserId,
  apiUserUuid,
  externalId,
}) => removeNullValues({
  'Address 1': donor.address1 || '',
  'Address 2': donor.address2 || '',
  City: donor.city || '',
  Country: donor.country || '',
  Email: donor.email || '',
  'First name': donor.firstName || '',
  'Last name': donor.lastName || '',
  State: donor.state || '',
  Zipcode: donor.zipCode || '',
  Notes: pledge.notes,
  organizationId: organization.id,
  origin: documentOrigin,
  quantity: pledge.quantity,
  assetSymbol: pledge.asset.ticker,
  assetDescription: pledge.asset.name,
  fundsDesignation: pledge.fundsDesignation || undefined,
  campaignId: pledge.campaignId || null,
  fundraiserId,
  phoneNumber: donor.phoneNumber || '',
  apiUserUuid,
  honoreeEmail: pledge.notifyHonoree ? pledge.honoreeEmail : null,
  honoreeName: pledge.honoreeName,
  externalId,
  isCharityCommunicationAllowed: donor.isCharityCommunicationAllowed,
  isDonorRetired: organization.areEmployerDetailsRequired ? donor?.isDonorRetired : null,
  employer: organization.areEmployerDetailsRequired ? donor?.employer : null,
  occupation: organization.areEmployerDetailsRequired ? donor?.occupation : null,
});

const KEYS = {
  SOMETHING_WENT_WRONG_ERROR: 'Something went wrong',
};

export const submitStockDonationPledge = ({
  pledge,
  organization,
  donor: donorInfo,
  fundraiserId,
  externalId,
}: SubmitStockDonationPledgeParams) => async (dispatch: Dispatch) => {
  const documentOrigin = await detectDocumentOrigin;
  const apiUserUuid = getApiUserUuid();

  const preparedPledge = preparePledge({
    donor: donorInfo,
    organization,
    pledge,
    documentOrigin,
    fundraiserId,
    apiUserUuid,
    externalId,
  });

  try {
    dispatch(submitStocksDonationPledgeStart.createAction());
    const response = await API.post(Endpoints.donationStocks, preparedPledge);
    const { donationUuid } = response?.data || {};

    if (donationUuid) {
      dispatch(submitStocksDonationPledgeSuccess.createAction({
        donationUuid,
      }));
      dispatch(changeStep.createAction(StockDonationWorkflowStep.FillBrokerInfo));
      dispatch(setDonorInfo.createAction(donorInfo));
      return;
    }

    dispatch(setError.createAction(KEYS.SOMETHING_WENT_WRONG_ERROR));
    const errorRecord = { errorMessage: KEYS.SOMETHING_WENT_WRONG_ERROR };
    dispatch(submitStocksDonationPledgeFail.createAction(errorRecord));
  } catch (e) {
    dispatch(setError.createAction(KEYS.SOMETHING_WENT_WRONG_ERROR));
    const errorRecord = { errorMessage: KEYS.SOMETHING_WENT_WRONG_ERROR };
    dispatch(submitStocksDonationPledgeFail.createAction(errorRecord));
  }
};

export default submitStockDonationPledge;
