import { Dispatch } from 'redux';

import { API, Endpoints } from 'api';

import fetchBrokersFail from '../fetchBrokers.fail';
import fetchBrokersStart from '../fetchBrokers.start';
import fetchBrokersSuccess from '../fetchBrokers.success';

const KEYS = {
  SOMETHING_WENT_WRONG_ERROR: 'Something went wrong',
};

export const fetchStockBrokers = () => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchBrokersStart.createAction());
    const response = await API.get(Endpoints.stockBrokers);
    const brokers = response?.data?.brokers;

    if (brokers) {
      dispatch(fetchBrokersSuccess.createAction(brokers));
      return;
    }

    const errorRecord = { errorMessage: KEYS.SOMETHING_WENT_WRONG_ERROR };
    dispatch(fetchBrokersFail.createAction(errorRecord));
  } catch (error: any) {
    const { message } = error;
    const errorRecord = { errorMessage: message || KEYS.SOMETHING_WENT_WRONG_ERROR };
    dispatch(fetchBrokersFail.createAction(errorRecord));
  }
};

export default fetchStockBrokers;
