import React from 'react';
import { environmentConfig } from 'environment/environment.config';

import runtimeLocalStorage, { RUNTIME_LOCAL_STORAGE_KEYS } from 'utils/runtimeLocalStore';

import { Logo } from '../logo';
import Spinner from './spinner';
import useStyles from './styles';

export interface LoadingIconProps {
  className?: string;
}

// TODO: Rename the component, as it's not a loading icon. It's a loading animation holder
export const LoadingIcon = ({
  className,
}: LoadingIconProps) => {
  const { classes, cx } = useStyles();
  const isPCDEnabled = runtimeLocalStorage.get(RUNTIME_LOCAL_STORAGE_KEYS.IS_PCD_ENABLED) ?? environmentConfig.isPCDEnabled;

  if (isPCDEnabled) {
    return (
      <Spinner className={cx(classes.spinner, className)} />
    );
  }

  return (
    <Logo className={cx(classes.loadingIcon, className)} />
  );
};

export default LoadingIcon;
