import btcLogo from '../assets/images/btc-logo.png';
import cardIcon from '../assets/images/card-icon.png';
import charityLogo from '../assets/images/charity-logo.jpg';
import ethLogo from '../assets/images/eth-logo.png';
import mainLogo from '../assets/images/main-logo.png';
import payStringLogo from '../assets/images/payid.png';
import usdcLogo from '../assets/images/usdc-logo.png';

export const ICONS = {
  mainLogo: {
    type: 'image',
    src: mainLogo,
  },
  btcLogo: {
    type: 'image',
    src: btcLogo,
  },
  cardIcon: {
    type: 'image',
    src: cardIcon,
  },
  charityLogo: {
    type: 'image',
    src: charityLogo,
  },
  ethLogo: {
    type: 'image',
    src: ethLogo,
  },
  payString: {
    type: 'image',
    src: payStringLogo,
  },
  usdcLogo: {
    type: 'image',
    src: usdcLogo,
  },
};

export default ICONS;
