import { DonationData } from 'types/donationData';

import { AnyAction } from '../state.types';
import { DonationDataReduxState } from './state';

export const actionTypeName = 'DONATION_DATA__SET_DONATION_DATA';

export type ActionSetDonationData = AnyAction & {
  donationData: DonationData;
}

export const createAction = (donationData: DonationData) => ({
  type: actionTypeName,
  donationData,
});

export const reducer = (
  state: DonationDataReduxState,
  action: ActionSetDonationData,
): DonationDataReduxState => ({
  ...state,
  ...action.donationData,
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
