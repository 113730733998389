export const PLACEHOLDERS = {
  PLEDGED_CRYPTO: '{PLEDGED_CRYPTO}',
};

export const KEYS = {
  TOOLTIP_TOUCH_DELAY: 50,
  ERC_20: '(ERC-20)',
  SPL_TOKEN: '(SPL Token)',
  SPL_NETWORK: 'solana',
  QR_ALT: 'donation wallet address',
  LONG_ADDRESS_CHARACTERS_LENGTH: 10,
  ADDRESS_CHOP_LENGTH: 14,
  LONG_ADDRESS_JOIN_SYMBOL: '...',
  STATUS_POLLING_INTERVAL: 10000,
};

export const LABELS = {
  CLICK_TO_COPY: 'Click here to copy wallet address',
  DONATE_PROPMT_1: 'Use the address below to donate',
  DONATE_PROPMT_2: 'from your wallet.',
  RIGHT_BUTTON_TEXT: 'Start Over',
  COMPLETED_BUTTON_TEXT: 'COMPLETED',
  LEFT_BUTTON_TEXT: 'Previous',
  LOADING_ADDRESS: 'Loading donation wallet',
  DONATION_NOTE:
    'Please note that your donation will clear even if you donate a different amount than you pledged.',
  DONATE_WITH_METAMASK: 'Donate with MetaMask',
  COPIED: 'Copied',
  XRP_DONATION_NOTE: 'Please ensure you enter the address and the tag exactly as instructed. Failure to do so may result in loss of funds.',
  DESTINATION_TAG: 'Destination tag',
  CLICK_WHEN_READY: 'Click completed once your donation is sent',
};

export const DONATION_NOTES = {
  DEFAULT: `Send only ${PLACEHOLDERS.PLEDGED_CRYPTO} to this address.
  Sending other unsupported tokens or NFTs
  to this address may result in the loss of your donation. The address will expire after 180 days if unused.`,
  MATIC: `Send only MATIC (Using the Ethereum Mainnet/ERC-20 token) to this address.
  Sending other unsupported tokens or NFTs
  to this address (including MATIC on the Polygon network)
  may result in the loss of your donation. The address will expire after 180 days if unused.`,
  BUSD: `Send only BUSD (Using the Ethereum Mainnet/ERC-20 token) to this address.
  Sending other unsupported tokens or NFTs to this address (including BUSD on Binance Smart
  Chain or other BEP-20 tokens) may result in the loss of your donation. The address will expire after 180 days if unused.`,
};
