import { AnyReduxState } from '../state.types';

export type StockReduxState = AnyReduxState & {
  brokerName: string;
  brokerageAccountNumber: string;
  brokerContactName: string;
  brokerEmail: string;
  brokerPhone: string;
  customBrokerName: string;
  submitting: boolean;
  error: Record<string, string>;
  signature: string;
}

export const initialState = (): StockReduxState => ({
  brokerName: '',
  brokerageAccountNumber: '',
  brokerContactName: '',
  brokerEmail: '',
  brokerPhone: '',
  customBrokerName: '',
  submitting: false,
  error: {},
  signature: '',
});
