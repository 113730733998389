import { createReducer } from '../createReducer';
import { buildReducerItem } from '../reducerItem';
import { Reducer } from '../state.types';
import fetchConnectionIdFail from './fetchConnectionId.fail';
import fetchConnectionIdStart from './fetchConnectionId.start';
import fetchConnectionIdSuccess from './fetchConnectionId.success';
import fetchDonationAmountFail from './fetchDonationAmount.fail';
import fetchDonationAmountStart from './fetchDonationAmount.start';
import fetchDonationAmountSuccess from './fetchDonationAmount.success';
import postDonationInfoFail from './postDonationInfo.fail';
import postDonationInfoStart from './postDonationInfo.start';
import postDonationInfoSuccess from './postDonationInfo.success';
import setConnectionId from './setConnectionId';
import setSession from './setSession';
import { ChariotReduxState, initialState } from './state';

// Reducers
const reducers: Map<string, Reducer<ChariotReduxState, any>> = new Map([
  buildReducerItem(fetchConnectionIdStart),
  buildReducerItem(fetchConnectionIdSuccess),
  buildReducerItem(fetchConnectionIdFail),
  buildReducerItem(postDonationInfoStart),
  buildReducerItem(postDonationInfoSuccess),
  buildReducerItem(postDonationInfoFail),
  buildReducerItem(fetchDonationAmountStart),
  buildReducerItem(fetchDonationAmountSuccess),
  buildReducerItem(fetchDonationAmountFail),
  buildReducerItem(setSession),
  buildReducerItem(setConnectionId),
]);

export const dashboardReducer = createReducer(initialState, reducers);

export default dashboardReducer;
