import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

export const useStyles = makeStyles()({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100% - 120px)',
    padding: 35,
  },
  title: {
    color: COLORS.PRIMARY,
    fontSize: 20,
    marginTop: 'unset',
  },
  frequencyOption: {
    border: 'none',
    flex: 'unset',
    margin: '5px !important',
    width: '100%',
    background: '#E6EDF2 !important',
  },
});

export default useStyles;
