import { AnyReduxState } from 'state/state.types';

export type MetamaskReduxState = AnyReduxState & {
  metamaskInstalled: boolean;
  selectedAddress: string | null;
}

export const initialState = (): MetamaskReduxState => {
  const metamaskInstalled = Boolean(window?.ethereum?.isMetaMask);
  const selectedAddress = window?.ethereum?.selectedAddress || null;

  return {
    metamaskInstalled,
    selectedAddress,
  };
};
