import { AnyAction } from '../state.types';
import { PledgeReduxState } from './state';

export const actionTypeName = 'PLEDGE__FETCH_CURRENCY_EXCHANGE_RATE__START';

export type ActionFetchCurrencyExchangeRateFail = AnyAction & {
}

export const createAction = () => ({
  type: actionTypeName,
});

export const reducer = (
  state: PledgeReduxState,
): PledgeReduxState => ({
  ...state,
  exchangeRate: 0,
  amount: 0,
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
