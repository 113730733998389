import React, {
  ChangeEvent,
  FocusEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, FormHelperText, Input } from '@mui/material';

import OptionallyVisible from 'components/optionallyVisible';
import ToggleButton from 'components/toggleButton';
import { AVAILABLE_FREQUENCIES, LABELS as FREQUENCY_LABELS } from 'pages/creditCard/frequency/frequency.keys';
import Frequency from 'types/frequency';
import { DonationPledge } from 'types/pledge';
import { calculateWithFeesBeforeGross, USDFormatter } from 'utils/currency';
import { locators, QALocator } from 'utils/tests/QA';

import { FiatFormatCustom } from '../../numberFormatCustom';
import { LABELS } from '../pledge.keys';
import SelectAmountButtons from './selectAmountButtons';
import useStyles from './styles';

export interface FiatCurrencyAmountBoxProps {
  onMainAmountChange: (value: number) => void;
  onSecondaryAmountChange: (value: number) => void;
  pledge: DonationPledge;
  showSubPrice: boolean;
  error?: string;
  fees: {
    percent?: number;
    usd: number;
  } | null;
  areRecurringDonationsEnabled: boolean;
  frequency: Frequency;
  setFrequency: (frequency: Frequency) => void;
}

export const FiatCurrencyAmountBox = ({
  onMainAmountChange,
  onSecondaryAmountChange,
  fees,
  showSubPrice,
  pledge,
  error = '',
  areRecurringDonationsEnabled,
  frequency,
  setFrequency,
}: FiatCurrencyAmountBoxProps) => {
  const formattedUsdValue = useMemo(() => USDFormatter(0).format(pledge.usdAmount), [pledge.usdAmount]);
  const formattedGrossValue = useMemo(() => {
    if (!fees) {
      return USDFormatter(2).format(pledge.usdAmount);
    }

    const amount = calculateWithFeesBeforeGross(pledge.usdAmount, fees);
    return USDFormatter(2).format(amount);
  }, [fees, pledge.usdAmount]);
  const [secondaryInputValue, setSecondaryInputValue] = useState<string>(formattedGrossValue);
  const [isFocused, setFocused] = useState<boolean>(false);
  const { classes, cx } = useStyles({ isFocused });

  const frequencies = useMemo(() => Object
    .values(AVAILABLE_FREQUENCIES)
    .map(frequencyKey => ({
      label: FREQUENCY_LABELS.FREQUENCY[frequencyKey],
      value: frequencyKey,
    })), []);

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onSecondaryAmountChange(Number(value));
    onMainAmountChange(Number(value));
  };

  const handleInputFocus = () => {
    setFocused(true);
  };

  const handleInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onSecondaryAmountChange(Number(value));
    setFocused(false);
  };

  const onFrequencyChange = (item) => {
    setFrequency(item.value);
  };

  useEffect(() => {
    setSecondaryInputValue(formattedGrossValue);
  }, [pledge.usdAmount]);

  return (
    <>
      <OptionallyVisible visible={areRecurringDonationsEnabled}>
        <div className={classes.frequencyOptionsWrapper}>
          <ToggleButton initialItem={frequency} items={frequencies} onChange={onFrequencyChange} />
        </div>
      </OptionallyVisible>
      <SelectAmountButtons onSelect={onMainAmountChange} usdAmount={formattedUsdValue} />
      <div className={classes.amountBoxWrapper}>
        <Box
          className={cx({
            [classes.amountBox]: true,
            error: Boolean(error),
          })}
        >
          <Box className={classes.mainInputContainer}>
            <Input
              disableUnderline
              inputProps={{
                className: classes.mainCurrencyInput,
                prefix: LABELS.USD_VALUE_PREFIX,
              }}
              value={formattedUsdValue}
              onChange={handleAmountChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              placeholder={LABELS.CRYPTO_INPUT_PLACEHOLDER}
              inputComponent={FiatFormatCustom}
              {...QALocator(locators.components.fiat.amountInput)}
            />
          </Box>
          <OptionallyVisible visible={showSubPrice}>
            <Box className={classes.secondaryInputContainerExtended}>
              <span className={classes.inputDecoration}>
                {LABELS.INCLUDING_FEES_PREFIX}
              </span>
              <Input
                disableUnderline
                inputProps={{
                  className: classes.secondaryCurrencyInput,
                  prefix: LABELS.USD_VALUE_PREFIX,
                }}
                value={secondaryInputValue}
                disabled
                placeholder={LABELS.USD_INPUT_PLACEHOLDER}
                inputComponent={FiatFormatCustom}
                {...QALocator(locators.components.fiat.amountWithFeesInput)}
              />
            </Box>
          </OptionallyVisible>
        </Box>
        <OptionallyVisible visible={!isFocused && Boolean(error)}>
          <FormHelperText className={classes.errorText}>{error}</FormHelperText>
        </OptionallyVisible>
      </div>
    </>
  );
};

export default FiatCurrencyAmountBox;
