import { AnyAction, Reducer } from '../state.types';
import { DonationReduxState } from './state';

export const actionTypeName = 'DONATION__FETCH_CRYPTO_DONATION_STATUS__SUCCESS';

export type ActionFetchCryptoDonationStatusSuccess = AnyAction & {
  donationReceived: boolean;
}

export const createAction = (donationReceived: boolean): ActionFetchCryptoDonationStatusSuccess => ({
  type: actionTypeName,
  donationReceived,
});

export const reducer: Reducer<DonationReduxState, ActionFetchCryptoDonationStatusSuccess> = (
  state: DonationReduxState,
  action: ActionFetchCryptoDonationStatusSuccess,
): DonationReduxState => {
  const nextState = {
    ...state,
    crypto: {
      ...state.crypto,
      isFetchingStatus: false,
      donationReceived: action.donationReceived,
    },
  };

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
