import { ChariotReduxState } from './state';

export const actionTypeName = 'CHARIOT__POST_DONATION_INFO__START';

export const createAction = () => ({
  type: actionTypeName,
});

export const reducer = (
  state: ChariotReduxState,
): ChariotReduxState => ({
  ...state,
  donation: {
    ...state.donation,
    isLoading: true,
  },
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
