import { Currency } from 'types/currency';
import { SortOrder } from 'types/sort';
import getComparator, { ComparatorType } from 'utils/tables/getComparator';
import stableSort from 'utils/tables/stableSort';

import { AnyAction, Reducer } from '../state.types';
import { CurrenciesReduxState } from './state';

export const actionTypeName = 'CURRENCIES__FETCH_CURRENCIES__SUCCESS';

export const SORT_FIELD = 'code';

export type ActionFetchCurrenciesSuccess = AnyAction & {
  currencies: Currency[];
}

export const createAction = (currencies: Currency[]): ActionFetchCurrenciesSuccess => ({
  type: actionTypeName,
  currencies,
});

export const reducer: Reducer<CurrenciesReduxState, ActionFetchCurrenciesSuccess> = (
  state: CurrenciesReduxState,
  action: ActionFetchCurrenciesSuccess,
): CurrenciesReduxState => {
  const { currencies = [] } = action;

  if (!Array.isArray(currencies)) {
    return state;
  }

  const sortedCurrencies = stableSort(currencies, getComparator(
    SortOrder.Asc,
    SORT_FIELD,
    { type: ComparatorType.String },
  ));

  const nextState: CurrenciesReduxState = {
    ...state,
    error: null,
    currencies: sortedCurrencies,
    isLoading: false,
  };

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
