import { AppReduxState } from 'state/state';
import { DonationWorkflowType } from 'types/workflow';

import { KEYS } from './keys';
import GTMEventType from './types';

const makeBasicPayload = (state: AppReduxState) => {
  const { isAnonymous } = state.donor;
  return {
    event: GTMEventType.PersonalInfo,
    isAnonymous: isAnonymous ? KEYS.YES : KEYS.NO,
  };
};

export const personalInfoPayloadFactory = (
  flow: DonationWorkflowType,
  { state }: { state: AppReduxState },
) => {
  if (flow === DonationWorkflowType.Daf) {
    return {};
  }

  return {
    ...makeBasicPayload(state),
    donationType: flow,
  };
};

export default personalInfoPayloadFactory;
