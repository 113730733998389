export const LABELS = {
  BROKER_FIRM_LABEL: 'Delivering Broker Firm',
  BROKER_FIRM_PLACEHOLDER: 'Select Broker',
  BROKER_ACCOUNT_NUMBER_LABEL: 'Brokerage Account Number',
  BROKER_ACCOUNT_NUMBER_PLACEHOLDER: 'Number',
  BROKER_CONTACT_NAME_LABEL: 'Broker Contact Name',
  BROKER_CONTACT_NAME_PLACEHOLDER: 'Name',
  BROKER_EMAIL_LABEL: 'Broker E-mail',
  BROKER_EMAIL_PLACEHOLDER: 'Email',
  BROKER_PHONE_NUMBER_LABEL: 'Broker Phone',
  BROKER_PHONE_NUMBER_PLACEHOLDER: 'Phone Number',
  CUSTOM_BROKER_NAME: 'Brokerage Firm',
  BACK_BUTTON_LABEL: 'PREVIOUS',
  CONTINUE_BUTTON_LABEL: 'CONTINUE',
  PROCESSING: 'Processing...',
  REQURED: 'Required field',
  INVALID_EMAIL: 'Please use a valid email address',
  PAGE_TITLE: 'Broker Info',
  NEXT: 'Next',
};

export const KEYS = {
  BROKER_NAME: 'brokerName',
  BROKERAGE_ACCOUNT_NUMBER: 'brokerageAccountNumber',
  BROKER_CONTACT_NAME: 'brokerContactName',
  BROKER_EMAIL: 'brokerEmail',
  BROKER_PHONE: 'brokerPhone',
  NUMBER_TYPE: 'number',
  EMAIL_TYPE: 'email',
  TEXT_TYPE: 'text',
  CUSTOM_BROKER_NAME: 'customBrokerName',
  OTHER_BROKER_NAME_VALUE: 'other',
  REQUIRED_ASTERISK: ' *',
};

export default LABELS;
