export const LABELS = {
  OTHER_CRYPTO_LABEL: 'Other crypto options',
  OTHER_CRYPTO_PLACEHOLDER: 'Search currencies',
  ERC20: 'ERC-20',
  SPL_TOKEN: 'SPL Token',
};

export const KEYS = {
  SPL_NETWORK: 'solana',
  RIGHT_BUTTON_TEXT: 'Start Over',
};

export default LABELS;
