import { ValidationAPIErrorDetails } from '../api.types';

export class ValidationError extends Error {
  details: ValidationAPIErrorDetails;

  constructor(details: ValidationAPIErrorDetails) {
    const message = details.meta.validationErrorMessage || details.message;
    super(message);
    this.details = details;
    this.message = message;
  }

  getValidationErrorDetails() {
    if (!this.details) {
      return null;
    }

    return this.details.meta?.failedAttributes?.reduce((obj, attr) => {
      const cloned = { ...obj };
      cloned[attr.attributeName] = attr.message;
      return cloned;
    }, {}) as Record<string, string>;
  }
}

export default ValidationError;
