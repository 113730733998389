import {
  DonationWorkflowType,
  WorkflowStep,
} from 'types/workflow';

import { AnyReduxState } from '../state.types';

export type WorkflowReduxState = AnyReduxState & {
  type: DonationWorkflowType | null;
  step: WorkflowStep | null;
  isFetching: boolean;
  isSubmitting: boolean;
  error: {
    message: string;
    title?: string;
  };
}

export const initialState = (): WorkflowReduxState => ({
  type: null,
  step: null,
  isFetching: false,
  isSubmitting: false,
  error: {
    title: undefined,
    message: '',
  },
});
