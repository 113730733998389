import { createContext } from 'react';

import { Shift4Utils } from 'types/card';

export type Shift4UtilsContextValues = {
  shift4Utils: Shift4Utils | null;
}

export const initialValues: Shift4UtilsContextValues = {
  shift4Utils: null,
};

export const Shift4UtilsContext = createContext(initialValues);

export default Shift4UtilsContextValues;
