import React, { FunctionComponent, useMemo } from 'react';

import { DonationSuccessConnect } from 'pages/common/donationSuccess/donationSuccess.connect';
import { DonorInfoConnect } from 'pages/common/donorInfo/donorInfo.connect';
import { ErrorConnect } from 'pages/common/error/daf/error.connect';
import DafConfirmConnect from 'pages/daf/confirm/dafConfirm.connect';
import NotesConnect from 'pages/daf/notes/notes.connect';
import DAFPledgeConnect from 'pages/daf/pledge/pledge.connect';
import { DafDonationWorkflowStep } from 'types/workflow';

const componentMap: Record<DafDonationWorkflowStep, FunctionComponent> = {
  [DafDonationWorkflowStep.Pledge]: DAFPledgeConnect,
  [DafDonationWorkflowStep.FillDonorInfo]: DonorInfoConnect,
  [DafDonationWorkflowStep.Donate]: DafConfirmConnect,
  [DafDonationWorkflowStep.Notes]: NotesConnect,
  [DafDonationWorkflowStep.Error]: ErrorConnect,
  [DafDonationWorkflowStep.Success]: DonationSuccessConnect,
};

interface DafRouterProps {
  step: DafDonationWorkflowStep;
}

export const DafRouter = ({ step }: DafRouterProps) => {
  const Component = useMemo(() => componentMap[step], [step]);
  return (
    <Component />
  );
};

export default DafRouter;
