import { createReducer } from '../createReducer';
import { buildReducerItem } from '../reducerItem';
import { Reducer } from '../state.types';
import fetchOrganizationInfoFail from './fetchOrganizationInfo.fail';
import fetchOrganizationInfoStart from './fetchOrganizationInfo.start';
import fetchOrganizationInfoSuccess from './fetchOrganizationInfo.success';
import { initialState, OrganizationReduxState } from './state';

// Reducers
const reducers: Map<string, Reducer<OrganizationReduxState, any>> = new Map([
  buildReducerItem(fetchOrganizationInfoStart),
  buildReducerItem(fetchOrganizationInfoSuccess),
  buildReducerItem(fetchOrganizationInfoFail),
]);

export const organizationReducer = createReducer(initialState, reducers);

export default organizationReducer;
