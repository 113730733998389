import React, { useMemo } from 'react';

import { ErrorConnect } from 'pages/common/error/error.connect';
import { GenericWorkflowStep } from 'types/workflow';

const componentMap = {
  [GenericWorkflowStep.Error]: ErrorConnect,
};

interface GenericRouterProps {
  step: GenericWorkflowStep;
}

export const GenericRouter = ({ step }: GenericRouterProps) => {
  const Component = useMemo(() => componentMap[step], [step]);
  return (
    <Component />
  );
};

export default GenericRouter;
