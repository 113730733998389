import React, { useMemo } from 'react';

import { BottomButtons, Button } from 'components';
import { ButtonDisplayVariant } from 'components/button/button';
import OptionallyVisible from 'components/optionallyVisible';
import Frequency from 'types/frequency';
import { locators, QALocator } from 'utils/tests/QA';

import { AVAILABLE_FREQUENCIES, LABELS } from './frequency.keys';
import useStyles from './frequency.style';

export interface FrequencyScreenProps {
  isPreviousStepAvailable: boolean;
  setFrequency: (frequency: Frequency) => void;
  resetFlow: () => void;
}

export const FrequencyScreen = ({
  isPreviousStepAvailable,
  setFrequency,
  resetFlow,
}: FrequencyScreenProps) => {
  const { classes } = useStyles();
  const frequencies = useMemo(() => Object.values(AVAILABLE_FREQUENCIES).map(frequencyKey => ({
    label: LABELS.FREQUENCY[frequencyKey],
    value: frequencyKey,
  })), []);

  const onFrequencyChange = item => () => {
    setFrequency(item.value);
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>{LABELS.CHOOSE_FREQUENCY}</h1>
      {frequencies.map(frequency => (
        <Button
          key={frequency.value}
          className={classes.frequencyOption}
          display={ButtonDisplayVariant.secondary}
          onClick={onFrequencyChange(frequency)}
          type="button"
          {...QALocator(locators.components.fiat.frequencyButton(frequency.value))}
        >
          {frequency.label}
        </Button>
      ))}
      <OptionallyVisible visible={isPreviousStepAvailable}>
        <BottomButtons
          leftButtonText={LABELS.GO_BACK_BUTTON}
          onClickLeft={resetFlow}
        />
      </OptionallyVisible>
    </div>
  );
};

export default FrequencyScreen;
