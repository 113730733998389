import { DonationWorkflowType } from 'types/workflow';

import GTMEventType from './types';

export const donationSharePayloadFactory = (
  flow: DonationWorkflowType,
) => ({
  event: GTMEventType.DonationShare,
  donationType: flow,
});

export default donationSharePayloadFactory;
