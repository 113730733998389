import React, { useEffect } from 'react';
import { Tooltip } from '@mui/material';

import { Button } from 'components';
import Checkbox from 'components/checkbox';
import IconQuestionMark from 'components/icon/question';
import LoadingIcon from 'components/loading/loadingIcon';
import OptionallyVisible from 'components/optionallyVisible';
import PageHeader from 'components/pageHeader/pageHeader';
import { DonationPledge } from 'types/pledge';

import { DafConfirmProps } from '../dafConfirm';
import { donateLabel, KEYS, LABELS } from './dafConfirm.keys';
import useStyles from './dafConfirm.styles';

export const DafConfirm = ({
  organization,
  amount,
  fetchedAmount,
  pledge,
  isFinalizing,
  isFetchingFinalAmount,
  finalAmountErrror,
  goBack,
  updatePledge,
  fetchDonationAmount,
  postDonationInfo,
}: DafConfirmProps) => {
  const { classes } = useStyles();
  const amountWithFees = (pledge.coverTransactionFees ? fetchedAmount : amount);
  const finalAmount = amountWithFees / 100;
  const shouldShowTributesControl = organization.areNotesEnabled && organization.areTributesEnabled;

  useEffect(() => {
    fetchDonationAmount(true);
  }, []);

  const getChangeHandler = (field: string) => (value: boolean) => {
    updatePledge({ [field]: value } as Partial<DonationPledge>);
  };

  return (
    <div className={classes.scrollContainer}>
      <PageHeader label={LABELS.PAGE_TITLE} withBackButton onGoBack={goBack} />
      <OptionallyVisible visible={isFetchingFinalAmount}>
        <div className={classes.loadingContainer}>
          <LoadingIcon />
        </div>
      </OptionallyVisible>
      <OptionallyVisible visible={!isFetchingFinalAmount}>
        <>
          <div className={classes.checkboxes}>
            <div className={classes.checkboxContainer}>
              <Checkbox
                disabled={isFinalizing}
                onChange={getChangeHandler(KEYS.COVER_TRANSACTION_FEES)}
                value={pledge.coverTransactionFees}
                name={KEYS.COVER_TRANSACTION_FEES}
                label={(
                  <div
                    className={classes.checkboxLabel}
                  >
                    {LABELS.COVER_TRANSACTION_FEES}
                    <Tooltip
                      classes={{
                        tooltip: classes.tooltip,
                      }}
                      title={LABELS.TRANSACTION_FEES_TOOLTIP}
                    >
                      <span className={classes.checkboxLabelTooltip}>
                        <IconQuestionMark width={16} height={16} />
                      </span>
                    </Tooltip>
                  </div>
              )}
              />
            </div>
            <OptionallyVisible visible={organization.areNotesEnabled}>
              <div className={classes.checkboxContainer}>
                <Checkbox
                  disabled={isFinalizing}
                  onChange={getChangeHandler(KEYS.ARE_NOTES_ENABLED)}
                  value={pledge.areNotesEnabled}
                  name={KEYS.ARE_NOTES_ENABLED}
                  label={(
                    <div
                      className={classes.checkboxLabel}
                    >
                      {LABELS.ENABLE_NOTES}
                    </div>
                )}
                />
              </div>
            </OptionallyVisible>
            <OptionallyVisible visible={shouldShowTributesControl}>
              <div className={classes.checkboxContainer}>
                <Checkbox
                  disabled={isFinalizing}
                  onChange={getChangeHandler(KEYS.ARE_TRIBUTES_ENABLED)}
                  value={pledge.areTributesEnabled}
                  name={KEYS.ARE_TRIBUTES_ENABLED}
                  label={(
                    <div
                      className={classes.checkboxLabel}
                    >
                      {LABELS.ENABLE_TRIBUTE}
                    </div>
                )}
                />
              </div>
            </OptionallyVisible>
          </div>
          <OptionallyVisible visible={Boolean(finalAmountErrror)}>
            <div>
              {finalAmountErrror.errorMessage}
            </div>
          </OptionallyVisible>
          <Button
            className={classes.proceedButton}
            disabled={isFetchingFinalAmount}
            loading={isFinalizing}
            onClick={postDonationInfo}
          >
            {donateLabel(finalAmount)}
          </Button>
        </>
      </OptionallyVisible>
    </div>
  );
};

export default DafConfirm;
