import { createTheme, ThemeOptions } from '@mui/material';

import { DesignVersion } from 'types/workflow';

import COLORS from './colors';

export interface ThemeSettings {
  designVersion: DesignVersion;
  isPCDEnabled: boolean;
}

const getColorPalette = (themeOptions: ThemeSettings) => {
  const isV2DonationForm = themeOptions.designVersion === DesignVersion.V2;

  if (themeOptions.isPCDEnabled) {
    return {
      primary: {
        main: '#FFFFFF',
        light: '#7F7F7F',
      },
      secondary: {
        main: '#000000',
      },
      divider: '#C5C5C5',
      text: {
        primary: '#000000',
        secondary: COLORS.WHITE,
      },
      background: {
        default: '#000000',
        paper: '#FFFFFF',
      },
      action: {
        disabledBackground: '#C5C5C5',
        disabled: '#F1F1F1',
      },
      error: {
        main: '#F44336',
        light: '#F44336',
      },
    };
  }

  if (isV2DonationForm) {
    return {
      primary: {
        main: '#291B4F',
        light: '#948DA7',
      },
      secondary: {
        main: '#FCD42B',
      },
      divider: COLORS.LIGHT_GREY,
      text: {
        primary: COLORS.TEXT.PRIMARY,
        secondary: COLORS.TEXT.PRIMARY,
      },
      background: {
        default: COLORS.GREY_BACKGROUND,
        paper: '#FFFFFF',
      },
      action: {
        disabledBackground: COLORS.GREY_BACKGROUND,
        disabled: COLORS.BACKGROUND,
      },
      error: {
        main: '#291B4F',
        light: '#FCD42B',
      },
    };
  }

  return {
    primary: {
      main: '#24292e',
    },
    secondary: {
      main: '#064c8f',
    },
  };
};

const getBorderRadius = (isPCDEnabled: boolean) => (isPCDEnabled ? 0 : 5);

const getAdditionalThemeProps = (themeOptions: ThemeSettings) => {
  if (themeOptions.designVersion === DesignVersion.V2) {
    return {};
  }

  return {
    props: {
      MuiButton: {
        variant: 'contained',
      },
      MuiTextField: {
        variant: 'outlined',
      },
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontFamily: 'Roboto',
            lineHeight: '1.1876em',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontFamily: 'Roboto',
            lineHeight: '1.1876em',
          },
          input: {
            fontSize: 14,
            height: '1.1876em',
            paddingTop: 8,
            paddingBottom: 8,
          },
          inputMultiline: {
            padding: 0,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontFamily: 'Roboto',
            lineHeight: '1.1876em',
            '&:not(.MuiInputLabel-shrink)': {
              transform: 'translate(14px, 12px) scale(1)',
            },
          },
        },
      },
    },
  };
};

const DEFAULT_THEME_VARIABLES: Partial<ThemeOptions> = {
  typography: {
    // @ts-ignore
    useNextVariants: true,
  },
  colorTextSecondary: {
    color: 'red',
  },
  props: {
    MuiButton: {
      variant: 'contained',
    },
    MuiTextField: {
      variant: 'outlined',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto',
          lineHeight: '1.1876em',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto',
          lineHeight: '1.1876em',
        },
        input: {
          fontSize: 14,
          height: '1.1876em',
          paddingTop: 8,
          paddingBottom: 8,
        },
        inputMultiline: {
          padding: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto',
          lineHeight: '1.1876em',
          '&:not(.MuiInputLabel-shrink)': {
            transform: 'translate(14px, 12px) scale(1)',
          },
        },
      },
    },
  },
};

const getTheme = (themeOptions: ThemeSettings) => {
  const palette = getColorPalette(themeOptions);
  const borderRadius = getBorderRadius(themeOptions.isPCDEnabled);
  const additionalThemeProps = getAdditionalThemeProps(themeOptions);

  const theme: ThemeOptions = {
    ...DEFAULT_THEME_VARIABLES,
    palette,
    shape: {
      borderRadius,
    },
    ...additionalThemeProps,
  };

  return createTheme(theme);
};

export default getTheme;
