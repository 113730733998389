import React, { useMemo } from 'react';
import { Box, Container, Tooltip } from '@mui/material';

import { Checkbox } from 'components/checkbox';
import { OptionallyVisible } from 'components/optionallyVisible';
import Currency from 'types/currency';
import Frequency from 'types/frequency';
import { DonationPledge } from 'types/pledge';
import { locators, QALocator } from 'utils/tests/QA';

import { OrganizationWithFees } from '../pledge.types';
import CryptoCurrencyAmountBox from './currencyAmountBox/currencyAmountBox.crypto';
import FiatCurrencyAmountBox from './currencyAmountBox/currencyAmountBox.fiat';
import FundsDesignations from './fundsDesignations';
import { SelectOption } from './fundsDesignations/types';
import OptionsCrypto from './optionsCrypto/optionsCrypto';
import { KEYS, LABELS } from './pledge.keys';
import useStyles from './styles';

export interface PledgeFormProps {
  pledge: DonationPledge;
  onMainAmountChange: (value: number) => void;
  onSecondaryAmountChange: (value: number) => void;
  enableNotes: boolean;
  onChange: (pledge: Partial<DonationPledge>) => void;
  currencies: Currency[];
  organization: OrganizationWithFees;
  cryptoDropdownTouched: boolean;
  setDropdownTouched: (value: boolean) => void;
  isCreditCardDonation: boolean;
  isFiatDonation: boolean;
  error: Record<string, string> | null;
  frequency: Frequency;
  setFrequency: (frequency: Frequency) => void;
  areRecurringDonationsEnabled: boolean;
}

export const PledgeForm = ({
  pledge,
  onMainAmountChange,
  onSecondaryAmountChange,
  onChange,
  enableNotes,
  currencies,
  organization,
  cryptoDropdownTouched,
  setDropdownTouched,
  isCreditCardDonation,
  isFiatDonation,
  error,
  frequency,
  setFrequency,
  areRecurringDonationsEnabled,
}: PledgeFormProps) => {
  const { classes, cx } = useStyles();
  const shouldShowNotesControl = organization.areNotesEnabled && !organization.isPCDEnabled;
  const shouldShowTributesControl = shouldShowNotesControl && organization.areTributesEnabled;

  const fundsDesignationOptions = useMemo(() => {
    const fundsDesignations = organization.fundsDesignations || [];

    return fundsDesignations.map(({ value, uuid }) => ({
      label: value,
      id: uuid,
    }));
  }, [organization.fundsDesignations]);

  const containerClasses = cx([
    classes.contentContainer,
    !enableNotes ? classes.contentContainerPadded : null,
  ]);

  const handleChange = (field: string) => (value: string | number | boolean) => {
    onChange({ [field]: value });
  };

  const hasDesignationOptions = fundsDesignationOptions.length > 0;
  const areDesignationsEnabled = Boolean(organization.fundsDesignations)
    && hasDesignationOptions;

  const shouldShowFrequencyOptions = isCreditCardDonation && areRecurringDonationsEnabled;

  const currencyAmountBox = isFiatDonation
    ? (
      <FiatCurrencyAmountBox
        showSubPrice={pledge.coverTransactionFees}
        onMainAmountChange={onMainAmountChange}
        onSecondaryAmountChange={onSecondaryAmountChange}
        fees={organization.fee}
        pledge={pledge}
        error={error?.amount || error?.pledgeAmount}
        areRecurringDonationsEnabled={shouldShowFrequencyOptions}
        frequency={frequency}
        setFrequency={setFrequency}
      />
    )
    : (
      <CryptoCurrencyAmountBox
        onMainAmountChange={onMainAmountChange}
        onSecondaryAmountChange={onSecondaryAmountChange}
        fiatCurrency={organization.payoutCurrency}
        pledge={pledge}
        error={error?.amount || error?.pledgeAmount}
      />
    );

  return (
    <Container className={containerClasses} {...QALocator(locators.components.common.pledgeForm)}>
      <OptionallyVisible visible={!isFiatDonation}>
        <div className={classes.inputButtonsWrapper}>
          <OptionsCrypto
            handleChange={handleChange(KEYS.CURRENCY_FIELD)}
            selectedCurrency={pledge.currency}
            currencies={currencies}
            isOnlyInput
            isTouched={cryptoDropdownTouched}
            setTouched={setDropdownTouched}
          />
        </div>
      </OptionallyVisible>
      {currencyAmountBox}
      <OptionallyVisible visible={isFiatDonation}>
        <Box className={classes.checkboxContainer}>
          <Checkbox
            onChange={handleChange(KEYS.COVER_TRANSACTION_FEES)}
            value={pledge.coverTransactionFees}
            name={KEYS.COVER_TRANSACTION_FEES}
            {...QALocator(locators.components.fiat.feesCheckbox)}
            label={(
              <div
                className={classes.checkboxLabel}
              >
                {LABELS.COVER_TRANSACTION_FEES}
                <Tooltip
                  classes={{
                    tooltip: classes.tooltip,
                  }}
                  title={LABELS.TRANSACTION_FEES_TOOLTIP}
                >
                  <span className={classes.checkboxLabelTooltip}>&nbsp;(?)</span>
                </Tooltip>
              </div>
            )}
          />
        </Box>
      </OptionallyVisible>
      <OptionallyVisible visible={areDesignationsEnabled}>
        <div className={classes.inputButtonsWrapper}>
          <FundsDesignations
            options={fundsDesignationOptions as SelectOption[]}
            onChange={handleChange(KEYS.FUNDS_DESIGNATION)}
            value={pledge.fundsDesignation || ''}
          />
        </div>
      </OptionallyVisible>
      <OptionallyVisible visible={shouldShowNotesControl}>
        <Box
          className={classes.checkboxContainer}
          {...QALocator(locators.page.common.components.notesCheckbox)}
        >
          <Checkbox
            onChange={handleChange(KEYS.ARE_NOTES_ENABLED)}
            value={pledge.areNotesEnabled}
            name={KEYS.ARE_NOTES_ENABLED}
            label={(
              <div
                className={classes.checkboxLabel}
              >
                {LABELS.NOTES_CHECKBOX_LABEL}
              </div>
            )}
          />
        </Box>
      </OptionallyVisible>
      <OptionallyVisible visible={shouldShowTributesControl}>
        <Box
          className={classes.checkboxContainer}
          {...QALocator(locators.page.common.components.tributesCheckbox)}
        >
          <Checkbox
            onChange={handleChange(KEYS.ARE_TRIBUTES_ENABLED)}
            value={pledge.areTributesEnabled}
            name={KEYS.ARE_TRIBUTES_ENABLED}
            label={(
              <div
                className={classes.checkboxLabel}
              >
                {LABELS.TRIBUTE_CHECKBOX_LABEL}
              </div>
            )}
          />
        </Box>
      </OptionallyVisible>
    </Container>
  );
};

export default PledgeForm;
