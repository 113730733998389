import { connect } from 'react-redux';
import qs from 'query-string';
import { Dispatch } from 'redux';

import { DEFAULT_USD_VALUES, MINIMAL_USD_VALUES } from 'config/workflow';
import { BASIC_CURRENCIES } from 'constants/currencies';
import setDonorInfo from 'state/donor/setDonorInfo';
import { initialState as initialDonorInfoState } from 'state/donor/state';
import setFrequency from 'state/frequency/setFrequency';
import changeCryptoAmount from 'state/pledge/changeCryptoAmount';
import changeFiatAmount from 'state/pledge/changeFiatAmount';
import fetchCurrencyExchangeRateSuccess from 'state/pledge/fetchCurrencyExchangeRate.success';
import setCalculateFromFiat from 'state/pledge/setCalculateFromFiat';
import setDropdownTouched from 'state/pledge/setDropdownTouched';
import setError from 'state/pledge/setError';
import fetchCurrencyExchangeRate from 'state/pledge/thunk/fetchCurrencyExchangeRate.thunk';
import updatePledge from 'state/pledge/updatePledge';
import { AppReduxState } from 'state/state';
import { initialState as initialBrokerInfoState } from 'state/stock/state';
import setBrokerInfo from 'state/stock/updateBrokerInfo';
import changeStep from 'state/workflow/changeStep';
import setType from 'state/workflow/setType';
import Frequency from 'types/frequency';
import { DonationPledge } from 'types/pledge';
import {
  AchDonationWorkflowStep,
  CreditCardDonationWorkflowStep,
  CryptoDonationWorkflowStep,
  DafDonationWorkflowStep,
  DonationWorkflowType,
  WorkflowStep,
} from 'types/workflow';
import componentSwitch, { getUsedDesignVersion } from 'utils/componentSwitch';
import { pushDataLayerEvent } from 'utils/gtm';
import GTMEventType from 'utils/gtm/types';
import eventDataFactory from 'utils/gtm/util';
import {
  getAvailableFlows,
  getInitialWorkflowType,
  hasMultipleFlowsEnabled,
} from 'utils/organization';
import { checkIsFiatDonationType } from 'utils/workflow';

import OverlayPledgeScreen from './overlayVariant/pledge';
import PledgeScreen from './pledge';
import { LABELS } from './pledge.keys';

interface DefaultStateParams {
  defaultAmount: number | null;
  defaultCryptocurrency: string | null;
}

const mapStateToProps = (state: AppReduxState) => {
  const {
    currency,
    amount,
    usdAmount,
    exchangeRate,
    calculateFromFiat,
    error,
    fundsDesignation,
    cryptoDropdownTouched,
    coverTransactionFees,
    areNotesEnabled,
    areTributesEnabled,
    notes,
  } = state.pledge;

  const { defaultAmount, defaultCryptocurrency } = state.donationData;

  const {
    isSubmitting,
  } = state.donation;

  const {
    organization,
  } = state.organization;

  const {
    type,
    step,
  } = state.workflow;

  const isCreditCardDonation = type === DonationWorkflowType.CreditCard;
  const isAchDonation = type === DonationWorkflowType.Ach;
  const isFiatDonation = isCreditCardDonation || isAchDonation;

  const pledge: DonationPledge = {
    currency: isFiatDonation ? BASIC_CURRENCIES.USD : currency,
    amount,
    usdAmount,
    notes,
    coverTransactionFees,
    exchangeRate: isFiatDonation ? 1 : exchangeRate,
    fundsDesignation,
    areNotesEnabled,
    areTributesEnabled,
  };

  const exchangeAvailable = isFiatDonation || Boolean(exchangeRate);
  const mimialAmount = isFiatDonation ? MINIMAL_USD_VALUES.FIAT : MINIMAL_USD_VALUES.CRYPTO;
  const isDisabledSubmit = amount < mimialAmount
    || (exchangeAvailable ? usdAmount <= 0 : false);

  const {
    currencies,
  } = state.currencies;

  const availableFlows = getAvailableFlows(organization);

  let passedError = error;
  if (isFiatDonation && (amount || 0) < MINIMAL_USD_VALUES.FIAT) {
    passedError = error || {};
    const amountError = isCreditCardDonation ? LABELS.MINIMAL_CARD_USD_VALUE_ERROR : LABELS.MINIMAL_ACH_USD_VALUE_ERROR;
    passedError.amount = amountError;
  }

  const {
    frequency,
  } = state.frequency;

  const { search } = window.location;
  const { display } = qs.parse(search);

  const { areRecurringDonationsEnabled } = organization;
  const shouldBeAbleToChangeFrequency = type === DonationWorkflowType.CreditCard && areRecurringDonationsEnabled;
  const isPreviousStepAvailable = hasMultipleFlowsEnabled(organization) || shouldBeAbleToChangeFrequency;
  const donationStartEventData = eventDataFactory(GTMEventType.DonationStart, type!, { state });

  return {
    donationStartEventData,
    pledge,
    step,
    calculateFromFiat: isFiatDonation || calculateFromFiat,
    organization: {
      ...organization,
      fee: isCreditCardDonation ? organization.fiatFee : organization.achFee,
    },
    error: passedError,
    isDisabledSubmit,
    currencies,
    cryptoDropdownTouched,
    isFiatDonation,
    isCreditCardDonation,
    type,
    isSubmitting,
    availableFlows,
    frequency,
    areRecurringDonationsEnabled,
    display,
    isPreviousStepAvailable,
    areNotesEnabled,
    areTributesEnabled,
    defaultAmount,
    defaultCryptocurrency,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  changeFiatAmount: (value: number, type: DonationWorkflowType) => {
    dispatch(changeFiatAmount.createAction(value, type));
  },
  changeCryptoAmount: (value: number) => dispatch(changeCryptoAmount.createAction(value)),
  swapCurrencies: (value: boolean) => dispatch(setCalculateFromFiat.createAction(value)),
  updatePledge: (changes: Partial<DonationPledge>) => dispatch(updatePledge.createAction(changes)),
  fetchCurrencyExchangeRate: (currency: string) => dispatch(fetchCurrencyExchangeRate(currency)),
  setDropdownTouched: (value: boolean) => dispatch(setDropdownTouched.createAction(value)),
  proceed: (step: WorkflowStep) => dispatch(changeStep.createAction(step)),
  resetFlow: (
    workflowType: DonationWorkflowType | null,
    shouldShowFrequencyStep: boolean,
  ) => dispatch(setType.createAction(workflowType, shouldShowFrequencyStep)),
  setExchangeRate: (rate: number) => dispatch(fetchCurrencyExchangeRateSuccess.createAction(rate)),
  clearErrors: () => dispatch(setError.createAction(null)),
  setType: (type: DonationWorkflowType | null, {
    defaultCryptocurrency,
    defaultAmount,
  }: DefaultStateParams) => {
    const isFiatDonation = checkIsFiatDonationType(type);
    dispatch(setType.createAction(type));
    if (isFiatDonation) {
      dispatch(fetchCurrencyExchangeRateSuccess.createAction(1));
      dispatch(updatePledge.createAction({ currency: BASIC_CURRENCIES.USD }));
      dispatch(changeFiatAmount.createAction(DEFAULT_USD_VALUES.FIAT));
    } else {
      dispatch(updatePledge.createAction({ currency: defaultCryptocurrency ?? BASIC_CURRENCIES.BTC }));
      dispatch(changeFiatAmount.createAction(defaultAmount ?? DEFAULT_USD_VALUES.CRYPTO));
    }
  },
  setFrequency: (frequency: Frequency) => {
    dispatch(setFrequency.createAction(frequency));
  },
  clearState: (workflowType: DonationWorkflowType | null, {
    defaultAmount,
    defaultCryptocurrency,
  }: DefaultStateParams) => {
    const isFiatDonation = checkIsFiatDonationType(workflowType);
    dispatch(setBrokerInfo.createAction(initialBrokerInfoState()));
    dispatch(setDonorInfo.createAction(initialDonorInfoState()));
    if (isFiatDonation) {
      dispatch(fetchCurrencyExchangeRateSuccess.createAction(1));
      dispatch(updatePledge.createAction({ currency: BASIC_CURRENCIES.USD }));
      dispatch(changeFiatAmount.createAction(DEFAULT_USD_VALUES.FIAT));
    } else {
      dispatch(updatePledge.createAction({ currency: defaultCryptocurrency ?? BASIC_CURRENCIES.BTC }));
      dispatch(changeFiatAmount.createAction(defaultAmount ?? DEFAULT_USD_VALUES.CRYPTO));
    }
  },
});

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
) => {
  const {
    isFiatDonation,
    type,
    organization,
    areRecurringDonationsEnabled,
  } = stateProps;

  const changeAmount = (value: number) => {
    if (stateProps.calculateFromFiat && type) {
      return dispatchProps.changeFiatAmount(value, type);
    }

    return dispatchProps.changeCryptoAmount(value);
  };

  const emptyCallback = () => {};
  const swapCurrencies = isFiatDonation ? emptyCallback
    : () => dispatchProps.swapCurrencies(!stateProps.calculateFromFiat);

  const fetchCurrencyExchangeRate = isFiatDonation ? emptyCallback
    : () => dispatchProps.fetchCurrencyExchangeRate(stateProps.pledge.currency);

  const proceed = () => {
    const {
      step,
      areNotesEnabled,
      areTributesEnabled,
      type,
    } = stateProps;

    const hasDefinedFlow = step !== null && type;
    if (!hasDefinedFlow) {
      return;
    }

    const shouldShowNotesStep = areNotesEnabled || areTributesEnabled;
    const fallbackNextWorkflowStep = step + 1;

    const getNextWorkflowStepPerWorkflowType = (type: DonationWorkflowType) => {
      if (type === DonationWorkflowType.CreditCard) {
        return shouldShowNotesStep
          ? CreditCardDonationWorkflowStep.Notes
          : CreditCardDonationWorkflowStep.FillDonorInfo;
      }

      if (type === DonationWorkflowType.Ach) {
        return shouldShowNotesStep
          ? AchDonationWorkflowStep.Notes
          : AchDonationWorkflowStep.FillDonorInfo;
      }

      if (type === DonationWorkflowType.Crypto) {
        return shouldShowNotesStep
          ? CryptoDonationWorkflowStep.Notes : CryptoDonationWorkflowStep.FillDonorInfo;
      }

      if (type === DonationWorkflowType.Daf) {
        return shouldShowNotesStep
          ? CreditCardDonationWorkflowStep.Notes : DafDonationWorkflowStep.FillDonorInfo;
      }

      return fallbackNextWorkflowStep;
    };

    const nextStep = getNextWorkflowStepPerWorkflowType(type);

    pushDataLayerEvent(stateProps.donationStartEventData);

    dispatchProps.proceed(nextStep);
  };
  const version = getUsedDesignVersion();
  const donationType = getInitialWorkflowType(
    organization,
    type!,
    version,
  );

  const clearState = (workflowType: DonationWorkflowType | null) => dispatchProps.clearState(
    workflowType,
    {
      defaultAmount: stateProps.defaultAmount,
      defaultCryptocurrency: stateProps.defaultCryptocurrency,
    },
  );
  const setType = (workflowType: DonationWorkflowType | null) => (
    dispatchProps.setType(workflowType, {
      defaultCryptocurrency: stateProps.defaultCryptocurrency,
      defaultAmount: stateProps.defaultAmount,
    })
  );

  const resetFlowWithDesiredDonationType = () => {
    dispatchProps.clearState(donationType, {
      defaultAmount: stateProps.defaultAmount,
      defaultCryptocurrency: stateProps.defaultCryptocurrency,
    });
    dispatchProps.resetFlow(donationType, areRecurringDonationsEnabled);
  };

  return {
    ...stateProps,
    ...dispatchProps,
    changeAmount,
    swapCurrencies,
    fetchCurrencyExchangeRate,
    proceed,
    resetFlowWithDesiredDonationType,
    clearState,
    setType,
  };
};

export const PledgeConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(componentSwitch(PledgeScreen, OverlayPledgeScreen));

export default PledgeConnect;
