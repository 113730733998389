import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

export const useStyles = makeStyles()(() => ({
  container: {
    boxSizing: 'border-box',
    fontSize: 11,
    textAlign: 'center',
    width: '100%',
    padding: '10px 34px',
    color: COLORS.TEXT.INSUBSTANTIAL_GREY,
  },
  link: {
    color: COLORS.TEXT.INSUBSTANTIAL_GREY,
    textDecoration: 'unset',
    whiteSpace: 'pre-wrap',
  },
}));

export default useStyles;
