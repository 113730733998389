import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

export const breatheAnimation = keyframes`
  0%, 20%, 80%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  30%, 70% {
    opacity: 0.7;
    transform: scale(1.2);
  }
`;

export const fadeAnimation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const useStyles = makeStyles()(theme => ({
  loadingIcon: {
    animation: `${breatheAnimation} 1.8s linear infinite`,
  },
  icon: {
    width: 150,
  },
  label: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '38px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 360,
    width: '100%',
  },
  '@keyframes breathe': {
    '0%, 20%, 80%, 100%': {
      opacity: 1,
      transform: 'scale(1)',
    },
    '30%, 70%': {
      opacity: 0.7,
      transform: 'scale(1.2)',
    },
  },
  spinner: {
    position: 'relative',
    width: 40,
    height: 40,
    '& path': {
      fill: theme.palette.secondary.main,
      animation: `${fadeAnimation} 0.8s linear infinite`,
    },
    '& path:nth-of-type(1)': {
      animationDelay: '-0.8s',
    },
    '& path:nth-of-type(2)': {
      animationDelay: '-0.7s',
    },
    '& path:nth-of-type(3)': {
      animationDelay: '-0.6s',
    },
    '& path:nth-of-type(4)': {
      animationDelay: '-0.5s',
    },
    '& path:nth-of-type(5)': {
      animationDelay: '-0.4s',
    },
    '& path:nth-of-type(6)': {
      animationDelay: '-0.3s',
    },
    '& path:nth-of-type(7)': {
      animationDelay: '-0.2s',
    },
    '& path:nth-of-type(8)': {
      animationDelay: '-0.1s',
    },
    '& path:nth-of-type(9)': {
      animationDelay: '0s',
    },
  },
}));

export default useStyles;
