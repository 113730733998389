import { AnyAction } from '../state.types';
import { DonationReduxState } from './state';

export const actionTypeName = 'WORKFLOW__SUBMIT_PLEDGE__SUCCESS';

export type ActionSubmitPledgeSuccess = AnyAction & {
  donationAddress: string;
  fallbackAddressUsed: boolean;
  destinationTag?: string;
  qrValue?: string;
  donationUuid: string;
}

export const createAction = (action: ActionSubmitPledgeSuccess) => ({
  ...action,
  type: actionTypeName,
});

export const reducer = (
  state: DonationReduxState,
  {
    donationAddress,
    fallbackAddressUsed,
    destinationTag,
    qrValue,
    donationUuid,
  }: ActionSubmitPledgeSuccess,
): DonationReduxState => ({
  ...state,
  isSubmitting: false,
  crypto: {
    ...state.crypto,
    donationAddress,
    fallbackAddressUsed,
    destinationTag,
    qrValue,
    donationUuid,
  },
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
