import React from 'react';

import { IconProps } from './icon.types';

const path = [
  'M8.12733 3.00936C8.60184 3.00982 9.05678 3.19852 9.3923 3.53405C9.72783 3.86958 9.91653',
  '4.32452 9.917 4.79902C9.917 4.95373 9.97845 5.10211 10.0878 5.2115C10.1972 5.3209 10.3456',
  '5.38236 10.5003 5.38236C10.655 5.38236 10.8034 5.3209 10.9128 5.2115C11.0222 5.10211 11.0837',
  '4.95373 11.0837 4.79902V4.75936C11.0837 4.74827 11.0837 4.73836 11.0837 4.72727C11.0642',
  '3.95595 10.7442 3.22273 10.192 2.68389C9.63976 2.14505 8.8989 1.84321 8.12733',
  '1.84269H7.58366V1.25936C7.58366 1.10465 7.5222 0.956276 7.41281 0.84688C7.30341 0.737484',
  '7.15504 0.676025 7.00033 0.676025C6.84562 0.676025 6.69724 0.737484 6.58785 0.84688C6.47845',
  '0.956276 6.41699 1.10465 6.41699 1.25936V1.84269H5.87333C5.17176 1.84344 4.49333 2.09366',
  '3.95929 2.54863C3.42525 3.0036 3.07041 3.63366 2.95821 4.32619C2.846 5.01872 2.98373 5.72859',
  '3.34679 6.32891C3.70984 6.92923 4.27456 7.38087 4.93999 7.60311L6.41699',
  '8.09602V12.3427H5.87333C5.39882 12.3422 4.94388 12.1535 4.60835 11.818C4.27283 11.4825',
  '4.08412 11.0275 4.08366 10.553C4.08366 10.3983 4.0222 10.2499 3.9128 10.1405C3.80341 10.0311',
  '3.65504 9.96969 3.50033 9.96969C3.34562 9.96969 3.19724 10.0311 3.08785 10.1405C2.97845',
  '10.2499 2.91699 10.3983 2.91699 10.553V10.5927C2.91699 10.6038 2.91699 10.6137 2.91699',
  '10.6248C2.93646 11.3961 3.25641 12.1293 3.80866 12.6682C4.3609 13.207 5.10176 13.5088',
  '5.87333 13.5094H6.41699V14.0927C6.41699 14.2474 6.47845 14.3958 6.58785 14.5052C6.69724',
  '14.6146 6.84562 14.676 7.00033 14.676C7.15504 14.676 7.30341 14.6146 7.41281 14.5052C7.5222',
  '14.3958 7.58366 14.2474 7.58366 14.0927V13.5094H8.12733C8.82889 13.5086 9.50733 13.2584',
  '10.0414 12.8034C10.5754 12.3485 10.9302 11.7184 11.0424 11.0259C11.1547 10.3333 11.0169',
  '9.62346 10.6539 9.02314C10.2908 8.42282 9.7261 7.97118 9.06066 7.74894L7.58366',
  '7.25602V3.00936H8.12733ZM8.69316 8.85494C9.09649 8.98918 9.43887 9.2626 9.659 9.62625C9.87913',
  '9.9899 9.96263 10.42 9.89456 10.8396C9.82649 11.2592 9.61128 11.6409 9.28748 11.9163C8.96367',
  '12.1917 8.55241 12.3428 8.12733 12.3427H7.58366V8.48511L8.69316 8.85494ZM6.41699',
  '6.86694L5.30866 6.49711C4.90542 6.3629 4.56311 6.08957 4.34297 5.72605C4.12284 5.36252',
  '4.03925 4.93252 4.10717 4.513C4.17509 4.09348 4.39008 3.71182 4.71368 3.43633C5.03727',
  '3.16083 5.44834 3.00948 5.87333 3.00936H6.41699V6.86694Z',
].join(' ');

export const IconDollar = ({
  width = 14,
  height = 14,
  color = 'black',
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d={path} fill={color} />
  </svg>
);

export default IconDollar;
