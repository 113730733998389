import React, { FunctionComponent } from 'react';

import DonationWorkflowType from 'types/workflow';

import EmailConfirmationDialog from './emailConfirmationDialog';
import TaxReceiptInquiryDialog from './taxReceiptInquiryDialog';
import { EmailDialogProps } from './types';

const typeComponentMap: Record<string, FunctionComponent<EmailDialogProps>> = {
  [DonationWorkflowType.Daf]: EmailConfirmationDialog,
  default: TaxReceiptInquiryDialog,
};

export interface EmailDialogWrapperProps extends EmailDialogProps {
  workflowType: DonationWorkflowType;
}

export const EmailDialogWrapper = ({
  workflowType,
  ...props
}: EmailDialogWrapperProps) => {
  const Component = typeComponentMap[workflowType] || typeComponentMap.default;

  return <Component {...props} />;
};

export default EmailDialogWrapper;
