import React, { useState } from 'react';

import Button from 'components/button';
import { ButtonDisplayVariant } from 'components/button/button';
import { locators, QALocator } from 'utils/tests/QA';

import useStyles from './style';

export interface ToggleButtonItem {
  label: string;
  value: string;
}

export interface ToggleButtonProps {
  initialItem?: string;
  items: ToggleButtonItem[];
  onChange: (item: ToggleButtonItem) => void;
}

export const ToggleButton = ({
  initialItem,
  items,
  onChange,
}: ToggleButtonProps) => {
  const { classes, cx } = useStyles();
  const [selectedValue, setSelectedValue] = useState(initialItem);

  const onClick = (item: ToggleButtonItem) => () => {
    onChange(item);
    setSelectedValue(item.value);
  };

  return (
    <div className={classes.optionsWrapper}>
      {items.map(({ label, value }) => (
        <Button
          key={value}
          display={ButtonDisplayVariant.secondary}
          className={cx(classes.option, {
            [classes.selectedOption]: value === selectedValue,
          })}
          type="button"
          {...QALocator(locators.components.fiat.frequencyButton(value))}
          onClick={onClick({ label, value })}
        >
          {label}
        </Button>
      ))}
    </div>
  );
};

export default ToggleButton;
