import { OrganizationInfo } from 'types/organization';

import { AnyAction } from '../state.types';
import { OrganizationReduxState } from './state';

export const actionTypeName = 'ORGANIZATION__FETCH_ORGANIZATION_INFO__SUCCESS';

export type ActionFetchOrganizationInfoSuccess = AnyAction & {
  organizationInfo: OrganizationInfo;
}

export const createAction = (organizationInfo: OrganizationInfo) => ({
  type: actionTypeName,
  organizationInfo,
});

export const reducer = (
  state: OrganizationReduxState,
  action: ActionFetchOrganizationInfoSuccess,
): OrganizationReduxState => ({
  ...state,
  isFetching: false,
  organization: action.organizationInfo,
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
