import React from 'react';

import KEYS from 'pages/creditCard/cardDetails/overlayVariant/creditCardDetails.keys';
import { CustomTextField } from 'pages/creditCard/cardDetails/overlayVariant/fields/customTextField';
import { FormFieldDescription } from 'pages/creditCard/cardDetails/overlayVariant/fields/fields.types';
import { MaskedTextField } from 'pages/creditCard/cardDetails/overlayVariant/fields/maskedTextField';
import Shift4Field from 'pages/creditCard/cardDetails/overlayVariant/fields/shift4FormField';
import { useStyles } from 'pages/creditCard/cardDetails/overlayVariant/fields/styles';

interface TextFieldProps {
  item: FormFieldDescription;
  isSubmitting: boolean;
  setFieldValue: (field: string, value: any) => void;
  errorText?: string;
  dataAttributes?: Record<string, string>;
}

export const TextField = ({
  item,
  isSubmitting,
  setFieldValue,
  errorText,
  dataAttributes,
}: TextFieldProps) => {
  const { classes, cx } = useStyles();

  const isShift4Field = dataAttributes?.[KEYS.SHIFT4.DATA_ATTRIBUTE];

  const componentMap = {
    [KEYS.CARD_CVV]: MaskedTextField,
    [KEYS.CARD_EXPIRATION_DATE]: MaskedTextField,
    default: CustomTextField,
  };

  const SelectedTextField = componentMap[item.name] || componentMap.default;
  const TargetField = isShift4Field ? Shift4Field : SelectedTextField;

  return (
    <div key={item.label} className={cx([item.view === 'short' ? '' : classes.longView, classes.formItem])}>
      <TargetField
        item={item}
        required={Boolean(item.isRequired)}
        name={item.name}
        isSubmitting={isSubmitting}
        disabled={Boolean(item.isDisabled)}
        className={classes.defaultInput}
        setFieldValue={setFieldValue}
        errorText={errorText}
        dataAttributes={dataAttributes}
      />
    </div>
  );
};

export default TextField;
