import { makeStyles } from 'tss-react/mui';

import { breatheAnimation } from 'components/loading/styles';
import COLORS from 'theme/colors';

export const useStyles = makeStyles()({
  pageHeader: {
    color: COLORS.PRIMARY,
    fontWeight: '600 !important',
    fontSize: '20px !important',
    textAlign: 'center',
    fontFamily: 'Poppins !important',
    marginBottom: '4px !important',
  },
  submissionLoadingContainerWrapper: {
    height: 'calc(100% - 72px)',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  loadingText: {
    marginTop: '10px !important',
  },
  imageLoadingContainer: {
    height: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingImage: {
    animation: `${breatheAnimation} 1.5s linear infinite`,
  },
  '@keyframes breathe': {
    '20%, 80%': {
      transform: 'scale(1)',
      opacity: 1,
    },
    '30%, 70%': {
      transform: 'scale(1.1)',
      opacity: 0.5,
    },
  },
  container: {
    width: 100,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80%',
    '&.MuiContainer-root': {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 6,
    },
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
    },
  },
  fieldHeader: {
    fontSize: 13,
    fontWeight: 500,
    marginBottom: 4,
    marginTop: 10,
    width: '100%',
    textAlign: 'left',
    color: '#aaa',
  },
  mainInput: {
    fontSize: '13px !important',
    '& .MuiOutlinedInput-input': {
      padding: '10.5px 15px',
      paddingLeft: '15px !important',
    },
  },
  inputWrapper: {
    marginTop: 5,
    marginBottom: 5,
    display: 'flex',
    width: 220,
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    '& .MuiInputLabel-outlined.MuiInputLabel-root': {
      fontSize: 13,
      '& .Mui-shrink': {
        transform: 'translate(14px, -5px) scale(0.75)',
      },
    },
  },
  input: {
    textAlign: 'left',
    width: '100%',
    margin: '0px !important',
  },
  select: {
    marginBottom: '0px !important',
    '& .MuiFormLabel-root': {
      lineHeight: 'unset',
    },
    '& .MuiInputBase-root': {
      height: '38px !important',
    },
    '& .MuiSelect-root': {
      fontSize: 13,
      paddingLeft: '15px !important',
    },
    '& .MuiOutlinedInput-notchedOutline legend': {
      fontSize: 13,
      transform: 'translate(-16px, -4px) scale(0.75)',
      width: '115px !important',
    },
  },
  menuItem: {
    fontSize: '13px !important',
  },
});

export default useStyles;
