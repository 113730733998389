import { createReducer } from '../createReducer';
import { buildReducerItem } from '../reducerItem';
import { Reducer } from '../state.types';
import createPaymentIntentAndSubmitPledgeFail from './createPaymentIntentAndSubmitPledge.fail';
import createPaymentIntentAndSubmitPledgeStart from './createPaymentIntentAndSubmitPledge.start';
import createPaymentIntentAndSubmitPledgeSuccess from './createPaymentIntentAndSubmitPledge.success';
import fetchCryptoDonationStatusFail from './fetchCryptoDonationStatus.fail';
import fetchCryptoDonationStatusStart from './fetchCryptoDonationStatus.start';
import fetchCryptoDonationStatusSuccess from './fetchCryptoDonationStatus.success';
import finalizeAchFail from './finalizeAch.fail';
import finalizeAchStart from './finalizeAch.start';
import finalizeAchSuccess from './finalizeAch.success';
import getCardTokenFail from './getCardToken.fail';
import getCardTokenStart from './getCardToken.start';
import getCardTokenSuccess from './getCardToken.success';
import set3DSError from './set3DSError';
import setAchError from './setAchError';
import setError from './setError';
import { DonationReduxState, initialState } from './state';
import submitAchDonationPledgeFail from './submitAchDonationPledge.fail';
import submitAchDonationPledgeStart from './submitAchDonationPledge.start';
import submitAchDonationPledgeSuccess from './submitAchDonationPledge.success';
import submitPledgeFail from './submitPledge.fail';
import submitPledgeStart from './submitPledge.start';
import submitPledgeSuccess from './submitPledge.success';
import submitStocksDonationPledgeFail from './submitStocksDonationPledge.fail';
import submitStocksDonationPledgeStart from './submitStocksDonationPledge.start';
import submitStocksDonationPledgeSuccess from './submitStocksDonationPledge.success';

const reducers: Map<string, Reducer<DonationReduxState, any>> = new Map([
  buildReducerItem(submitPledgeStart),
  buildReducerItem(submitPledgeSuccess),
  buildReducerItem(submitPledgeFail),
  buildReducerItem(submitStocksDonationPledgeStart),
  buildReducerItem(submitStocksDonationPledgeSuccess),
  buildReducerItem(submitStocksDonationPledgeFail),
  buildReducerItem(createPaymentIntentAndSubmitPledgeStart),
  buildReducerItem(createPaymentIntentAndSubmitPledgeSuccess),
  buildReducerItem(createPaymentIntentAndSubmitPledgeFail),
  buildReducerItem(getCardTokenStart),
  buildReducerItem(getCardTokenSuccess),
  buildReducerItem(getCardTokenFail),
  buildReducerItem(setError),
  buildReducerItem(set3DSError),
  buildReducerItem(fetchCryptoDonationStatusFail),
  buildReducerItem(fetchCryptoDonationStatusStart),
  buildReducerItem(fetchCryptoDonationStatusSuccess),
  buildReducerItem(submitAchDonationPledgeFail),
  buildReducerItem(submitAchDonationPledgeStart),
  buildReducerItem(submitAchDonationPledgeSuccess),
  buildReducerItem(finalizeAchFail),
  buildReducerItem(finalizeAchStart),
  buildReducerItem(finalizeAchSuccess),
  buildReducerItem(setAchError),
]);

export const dashboardReducer = createReducer(initialState, reducers);

export default dashboardReducer;
