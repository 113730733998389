import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

const appearAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const waitingAnimation = keyframes`
  20%, 80%{
    opacity: 1;
  }
  30%, 70% {
    opacity: 0.5;
  }
`;

const fadeOutAnimation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const useStyles = makeStyles()({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100% - 72px)',
  },
  progressContainer: {
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 360,
  },
  pageContainer: {
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 300,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 120,
    marginBottom: 10,
  },
  animatedRow: {
    animation: `${waitingAnimation} 1.5s linear infinite`,
  },
  header: {
    fontSize: 26,
    fontFamily: 'Poppins',
    color: COLORS.BLACK,
    fontWeight: 600,
    marginBottom: 5,
  },
  subHeader: {
    fontSize: 16,
    fontFamily: 'Noto Sans',
    color: COLORS.BLACK,
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: 23,
  },
  message: {
    fontSize: 16,
    fontFamily: 'Poppins',
    color: COLORS.BLACK,
    fontWeight: 600,
  },
  icon: {
    marginBottom: 12,
  },
  successCheck: {
    opacity: 1,
    animation: `${appearAnimation} 1s ease-in`,
  },
  hidden: {
    display: 'none',
  },
  fadeOut: {
    animation: `${fadeOutAnimation} 0.5s ease-in`,
    opacity: 0,
  },
  successMessage: {
    marginTop: -24,
  },
  invisible: {
    opacity: 0,
  },
  fadeIn: {
    animation: `${fadeInAnimation} 1s ease-in`,
    opacity: 1,
  },
  autoRedirectMessage: {
    marginTop: 8,
    paddingTop: 2,
    fontSize: 12,
    lineHeight: '16px',
    textAlign: 'center',
    maxWidth: 190,
  },
});

export default useStyles;
