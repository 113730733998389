import React, { useMemo } from 'react';
import { Button, Grid } from '@mui/material';

import ICONS from 'constants/icons';
import Currency from 'types/currency';
import { locators, QALocator } from 'utils/tests/QA';

import { BASIC_CURRENCIES, LABELS } from '../pledge.keys';
import useStyles from './styles';

interface PledgeDefaultButtonsProps {
  currency: string;
  handleChange: (currency: string) => void;
  currencies: Currency[];
}

export const PledgeDefaultButtons = ({
  currency,
  handleChange,
  currencies,
}: PledgeDefaultButtonsProps) => {
  const { classes, cx } = useStyles();

  const defaultCurrencies = useMemo(() => {
    if (currencies.length > 0) {
      return currencies.reduce<Currency[]>((acc, currency) => {
        if (BASIC_CURRENCIES[currency.code.toUpperCase()]) {
          acc.push({
            ...currency,
            code: currency.code.toLowerCase(),
          });
        }

        return acc;
      }, []);
    }

    return [
      {
        cryptoCurrency: BASIC_CURRENCIES.BTC,
        imageUrl: ICONS.btcLogo.src,
        code: BASIC_CURRENCIES.BTC,
        name: LABELS.BTC,
      },
      {
        cryptoCurrency: BASIC_CURRENCIES.ETH,
        imageUrl: ICONS.ethLogo.src,
        code: BASIC_CURRENCIES.ETH,
        name: LABELS.ETH,
      },
      {
        cryptoCurrency: BASIC_CURRENCIES.USDC,
        imageUrl: ICONS.usdcLogo.src,
        code: BASIC_CURRENCIES.USDC,
        name: LABELS.USDC,
      },
    ];
  }, [currencies]);

  const buttons = defaultCurrencies.map((cryptoInformation) => {
    const {
      code: currentCrypto,
      imageUrl: iconSrc,
    } = cryptoInformation;

    const isSelected = currency === currentCrypto;

    return (
      <Grid item key={currentCrypto} md={4} lg={4} xs={4} sm={4}>
        <Button
          className={cx({
            [classes.item]: true,
            [classes.itemIsSelected]: isSelected,
          })}
          classes={{ text: classes.itemLabel }}
          size="small"
          fullWidth
          variant="outlined"
          onClick={() => handleChange(currentCrypto)}
          {...QALocator(locators.components.crypto.optionButton(currentCrypto))}
        >
          <img
            src={iconSrc}
            alt={currentCrypto}
            className={classes.icon}
          />
          <span>
            {currentCrypto}
          </span>
        </Button>
      </Grid>
    );
  });

  return (
    <Grid container spacing={1} className={classes.container}>
      {buttons}
    </Grid>
  );
};

export default PledgeDefaultButtons;
