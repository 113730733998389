import Web3 from 'web3';
import { AbiItem, EtherUnits } from 'web3-utils';

import { CRYPTO_CURRENIES } from 'constants/currencies';
import Currency from 'types/currency';

export const KEYS = {
  METAMASK_NOT_DETECTED: 'MetaMask not detected',
  CONNECTION_ERROR: 'Connection attempt failed',
  STABLE_COINS_DECIMAL_PRECISION: 6,
};

export const WALLET_METHODS = {
  SEND_TRANSACTION: 'eth_sendTransaction',
  REQUEST_ACCOUNTS: 'eth_requestAccounts',
  ACCOUNTS: 'eth_accounts',
};

export const EVENTS = {
  ACCOUNTS_CHANGED: 'accountsChanged',
};

export const CONVERSION_METRIX: Record<string, EtherUnits> = {
  ETHER: 'ether',
  MWEI: 'mwei',
  GWEI: 'gwei',
  KWEI: 'kwei',
  WEI: 'wei',
};

export const CURRENCY_CONVERSION_MAPPER = {
  [CRYPTO_CURRENIES.ETH]: (amount: number) => [amount.toString(), CONVERSION_METRIX.ETHER],
  [CRYPTO_CURRENIES.CUBE]: (amount: number) => [(amount / 10).toString(), CONVERSION_METRIX.GWEI],
  [CRYPTO_CURRENIES.USDC]: (amount: number) => [amount.toFixed(KEYS.STABLE_COINS_DECIMAL_PRECISION), CONVERSION_METRIX.MWEI],
  [CRYPTO_CURRENIES.CVC]: (amount: number) => [(amount / 10).toString(), CONVERSION_METRIX.GWEI],
  [CRYPTO_CURRENIES.GALA]: (amount: number) => [(amount / 10).toString(), CONVERSION_METRIX.GWEI],
  [CRYPTO_CURRENIES.GRT]: (amount: number) => [(amount / 10).toString(), CONVERSION_METRIX.GWEI],
  [CRYPTO_CURRENIES.GUSD]: (amount: number) => [(amount / 10).toString(), CONVERSION_METRIX.KWEI],
  [CRYPTO_CURRENIES.QRDO]: (amount: number) => [(amount / 10).toString(), CONVERSION_METRIX.GWEI],
  [CRYPTO_CURRENIES.STORJ]: (amount: number) => [(amount / 10).toString(), CONVERSION_METRIX.GWEI],
  [CRYPTO_CURRENIES.USDT]: (amount: number) => [amount.toFixed(KEYS.STABLE_COINS_DECIMAL_PRECISION), CONVERSION_METRIX.MWEI],
  [CRYPTO_CURRENIES.SLP]: (amount: number) => [(Math.ceil(amount)).toString(), CONVERSION_METRIX.WEI],
};

const ERC_20_TOKEN_ABI: AbiItem[] = [{
  inputs: [{ internalType: 'address', name: '_owner', type: 'address' }],
  stateMutability: 'nonpayable',
  type: 'constructor',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'owner', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: 'spender', type: 'address',
  }, {
    indexed: false, internalType: 'uint256', name: 'value', type: 'uint256',
  }],
  name: 'Approval',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'previousOwner', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: 'newOwner', type: 'address',
  }],
  name: 'OwnershipTransferred',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'from', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: 'to', type: 'address',
  }, {
    indexed: false, internalType: 'uint256', name: 'value', type: 'uint256',
  }],
  name: 'Transfer',
  type: 'event',
}, {
  inputs: [],
  name: 'DOMAIN_SEPARATOR',
  outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [
    { internalType: 'address', name: 'owner', type: 'address' },
    { internalType: 'address', name: 'spender', type: 'address' },
  ],
  name: 'allowance',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [
    { internalType: 'address', name: 'spender', type: 'address' },
    { internalType: 'uint256', name: 'amount', type: 'uint256' }],
  name: 'approve',
  outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
  name: 'balanceOf',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
  name: 'burn',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [
    { internalType: 'address', name: 'account', type: 'address' },
    { internalType: 'uint256', name: 'amount', type: 'uint256' }],
  name: 'burnFrom',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [], name: 'decimals', outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [
    { internalType: 'address', name: 'spender', type: 'address' },
    { internalType: 'uint256', name: 'subtractedValue', type: 'uint256' },
  ],
  name: 'decreaseAllowance',
  outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [
    { internalType: 'address', name: 'spender', type: 'address' },
    { internalType: 'uint256', name: 'addedValue', type: 'uint256' },
  ],
  name: 'increaseAllowance',
  outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [
    { internalType: 'address', name: 'to', type: 'address' },
    { internalType: 'uint256', name: 'amount', type: 'uint256' },
  ],
  name: 'mint',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [], name: 'name', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
  name: 'nonces',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [], name: 'owner', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [
    { internalType: 'address', name: 'owner', type: 'address' },
    { internalType: 'address', name: 'spender', type: 'address' },
    { internalType: 'uint256', name: 'value', type: 'uint256' },
    { internalType: 'uint256', name: 'deadline', type: 'uint256' },
    { internalType: 'uint8', name: 'v', type: 'uint8' },
    { internalType: 'bytes32', name: 'r', type: 'bytes32' },
    { internalType: 'bytes32', name: 's', type: 'bytes32' },
  ],
  name: 'permit',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'symbol', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [],
  name: 'totalSupply',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [
    { internalType: 'address', name: 'recipient', type: 'address' },
    { internalType: 'uint256', name: 'amount', type: 'uint256' },
  ],
  name: 'transfer',
  outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [
    { internalType: 'address', name: 'sender', type: 'address' },
    { internalType: 'address', name: 'recipient', type: 'address' },
    { internalType: 'uint256', name: 'amount', type: 'uint256' },
  ],
  name: 'transferFrom',
  outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
  name: 'transferOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}];

export const requestSelectAccount = async () => {
  if (!window.ethereum) {
    return null;
  }

  try {
    await window.ethereum.request({ method: WALLET_METHODS.REQUEST_ACCOUNTS });
    const accounts: string[] = (await window.ethereum.request({ method: WALLET_METHODS.ACCOUNTS })) as string[];
    const selectedAddress = window?.ethereum?.selectedAddress;
    if (selectedAddress) {
      return selectedAddress;
    }
    if (accounts?.length) {
      return accounts[0];
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getSelectAddress = () => {
  if (!window.ethereum) {
    return null;
  }

  return window?.ethereum?.selectedAddress || null;
};

export const getEtheriumPaymentParams = (fromAddress: string, toAddress: string, amount: number) => {
  const [value] = (amount * 10 ** 18)
    .toString(16)
    .split('.');

  return {
    from: fromAddress,
    to: toAddress,
    value,
  };
};

export const getErcTokenPaymentParams = (fromAddress: string, toAddress: string, amount: number, currency: Currency) => {
  const web3 = new Web3();
  const contractAddress = currency?.contractAddress;
  const currencyCode = currency?.code.toLowerCase();
  const mapper = CURRENCY_CONVERSION_MAPPER[currencyCode] || CURRENCY_CONVERSION_MAPPER[CRYPTO_CURRENIES.ETH];
  const conversionParams = mapper(amount);
  const [convertedAmount, metric] = conversionParams;
  const tokenValue = web3.utils.toWei(convertedAmount, metric as EtherUnits);
  const transferFunction = new web3.eth.Contract(ERC_20_TOKEN_ABI, contractAddress as string).methods.transfer;
  const encodedTransferFunctionCall = transferFunction(toAddress, tokenValue).encodeABI();

  return {
    from: fromAddress,
    to: contractAddress,
    data: encodedTransferFunctionCall,
    value: 0,
  };
};

export const initiatePayment = async (fromAddress: string, toAddress: string, amount: number, currency: Currency) => {
  if (!window.ethereum) {
    return {
      error: {
        message: KEYS.METAMASK_NOT_DETECTED,
      },
    };
  }

  try {
    const currencyIsEth = currency?.code.toLowerCase() === CRYPTO_CURRENIES.ETH;
    const currencyHasContractAddress = Boolean(currency?.contractAddress);
    const shouldMakeETHTransfer = currencyIsEth || !currencyHasContractAddress;
    const paymentParams = shouldMakeETHTransfer
      ? getEtheriumPaymentParams(fromAddress, toAddress, amount)
      : getErcTokenPaymentParams(fromAddress, toAddress, amount, currency);
    const txHash = await window.ethereum
      .request({
        method: WALLET_METHODS.SEND_TRANSACTION,
        params: [
          paymentParams,
        ],
      });
    return {
      txHash,
    };
  } catch (error: any) {
    return {
      error: {
        code: error.code,
        message: KEYS.CONNECTION_ERROR,
      },
    };
  }
};

export const subscribe = (callback: (accounts: unknown) => void) => {
  if (!window.ethereum) {
    return;
  }

  window.ethereum.on(EVENTS.ACCOUNTS_CHANGED, callback);
};

export const unsubscribe = (callback: (accounts: unknown) => void) => {
  if (!window.ethereum) {
    return;
  }

  window.ethereum.removeListener(EVENTS.ACCOUNTS_CHANGED, callback);
};

export const getIsCurrencySuppored = (currenyCode, isERC) => {
  const nonERCMetamaskSupportedTokens = [CRYPTO_CURRENIES.ETH];
  return isERC || nonERCMetamaskSupportedTokens.includes(currenyCode);
};

export default requestSelectAccount;
