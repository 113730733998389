import { createReducer } from '../createReducer';
import { buildReducerItem } from '../reducerItem';
import { Reducer } from '../state.types';
import changeStep from './changeStep';
import payFail from './pay.fail';
import payStart from './pay.start';
import paySuccess from './pay.success';
import setError from './setError';
import setType from './setType';
import { initialState, WorkflowReduxState } from './state';

// Reducers
const reducers: Map<string, Reducer<WorkflowReduxState, any>> = new Map([
  buildReducerItem(changeStep),
  buildReducerItem(setError),
  buildReducerItem(setType),
  buildReducerItem(payStart),
  buildReducerItem(payFail),
  buildReducerItem(paySuccess),
]);

export const dashboardReducer = createReducer(initialState, reducers);

export default dashboardReducer;
