import { AnyAction } from '../state.types';
import { PledgeReduxState } from './state';

export const actionTypeName = 'PLEDGE__SET_ERROR';

export type ActionSetCalculateFromFiat = AnyAction & {
  error: Record<string, string> | null;
}

export const createAction = (error: Record<string, string> | null) => ({
  type: actionTypeName,
  error,
});

export const reducer = (
  state: PledgeReduxState,
  action: ActionSetCalculateFromFiat,
): PledgeReduxState => {
  const { error } = action;

  return {
    ...state,
    error,
  };
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
