import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
  input: {
    '& .MuiInputBase-root': {
      '& .MuiInputBase-input': {
        padding: 0,
        fontSize: 13,
        lineHeight: '16px',
        fontWeight: 500,
      },
    },
  },
  combobox: {
  },
  dropdown: {
  },
  removeItemButton: {
  },
});

export default useStyles;
