import { AnyAction, Reducer } from '../state.types';
import { DonationReduxState } from './state';

export const actionTypeName = 'DONATION__SUBMIT_ACH_DONATION_PLEDGE__START';

export type ActionSubmitAchDonationPledgeStart = AnyAction;

export const createAction = (): ActionSubmitAchDonationPledgeStart => ({
  type: actionTypeName,
});

export const reducer: Reducer<DonationReduxState, ActionSubmitAchDonationPledgeStart> = (
  state: DonationReduxState,
): DonationReduxState => {
  const nextState = {
    ...state,
    isSubmitting: true,
  };

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
