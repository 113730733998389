import { FiatCurrency } from 'types/currency';

export const KEYS = {
  CURRENCY_FIELD: 'currency',
  FUNDS_DESIGNATION: 'fundsDesignation',
  COVER_TRANSACTION_FEES: 'coverTransactionFees',
  FIRST_ROW_PLEDGE_AMOUNTS: [45, 100, 200],
  SECOND_ROW_PLEDGE_AMOUNTS: [500, 2500, 5000],
  USD_DECIMAL_COUNT: 2,
  DEFAULT_CRYPTO_PRICE: 0,
  DEFAULT_DESIGNATION_VALUE: 'default',
  NOTES_FIELD: 'notes',
  ARE_NOTES_ENABLED: 'areNotesEnabled',
  ARE_TRIBUTES_ENABLED: 'areTributesEnabled',
};

export const LABELS = {
  PAGE_TITLE: 'Make a Donation',
  APPROXIMATE_FIAT_VALUE_PREFIX: '≈',
  INCLUDING_FEES_PREFIX: 'incl. fees',
  TRANSACTION_FEES_TOOLTIP: 'Make your impact go even further by covering the processing fees of this donation',
  COVER_TRANSACTION_FEES: 'Cover transaction fees',
  CONTINUE_LABEL: 'Continue',
  BACK_BUTTON_LABEL: 'Previous',
  DONATE_LABEL: 'Donate',
  ENTER_AMOUNT_TITLE: 'Enter Donation Amount',
  SELECT_CRYPTO_TITLE: 'Select Your Crypto',
  FUNDS_DESIGNATIONS_TITLE: 'Select Impact Area',
  DONATION_NUMBER_VALIDATION: 'Donation amount cannot be 0, please enter another amount to continue',
  BTC: 'Bitcoin',
  ETH: 'Etherium',
  USDC: 'USDC',
  DEFAULT_DESIGNATION_VALUE: 'Default',
  PROCEED_BUTTON_TEXT: 'Donate',
  CRYPTO_INPUT_PLACEHOLDER: 'Amount',
  USD_INPUT_PLACEHOLDER: '$ Value',
  NOTES_CHECKBOX_LABEL: 'Add a note to your donation',
  TRIBUTE_CHECKBOX_LABEL: 'Dedicate this donation to a friend or loved one',
  USD_VALUE_PREFIX: '$',
};

export const getCurrencyFieldPlaceholder = (currency: FiatCurrency | null) => {
  if (currency === FiatCurrency.GBP) {
    return '£ Value';
  }

  return '$ Value';
};

export const BASIC_CURRENCIES = {
  BTC: 'btc',
  ETH: 'eth',
  USD: 'USD',
  USDC: 'usdc',
};
