import { FormFieldDescription } from './fields/field.types';

export const LABELS = {
  BROKER_INFO_PAGE_TITLE: 'Broker Info',
  BROKER_FIRM_LABEL: 'Delivering Broker Firm',
  BROKER_FIRM_PLACEHOLDER: 'Select Broker',
  BROKER_ACCOUNT_NUMBER_LABEL: 'Brokerage Account Number',
  BROKER_ACCOUNT_NUMBER_PLACEHOLDER: 'Number',
  BROKER_CONTACT_NAME_LABEL: 'Broker Contact Name',
  BROKER_CONTACT_NAME_PLACEHOLDER: 'Name',
  BROKER_EMAIL_LABEL: 'Broker Email',
  BROKER_EMAIL_PLACEHOLDER: 'Email',
  BROKER_PHONE_NUMBER_LABEL: 'Broker Phone',
  BROKER_PHONE_NUMBER_PLACEHOLDER: 'Phone Number',
  CUSTOM_BROKER_NAME: 'Brokerage Firm',
  BACK_BUTTON_LABEL: 'PREVIOUS',
  CONTINUE_BUTTON_LABEL: 'CONTINUE',
  PROCESSING: 'Processing...',
  REQURED: 'Required',
  INVALID_EMAIL: 'Invalid email',
};

export const KEYS = {
  BROKER_NAME: 'brokerName',
  BROKERAGE_ACCOUNT_NUMBER: 'brokerageAccountNumber',
  BROKER_CONTACT_NAME: 'brokerContactName',
  BROKER_EMAIL: 'brokerEmail',
  BROKER_PHONE: 'brokerPhone',
  NUMBER_TYPE: 'number',
  EMAIL_TYPE: 'email',
  TEXT_TYPE: 'text',
  CUSTOM_BROKER_NAME: 'customBrokerName',
  OTHER_BROKER_NAME_VALUE: 'other',
  REQUIRED_ASTERISK: ' *',
};

export const getBrokerInfoFormFields = (isBrokerAdditionalInfoRequired: boolean): FormFieldDescription[] => [
  {
    label: LABELS.BROKER_FIRM_LABEL,
    placeholder: LABELS.BROKER_FIRM_PLACEHOLDER,
    name: KEYS.BROKER_NAME,
    isRequired: true,
    type: KEYS.TEXT_TYPE,
  },
  {
    label: LABELS.CUSTOM_BROKER_NAME,
    placeholder: LABELS.CUSTOM_BROKER_NAME,
    name: KEYS.CUSTOM_BROKER_NAME,
    isRequired: true,
    type: KEYS.TEXT_TYPE,
    maxLength: 255,
  },
  {
    label: LABELS.BROKER_ACCOUNT_NUMBER_LABEL,
    placeholder: LABELS.BROKER_ACCOUNT_NUMBER_PLACEHOLDER,
    name: KEYS.BROKERAGE_ACCOUNT_NUMBER,
    isRequired: true,
    type: KEYS.TEXT_TYPE,
  },
  {
    label: LABELS.BROKER_CONTACT_NAME_LABEL,
    placeholder: LABELS.BROKER_CONTACT_NAME_PLACEHOLDER,
    name: KEYS.BROKER_CONTACT_NAME,
    isRequired: isBrokerAdditionalInfoRequired,
    type: KEYS.TEXT_TYPE,
  },
  {
    label: LABELS.BROKER_EMAIL_LABEL,
    placeholder: LABELS.BROKER_EMAIL_PLACEHOLDER,
    name: KEYS.BROKER_EMAIL,
    isRequired: isBrokerAdditionalInfoRequired,
    type: KEYS.EMAIL_TYPE,
  },
  {
    label: LABELS.BROKER_PHONE_NUMBER_LABEL,
    placeholder: LABELS.BROKER_PHONE_NUMBER_PLACEHOLDER,
    name: KEYS.BROKER_PHONE,
    isRequired: isBrokerAdditionalInfoRequired,
    type: KEYS.NUMBER_TYPE,
  },
];

export default LABELS;
