import React from 'react';

import { IconProps } from './icon.types';

const path = [
  [
    'M8.96116 7.35209L6.69631 4.99952L1.249 10.5356L0.5 9.79921L6.70419 3.49365L8.9911',
    '5.8688L12.9252 2.10175L11.798 0.975098H14.741V3.91699L13.6679 2.84444L8.96116',
    '7.35209ZM3.10626 15.0254H6.65166V10.0356H3.10626V15.0254ZM8.03043 15.0254H11.5758V8.45983H8.03043V15.0254ZM12.9546',
    '5.17705V15.0254H16.5V5.17705H12.9546Z',
  ].join(' '),
];

export const IconStocks = ({
  width = 17,
  height = 16,
  color = 'black',
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {path.map(item => <path key={item} d={item} fill={color} />)}
  </svg>
);

export default IconStocks;
