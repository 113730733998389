import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  container: {
    width: 100,
  },
  dialogPaper: {
    width: 260,
    background: 'non',
  },
  modalContainer: {
    overflow: 'hidden',
    bottomLeftBorderRadius: 8,
    bottomRightBorderRadius: 8,
  },
  formHelperText: {
    marginLeft: 74,
  },
  iconExchangeWrapper: {
    width: '16px',
    height: '16px',
    background: '#FFFFFF',
    border: '1px solid #DADFE5',
    boxSizing: 'border-box',
    borderRadius: '50%',
    display: 'flex',
    color: '#86909F',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    zIndex: '999',
  },
  fieldHeader: {
    fontSize: 13,
    fontWeight: 500,
    marginBottom: 4,
    marginTop: 0,
    width: '100%',
    textAlign: 'left',
    color: '#aaa',
  },
  swapBox: {
    padding: '0 8px',
    height: '100%',
  },
  activeCurrency: {
    fontSize: 12,
    color: '#86909F',
    display: 'flex',
  },
  calculatedCurrency: {
    fontSize: 12,
    color: '#86909F',
  },
  inputWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },

  input: {
    textAlign: 'left',
    maxWidth: 152,
    display: 'inline-block',
    paddingLeft: 8,
  },
  absolute: {
    position: 'absolute',
  },
  mainCurrencyInput: {
    textAlign: 'left',
    fontSize: '22px',
    padding: '0!important',
    display: 'inline',
  },
  subPrice: {
    color: '#86909F',
    paddingRight: 0,
    fontSize: 14,
    maxWidth: 152,
    marginRight: '8px',
    overflow: 'hidden',
    textAlign: 'left',
    width: '100%',
    paddingLeft: 8,
  },
  currency: {
    position: 'absolute',
    right: -30,
    top: -5,
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '15px',
    fontFamily: 'Roboto',
    lineHeight: '48px',
    textTransform: 'uppercase',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    color: '#000',
    display: 'inline',
    '> .MuiTypographyColorTextSecondary': {
      color: '#000',
    },
  },
  iconExchange: {
    transform: 'rotate(90deg)',
    fontSize: 8,
  },
  amountBox: {
    border: '1px solid rgba(0, 0, 0, 0.27)',
    borderRadius: 4,
    width: '100%',
    boxSizing: 'border-box',
    padding: 8,
    '&.error': {
      border: '1px solid #F02B2B',
    },
  },
  tooltip: {
    backgroundColor: '#333!important',
    border: '0!important',
    fontSize: '13px!important',
    fontFamily: 'Roboto!important',
    fontWeight: '500!important',
    lineHeight: '16px!important',
    marginTop: '6px!important',
    marginLeft: '15px!important',
  },
  tooltipArrow: {
    left: '5px!important',
    '&::before': {
      backgroundColor: 'transparent!important',
      borderLeft: '8px solid #333',
      borderBottom: '8px solid #333',
      borderTop: '8px solid transparent',
      borderRight: '8px solid transparent',
      transform: 'none!important',
      width: '0px!important',
      height: '0px!important',
    },
  },
  checkboxLabel: {
    fontSize: '14px',
    marginTop: 4,
    marginLeft: -4,
  },
  checkboxLabelTooltip: {
    color: '#888',
  },
  checkboxContainer: {
    marginLeft: -14,
    marginBottom: -10,
    marginTop: -4,

    '& .MuiSvgIcon-root path': {
      fill: '#291B4F',
    },
  },
  iconEyeTooltip: {
    marginLeft: 5,
    cursor: 'pointer',
    // TODO: Add color to palette and use it on all places
    color: '#86909F',
  },
  donationTooltip: {
    maxWidth: 200,
    textAlign: 'center',
  },
});

export default useStyles;
