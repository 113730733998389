export const insertStringIntoSpecificIndex = (initialValue: string, insertedString: string, index: number) => {
  const hasAlreadyInserted = initialValue.indexOf(insertedString) > -1;
  if (initialValue.length <= index || hasAlreadyInserted) {
    return initialValue;
  }

  return `${initialValue.slice(0, index)}${insertedString}${initialValue.slice(index)}`;
};

export default insertStringIntoSpecificIndex;
