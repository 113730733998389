import { BrokerInfo } from 'types/broker';

export const KEYS = {
  MORGAN_STANLEY_BROKER_NAME: 'morganstanley',
  OTHER_BROKER_NAME: 'other',
  EMAIL_REGEX: /\S+@\S+\.\S+/,
};

export const brokerNameIsOther = (brokerInfo: BrokerInfo) => (
  brokerInfo.brokerName === KEYS.OTHER_BROKER_NAME
);

export const brokerIsMorganStanley = (brokerInfo: BrokerInfo) => (
  brokerInfo.brokerName === KEYS.MORGAN_STANLEY_BROKER_NAME
);

export const brokerInformationIsValid = (brokerInfo: BrokerInfo) => {
  const shouldValidateBrokerCustomName = brokerNameIsOther(brokerInfo);
  const shouldValidateAdditionalBrokerInfoFields = brokerIsMorganStanley(brokerInfo);

  const brokerCustomNameIsValid = shouldValidateBrokerCustomName ? Boolean(brokerInfo.customBrokerName) : true;

  const nameIsValid = Boolean(brokerInfo.brokerName);
  const brokerageAccountNumberIsValid = Boolean(brokerInfo.brokerageAccountNumber);
  const brokerEmailIsValid = shouldValidateAdditionalBrokerInfoFields
    ? Boolean(brokerInfo.brokerEmail) && KEYS.EMAIL_REGEX.test(brokerInfo.brokerEmail)
    : true;
  const brokerContactNameIsValid = shouldValidateAdditionalBrokerInfoFields ? Boolean(brokerInfo.brokerContactName) : true;
  const brokerPhoneIsValid = shouldValidateAdditionalBrokerInfoFields ? Boolean(brokerInfo.brokerPhone) : true;

  const areOtherFieldsValid = brokerEmailIsValid && brokerContactNameIsValid && brokerPhoneIsValid;

  return brokerCustomNameIsValid && nameIsValid && brokerageAccountNumberIsValid && areOtherFieldsValid;
};
