import React, { ChangeEvent } from 'react';
import { Box, TextField } from '@mui/material';

import Checkbox from 'components/checkbox';
import OptionallyVisible from 'components/optionallyVisible';
import { DonationPledge } from 'types/pledge';

import { KEYS, LABELS, PLEDGE_KEYS } from './notes.keys';
import useStyles from './notes.style';

export interface TributeNoteProps {
  honoreeName: string | null;
  honoreeEmail: string | null;
  isEmailValid: boolean;
  notifyHonoree: boolean;
  notes: string;
  onChange: (fieldName: keyof DonationPledge) => (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onBooleanChange: (fieldName: keyof DonationPledge) => (checked: boolean)=> void;
}

export const TributeNote = ({
  honoreeName,
  honoreeEmail,
  isEmailValid,
  notifyHonoree,
  notes,
  onChange,
  onBooleanChange,
}: TributeNoteProps) => {
  const { classes } = useStyles();
  const isEmailPresent = Boolean(honoreeEmail?.trim().length);
  const hasError = !isEmailValid;
  const errorMessage = (isEmailPresent && !isEmailValid) ? LABELS.EMAIL_INVALID : LABELS.EMAIL_EMPTY;

  return (
    <div className={classes.form}>
      <h6 className={classes.inputTitle}>
        {LABELS.TRIBUTE_NOTE.INPUT_TITLE}
      </h6>
      <TextField
        value={notes}
        onChange={onChange(PLEDGE_KEYS.NOTES)}
        placeholder={LABELS.TRIBUTE_NOTE.INPUT_PLACEHOLDER}
        type="text"
        name={LABELS.TRIBUTE_NOTE.INPUT_NAME}
        variant="outlined"
        className={classes.textInput}
        multiline
        maxRows={KEYS.TRIBUTE_NOTE.MAX_INPUT_ROWS}
        minRows={KEYS.TRIBUTE_NOTE.INPUT_ROWS}
        inputProps={{
          maxLength: KEYS.MAX_NOTE_LENGTH,
        }}
        required
      />
      <TextField
        value={honoreeName}
        onChange={onChange(PLEDGE_KEYS.HONOREE_NAME)}
        placeholder={LABELS.TRIBUTE_NOTE.NAME_INPUT_LABEL}
        type="text"
        name={LABELS.TRIBUTE_NOTE.NAME_INPUT_LABEL}
        label={LABELS.TRIBUTE_NOTE.NAME_INPUT_LABEL}
        variant="outlined"
        className={classes.textInput}
        required
      />
      <Box className={classes.checkboxContainer}>
        <Checkbox
          onChange={onBooleanChange(PLEDGE_KEYS.NOTIFY_HONOREE)}
          value={notifyHonoree}
          name={LABELS.TRIBUTE_NOTE.NOTIFY_HONOREE}
          label={(
            <div
              className={classes.checkboxLabel}
            >
              {LABELS.TRIBUTE_NOTE.NOTIFY_HONOREE_LABEL}
            </div>
            )}
        />
      </Box>
      <OptionallyVisible visible={notifyHonoree}>
        <>
          <TextField
            value={honoreeEmail}
            onChange={onChange(PLEDGE_KEYS.HONOREE_EMAIL)}
            placeholder={LABELS.TRIBUTE_NOTE.EMAIL_INPUT_PLACEHOLDER}
            type="text"
            name={LABELS.TRIBUTE_NOTE.EMAIL_INPUT_PLACEHOLDER}
            variant="outlined"
            className={classes.textInput}
            error={hasError}
          />
          <OptionallyVisible visible={hasError}>
            <div className={classes.error}>
              {errorMessage}
            </div>
          </OptionallyVisible>
        </>
      </OptionallyVisible>
    </div>
  );
};

export default TributeNote;
