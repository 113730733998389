import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

const borderMixin = {
  border: `2px solid ${COLORS.SECONDARY} !important`,
  margin: -1,
  marginTop: 4,
};

export const SHIFT4_COMPONENTS_STYLES = {
  style: {
    base: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: '16px',
      fontFamily: 'Noto Sans',
      '::placeholder': {
        color: '#a6a6a6',
      },
    },
    invalid: {
      color: '#F44336',
      '::placeholder': {
        color: '#a6a6a6',
      },
    },
  },
  fonts: [{
    family: 'Noto Sans',
    style: 'normal',
    weight: 400,
    display: 'swap',
    src: "url(https://fonts.gstatic.com/s/notosans/v27/o-0IIpQlx3QUlC5A4PNr5TRASf6M7Q.woff2) format('woff2');",
  }],
};

export const useStyles = makeStyles()({
  '@global': {
    '.Shift4Component--focus': borderMixin,
    '.Shift4Component--invalid': borderMixin,
    '.Shift4Component--invalid .inputAdornmentError': {
      display: 'block !important',
    },
  },
  formContainer: {
    overflowY: 'auto',
    padding: '30px 30px 45px 30px',
  },
  container: {
    display: 'grid',
    justifyContent: 'center',
    columnGap: 20,
    gridTemplateColumns: 'repeat(2, minmax(0,1fr))',
    gap: '0px 4px',
    '&$anonDisabled': {
      paddingTop: 16,
    },
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  creditCard: {
    padding: 5,
  },
  longView: {
    gridColumn: '1 / span 2',
  },
  anonDisabled: {},
  shortInput: {
    width: '50%',
    '&:first-of-type': {
      width: 'calc(50% - 5px)',
      marginRight: 5,
    },
  },
  defaultInput: {
    fontSize: 16,
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 9px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& fieldset': {
      borderColor: '#DADFE5',
      borderRadius: '3px',
    },
  },
  sectionHeader: {
    textAlign: 'left',
    marginTop: '10px',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#2D2E31',
  },
  donationAmount: {
    fontSize: '26px !important',
    textAlign: 'center',
    fontWeight: '700 !important',
    marginBottom: '-10px !important',
  },
  nextButton: {
    flex: 1,
    height: 50,
    fontSize: 16,
    borderRadius: '5px !important',
    color: '#291B4F !important',
    lineHeight: '24px',
    textTransform: 'none !important' as 'none',
    background: '#FCD42B !important',
    boxShadow: 'none !important',
    '&:disabled': {
      background: '#F0F4F8 !important',
      color: '#928E9C !important',
    },
  },
  buttonLoading: {
    background: '#F0F4F8 !important',
    color: '#928E9C !important',
  },
  thirdPartyPaymentMethodsContainer: {
    display: 'grid',
    gridColumn: '1 / span 2',
    gridTemplateColumns: '1fr',
    gap: 10,
  },
  dividerContainer: {
    gridColumn: '1 / span 2',
    marginTop: '13px',
    marginBottom: '8px',
  },
  divider: {
    background: '#D5DDE3',
    width: '100%',
    height: '1px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  dividerText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '12px',
    color: '#291B4F',
    padding: '0 17px',
  },
});

export default useStyles;
