import { Dispatch } from 'redux';

import { API, Endpoints } from 'api';
import changeStep from 'state/workflow/changeStep';
import setError from 'state/workflow/setError';
import { DafDonationWorkflowStep } from 'types/workflow';

import fetchConnectionIdFail from '../fetchConnectionId.fail';
import fetchConnectionIdStart from '../fetchConnectionId.start';
import fetchConnectionIdSuccess from '../fetchConnectionId.success';

export const DEFAULT_ERROR_CODE = 'Something went wrong.';

export const fetchConnectionId = (organizationId: number) => async (dispatch: Dispatch<any>) => {
  dispatch(fetchConnectionIdStart.createAction());

  try {
    const response = await API.get(Endpoints.chariot.byOrganizationId(organizationId));

    if (!response?.data?.connectId) {
      throw DEFAULT_ERROR_CODE;
    }

    dispatch(fetchConnectionIdSuccess.createAction(response.data.connectId));
  } catch (e: any) {
    const errorMessage = e.message || DEFAULT_ERROR_CODE;
    dispatch(fetchConnectionIdFail.createAction({ errorMessage }));
    dispatch(changeStep.createAction(DafDonationWorkflowStep.Error));
    dispatch(setError.createAction(errorMessage));
  }
};

export default fetchConnectionId;
