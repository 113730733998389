import React, { FunctionComponent, useMemo } from 'react';

import { LayoutConnect } from 'layout/layout.connect';
import { StartConnect } from 'pages/common/start/start.connect';
import type { WorkflowStep } from 'types/workflow';
import { DonationWorkflowType } from 'types/workflow';

import { AchRouter } from './router.ach';
import { CreditCardRouter } from './router.creditCard';
import { CryptoRouter } from './router.crypto';
import { DafRouter } from './router.daf';
import { GenericRouter } from './router.generic';
import { StocksRouter } from './router.stocks';

interface RouterProps {
  type: DonationWorkflowType;
  step: WorkflowStep;
}

export const Router = ({ type, step }: RouterProps) => {
  const Component = useMemo(() => {
    const componentMap: Record<DonationWorkflowType, FunctionComponent<{ step }>> = {
      [DonationWorkflowType.Crypto]: CryptoRouter,
      [DonationWorkflowType.CreditCard]: CreditCardRouter,
      [DonationWorkflowType.Stock]: StocksRouter,
      [DonationWorkflowType.Daf]: DafRouter,
      [DonationWorkflowType.Ach]: AchRouter,
      [DonationWorkflowType.Generic]: GenericRouter,
    };
    if (type === null) {
      return StartConnect;
    }

    return componentMap[type];
  }, [type]);

  return (
    <LayoutConnect>
      <Component step={step} />
    </LayoutConnect>
  );
};

export default Router;
