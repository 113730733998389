import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';
import FONT_FAMILIES from 'theme/fontFamilies';

export const useStyles = makeStyles()(theme => ({
  input: {
    '& .MuiInputBase-root': {
      fontSize: 16,
      boxShadow: `0px 3px 12px ${COLORS.INPUT.SHADOW}`,
      borderRadius: theme.shape.borderRadius,
      background: COLORS.WHITE,
      '&.Mui-disabled': {
        background: COLORS.INPUT.DISABLED.BACKGROUND,
      },
      '&.MuiInputBase-multiline': {
        padding: 0,
        fontFamily: FONT_FAMILIES.INTER,
        color: COLORS.INPUT.TEXT,
      },
      '& .MuiInputBase-inputMultiline': {
        overflowY: 'auto',
      },
    },
    '& .MuiInputBase-input': {
      fontSize: [16, '!important'],
      lineHeight: '23px',
      minHeight: 23,
      color: COLORS.PRIMARY,
      fontFamily: FONT_FAMILIES.INTER,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: '13px 20px 14px',
      '&.MuiInputBase-inputAdornedStart': {
        paddingLeft: '10px',
      },
      '&.Mui-disabled': {
        background: COLORS.INPUT.DISABLED.BACKGROUND,
        color: `${COLORS.GREY} !important`,
      },
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 20,
    },
    [`
      & .MuiOutlinedInput-notchedOutline,
      & .Mui-disabled .MuiOutlinedInput-notchedOutline,
    `]: {
      borderColor: `${COLORS.INPUT.BORDER} !important`,
    },
    '& .MuiOutlinedInput-notchedOutline > legend': {
      fontFamily: FONT_FAMILIES.INTER,
      fontSize: 12,
    },
    [`& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
    & .MuiOutlinedInput-root:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline,
    & .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline`]: {
      border: `2px solid ${COLORS.SECONDARY}`,
      borderColor: `${COLORS.SECONDARY} !important`,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 9px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
      fontSize: 16,
      lineHeight: '17px',
      marginTop: 0,
    },
    '& .MuiInputLabel-root': {
      fontFamily: FONT_FAMILIES.INTER,
      transform: 'translate(20px, 13px) scale(1)',
      color: COLORS.TEXT.SECONDARY,
      fontSize: 16,
      lineHeight: '23px',
      '&.Mui-focused': {
        color: COLORS.TEXT.SECONDARY,
      },
    },
    '& fieldset': {
      borderRadius: theme.shape.borderRadius,
    },
    '& .MuiOutlinedInput-inputMarginDense': {
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: 14,
    },
    '& .MuiFormControl-marginDense': {
      marginTop: 1,
      marginBottom: 1,
    },
  },
  formItem: {
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 9px) scale(1)',
      fontSize: 14,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& fieldset': {
      borderColor: COLORS.INPUT.BORDER,
      borderRadius: '3px',
    },
    '& .MuiOutlinedInput-inputMarginDense': {
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: 14,
    },
    '& .MuiFormControl-marginDense': {
      marginTop: 5,
      marginBottom: 1,
    },
    '& .MuiFormHelperText-root': {
    },
  },
  helperTextRoot: {
    fontFamily: FONT_FAMILIES.POPPINS,
    color: COLORS.TEXT.PRIMARY,
    fontWeight: 500,
    margin: 0,
    marginTop: 5,
    fontSize: 12,
    lineHeight: '18px',
  },
  helperTextError: {
    color: `${COLORS.PRIMARY} !important`,
  },
  startAdornment: {
    fontSize: 16,
    lineHeight: '24px',
    color: COLORS.TEXT.SECONDARY,
  },
  endAdornment: {
    fontSize: 16,
    lineHeight: '24px',
    color: COLORS.TEXT.SECONDARY,
  },
}));

export default useStyles;
