import { AnyAction } from '../state.types';
import { DonorReduxState, initialState } from './state';

export const actionTypeName = 'DONOR__UPDATE_DONOR_INFO_VALUE';
export const ANONYMOUS_KEY = 'isAnonymous';

export type ActionUpdateDonorInfoValue = AnyAction & {
  field: string;
  value: string | boolean;
}

export const createAction = (field: string, value: string | boolean) => ({
  type: actionTypeName,
  field,
  value,
});

export const reducer = (
  state: DonorReduxState,
  action: ActionUpdateDonorInfoValue,
): DonorReduxState => {
  const { field, value } = action;
  if (!field) {
    return state;
  }

  const shouldResetFields = field === ANONYMOUS_KEY && value === true;
  if (shouldResetFields) {
    const nextState = initialState();

    return {
      ...nextState,
      [field]: value,
    };
  }

  return {
    ...state,
    [field]: value,
  };
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
