import type { DonationFormAppConfig } from 'types/config';

import { Storage } from '../storage';

// @ts-ignore
export const generalConfig: DonationFormAppConfig = window?.donationFormConfig || {};
const STORAGE_CONFIG_KEY = 'donationFormConfig';
let localConfig;
if (generalConfig.enableLocalConfig) {
  localConfig = Storage.get(STORAGE_CONFIG_KEY);
  if (!localConfig) {
    Storage.set(STORAGE_CONFIG_KEY, generalConfig);
    localConfig = generalConfig;
  }

  localConfig.save = () => {
    Storage.set(STORAGE_CONFIG_KEY, localConfig);
  };

  localConfig.clear = () => {
    Storage.remove(STORAGE_CONFIG_KEY);
  };

  // @ts-ignore
  window.donationFormConfig = localConfig;
}

export const exportedLocalConfig = localConfig;
export const appConfig = localConfig || generalConfig;
export default exportedLocalConfig;
