import { AnyAction } from '../state.types';
import { StockReduxState } from './state';

export const actionTypeName = 'STOCK__UPDATE_BROKER_INFO';

export type ActionUpdateBrokerInfo = AnyAction & {
  brokerInfo: Partial<StockReduxState>;
}

export const createAction = (brokerInfo: Partial<StockReduxState>) => ({
  type: actionTypeName,
  brokerInfo,
});

export const reducer = (
  state: StockReduxState,
  action: ActionUpdateBrokerInfo,
): StockReduxState => ({
  ...state,
  error: {},
  ...action.brokerInfo,
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
