import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';
import FONT_FAMILIES from 'theme/fontFamilies';

export const useStyles = makeStyles()({
  scrollContainer: {
    padding: '30px 30px 45px 30px',
  },
  formContainer: {
    overflowY: 'auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0,1fr))',
    columnGap: 20,
    gap: '0px 4px',
    '&$anonDisabled': {
      paddingTop: 16,
    },
  },
  longView: {
    gridColumn: '1 / span 2',
  },
  anonDisabled: {},
  subscriptionText: {
    display: 'inline-block',
    color: '#2D2E31',
    lineHeight: '12px',
    fontSize: 12,
    cursor: 'pointer',
  },
  checkboxContainerAnonim: {
    opacity: 0.3,
    pointerEvents: 'none',
    cursor: 'default',
  },
  checkboxContainer: {
    '& .MuiCheckbox-root': {
      color: '#DADFE5',
      borderRadius: '3px',
      padding: 0,
      marginRight: 8,
    },
    borderBottom: '1px solid rgba(0,0,0,.2)',
    paddingTop: 8,
    paddingBottom: 8,
    marginBottom: 2,
    gridColumn: '1 / span 2',
  },
  shortInput: {
    width: '50%',
    '&:first-of-type': {
      width: 'calc(50% - 5px)',
      marginRight: 5,
    },
  },
  defaultInput: {
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 9px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& fieldset': {
      borderColor: '#DADFE5',
      borderRadius: '3px',
    },
  },
  sectionHeader: {
    textAlign: 'left',
    marginTop: '10px',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#2D2E31',
  },
  checkboxSquare: {
    width: 16,
    height: 16,
    borderRadius: 3,
    borderWidth: 1,
    borderColor: '#DADFE5',
    borderStyle: 'solid',
    color: '#000',
  },
  plaidButton: {
    flex: 1,
    height: 50,
    fontSize: 18,
    borderRadius: '5px !important',
    color: `${COLORS.WHITE} !important`,
    lineHeight: '24px',
    textTransform: 'none !important' as 'none',
    background: `${COLORS.PLAID_BLACK} !important`,
    boxShadow: 'none !important',
    fontFamily: FONT_FAMILIES.POPPINS,
    fontWeight: 500,
    '&:disabled, &.Mui-disabled': {
      border: 0,
      background: '#F0F4F8 !important',
      color: '#928E9C !important',
    },
  },
  donationContainerWrapper: {
    '&.MuiContainer-root': {
      padding: '30px 30px 45px 30px',
    },
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  captchaTerms: {
    marginTop: 20,
    padding: '0 !important',
    fontSize: '14px !important ',
  },
  plaidLogo: {
    marginLeft: 8,
  },
  mandateButton: {
    height: 'unset',
    paddingLeft: 0,
    paddingRight: 0,
    marginBottom: 40,
  },
  mandateGrid: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mandateText: {
    color: COLORS.PRIMARY,
    fontFamily: FONT_FAMILIES.INTER,
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'left',
    lineHeight: 'normal',
    width: 'calc(100% - 22px)',
  },
  mandateContainer: {
    backgroundColor: COLORS.GREY_BACKGROUND,
  },
});

export default useStyles;
