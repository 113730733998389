export const KEYS = {
  DIALOG_TITLE: 'Want A Tax Receipt?',
  DIALOG_TEXT: `If you would like to receive a tax receipt while remaining anonymous, enter your
    email below. This email will only be used for the purpose of issuing your tax
    receipt.`,
  SKIP: 'Skip',
  GET_RECEIPT: 'Get receipt',
  EMAIL_FIELD_LABEL: 'Enter email for tax receipt',
  MODAL_CONTAINER_ID: 'modalSubmitContainer',
  EMAIL_FIELD_KEY: 'receiptEmail',
};

export default KEYS;
