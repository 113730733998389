export const KEYS = {
  REJECTED_BY_USER_CODE: 4001,
  SUCCESS_ANIMATION_DURATION: 2500,
};

export const LABELS = {
  CONNECTING: 'Connecting...',
  PROCESSING: 'Processing...',
  CONNECTED: 'Connected!',
  PROCESSED: 'Processed!',
  TRY_AGAIN: 'Try Again',
  DONE: 'Done',
  ERROR: 'Error',
  CONNECTION_ERROR: 'Connection attempt failed',
  DONATION_COMPLETE: 'Donation Complete',
  THANK_YOU_MESSAGE: 'Thank you for donating!',
  START_OVER: 'Start Over',
  REJECTED: 'Rejected',
  REJECTED_MESSAGE: 'Transaction Rejected in MetaMask',
  CLICK_WHEN_READY: 'I\'ve sent my donation',
};

export default KEYS;
