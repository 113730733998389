import AchDonationStatus from 'types/ach';

import { AnyAction, Reducer } from '../state.types';
import { DonationReduxState } from './state';

export const actionTypeName = 'DONATION__FINALIZE_ACH__SUCCESS';

export type ActionFinalizeAchSuccess = AnyAction & {
  paymentMethodStatus: AchDonationStatus;
}

export const createAction = (paymentMethodStatus: AchDonationStatus): ActionFinalizeAchSuccess => ({
  type: actionTypeName,
  paymentMethodStatus,
});

export const reducer: Reducer<DonationReduxState, ActionFinalizeAchSuccess> = (
  state: DonationReduxState,
  action: ActionFinalizeAchSuccess,
): DonationReduxState => {
  const nextState = {
    ...state,
    isSubmitting: false,
    ach: {
      ...state.ach,
      ach: {
        ...state.ach,
        paymentMethodStatus: action.paymentMethodStatus,
        isFinalizing: false,
      },
    },
  };

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
