import { ChariotReduxState } from './state';

export const actionTypeName = 'CHARIOT__FETCH_AMOUNT__START';

export const createAction = () => ({
  type: actionTypeName,
});

export const reducer = (
  state: ChariotReduxState,
): ChariotReduxState => ({
  ...state,
  details: {
    ...state.details,
    isLoading: true,
  },
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
