import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field } from 'formik';
import { Checkbox } from 'formik-mui';

import { DonorInfo } from 'types/donor';

import { FormFieldDescription, InputViewType } from './fields.types';
import { useStyles, withCheckboxStyles } from './styles';

const CheckboxComponent = (props) => {
  const { classes } = useStyles();
  const checkedIcon = (
    <div className={classes.checkboxSquare}>
      <FontAwesomeIcon icon={['fas', 'check']} />
    </div>
  );

  return (
    <Checkbox
      color="default"
      {...props}
      icon={<div className={classes.checkboxSquare} />}
      checkedIcon={checkedIcon}
      fontSize="small"
    />
  );
};

const CustomCheckbox = withCheckboxStyles(CheckboxComponent);

interface CheckboxFieldProps {
  item: FormFieldDescription;
  setFieldValue: (field: string, value: any) => void;
  values: DonorInfo;
}

export const CheckboxField = ({ item, setFieldValue, values }: CheckboxFieldProps) => {
  const { cx, classes } = useStyles();

  const handleCheckboxChange = () => {
    setFieldValue(item.name, !values[item.name]);
  };

  const isCleanView = item?.view?.display === InputViewType.Clean;

  return (
    <div
      key={item.label}
      className={cx(classes.checkboxContainer, {
        [classes.cleanCheckboxContainer]: isCleanView,
      })}
    >
      <Field
        label={item.label}
        type="checkbox"
        name={item.name}
        required={values[item.name] ? false : item.isRequired}
        fullWidth
        component={CustomCheckbox}
        id="outlined-dense"
        margin="dense"
        checked={Boolean(values[item.name])}
        onChange={handleCheckboxChange}
      />
      <div
        role="checkbox"
        onKeyDown={() => {}}
        aria-checked={values[item.name]}
        className={classes.subscriptionText}
        tabIndex={0}
        onClick={handleCheckboxChange}
      >
        {item.label}
      </div>
    </div>
  );
};

export default CheckboxField;
