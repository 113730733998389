export const KEYS = {
  DIALOG_TITLE: 'Want A Tax Receipt?',
  DIALOG_TEXT: `If you would like to receive a tax receipt while remaining anonymous, enter your
    email below. This email will only be used for the purpose of issuing your tax
    receipt.`,
  SKIP: 'Skip',
  GET_RECEIPT: 'Get receipt',
  EMAIL_FIELD_LABEL: 'Enter email for tax receipt',
  EMAIL_FIELD_KEY: 'receiptEmail',
  EMAIL_VALIDATION_MESSAGE: 'Please provide a valid email address',
  REQUIRED_VALIDATION_MESSAGE: 'Email address is required',
};

export const defaultFormValue = {
  [KEYS.EMAIL_FIELD_KEY]: '',
};

export default KEYS;
