import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

const useStyles = makeStyles()(theme => ({
  container: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
  },
  item: {
    flex: 1,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 400,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    fontFamily: 'Noto Sans',
    '&:not(last-child)': {
      marginRight: 20,
    },
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '& span': {
      textTransform: 'uppercase',
      color: '#86909F',
    },
  },
  itemLabel: {
  },
  itemIsSelected: {
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    '& span': {
      color: theme.palette.common.black,
    },
  },
  icon: {
    width: 22,
    maxHeight: 22,
    marginRight: 8,
  },
  isSelected: {
    color: theme.palette.common.black,
    backgroundColor: COLORS.SECONDARY,
    '& span': {
      color: theme.palette.common.black,
    },
  },
}));

export default useStyles;
