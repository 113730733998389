export const KEYS = {
  RIGHT_BUTTON_TEXT: 'Start Over',
  LEFT_BUTTON_TEXT: 'Previous',
  LOADING_ADDRESS: 'Loading donation wallet',
  DONATION_NOTE:
    'Please note that your donation will clear even if you donate a different amount than you pledged.',
  PROCESSING: 'Processing...',
};

export default KEYS;
