import React from 'react';

import BottomButtons from 'components/bottomButtons';
import BottomButtonsNext from 'components/bottomButtonsNext';
import OptionallyVisible from 'components/optionallyVisible';
import { OrganizationInfo } from 'types/organization';
import { StocksDonationPledge } from 'types/pledge';

import { PledgeForm } from './pledgeForm';
import { LABELS } from './stockDonationPledge.keys';

interface PledgeScreenProps {
  pledge: StocksDonationPledge;
  organization: OrganizationInfo;
  updatePledge: (changes: Partial<StocksDonationPledge>) => void;
  proceed: () => void;
  isDisabledSubmit: boolean;
  goBackToStart: () => void;
  isSubmitting: boolean;
  error: Record<string, string> | null;
  clearErrors: () => void;
  isPreviousStepAvailable: boolean;
  updateExchangeRate: () => void;
  isExchangeRateUnavailable: boolean;
}

const PledgeScreen = ({
  pledge,
  organization,
  updatePledge,
  proceed,
  isDisabledSubmit,
  goBackToStart,
  isSubmitting,
  clearErrors,
  error,
  isPreviousStepAvailable,
  updateExchangeRate,
  isExchangeRateUnavailable,
}: PledgeScreenProps) => (
  <>
    <PledgeForm
      pledge={pledge}
      onChange={updatePledge}
      organization={organization}
      updateExchangeRate={updateExchangeRate}
      error={error}
      isExchangeRateUnavailable={isExchangeRateUnavailable}
    />
    <OptionallyVisible visible={isPreviousStepAvailable}>
      <BottomButtons
        isDisabledSubmit={isDisabledSubmit}
        onClickRight={proceed}
        onClickLeft={goBackToStart}
        leftButtonText={LABELS.PREVIOUS}
        rightButtonText={LABELS.DONATE}
        rightButtonLoading={isSubmitting}
      />
    </OptionallyVisible>
    <OptionallyVisible visible={!isPreviousStepAvailable}>
      <BottomButtonsNext
        isDisabledSubmit={isDisabledSubmit}
        onClickRight={proceed}
        rightButtonText={LABELS.DONATE}
      />
    </OptionallyVisible>
  </>
);

export default PledgeScreen;
