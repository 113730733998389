import { DonationPledge } from 'types/pledge';

export const LABELS = {
  EMAIL_INVALID: 'Invalid E-mail address',
  EMAIL_EMPTY: 'Please enter an E-mail address',
  NOTE: {
    INPUT_NAME: 'Notes',
    INPUT_PLACEHOLDER: 'Your note',
    INPUT_TITLE: 'Add a note to your donation.',
    PAGE_TITLE: 'Donation Note',
  },
  TRIBUTE_NOTE: {
    INPUT_NAME: 'Tribute Note',
    INPUT_PLACEHOLDER: 'Your note',
    INPUT_TITLE: 'Add a note and dedicate your donation in honor or memory of someone.',
    NAME_INPUT_LABEL: 'Honoree Full Name',
    EMAIL_INPUT_NAME: 'Honoree Email',
    EMAIL_INPUT_PLACEHOLDER: 'E-mail',
    NOTIFY_HONOREE: 'Notify Honoree',
    NOTIFY_HONOREE_LABEL: 'Notify the honoree and include your message',
    PAGE_TITLE: 'Dedicate Donation',
  },
  GO_BACK: 'Previous',
  PROCEED: 'Continue',
  NEXT: 'Next',
};

export const KEYS = {
  MAX_NOTE_LENGTH: 280,
  EMAIL_REGEX: /\S+@\S+\.\S+/,
  DEBOUNCE_TIMEOUT: 500,
  NOTE: {
    MAX_INPUT_ROWS: 6,
    INPUT_ROWS: 6,
  },
  TRIBUTE_NOTE: {
    MAX_INPUT_ROWS: 3,
    INPUT_ROWS: 3,
  },
};

export const PLEDGE_KEYS: Record<string, keyof DonationPledge> = {
  NOTES: 'notes',
  HONOREE_EMAIL: 'honoreeEmail',
  HONOREE_NAME: 'honoreeName',
  NOTIFY_HONOREE: 'notifyHonoree',
};
export default LABELS;
