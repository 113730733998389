import { AnyAction } from '../state.types';
import { DonorReduxState } from './state';

export const actionTypeName = 'DONOR__SET_ANONYMOUS';

export type ActionSetAnonymous = AnyAction & {
  value: boolean;
}

export const createAction = (value: boolean) => ({
  type: actionTypeName,
  value,
});

export const reducer = (
  state: DonorReduxState,
  action: ActionSetAnonymous,
): DonorReduxState => ({
  ...state,
  isAnonymous: action.value,
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
