import React from 'react';

import { LoadingIcon } from 'components/loading';
import AchDonationStatus from 'types/ach';
import usePlaid from 'utils/hooks/usePlaid';

import { LABELS } from '../keys';
import useStyles from './styles';

export interface PlaidProps {
  clientObjectId: string;
  finalizeAch: () => Promise<{
    errorMessage?: string;
    paymentMethodStatus?: AchDonationStatus;
  }>;
  setAchError: (errorMessage: string) => void;
  isChaging: boolean;
}

const Plaid = ({
  clientObjectId,
  finalizeAch,
  setAchError,
  isChaging,
}: PlaidProps) => {
  const { classes } = useStyles();

  usePlaid({
    clientObjectId,
    finalizeAch,
    setAchError,
  });

  const processingLabel = isChaging ? LABELS.PROCESSING : LABELS.LOADING;

  return (
    <div className={classes.scrollContainer}>
      <LoadingIcon className={classes.loadingIcon} />
      <div className={classes.processingLabel}>{processingLabel}</div>
    </div>
  );
};

export default Plaid;
