import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';
import FONT_FAMILIES from 'theme/fontFamilies';

export const useStyles = makeStyles()({
  scrollContainer: {
    overflowY: 'auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0,1fr))',
    gap: '0px 4px',
    marginTop: 10,
    '&$anonDisabled': {
      paddingTop: 16,
    },
  },
  longView: {
    gridColumn: '1 / span 2',
  },
  anonDisabled: {},
  subscriptionText: {
    display: 'inline-block',
    color: '#2D2E31',
    lineHeight: '12px',
    fontSize: 12,
    cursor: 'pointer',
  },
  checkboxContainerAnonim: {
    opacity: 0.3,
    pointerEvents: 'none',
    cursor: 'default',
  },
  checkboxContainer: {
    '& .MuiCheckbox-root': {
      color: '#DADFE5',
      borderRadius: '3px',
      padding: 0,
      marginRight: 8,
    },
    borderBottom: '1px solid rgba(0,0,0,.2)',
    paddingTop: 8,
    paddingBottom: 8,
    marginBottom: 2,
    gridColumn: '1 / span 2',
  },
  shortInput: {
    width: '50%',
    '&:first-of-type': {
      width: 'calc(50% - 5px)',
      marginRight: 5,
    },
  },
  defaultInput: {
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 9px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& fieldset': {
      borderColor: '#DADFE5',
      borderRadius: '3px',
    },
  },
  sectionHeader: {
    textAlign: 'left',
    marginTop: '10px',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#2D2E31',
  },
  checkboxSquare: {
    width: 16,
    height: 16,
    borderRadius: 3,
    borderWidth: 1,
    borderColor: '#DADFE5',
    borderStyle: 'solid',
    color: '#000',
  },
  captchaTerms: {
    padding: 0,
    marginTop: 13,
  },
  mandateButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  overlayOpenButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
    borderRadius: 10,
    background: COLORS.GREY_BACKGROUND_SECOND,
    borderWidth: 0,
    textTransform: 'none',
    height: 25,
    '&:hover': {
      borderWidth: 0,
    },
  },
  overlayOpenButtonLabel: {
    color: '#26242A',
    fontFamily: FONT_FAMILIES.ROBOTO,
    fontSize: 13,
    fontWeight: 400,
    whiteSpace: 'nowrap',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '24px',
  },
});

export default useStyles;
