import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

const useStyles = makeStyles()({
  page: {

  },
  pageTitle: {
    fontFamily: 'Poppins !important',
    fontSize: '20px !important',
    lineHeight: '30px !important',
    fontWeight: '600 !important',
    textAlign: 'center',
    margin: '10px 0 !important',
    color: COLORS.PRIMARY,
  },
  signatureField: {
    marginBottom: 8,
  },
  compactSignatureField: {
    '& h6': {
      display: 'none',
    },
    '& button': {
      position: 'absolute',
      bottom: 5,
      right: 51,
    },
  },
  signatureInput: {
    border: '1px solid #C4C4C4',
    borderRadius: 5,
  },
  agreementContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  agreement: {
    fontWeight: 400,
    fontSize: '12px !important',
    lineHeight: '14px !important',
    color: '#AAAAAA',
    width: 250,
    textAlign: 'center',
  },
  buttons: {

  },
  clearButton: {

  },
  nextButton: {

  },
  showMore: {
    display: 'inline',
    padding: 0,
    background: 'transparent',
    border: 0,
    marginLeft: 5,
    fontWeight: 500,
    fontSize: '12px !important',
    lineHeight: '14px !important',
  },
  transferNoticePage: {
    display: 'flex!important',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '29px!important',
    height: 362,
  },
  transferNoticeTitle: {
    fontFamily: 'Poppins!important',
    fontWeight: '600!important',
    fontSize: '26px!important',
    lineHeight: '39px!important',
  },
  transferNoticeText: {
    fontSize: '16px!important',
    lineHeight: '22px!important',
  },
});

export default useStyles;
