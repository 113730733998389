import { AnyAction } from '../state.types';
import { StockReduxState } from './state';

export const actionTypeName = 'STOCK__SUBMIT_BROKER_INFO__FAIL';

export type ActionSubmitBrokerInfoFail = AnyAction & {
  error: Record<string, string>;
}

export const createAction = (error: Record<string, string>) => ({
  type: actionTypeName,
  error,
});

export const reducer = (
  state: StockReduxState,
  action: ActionSubmitBrokerInfoFail,
): StockReduxState => ({
  ...state,
  submitting: false,
  error: action.error,
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
