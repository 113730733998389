import React from 'react';
import { Box, Container } from '@mui/material';

import { BottomButtons, Icon, SectionHeader } from 'components';

import useStyles from './styles';

interface ErrorScreenProps {
  error: string;
  hasBackButton: boolean;
  goBack: () => void;
}

const KEYS = {
  LEFT_BUTTON_TEXT: 'Go back',
};

const ErrorScreen = ({ error, hasBackButton, goBack }: ErrorScreenProps) => {
  const { classes } = useStyles();

  return (
    <Container className={classes.container}>
      <Box padding={2} className={classes.boxContainer}>
        <SectionHeader text={error} />
        <br />
        <Icon name="mainLogo" className={classes.icon} />
      </Box>
      {hasBackButton ? (
        <BottomButtons
          leftButtonText={KEYS.LEFT_BUTTON_TEXT}
          onClickLeft={goBack}
        />
      ) : null}
    </Container>
  );
};

export default ErrorScreen;
