import { createReducer } from '../createReducer';
import { buildReducerItem } from '../reducerItem';
import { Reducer } from '../state.types';
import setAnonymous from './setAnonymous';
import setDonorInfo from './setDonorInfo';
import { DonorReduxState, initialState } from './state';
import updateDonorInfoValue from './updateDonorInfoValue';

// Reducers
const reducers: Map<string, Reducer<DonorReduxState, any>> = new Map([
  buildReducerItem(updateDonorInfoValue),
  buildReducerItem(setAnonymous),
  buildReducerItem(setDonorInfo),
]);

export const dashboardReducer = createReducer(initialState, reducers);

export default dashboardReducer;
