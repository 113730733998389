import React from 'react';
import { Error as ErrorIcon } from '@mui/icons-material';

import OptionallyVisible from 'components/optionallyVisible';

import { FormFieldDescription } from './fields.types';
import useStyles from './styles';

interface Shift4FieldProps {
  item: FormFieldDescription;
  required: boolean;
  disabled: boolean;
  isSubmitting: boolean;
  name: string;
  className: string;
  setFieldValue: (field: string, value: any) => void;
  errorText?: string;
  dataAttributes?: Record<string, string>;
  onBlur?: () => void;
}

export const Shift4Field = ({
  dataAttributes,
  errorText,
}: Shift4FieldProps) => {
  const { classes, cx } = useStyles();

  return (
    <>
      <div
        className={cx(classes.shift4InputWrapper, {
          [classes.shift4InputWrapperError]: Boolean(errorText),
        })}
        {...dataAttributes}
      >

        <div className={cx('inputAdornmentError', classes.hidden, classes.shift4InputWrapperAdornment)}>
          <ErrorIcon color="error" fontSize="small" />
        </div>
      </div>
      <OptionallyVisible visible={Boolean(errorText)}>
        <p className={classes.errorWrapper}>{errorText}</p>
      </OptionallyVisible>

    </>
  );
};

export default Shift4Field;
