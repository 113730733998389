import React from 'react';

import OptionallyVisible from 'components/optionallyVisible';
import { WorkflowStep } from 'types/workflow';

import useStyles from './styles';

interface StagesProps {
  stage: WorkflowStep;
  steps: {
    title: string;
    key: WorkflowStep;
  }[];
}

const Stages = ({ stage, steps }: StagesProps) => {
  const { classes, cx } = useStyles();

  const getStep = (title: string, key: WorkflowStep) => {
    const classNames = cx(classes.stage, {
      [classes.active]: stage >= key,
    });

    return (
      <div key={key} className={classNames}>{title}</div>
    );
  };

  return (
    <OptionallyVisible visible={Boolean(steps.length)}>
      <div className={classes.container}>
        {steps.map(({ title, key }) => (getStep(title, key)))}
      </div>
    </OptionallyVisible>
  );
};

export default Stages;
