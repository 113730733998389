export const KEYS = {
  PAGE_SIZE: 40,
};

export const LABELS = {
  ASSET_SYMBOL_LABEL: 'Stock Ticker Symbol',
  ASSET_DESCRIPTION_LABEL: 'Stock Name',
};

export default KEYS;
