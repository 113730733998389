import qs from 'query-string';

const UA_API_DUCK_DUCK_GO = 'https://api.duckduckgo.com/?q=whats+my+user+agent&format=json&pretty=1';
const UTM_SOURCE_PARAM = 'utm_source';

// helper to find Brave in User Agent string
const isBraveAgent = (userAgentResponse) => {
  // eslint-disable-next-line no-bitwise
  const isBraveIndex = ~userAgentResponse.indexOf('Brave');
  return isBraveIndex < 0;
};

// get iframe's parent origin
export const getOrigin = (isBrave): string | undefined => {
  const { search } = window.location;
  const { origin } = qs.parse(search);

  if (origin) {
    return origin.toString();
  }

  try {
    const url = new URL(document?.location?.href);
    const utmSource = url.searchParams.get(UTM_SOURCE_PARAM);
    if (utmSource) {
      return utmSource;
    }

    if (
      isBrave
      && typeof document?.location?.ancestorOrigins !== 'undefined'
      && typeof document.location.ancestorOrigins[0] !== 'undefined'
    ) {
      return document.location.ancestorOrigins[0];
    }

    return document?.referrer?.length ? document.referrer : undefined;
  } catch (e) {
    return undefined;
  }
};

// Brave only shows user agent to DuckDuckGo
const getUseragentAsync = async () => {
  const response = await fetch(UA_API_DUCK_DUCK_GO);
  // eslint-disable-next-line no-return-assign,no-return-await
  return await response.json();
};

const detectDocumentOrigin = getUseragentAsync()
  .then(res => getOrigin(isBraveAgent(res.Answer)))
  .catch(getOrigin);

export default detectDocumentOrigin;
