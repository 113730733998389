import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { AppReduxState } from 'state/state';
import changeStep from 'state/workflow/changeStep';
import DonationWorkflowType, {
  AchDonationWorkflowStep,
  CreditCardDonationWorkflowStep,
  CryptoDonationWorkflowStep,
  DafDonationWorkflowStep,
  GenericWorkflowStep,
  StockDonationWorkflowStep,
  WorkflowStep,
} from 'types/workflow';
import componentSwitch from 'utils/componentSwitch';

import ErrorBlock from './errorBlock';
import OverlayErrorBlock from './overlayVariant/errorBlock';

const mapStateToProps = (state: AppReduxState) => {
  const { type } = state.workflow;
  const { organization } = state.organization;
  const pledgeErrorString = state.pledge.error ? Object.values(state.pledge.error).filter(e => e !== null).join(', ') : null;
  return ({
    type,
    error: state.workflow.error.message || pledgeErrorString,
    title: state.workflow.error.title,
    hasBackButton: state.organization?.organization?.id,
    organization,
  });
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  goBack: (step: WorkflowStep) => dispatch(changeStep.createAction(step)),
});

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
) => ({
  ...stateProps,
  ...dispatchProps,
  goBack: () => {
    if (!stateProps.type) {
      return;
    }

    const previousStep: Record<DonationWorkflowType, WorkflowStep> = {
      [DonationWorkflowType.Crypto]: CryptoDonationWorkflowStep.Pledge,
      [DonationWorkflowType.CreditCard]: CreditCardDonationWorkflowStep.FillCardInfo,
      [DonationWorkflowType.Stock]: StockDonationWorkflowStep.Pledge,
      [DonationWorkflowType.Daf]: DafDonationWorkflowStep.FillDonorInfo,
      [DonationWorkflowType.Ach]: AchDonationWorkflowStep.Pledge,
      [DonationWorkflowType.Generic]: GenericWorkflowStep.Error,
    };

    dispatchProps.goBack(previousStep[stateProps.type]);
  },
});

export const ErrorConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(componentSwitch(ErrorBlock, OverlayErrorBlock));

export default ErrorConnect;
