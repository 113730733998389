import { AnyAction, Reducer } from '../state.types';
import { DonationReduxState } from './state';

export const actionTypeName = 'DONATION__CREATE_PAYMENT_INTENT_AND_SUBMIT_PLEDGE__FAIL';

export type ActionCreatePaymentIntentAndSubmitPledgeFail = AnyAction & {
  error: Record<string, string>;
}

export const createAction = (error): ActionCreatePaymentIntentAndSubmitPledgeFail => ({
  type: actionTypeName,
  error,
});

export const reducer: Reducer<DonationReduxState, ActionCreatePaymentIntentAndSubmitPledgeFail> = (
  state: DonationReduxState,
): DonationReduxState => {
  const nextState = {
    ...state,
    isSubmitting: false,
  };

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
