import React from 'react';

import { Button } from 'components';
import { ButtonDisplayVariant } from 'components/button/button';
import IconChevron from 'components/icon/chevron';
import OptionallyVisible from 'components/optionallyVisible';
import COLORS from 'theme/colors';
import prepareLine from 'utils/string';

import {
  KEYS,
  LABELS,
} from './keys';
import useStyles from './styles';

export interface MandateOverlayProps {
  open: boolean;
  closeOverlay: () => void;
  oganizationName: string;
}

export const MandateOverlay = ({
  open,
  closeOverlay,
  oganizationName,
}: MandateOverlayProps) => {
  const { classes } = useStyles();
  const mandateFirstParagraph = prepareLine(LABELS.MANDATE[0], {
    [KEYS.PLACEHOLDERS.ORGANIZATION_NAME]: oganizationName,
  });
  const mandateSecondParagraph = prepareLine(LABELS.MANDATE[1], {
    [KEYS.PLACEHOLDERS.ORGANIZATION_NAME]: oganizationName,
  });
  const mandateThirdParagraph = prepareLine(LABELS.MANDATE[2], {
    [KEYS.PLACEHOLDERS.ORGANIZATION_NAME]: oganizationName,
  });

  return (
    <OptionallyVisible visible={open}>
      <section className={classes.dialogContainer}>
        <div className={classes.dialogHeaderRow}>
          <div className={classes.dialogHeader}>
            {LABELS.MANDATE_HEADER}
          </div>
          <div className={classes.closeButtonContainer}>
            <Button
              onClick={closeOverlay}
              display={ButtonDisplayVariant.text}
              className={classes.closeButton}
            >
              <IconChevron color={COLORS.PRIMARY} width={12} />
            </Button>
          </div>
        </div>
        <div className={classes.dialogContent}>
          <div className={classes.contentParagraph}>
            {mandateFirstParagraph}
          </div>
          <div className={classes.contentParagraph}>
            {mandateSecondParagraph}
          </div>
          <div className={classes.contentParagraph}>
            {mandateThirdParagraph}
          </div>
        </div>
      </section>
    </OptionallyVisible>
  );
};

export default MandateOverlay;
