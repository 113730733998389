/* eslint-disable react/no-array-index-key */
import React from 'react';

import { COLORS } from 'theme/colors';

import { IconProps } from './icon.types';

const path = [
  [
    'M15.9995 21.9302C15.1049 21.9302 14.355 22.6801 14.355 23.5747C14.355',
    '24.4693 15.1049 25.2192 15.9995 25.2192C16.8612 25.2192 17.644 24.4693',
    '17.6046 23.6142C17.644 22.6735 16.9007 21.9302 15.9995 21.9302Z',
  ].join(' '),
  [
    'M31.2213 27.7584C32.2541 25.9757 32.2607 23.851 31.2345 22.0749L20.9332',
    '4.2351C19.9136 2.43928 18.0717 1.37363 16.0062 1.37363C13.9407 1.37363',
    '12.0988 2.44586 11.0792 4.22852L0.764735 22.088C-0.261448 23.8839 -0.25487',
    '26.0217 0.784469 27.8044C1.81065 29.5673 3.64594 30.6264 5.69831 30.6264H26.2746C28.3335',
    '30.6264 30.182 29.5542 31.2213 27.7584ZM28.9848 26.4691C28.4125 27.4558 27.3994 28.0412',
    '26.268 28.0412H5.69173C4.57345 28.0412 3.567 27.4689 3.00787 26.5019C2.44215 25.5218 2.43557',
    '24.3509 3.00129 23.3642L13.3157 5.51125C13.8749 4.53111 14.8747 3.95224 16.0062 3.95224C17.131',
    '3.95224 18.1375 4.53769 18.6966 5.51783L29.0045 23.3708C29.5571 24.3312 29.5505 25.4889 28.9848 26.4691Z',
  ].join(' '),
  [
    'M15.5918 10.3856C14.809 10.6093 14.3223 11.3197 14.3223 12.1815C14.3617',
    '12.7011 14.3946 13.2274 14.4341 13.747C14.5459 15.727 14.6577 17.6676 14.7696',
    '19.6476C14.809 20.3186 15.3287 20.8053 15.9997 20.8053C16.6706 20.8053 17.1969',
    '20.2857 17.2298 19.6081C17.2298 19.2003 17.2298 18.8253 17.2693 18.4109C17.3416 17.1413',
    '17.4205 15.8718 17.4929 14.6022C17.5324 13.7799 17.6047 12.9577 17.6442 12.1354C17.6442',
    '11.8394 17.6047 11.5763 17.4929 11.3131C17.1574 10.5764 16.3746 10.2014 15.5918 10.3856Z',
  ].join(' '),
];

export const IconWarning = ({
  width = 32,
  height = 32,
  color = COLORS.PRIMARY,
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {path.map((item, key) => <path d={item} fill={color} key={key} />)}
  </svg>
);

export default IconWarning;
