import { environmentConfig } from 'environment/environment.config';

export const KEYS = {
  UNSPECIFIED_ORIGIN: '*',
  STATUS_POLLING_INTERVAL: 10000,
  NEW_TAB_PARAM: '_blank',
  PLAID_IFRAME_SELECTOR: 'iframe[title="Plaid Link"]',
  MIN_PLAID_INNER_WIDTH: 360,
  DIALOG_OFFSET_ON_THE_SIDES: 20,
  SCALE_CSS_PROPERTY: 'scale',
  WIDTH_CSS_PROPERTY: 'width',
  HEIGHT_CSS_PROPERTY: 'height',
  LEFT_CSS_PROPERTY: 'left',
  TOP_CSS_PROPERTY: 'top',
};

export const DEFAULT_ERROR_MESSGAE = [
  'Your donation could not be processed, please try again or contact support',
  environmentConfig.supportDomain ? `@${environmentConfig.supportDomain}.com` : '.',
].join('');

export const ACH_ERROR_LABELS = {
  UNEXPECTED_PAYMENT_STATUS: 'Unexpected payment method status',
  USER_CANCELED: 'User cancelled Plaid process',
  FAILED_VERIFICATION: 'Failed to verify the account',
  GENERIC_ERROR: [
    'Your donation could not be processed, please try again or contact support',
    environmentConfig.supportDomain ? `@${environmentConfig.supportDomain}.com` : '.',
  ].join(''),
};

export default KEYS;
