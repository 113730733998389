import { API, Endpoints } from 'api';

import KEYS from './keys';

export interface FetchStockAssetsArgs {
  page: number;
  search: {
    name?: string;
    ticker?: string;
  };
}

export const fetchStockAssets = async ({
  page = 1,
  search,
}: FetchStockAssetsArgs) => {
  if (!search) {
    return null;
  }

  const body: Record<string, object> = {
    pagination: {
      page,
      itemsPerPage: KEYS.PAGE_SIZE,
    },
  };

  if (search) {
    body.filter = search;
  }

  const response = await API.post(Endpoints.stockAssets, body);
  return response.data;
};
