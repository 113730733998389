import { countryMap, stateMap } from 'constants/countries';

const toList = (map = {}) => {
  const mapEntries = Object.entries(map);
  return mapEntries.map(([id, label]) => ({ id, label }));
};

export const getStates = (countryCode: string) => toList(stateMap[countryCode] || []);

export const getCountries = () => toList(countryMap);

export const getCountryNameFromISOCode = (countryISOCode: string) => countryMap[countryISOCode] ?? '';
