import React, { useMemo } from 'react';

import { DonationSuccessConnect } from 'pages/common/donationSuccess/donationSuccess.connect';
import { DonorInfoConnect } from 'pages/common/donorInfo/donorInfo.connect';
import { ErrorConnect } from 'pages/common/error/error.connect';
import { NotesConnect } from 'pages/common/notes/notes.connect';
import { PledgeConnect } from 'pages/common/pledge/pledge.connect';
import { CreditCardDetailsConnect } from 'pages/creditCard/cardDetails/creditCardDetails.connect';
import { CardDonationConnect } from 'pages/creditCard/donation/cardDonation.connect';
import { FrequencyConnect } from 'pages/creditCard/frequency/frequency.connect';
import { CreditCardDonationWorkflowStep } from 'types/workflow';

const componentMap = {
  [CreditCardDonationWorkflowStep.Frequency]: FrequencyConnect,
  [CreditCardDonationWorkflowStep.Notes]: NotesConnect,
  [CreditCardDonationWorkflowStep.Pledge]: PledgeConnect,
  [CreditCardDonationWorkflowStep.FillDonorInfo]: DonorInfoConnect,
  [CreditCardDonationWorkflowStep.FillCardInfo]: CreditCardDetailsConnect,
  [CreditCardDonationWorkflowStep.Error]: ErrorConnect,
  [CreditCardDonationWorkflowStep.Donate]: CardDonationConnect,
  [CreditCardDonationWorkflowStep.Success]: DonationSuccessConnect,
};

interface CreditCardRouterProps {
  step: CreditCardDonationWorkflowStep;
}

export const CreditCardRouter = ({ step }: CreditCardRouterProps) => {
  const Component = useMemo(() => componentMap[step], [step]);

  return (
    <Component />
  );
};

export default CreditCardRouter;
