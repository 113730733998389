import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import Notes from 'pages/common/notes/notes';
import OverlayNotes from 'pages/common/notes/overlayVariant/notes';
import postDonationInfo, { PostDonationInfoParams } from 'state/chariot/thunk/postDonationInfo.thunk';
import updatePledge from 'state/pledge/updatePledge';
import { AppReduxState } from 'state/state';
import changeStep from 'state/workflow/changeStep';
import setType from 'state/workflow/setType';
import { DonationPledge } from 'types/pledge';
import { DafDonationWorkflowStep } from 'types/workflow';
import componentSwitch from 'utils/componentSwitch';

const mapStateToProps = (state: AppReduxState) => {
  const {
    notes,
    areTributesEnabled,
    honoreeName,
    honoreeEmail,
    notifyHonoree,
    usdAmount,
    coverTransactionFees,
    campaignId,
  } = state.pledge;
  const { externalId } = state.donationData;
  const { donation } = state.chariot;
  const { fundraiserId } = state.fundraiser;
  const { donor } = state;

  const {
    organization,
  } = state.organization;

  const { step, type } = state.workflow;
  const workflowSessionId = donation.session?.workflowSessionId ?? '';
  const amount = donation.session?.grantIntent.amount ?? 0;
  const DAFDonationInfo = {
    organization,
    workflowSessionId,
    donor,
    pledge: state.pledge,
    pledgeAmount: amount,
    fundraiserId,
    coverTransactionFees,
    campaignId,
    externalId,
  };

  return ({
    notes,
    honoreeName,
    honoreeEmail,
    notifyHonoree,
    usdAmount,
    step,
    type,
    isTributeNote: areTributesEnabled,
    areFeesCovered: coverTransactionFees,
    DAFDonationInfo,
  });
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  resetFlow: () => dispatch(setType.createAction(null)),
  updatePledgeNotes: (changes: Partial<DonationPledge>) => dispatch(updatePledge.createAction(changes)),
  goForward: () => dispatch(changeStep.createAction(DafDonationWorkflowStep.Success)),
  goBack: () => dispatch(changeStep.createAction(DafDonationWorkflowStep.Donate)),
  postDonationInfo: (params: PostDonationInfoParams) => dispatch(postDonationInfo(params)),
});

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
) => ({
  ...stateProps,
  ...dispatchProps,
  proceed: () => dispatchProps.postDonationInfo(stateProps.DAFDonationInfo),
});

export const NotesConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(componentSwitch(Notes, OverlayNotes));

export default NotesConnect;
