import React from 'react';

import heartsSrc from 'assets/images/filled-hearts.svg';
import { BottomButtonsNext } from 'components';
import IconStars from 'components/icon/starsIcon';
import OptionallyVisible from 'components/optionallyVisible';
import COLORS from 'theme/colors';

import {
  getLinkButtons,
  LABELS,
} from './keys';
import useStyles from './style';
import { DonationSuccessLayoutProps } from './types';

export const DonationSuccessLayout = ({
  restart,
  additionalContent,
  withSocialLinks = false,
  organizationName,
  onShare,
  hideShareHighlight = false,
}: DonationSuccessLayoutProps) => {
  const { classes, cx } = useStyles();

  const links = getLinkButtons(organizationName);

  const handleLinkClick = link => () => {
    window.open(link.link, '_blank');
    onShare?.(link.key);
  };

  return (
    <div className={classes.container}>
      <section className={cx(classes.section, classes.headerSection)}>
        <div className={classes.titleContainer}>
          <img src={heartsSrc} className={classes.gratitudeImage} alt={LABELS.ALT.GRATITUDE} />
          <span className={classes.textSemiBold}>
            {LABELS.PAGE_HEADER_TEXT}
          </span>
          {LABELS.PAGE_SUB_HEADER_TEXT}
        </div>
      </section>
      <section className={cx(classes.section, classes.instructionsSection)}>
        {additionalContent}
      </section>
      <OptionallyVisible visible={withSocialLinks}>
        <section className={classes.socialSection}>
          <div className={classes.shareHeader}>{LABELS.SHARE_HEADER}</div>
          <OptionallyVisible visible={!hideShareHighlight}>
            <div className={classes.shareMessage}>
              <div className={classes.starsIconContainer}>
                <IconStars className={classes.iconStars} color={COLORS.PRIMARY} />
              </div>
              <span>
                {LABELS.SHARE_MESSAGE}
              </span>
            </div>
          </OptionallyVisible>
          <div className={classes.socialButtonsRow}>
            {links.map(link => (
              <button
                onClick={handleLinkClick(link)}
                className={classes.socialButton}
                type="button"
                key={link.key}
                style={{
                  backgroundColor: link.backgroundColor,
                }}
                title={link.linkDescription}
              >
                <img
                  src={link.icon}
                  alt={link.key}
                  className={classes.icon}
                  style={{
                    width: link?.iconWidth,
                  }}
                />
              </button>
            ))}
          </div>
        </section>
      </OptionallyVisible>
      <BottomButtonsNext
        rightIcon={['fas', 'sync']}
        onClickRight={restart}
        rightButtonText={LABELS.PROCEED_BUTTON_TEXT}
      />
    </div>
  );
};

export default DonationSuccessLayout;
