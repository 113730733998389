export const prepareLine = (line: string, replacements: Record<string, string>): string => {
  if (!replacements) {
    return line;
  }

  return Object.entries(replacements)
    .reduce((
      output,
      [key, value],
    ) => {
      if (!value) {
        return output;
      }

      return output.replaceAll(key, value);
    }, line);
};

export default prepareLine;
