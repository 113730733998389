import React, { useEffect } from 'react';

import BottomButtons from 'components/bottomButtons';
import BottomButtonsNext from 'components/bottomButtonsNext';
import OptionallyVisible from 'components/optionallyVisible';
import Currency from 'types/currency';
import Frequency from 'types/frequency';
import { DonationPledge } from 'types/pledge';

import DonationWorkflowType from '../../../types/workflow';
import { LABELS } from './pledge.keys';
import { OrganizationWithFees } from './pledge.types';
import { PledgeForm } from './pledgeForm';

interface PledgeScreenProps {
  pledge: DonationPledge;
  calculateFromFiat: boolean;
  organization: OrganizationWithFees;
  isPreviousStepAvailable: boolean;
  changeAmount: (value: number) => void;
  swapCurrencies: () => void;
  updatePledge: (changes: Partial<DonationPledge>) => void;
  fetchCurrencyExchangeRate: () => void;
  proceed: () => void;
  isDisabledSubmit: boolean;
  currencies: Currency[];
  cryptoDropdownTouched: boolean;
  setDropdownTouched: (value: boolean) => void;
  isFiatDonation: boolean;
  resetFlowWithDesiredDonationType: () => void;
  isSubmitting: boolean;
  error: Record<string, string> | null;
  clearErrors: () => void;
  /* eslint-disable */
  type: DonationWorkflowType;
  setType: (type: DonationWorkflowType) => void;
  changeFiatAmount: (value: number) => void;
  changeCryptoAmount: (value: number) => void;
  /* eslint-enable */
  frequency: Frequency;
  fiatFeeObject: { usd: number };
}

const PledgeScreen = ({
  pledge,
  calculateFromFiat,
  organization,
  isPreviousStepAvailable,
  changeAmount,
  swapCurrencies,
  updatePledge,
  fetchCurrencyExchangeRate,
  proceed,
  isDisabledSubmit,
  currencies,
  cryptoDropdownTouched,
  setDropdownTouched,
  isFiatDonation,
  resetFlowWithDesiredDonationType,
  isSubmitting,
  clearErrors,
  error,
  frequency,
  fiatFeeObject,
}: PledgeScreenProps) => {
  useEffect(() => {
    fetchCurrencyExchangeRate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return clearErrors;
  }, [pledge.currency]);

  const continueButtonLabel = isFiatDonation ? LABELS.DONATE_LABEL : LABELS.CONTINUE_LABEL;

  return (
    <>
      <PledgeForm
        pledge={pledge}
        onAmountChange={changeAmount}
        swapCurrencies={swapCurrencies}
        onChange={updatePledge}
        calculateFromFiat={calculateFromFiat}
        currencies={currencies}
        organization={organization}
        cryptoDropdownTouched={cryptoDropdownTouched}
        setDropdownTouched={setDropdownTouched}
        isFiatDonation={isFiatDonation}
        error={error}
        frequency={frequency}
      />
      <OptionallyVisible visible={isPreviousStepAvailable}>
        <BottomButtons
          isDisabledSubmit={isDisabledSubmit}
          onClickRight={proceed}
          onClickLeft={resetFlowWithDesiredDonationType}
          leftButtonText={LABELS.BACK_BUTTON_LABEL}
          rightButtonText={continueButtonLabel}
          rightButtonLoading={isSubmitting}
        />
      </OptionallyVisible>
      <OptionallyVisible visible={!isPreviousStepAvailable}>
        <BottomButtonsNext
          isDisabledSubmit={isDisabledSubmit}
          onClickRight={proceed}
          rightButtonText={continueButtonLabel}
        />
      </OptionallyVisible>
    </>
  );
};

export default PledgeScreen;
