import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  donationTypeButtonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  donationTypeButtonsContainerGrid: {
    columnGap: 10,
    rowGap: 10,
  },
  donationTypeButton: {
    flex: 1,
    background: 'linear-gradient(180deg, #F0F4F8 0%, rgba(240, 244, 248, 0) 100%) !important',
    height: 40,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '21px',
    color: '#928E9C',
    boxShadow: 'unset',
    '& svg': {
      marginRight: 10,
    },
    textTransform: 'none',
    fontFamily: 'Poppins',
    borderRadius: 0,
    '&:first-of-type': {
      borderRadius: '5px 0 0 5px',
    },
    '&:last-child': {
      borderRadius: '0 5px 5px 0',
    },
  },
  gridDonationTypeButton: {
    background: 'unset !important',
    height: 40,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px !important',
    boxShadow: '0px 3px 12px rgba(41, 27, 79, 0.05)',
    width: '100%',
    padding: 0,
  },
  donationTypeButtonActive: {
    height: 50,
    fontSize: 16,
    borderRadius: '5px !important',
    color: '#291B4F',
    lineHeight: '24px',
    background: `${theme.palette.secondary.main} !important`,
  },
  gridDonationTypeButtonActive: {
    height: 40,
    fontSize: '14px !important',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 5,
    paddingBottom: 5,
  },
}));

export default useStyles;
