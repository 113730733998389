import React from 'react';
import { Typography } from '@mui/material';

import { Icon } from 'components';
import OptionallyVisible from 'components/optionallyVisible';
import { OrganizationInfo } from 'types/organization';

import useStyles, { styles } from './styles';

interface HeaderProps {
  organization: OrganizationInfo;
}

export const Header = ({ organization }: HeaderProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.topBarContainer}>
      <div className={classes.imageContainer}>
        <OptionallyVisible visible={Boolean(organization.logo)}>
          <img className={classes.image} src={organization.logo} alt={organization.name} />
        </OptionallyVisible>
        <OptionallyVisible visible={!organization.logo}>
          <Icon className={classes.image} name="charityLogo" />
        </OptionallyVisible>
      </div>
      <div>
        <Typography className={classes.charityName} variant="h6" style={styles.header(organization.name)}>
          {organization.name}
        </Typography>
      </div>
    </div>
  );
};

export default Header;
