import { makeStyles, withStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
  longView: {
    gridColumn: '1 / span 2',
  },
  anonDisabled: {},
  subscriptionText: {
    display: 'inline-block',
    color: '#2D2E31',
    lineHeight: '12px',
    fontSize: 12,
    cursor: 'pointer',
  },
  checkboxContainerAnonim: {
    opacity: 0.3,
    pointerEvents: 'none',
    cursor: 'default',
  },
  checkboxContainer: {
    '& .MuiCheckbox-root': {
      color: '#DADFE5',
      borderRadius: '3px',
      padding: 0,
      marginRight: 8,
    },
    borderBottom: '1px solid rgba(0,0,0,.2)',
    paddingTop: 8,
    paddingBottom: 8,
    marginBottom: 2,
    gridColumn: '1 / span 2',
  },
  cleanCheckboxContainer: {
    padding: 0,
    paddingTop: 5,
    borderBottomWidth: 0,
  },
  shortInput: {
    width: '50%',
    '&:first-of-type': {
      width: 'calc(50% - 5px)',
      marginRight: 5,
    },
  },
  defaultInput: {
    '& .MuiInputLabel-outlined.MuiInputLabel-root': {
      transform: 'translate(14px, 9px) scale(1)',
      fontSize: 14,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& fieldset': {
      borderColor: '#DADFE5',
      borderRadius: '3px',
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: 14,
    },
    '& .MuiFormControl-root': {
      marginTop: 1,
      marginBottom: 1,
    },
  },
  formItem: {
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 8px) scale(1)',
      fontSize: 14,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& fieldset': {
      borderColor: '#DADFE5',
      borderRadius: '3px',
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: 14,
    },
    '& .MuiFormControl-marginDense': {
      marginTop: 5,
      marginBottom: 1,
    },
  },
  autocompleteInput: {
    '& .MuiInputLabel-root': {
      transform: 'translate(14px, 8px) scale(1)',
      fontSize: 14,
    },
    '& .MuiInputBase-root': {
      height: '32.625px',
      padding: '3px 39px 6px 6px',
      '& .MuiInputBase-input': {
        paddingTop: 4.5,
        paddingBottom: 4.5,
      },
    },
  },
  checkboxSquare: {
    width: 16,
    height: 16,
    borderRadius: 3,
    borderWidth: 1,
    borderColor: '#DADFE5',
    borderStyle: 'solid',
    color: '#000',
    fontSize: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const checkboxStyles = {
  root: {
    '&$checked': {
      fontSize: '10px',
      lineHeight: '16px',
      color: '#000',
    },
  },
  checked: {},
};

export const withCheckboxStyles = Component => withStyles(Component, checkboxStyles);

export default useStyles;
