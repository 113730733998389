import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  checkedIcon: {
    background: theme.palette.secondary.main,
    borderRadius: theme.shape.borderRadius,
  },
  checkbox: {
    '& .MuiSvgIcon-root': {
      background: theme.palette.divider,

      borderRadius: theme.shape.borderRadius,
      width: 20,
      height: 20,

      '& path': {
        fill: 'transparent',
      },
    },
  },
}));

export default useStyles;
