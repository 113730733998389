import React, { useEffect, useState } from 'react';

import { Button } from 'components/button/button';
import OptionallyVisible from 'components/optionallyVisible';

import { Event, KEYS } from './keys';
import useStyles from './styles';

const noop = () => { };

interface ChariotWebComponent extends HTMLElement {
 onDonationRequest: (onDonationRequestFunc: () => void) => void;
 registerTheme: (target: string, themeName: string | Object) => void;
}

type ChariotConnectProps = {
    cid: string;
    dependecies: any[];
    theme?: string | Object;
    onDonationRequest: () => Object;
    onSuccess?: (e: any) => void;
    onExit?: (e: any) => void;
    disabled?: boolean;
    onUnmount?: () => void;
}

const ChariotConnect: React.FC<ChariotConnectProps> = ({
  cid,
  dependecies,
  theme = 'DefaultTheme',
  onDonationRequest,
  onSuccess = noop,
  onExit = noop,
  disabled = false,
  onUnmount,
}) => {
  const { classes } = useStyles();
  const [node, setNode] = useState<any>(null);
  const [initialized, setInitialized] = useState(false);

  const updateListener = () => {
    if (!initialized) {
      return;
    }
    node?.onDonationRequest(onDonationRequest);
    node?.removeEventListener(Event.Success, onSuccess);
    node?.addEventListener(Event.Success, onSuccess);
  };

  useEffect(() => {
    const script = document.createElement('script');

    script.id = KEYS.CHARIOT_SCRIPT_IDENTIFIER;
    script.src = window?.donationFormConfig?.chariot?.scriptSrc || '';
    script.async = true;

    const existingScript = document.getElementById(KEYS.CHARIOT_SCRIPT_IDENTIFIER);
    if (!existingScript) {
      document.body.appendChild(script);
    }

    const connect = document.createElement(KEYS.CHARIOT_NODE_NAME) as ChariotWebComponent;
    connect.setAttribute('cid', cid);
    connect.setAttribute('theme', (typeof theme === 'string') ? theme : 'customTheme');
    if (disabled) {
      connect.setAttribute(KEYS.DISABLED_ATTRIBUTE, '');
    }

    connect.addEventListener(Event.Init, () => {
      setInitialized(true);
      connect.onDonationRequest(onDonationRequest);
      connect.registerTheme('customTheme', theme);
    });

    connect.addEventListener(Event.Success, onSuccess);
    connect.addEventListener(Event.Exit, onExit);

    const connectContainer = document.getElementById(KEYS.CONTAINER_IDENTIFIER);
    connectContainer?.appendChild(connect);

    setNode(connect);

    return () => {
      connectContainer?.removeChild(connect);
      onUnmount?.();
    };
  }, []);

  useEffect(() => {
    updateListener();

    return () => {
      node?.removeEventListener(Event.Success, onSuccess);
    };
  }, dependecies);

  useEffect(() => {
    if (disabled) {
      node?.setAttribute(KEYS.DISABLED_ATTRIBUTE, '');
    } else {
      node?.removeAttribute(KEYS.DISABLED_ATTRIBUTE);
    }
  }, [disabled]);

  return (
    <div id={KEYS.CONTAINER_IDENTIFIER}>
      <OptionallyVisible visible={!initialized}>
        <Button className={classes.fakeButton} loading />
      </OptionallyVisible>
    </div>
  );
};

export default ChariotConnect;
