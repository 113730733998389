import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, Typography } from '@mui/material';
import {
  Form,
  Formik,
  FormikErrors,
  FormikTouched,
  FormikValues,
} from 'formik';
import * as Yup from 'yup';

import amexCard from 'assets/images/amex.svg';
import discoverCard from 'assets/images/discover.svg';
import mastercardCard from 'assets/images/mastercard.svg';
import visaCard from 'assets/images/visa.svg';
import { BottomButtons } from 'components';
import { ApplePay } from 'components/applePay';
import { CaptchaTermsAndConditions } from 'components/captcha/termsAndConditions';
import { OptionallyVisible } from 'components/optionallyVisible';
import { Shift4UtilsContext } from 'components/shift4Utils/context';
import { CreditCardReduxState } from 'state/creditCard/state';
import { FiatPaymentMethod, Shift4Utils } from 'types/card';
import { USDFormatter } from 'utils/currency';
import useApplePayAvailability from 'utils/hooks/useApplePayAvailability';
import stringToNumeric from 'utils/stringToNumeric';

import {
  creditCardInfoFormFields, KEYS,
  LABELS,
} from './creditCardDetails.keys';
import { SHIFT4_COMPONENTS_STYLES, useStyles } from './creditCardDetails.styles';
import TextField from './fields/textField';
import { insertStringIntoSpecificIndex } from './utils';

interface CreditCardDetailsProps {
  initialCreditCardInformation: CreditCardReduxState;
  isSubmitting: boolean;
  error: Record<string, string>;
  donationAmount: number;
  isRecurringDonation: boolean;
  clearFields: () => void;
  set3DSError: (error: Record<string, string> | undefined) => void;
  updateValue: (field: string, value: any) => void;
  goBack: () => void;
  getCardToken: (
    method: FiatPaymentMethod,
    options: {
    shift4Utils: Shift4Utils | null;
    components: unknown;
    additionalFields?: unknown;
  }) => void;
}

interface ValidationFormProps {
  values: FormikValues;
  validateForm: (values: any) => Promise<FormikErrors<FormikValues>>;
  setTouched: (touched: FormikTouched<FormikValues>, shouldValidate?: boolean) => void;
}

export const CreditCardDetails = ({
  initialCreditCardInformation,
  goBack,
  updateValue,
  getCardToken,
  isSubmitting: tokenizingCard,
  error,
  clearFields,
  donationAmount,
  isRecurringDonation,
  set3DSError,
}: CreditCardDetailsProps) => {
  const { classes, cx } = useStyles();
  const [errors, setErrors] = useState({});
  const { shift4Utils } = useContext(Shift4UtilsContext);
  const [components, setComponents] = useState(null);

  const isApplePayAvailable = useApplePayAvailability();
  const isThirdPartyPaymentMethodAvailable = !isRecurringDonation && isApplePayAvailable;

  useEffect(() => {
    clearFields();
    return () => {
      setErrors({});
      set3DSError(undefined);
    };
  }, []);

  useEffect(() => {
    if (!shift4Utils) {
      return;
    }

    const shift4Components = shift4Utils
      .createComponentGroup(SHIFT4_COMPONENTS_STYLES)
      .automount(`#${KEYS.SHIFT4.FORM_IDENTIFIER}`);

    setComponents(shift4Components);
  }, [shift4Utils]);

  const schemaObject = useMemo(() => creditCardInfoFormFields(Boolean(shift4Utils)).reduce((schema, item) => {
    if (!item.isRequired) {
      return schema;
    }

    return {
      ...schema,
      [item.name]: Yup.string().required(LABELS.REQURED),
    };
  }, {}), [shift4Utils]);

  const creditCardFieldsMapper = useMemo(() => creditCardInfoFormFields(Boolean(shift4Utils)).reduce((schema, item) => ({
    ...schema,
    [item.name]: item,
  }), {}), [shift4Utils]);

  const validationSchema = Yup.object().shape(schemaObject);

  const getFieldHandler = (setFieldValue: (field: string, value: any) => void) => (field: string, value: any) => {
    const creditCardField = creditCardFieldsMapper[field];
    const shouldParseToNumber = creditCardField?.type === KEYS.NUMBER_TYPE && value !== '';
    const nextValue = shouldParseToNumber ? stringToNumeric(value) : value;

    const hasToInsertSpecialSymbol = field === KEYS.CARD_EXPIRATION_DATE;
    const changedToValue = hasToInsertSpecialSymbol
      ? insertStringIntoSpecificIndex(nextValue, KEYS.DATE_SEPARATOR, 2) : nextValue;

    updateValue(field, changedToValue);
    setFieldValue(field, changedToValue);
  };

  const getErrorFields = async ({
    values,
    validateForm,
    setTouched,
  }: ValidationFormProps) => {
    const validationResult = await validateForm(values);
    setTouched(validationResult as FormikTouched<FormikValues>);
    setErrors(validationResult);

    return Object.keys(validationResult);
  };

  const triggerValidateForm = async (validationFormProps: ValidationFormProps) => {
    const erroredFields = await getErrorFields(validationFormProps);
    return erroredFields.length === 0;
  };

  const handleTokenizeCard = (method: FiatPaymentMethod, validationFormProps: ValidationFormProps) => async () => {
    if (method !== FiatPaymentMethod.ApplePay) {
      const isValid = await triggerValidateForm(validationFormProps);
      if (!isValid) {
        return;
      }
    }

    if (!shift4Utils) {
      return;
    }

    getCardToken(method, { shift4Utils, components });
  };

  const handleBlur = (validationFormProps: ValidationFormProps) => async () => {
    triggerValidateForm(validationFormProps);
  };

  return (
    // @ts-ignore
    <Formik
      initialValues={initialCreditCardInformation}
      validationSchema={validationSchema}
      errors={errors}
    >
      {({
        setFieldValue,
        isSubmitting,
        isValid,
        values,
        validateForm,
        setTouched,
      }) => (
        <Form
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          id={KEYS.SHIFT4.FORM_IDENTIFIER}
          className={classes.formContainer}
        >
          <Typography className={cx(classes.donationAmount, {
            [classes.withLessEmptySpace]: isThirdPartyPaymentMethodAvailable,
          })}
          >
            {USDFormatter().format(donationAmount)}
          </Typography>
          <div className={classes.container}>
            <OptionallyVisible visible={isThirdPartyPaymentMethodAvailable}>
              <>
                <Box
                  className={classes.thirdPartyPaymentMethodsContainer}
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <OptionallyVisible visible={isApplePayAvailable}>
                    <ApplePay
                      onPay={handleTokenizeCard(FiatPaymentMethod.ApplePay, { values, validateForm, setTouched })}
                    />
                  </OptionallyVisible>
                </Box>
                <Box
                  className={classes.dividerContainer}
                  display="flex"
                  justifyContent="center"
                >
                  <span className={classes.divider} />
                  <span className={classes.dividerText}>
                    {LABELS.OR}
                  </span>
                  <span className={classes.divider} />
                </Box>
              </>
            </OptionallyVisible>
            {creditCardInfoFormFields(Boolean(shift4Utils)).map(item => (
              <TextField
                key={item.name}
                item={item}
                isSubmitting={isSubmitting}
                setFieldValue={getFieldHandler(setFieldValue)}
                errorText={error?.[item.name]}
                dataAttributes={item.dataAttributes}
                onBlur={handleBlur({ values, validateForm, setTouched })}
              />
            ))}
          </div>
          <CaptchaTermsAndConditions />
          <div className={classes.cardsContainer}>
            <img src={visaCard} alt="visa" className={classes.creditCard} />
            <img src={mastercardCard} alt="mastercard" className={classes.creditCard} />
            <img src={amexCard} alt="amex" className={classes.creditCard} />
            <img src={discoverCard} alt="discover" className={classes.creditCard} />
          </div>
          <BottomButtons
            isDisabledSubmit={!isValid}
            onClickLeft={goBack}
            onClickRight={handleTokenizeCard(FiatPaymentMethod.Card, { values, validateForm, setTouched })}
            rightButtonText={LABELS.RIGHT_BUTTON_TEXT}
            leftButtonText={LABELS.LEFT_BUTTON_TEXT}
            rightButtonLoading={tokenizingCard}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CreditCardDetails;
