/* eslint-disable react/no-array-index-key */
import React from 'react';

import { IconProps } from './icon.types';

const paths = [
  {
    path: [
      'M7.37744 20.9079L10.4978 21.7358V20.6532L10.7525 20.3984H12.5355V21.672V22.5635H10.6251L8.26895 21.5447L7.37744 20.9079Z',
    ].join(' '),
    fill: '#CDBDB2',
  },
  {
    path: 'M17.5659 20.9079L14.5093 21.7358V20.6532L14.2546 20.3984H12.4716V21.672V22.5635H14.382L16.738 21.5447L17.5659 20.9079Z',
    fill: '#CDBDB2',
  },
  {
    path: [
      'M10.7525 18.5519L10.4978 20.6532L10.8161 20.3985H14.1275L14.5095 20.6532L14.2548',
      '18.5519L13.7454 18.2334L11.1982 18.2971L10.7525 18.5519Z',
    ].join(' '),
    fill: '#393939',
  },
  {
    path: 'M9.03296 4.35156L10.5613 7.9176L11.2618 18.2974H13.7452L14.5094 7.9176L15.9103 4.35156H9.03296Z',
    fill: '#F89C35',
  },
  {
    path: 'M2.34674 12.1838L0.563721 17.3418L5.02127 17.0871H7.88678V14.8583L7.75942 10.2734L7.12263 10.7829L2.34674 12.1838Z',
    fill: '#F89D35',
  },
  {
    path: 'M5.72168 12.7568L10.9434 12.8842L10.3703 15.5587L7.88677 14.9219L5.72168 12.7568Z',
    fill: '#D87C30',
  },
  {
    path: 'M5.72168 12.8208L7.88677 14.8585V16.8963L5.72168 12.8208Z',
    fill: '#EA8D3A',
  },
  {
    path: 'M7.88672 14.9219L10.4339 15.5587L11.2618 18.2969L10.6886 18.6153L7.88672 16.9596V14.9219Z',
    fill: '#F89D35',
  },
  {
    path: 'M7.88688 16.96L7.37744 20.9081L10.7525 18.552L7.88688 16.96Z',
    fill: '#EB8F35',
  },
  {
    path: 'M10.9434 12.8843L11.2619 18.2971L10.3066 15.527L10.9434 12.8843Z',
    fill: '#EA8E3A',
  },
  {
    path: 'M4.95752 17.0237L7.88676 16.96L7.37733 20.9081L4.95752 17.0237Z',
    fill: '#D87C30',
  },
  {
    path: 'M1.96466 22.6272L7.37734 20.9078L4.95753 17.0234L0.563721 17.3418L1.96466 22.6272Z',
    fill: '#EB8F35',
  },
  {
    path: 'M10.5613 7.91748L7.82315 10.2099L5.72168 12.7571L10.9434 12.9482L10.5613 7.91748Z',
    fill: '#E8821E',
  },
  {
    path: 'M7.37744 20.9078L10.7525 18.5518L10.4978 20.5895V21.7357L8.20533 21.2899L7.37744 20.9078Z',
    fill: '#DFCEC3',
  },
  {
    path: 'M17.5659 20.9078L14.2546 18.5518L14.5093 20.5895V21.7357L16.8018 21.2899L17.5659 20.9078Z',
    fill: '#DFCEC3',
  },
  {
    path: 'M9.54239 14.0308L10.2429 15.4954L7.75937 14.8586L9.54239 14.0308Z',
    fill: '#393939',
  },
  {
    path: 'M1.90088 1.3584L10.5613 7.9173L9.09669 4.35127L1.90088 1.3584Z',
    fill: '#E88F35',
  },
  {
    path: [
      'M1.90087 1.3584L0.754639 4.86076L1.39143 8.68151L0.945676 8.93623L1.58247',
      '9.50934L1.07303 9.9551L1.77351 10.5919L1.32775 10.974L2.34662 12.2475L7.12256',
      '10.7829C9.45747 8.915 10.6037 7.95981 10.5612 7.91736C10.5188 7.87491 7.632 5.68859 1.90087 1.3584Z',
    ].join(' '),
    fill: '#8E5A30',
  },
  {
    path: 'M22.5966 12.1838L24.3796 17.3418L19.9221 17.0871H17.0566V14.8583L17.1839 10.2734L17.8207 10.7829L22.5966 12.1838Z',
    fill: '#F89D35',
  },
  {
    path: 'M19.2217 12.7568L14 12.8842L14.573 15.5587L17.0566 14.9219L19.2217 12.7568Z',
    fill: '#D87C30',
  },
  {
    path: 'M19.2217 12.8208L17.0566 14.8585V16.8963L19.2217 12.8208Z',
    fill: '#EA8D3A',
  },
  {
    path: 'M17.0566 14.9219L14.5095 15.5587L13.6816 18.2969L14.2548 18.6153L17.0566 16.9596V14.9219Z',
    fill: '#F89D35',
  },
  {
    path: 'M17.0565 16.96L17.5659 20.9081L14.2546 18.6156L17.0565 16.96Z',
    fill: '#EB8F35',
  },
  {
    path: 'M13.9999 12.8843L13.6815 18.2971L14.6367 15.527L13.9999 12.8843Z',
    fill: '#EA8E3A',
  },
  {
    path: 'M19.9858 17.0237L17.0566 16.96L17.566 20.9081L19.9858 17.0237Z',
    fill: '#D87C30',
  },
  {
    path: 'M22.9787 22.6272L17.566 20.9078L19.9858 17.0234L24.3796 17.3418L22.9787 22.6272Z',
    fill: '#EB8F35',
  },
  {
    path: 'M14.3821 7.91748L17.1202 10.2099L19.2217 12.7571L14 12.9482L14.3821 7.91748Z',
    fill: '#E8821E',
  },
  {
    path: 'M15.401 14.0308L14.7004 15.4954L17.184 14.8586L15.401 14.0308Z',
    fill: '#393939',
  },
  {
    path: 'M23.0425 1.3584L14.3821 7.9173L15.8467 4.35127L23.0425 1.3584Z',
    fill: '#E88F35',
  },
  {
    path: [
      'M23.0425 1.3584L24.1887 4.86076L23.5519 8.68151L23.9977 8.93623L23.3609 9.50934L23.8703',
      '9.9551L23.1699 10.5919L23.6156 10.974L22.5967 12.2475L17.8208 10.7829C15.4859 8.915 14.3397 7.95981 14.3821',
      '7.91736C14.4246 7.87491 17.3114 5.68859 23.0425 1.3584Z',
    ].join(' '),
    fill: '#8E5A30',
  },
];

export const IconMetamask = ({
  width = 24,
  height = 24,
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {paths.map(({ path, fill }, key) => <path d={path} fill={fill} key={key} />)}
  </svg>
);

export default IconMetamask;
