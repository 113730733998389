import { createContext } from 'react';

type CaptchaContextValues = {
  execute: () => Promise<string | null>;
}

export const initialValues: CaptchaContextValues = {
  execute: async () => '',
};

export const CaptchaContext = createContext(initialValues);

export default CaptchaContextValues;
