import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  amountButtonContainer: {
    width: '100%',
    marginBottom: 20,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    '&:first-of-type': {
      marginBottom: 20,
    },
  },
  amountButton: {
    height: 50,
    flex: 1,
    backgroundColor: '#FFFFFF !important',
    border: '1px solid #D5DDE3',
    boxShadow: '0px 3px 12px rgba(41, 27, 79, 0.05) !important',
    fontFamily: 'Noto Sans',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '23px',
    color: '#291B4F',
    '&:not(:last-child)': {
      marginRight: 20,
    },
  },
  selectedAmountButton: {
    borderColor: '#FCD42B !important',
  },
});

export default useStyles;
