import { AnyAction, Reducer } from '../state.types';
import { DonationReduxState } from './state';

export const actionTypeName = 'DONATION__GET_CARD_TOKEN__START';

export type ActionGetCardTokenStart = AnyAction;

export const createAction = (): ActionGetCardTokenStart => ({
  type: actionTypeName,
});

export const reducer: Reducer<DonationReduxState, ActionGetCardTokenStart> = (
  state: DonationReduxState,
): DonationReduxState => {
  const nextState = {
    ...state,
    card: {
      ...state.card,
      threeDSecure: {
        ...state.card.threeDSecure,
        isLoading: true,
        token: null,
      },
    },
  };

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
