import React, {
  ChangeEvent, useCallback, useEffect, useState,
} from 'react';
import { debounce } from '@mui/material';

import { BottomButtons } from 'components';
import { DonationPledge } from 'types/pledge';

import Note from './note';
import LABELS, { KEYS, PLEDGE_KEYS } from './notes.keys';
import useStyles from './notes.style';
import TributeNote from './tributeNote';

interface NotesProps {
  notes: string;
  honoreeName: string | null;
  honoreeEmail: string | null;
  isTributeNote: boolean;
  notifyHonoree: boolean;
  updatePledgeNotes: (changes: Partial<DonationPledge>) => void;
  proceed: () => Promise<void>;
  goBack: () => void;
}

export const Notes = ({
  notes,
  honoreeName,
  honoreeEmail,
  isTributeNote,
  notifyHonoree,
  updatePledgeNotes,
  proceed,
  goBack,
}: NotesProps) => {
  const { classes } = useStyles();
  const [hasErrors, setHasErrors] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  useEffect(() => {
    const shouldCheckEmail = notifyHonoree && isTributeNote;
    const isNotePresent = Boolean(notes.trimEnd().length);
    const isNamePresent = isTributeNote ? Boolean(honoreeName?.trimEnd().length) : true;
    const isEmailPresent = shouldCheckEmail ? Boolean(honoreeEmail?.trimEnd().length) : true;
    const isEmailOk = shouldCheckEmail ? isEmailValid : true;

    const errorsPresent = [isNotePresent, isNamePresent, isEmailPresent, isEmailOk]
      .some(condition => condition === false);

    setHasErrors(errorsPresent);
  }, [notes, isTributeNote, notifyHonoree, honoreeName, honoreeEmail, isEmailValid]);

  useEffect(() => {
    if (!notifyHonoree) {
      setIsEmailValid(true);
      return;
    }

    if (!honoreeEmail) {
      return;
    }

    setIsEmailValid(KEYS.EMAIL_REGEX.test(honoreeEmail));
  }, [notifyHonoree]);

  const debounceEmailTest = useCallback(debounce((email) => {
    setIsEmailValid(KEYS.EMAIL_REGEX.test(email));
  }, KEYS.DEBOUNCE_TIMEOUT), []);

  const handlePledgeChange = (fieldName: keyof DonationPledge) => (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;

    if (fieldName === PLEDGE_KEYS.HONOREE_EMAIL) {
      debounceEmailTest(value);
    }

    updatePledgeNotes({
      [fieldName]: value,
    });
  };

  const handlePledgeBooleanChange = (fieldName: keyof DonationPledge) => (checked: boolean) => {
    updatePledgeNotes({
      [fieldName]: checked,
    });
  };

  const form = isTributeNote
    ? (
      <TributeNote
        honoreeEmail={honoreeEmail}
        isEmailValid={isEmailValid}
        honoreeName={honoreeName}
        notifyHonoree={notifyHonoree}
        notes={notes}
        onChange={handlePledgeChange}
        onBooleanChange={handlePledgeBooleanChange}
      />
    )
    : (
      <Note
        notes={notes}
        onChange={handlePledgeChange}
      />
    );

  return (
    <div className={classes.container}>
      {form}
      <BottomButtons
        isDisabledSubmit={hasErrors}
        onClickRight={proceed}
        onClickLeft={goBack}
        leftButtonText={LABELS.GO_BACK}
        rightButtonText={LABELS.PROCEED}
      />
    </div>
  );
};

export default Notes;
