import React, { useEffect, useMemo } from 'react';

import DonationWorkflowType from 'types/workflow';
import { componentSwitch } from 'utils/componentSwitch';

import { CardDonationSuccess } from './card/cardDonationSuccess';
import { DafDonationSuccess } from './daf/dafDonationSuccess';
import {
  CardDonationSuccess as OverlayCardDonationSuccess,
} from './overlayVariant/card/cardDonationSuccess';
import { DafDonationSuccess as OverlayDafDonationSuccess } from './overlayVariant/daf/dafDonationSuccess';
import {
  StockDonationSuccess as OverlayStockDonationSuccess,
} from './overlayVariant/stock/stockDonationSuccess';
import { StockDonationSuccess } from './stock/stockDonationSuccess';

export interface DonationSuccessProps {
  resetFlowWithDesiredDonationType: () => void;
  organizationName: string;
  workflowType: DonationWorkflowType;
  onInit: () => void;
  onShare: () => void;
  isPending: boolean;
}

export const DonationSuccess = (props: DonationSuccessProps) => {
  const {
    workflowType,
    onInit,
  } = props;

  useEffect(() => {
    onInit();
  }, []);

  const componentMap = useMemo(() => ({
    [DonationWorkflowType.Stock]: componentSwitch(StockDonationSuccess, OverlayStockDonationSuccess),
    [DonationWorkflowType.CreditCard]: componentSwitch(CardDonationSuccess, OverlayCardDonationSuccess),
    [DonationWorkflowType.Ach]: componentSwitch(CardDonationSuccess, OverlayCardDonationSuccess),
    [DonationWorkflowType.Daf]: componentSwitch(DafDonationSuccess, OverlayDafDonationSuccess),
    default: StockDonationSuccess,
  }), []);

  const SuccessComponent = componentMap[workflowType] || componentMap.default;

  return <SuccessComponent {...props} />;
};

export default DonationSuccess;
