import { MINIMAL_USD_VALUES } from 'config/workflow';

export const KEYS = {
  CURRENCY_FIELD: 'currency',
  ARE_NOTES_ENABLED: 'areNotesEnabled',
  ARE_TRIBUTES_ENABLED: 'areTributesEnabled',
  FUNDS_DESIGNATION: 'fundsDesignation',
  COVER_TRANSACTION_FEES: 'coverTransactionFees',
  PLEDGE_AMOUNTS: [45, 100, 200, 500, 2500, 5000],
  USD_DECIMAL_COUNT: 2,
  DEFAULT_CRYPTO_PRICE: 0,
  DEFAULT_DESIGNATION_VALUE: 'default',
};

export const LABELS = {
  USD_VALUE_PREFIX: '$',
  INCLUDING_FEES_PREFIX: 'incl. fees',
  TRANSACTION_FEES_TOOLTIP: 'Make your impact go even further by covering the processing fees of this donation',
  COVER_TRANSACTION_FEES: 'Cover transaction fees',
  CONTINUE_LABEL: 'Continue',
  BACK_BUTTON_LABEL: 'Previous',
  DONATE_LABEL: 'Donate',
  ENTER_AMOUNT_TITLE: 'Enter Donation Amount',
  SELECT_CRYPTO_TITLE: 'Select Your Crypto',
  FUNDS_DESIGNATIONS_TITLE: 'Select Impact Area',
  DONATION_NUMBER_VALIDATION: 'Donation amount cannot be 0, please enter another amount to continue',
  BTC: 'Bitcoin',
  ETH: 'Etherium',
  DEFAULT_DESIGNATION_VALUE: 'Default',
  MINIMAL_CARD_USD_VALUE_ERROR: `Minimum value for card donations is $${MINIMAL_USD_VALUES.FIAT}`,
  MINIMAL_ACH_USD_VALUE_ERROR: `Minimum value for ACH donations is $${MINIMAL_USD_VALUES.FIAT}`,
  NOTES_CHECKBOX_LABEL: 'Add a note',
  TRIBUTE_CHECKBOX_LABEL: 'Dedicate this donation',
  TOOLTIP_QUESTION_MARK: '(?)',
};

export const BASIC_CURRENCIES = {
  BTC: 'btc',
  ETH: 'eth',
  USD: 'USD',
  GBP: 'GBP',
};
