export const COLOR_SCHEMA = {
  GREY: '#928E9C',
  LIGHT_GREY: '#D5DDE3',
  BRIGHT_BLUE: '#447FB3',
  DARK_PRIMARY: '#344054',
  LIGHT_GREY_SHARP: '#87909E',
  GREY_BACKGROUND: '#F0F4F8',
  GREY_BACKGROUND_SECOND: '#E6EDF2',
  INSUBSTANTIAL_GREY: '#AAAAAA',
  PRIMARY: '#291B4F',
  SECONDARY: '#FCD42B',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  ERROR: '#f44336',
  ERROR_TWO: '#C24039',
  WARNING: '#FC6C2B',
  BACKGROUND: '#F4F9FE',
  WARNING_BACKGROUND: 'rgba(252, 212, 43, 0.20)',
  TRANSPARENT: 'transparent',
  PLAID_BLACK: '#111111',
};

export const COLORS = {
  ...COLOR_SCHEMA,
  INPUT: {
    BORDER: COLOR_SCHEMA.LIGHT_GREY,
    TEXT: COLOR_SCHEMA.SECONDARY,
    DISABLED: {
      BACKGROUND: COLOR_SCHEMA.BACKGROUND,
    },
    HIGHLIGHTED: {
      BORDER: COLOR_SCHEMA.PRIMARY,
    },
    SHADOW: 'rgba(41, 27, 79, 0.05)',
  },
  ICON: {
    PRIMARY: COLOR_SCHEMA.LIGHT_GREY,
    SECONDARY: COLOR_SCHEMA.GREY,
  },
  TEXT: {
    PRIMARY: COLOR_SCHEMA.PRIMARY,
    SECONDARY: COLOR_SCHEMA.GREY,
    INSUBSTANTIAL_GREY: COLOR_SCHEMA.INSUBSTANTIAL_GREY,
    NOTE_BACKGROUND: COLOR_SCHEMA.GREY_BACKGROUND,
    BRIGHT: COLOR_SCHEMA.BRIGHT_BLUE,
  },
  BUTTON: {
    SHADOW: 'rgba(41, 27, 79, 0.05)',
    BACKGROUND: {
      PRIMARY: COLOR_SCHEMA.SECONDARY,
      SECONDARY: 'linear-gradient(180deg, #F0F4F8 0%, rgba(240, 244, 248, 0) 100%)',
      DISABLED: COLOR_SCHEMA.GREY_BACKGROUND,
    },
    COLOR: {
      PRIMARY: COLOR_SCHEMA.PRIMARY,
      DISABLED: COLOR_SCHEMA.GREY,
    },
  },
};

export default COLORS;
