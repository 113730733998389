import React from 'react';

import { LABELS, LINKS } from './termsAndConditions.keys';
import useStyles from './termsAndConditions.styles';

interface CaptchaTermsAndConditionsProps {
  className?: string;
}

export const CaptchaTermsAndConditions = (
  { className }: CaptchaTermsAndConditionsProps,
) => {
  const { classes, cx } = useStyles();

  return (

    <div className={cx([classes.container, className])}>
      {LABELS.TERMS.START}
      {' '}
      <a className={classes.link} href={LINKS.PRIVACY_POLICY}>{LABELS.TERMS.PRIVACY_POLICY}</a>
      {' '}
      {LABELS.TERMS.CONJUNCTIVE}
      {' '}
      <a className={classes.link} href={LINKS.TERMS_AND_CONDITIONS}>{LABELS.TERMS.TERMS_AND_CONDITIONS}</a>
      {' '}
      {LABELS.TERMS.END}
    </div>
  );
};

export default CaptchaTermsAndConditions;
