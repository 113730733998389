import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ width: number }>()((theme, { width }) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  clearButton: {
    background: '#E6EDF2 !important',
    borderRadius: 5,
    border: '0 !important',
    fontSize: '13px !important',
    lineHeight: '20px !important',
    fontWeight: '600 !important',
    textTransform: 'unset !important' as 'unset',
    fontFamily: 'Poppins !important',
    width: 60,
    marginTop: '10px !important',
  },
  compactButton: {
    position: 'absolute!important' as 'absolute',
    /*
      crutchy button position changing to show the whole agreement
      container width = 256px, width = signature field width
      to position button near the borders of signature field we get the right offset
      of signature field: (containerWidth - sigFieldWidth) / 2
      and add 2 pixels of offset from signature field border

      bottom offset is 2 pixels + 1 pixel for border
    */
    right: (256 - width) / 2 + 2,
    bottom: 3,
  },
  signatureField: {
    border: '1px solid #C4C4C4',
    borderRadius: 5,
  },
  placeholder: {
    position: 'absolute',
    color: '#AAAAAA !important',
    fontSize: '13px !important',
    lineHeight: '18px !important',
  },
  label: {
    fontWeight: 500,
    fontSize: '13px !important',
    lineHeight: '15px !important',
    color: '#AAAAAA',
    textAlign: 'left',
    marginBottom: '4px !important',
  },
}));

export default useStyles;
