import React, {
  useState,
} from 'react';

import { BottomButtons, Button } from 'components';
import IconChevron from 'components/icon/chevron';
import IconPlaid from 'components/icon/plaid';
import OptionallyVisible from 'components/optionallyVisible';
import COLORS from 'theme/colors';
import { FEATURE_KEYS, featureMap } from 'utils/config';
import prepareLine from 'utils/string';

import MandateOverlay from '../donorInfo/components/mandateOvelay';
import { KEYS, LABELS } from './keys';
import { useStyles } from './styles';

interface MandateScreenProps {
  proceed: () => void;
  goBack: () => void;
  organizationName: string;
}

export const MandateScreen = ({
  proceed,
  goBack,
  organizationName,
}: MandateScreenProps) => {
  const { cx, classes } = useStyles();
  const [isMandateOverlayOpen, setMandateOverlayOpen] = useState<boolean>(false);
  const mandateShortLabel = prepareLine(LABELS.MANDATE_SHORT, {
    [KEYS.PLACEHOLDERS.ORGANIZATION_NAME]: organizationName,
  });

  const openOverlay = () => {
    setMandateOverlayOpen(true);
  };

  const closeOverlay = () => {
    setMandateOverlayOpen(false);
  };

  const isAchMandateEnabled = featureMap.get(FEATURE_KEYS.IS_ACH_MANDATE_ENABLED) as boolean;

  return (
    <div className={classes.scrollContainer}>
      <MandateOverlay open={isMandateOverlayOpen} closeOverlay={closeOverlay} oganizationName={organizationName} />
      <OptionallyVisible visible={!isMandateOverlayOpen}>
        <>
          <div className={cx(classes.header, {
            [classes.noMandateHeader]: !isAchMandateEnabled,
          })}
          >
            {LABELS.MANDATE_HEADER}
          </div>
          <OptionallyVisible visible={!isAchMandateEnabled}>
            <div className={cx(classes.mandateShort, classes.pressPlaidLabel)}>
              {LABELS.PRESS_PLAID_LABEL}
            </div>
          </OptionallyVisible>
          <OptionallyVisible visible={isAchMandateEnabled}>
            <>
              <div className={classes.mandateShort}>
                {mandateShortLabel}
              </div>
              <div className={classes.mandateButtonContainer}>
                <Button
                  classes={{
                    root: classes.overlayOpenButton,
                  }}
                  onClick={openOverlay}
                >
                  <div className={classes.overlayOpenButtonLabel}>
                    <span>
                      {LABELS.MANDATE}
                    </span>
                    <IconChevron width={10} color={COLORS.PRIMARY} className={classes.chevronIcon} />
                  </div>
                </Button>
              </div>
            </>
          </OptionallyVisible>
          <div>
            <Button
              onClick={proceed}
              className={classes.plaidButton}
            >
              {LABELS.PLAID_BUTTON_TEXT}
              <IconPlaid color={COLORS.WHITE} className={classes.plaidLogo} />
            </Button>
          </div>
        </>
      </OptionallyVisible>
      <BottomButtons
        onClickLeft={goBack}
        leftButtonText={LABELS.LEFT_BUTTON_TEXT}
      />
    </div>
  );
};

export default MandateScreen;
