import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

const useStyles = makeStyles()({
  page: {
    padding: '30px 30px 45px 30px',
  },
  signatureField: {
    boxShadow: '0px 3px 12px rgba(41, 27, 79, 0.05)',
    marginBottom: 24,
  },
  agreement: {
    fontFamily: 'Noto Sans !important',
    fontWeight: '400 !important',
    fontSize: '14px !important',
    lineHeight: '20px !important',
    color: COLORS.GREY,
    textAlign: 'center',
    marginBottom: '26px !important',
    letterSpacing: '0.01em !important',
  },
  showMore: {
    fontFamily: 'Noto Sans !important',
    fontWeight: '500 !important',
    fontSize: '14px !important',
    lineHeight: '20px !important',
    textAlign: 'center',
    marginBottom: '26px !important',
    letterSpacing: '0.01em !important',
    padding: 0,
    border: 0,
    backgroundColor: 'transparent',
    marginLeft: 5,
    display: 'inline',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > button': {
      '&:first-of-type': {
        marginRight: 10,
      },
      '&:last-child': {
        marginLeft: 10,
      },
    },
  },
  nextButton: {
    '&:disabled, &.Mui-disabled': {
      border: 0,
      background: '#F0F4F8 !important',
      color: '#928E9C !important',
    },
  },
  transferNotice: {
    fontFamily: 'Noto Sans !important',
    fontSize: '16px !important',
    lineHeight: '28px !important',
    textAlign: 'center',
    marginBottom: '39px !important',
    color: `${COLORS.TEXT.PRIMARY} !important`,
  },
});

export default useStyles;
