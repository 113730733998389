import intersection from 'lodash/intersection';
import { Dispatch } from 'redux';

import { API, Endpoints } from 'api';
import ValidationError from 'api/errors/validationError';
import submitPledgeFail from 'state/donation/submitPledge.fail';
import submitPledgeStart from 'state/donation/submitPledge.start';
import submitPledgeSuccess, { ActionSubmitPledgeSuccess } from 'state/donation/submitPledge.success';
import setPledgeError from 'state/pledge/setError';
import { DonorInfo } from 'types/donor';
import { FundraiserId } from 'types/fundraiser';
import { OrganizationInfo } from 'types/organization';
import { DonationPledge } from 'types/pledge';
import { CryptoDonationWorkflowStep, DonationWorkflowType } from 'types/workflow';
import detectDocumentOrigin from 'utils/detectDocumentOrigin';
import { pushDataLayerEvent } from 'utils/gtm';
import GTMEventType from 'utils/gtm/types';
import eventDataFactory from 'utils/gtm/util';
import removeNullValues from 'utils/objects/removeNullValues';
import getApiUserUuid from 'utils/queryParams';

import setDonorInfo from '../../donor/setDonorInfo';
import changeStep from '../changeStep';
import setError from '../setError';

interface SubmitPledgeParams {
  pledge: DonationPledge;
  organization: OrganizationInfo;
  donor: DonorInfo;
  fundraiserId: FundraiserId;
  captchaToken: string | null;
  externalId?: string | null;
}

const preparePledge = ({
  pledge,
  organization,
  donor,
  documentOrigin,
  fundraiserId,
  captchaToken,
  apiUserUuid,
  externalId,
}) => removeNullValues({
  'Address 1': donor.address1 || '',
  'Address 2': donor.address2 || '',
  Anonim: donor.isAnonymous,
  City: donor.city || '',
  Country: donor.country || '',
  Email: donor.email || '',
  'First name': donor.firstName || '',
  'Last name': donor.lastName || '',
  State: donor.state || '',
  Zipcode: donor.zipCode || '',
  Notes: pledge.notes,
  organizationId: organization.id,
  origin: documentOrigin,
  pledgeAmount: pledge.amount,
  pledgeCurrency: pledge.currency,
  fundsDesignation: pledge.fundsDesignation || undefined,
  campaignId: pledge.campaignId || null,
  fundraiserId,
  receiptEmail: donor.receiptEmail || '',
  captchaToken,
  apiUserUuid,
  honoreeEmail: pledge.notifyHonoree ? pledge.honoreeEmail : null,
  honoreeName: pledge.honoreeName,
  externalId,
  isCharityCommunicationAllowed: donor.isCharityCommunicationAllowed,
  isDonorRetired: organization.areEmployerDetailsRequired ? donor?.isDonorRetired : null,
  employer: organization.areEmployerDetailsRequired ? donor?.employer : null,
  occupation: organization.areEmployerDetailsRequired ? donor?.occupation : null,
});

const messageReplaceMap = new Map([
  ['"pledgeAmount" must be a safe number', 'Pledge amount is too large'],
]);

const KEYS = {
  DEPOSIT_ADDRESS_ERROR:
    'Temporary Maintenance: We are currently undergoing maintenance, please try again later.',
  PLEDGE_FIELDS: ['pledgeAmount'],
};

const replaceErrorMessages = (errors: Record<string, string>) => Object.entries(errors)
  .map(([field, error]) => [field, messageReplaceMap.get(error) || error])
  .reduce((object, [field, error]) => ({
    ...object,
    [field]: error,
  }), {});

export const submitPledge = ({
  pledge,
  organization,
  donor,
  fundraiserId,
  captchaToken,
  externalId,
}: SubmitPledgeParams) => async (dispatch: Dispatch, getState) => {
  const documentOrigin = await detectDocumentOrigin;
  const donorInfo = donor.isAnonymous ? { isAnonymous: true, receiptEmail: donor.receiptEmail } : donor;
  const apiUserUuid = getApiUserUuid();

  const preparedPledge = preparePledge({
    donor: donorInfo,
    organization,
    pledge,
    documentOrigin,
    fundraiserId,
    captchaToken,
    apiUserUuid,
    externalId,
  });

  try {
    dispatch(submitPledgeStart.createAction());
    const response = await API.post(Endpoints.crypto.donationCrypto, preparedPledge);
    const {
      depositAddress,
      fallbackAddressUsed,
      destinationTag,
      qrValue,
      donationUuid,
    } = response?.data || {};

    if (depositAddress || fallbackAddressUsed) {
      const pledgeSuccessActionPayload = {
        donationAddress: depositAddress,
        fallbackAddressUsed,
        destinationTag,
        qrValue,
        donationUuid,
      };
      dispatch(changeStep.createAction(CryptoDonationWorkflowStep.Donate));
      dispatch(submitPledgeSuccess.createAction(pledgeSuccessActionPayload as ActionSubmitPledgeSuccess));

      const state = getState();
      pushDataLayerEvent(
        eventDataFactory(
          GTMEventType.TaxReceipt,
          DonationWorkflowType.Crypto,
          { state, email: donor.receiptEmail || donor.email },
        ),
      );
      dispatch(setDonorInfo.createAction(donor));
      return;
    }

    throw KEYS.DEPOSIT_ADDRESS_ERROR;
  } catch (error: any) {
    const { message, details } = error ?? {};
    dispatch(submitPledgeFail.createAction());

    if (error instanceof ValidationError) {
      let errors = error.getValidationErrorDetails();
      if (errors) {
        errors = replaceErrorMessages(errors);
      }

      dispatch(setPledgeError.createAction(errors));
      const isPledgeError = Boolean(intersection(Object.keys(errors as Object), KEYS.PLEDGE_FIELDS).length);
      if (isPledgeError) {
        dispatch(changeStep.createAction(CryptoDonationWorkflowStep.Pledge));
      }
      return;
    }

    const errorMessage = details?.errorMessage || message || KEYS.DEPOSIT_ADDRESS_ERROR;
    dispatch(setError.createAction(errorMessage));
  }
};

export default submitPledge;
