import { AnyAction, Reducer } from '../state.types';
import { CurrenciesReduxState } from './state';

export const actionTypeName = 'CURRENCIES__FETCH_CURRENCIES__START';

export const SORT_FIELD = 'code';

export type ActionFetchCurrenciesStart = AnyAction;

export const createAction = (): ActionFetchCurrenciesStart => ({
  type: actionTypeName,
});

export const reducer: Reducer<CurrenciesReduxState, ActionFetchCurrenciesStart> = (
  state: CurrenciesReduxState,
): CurrenciesReduxState => {
  const nextState: CurrenciesReduxState = {
    ...state,
    isLoading: true,
  };

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
