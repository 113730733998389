export const KEYS = {
  REJECTED_BY_USER_CODE: 4001,
  SUCCESS_ANIMATION_DURATION: 2500,
};

export const LABELS = {
  CONNECTING: 'Connecting...',
  PROCESSING: 'Processing...',
  CONNECTED: 'Connected!',
  PROCESSED: 'Processed!',
  TRY_AGAIN: 'Try Again',
  DONE: 'Done',
  ERROR: 'Error',
  CONNECTION_ERROR: 'Connection attempt failed',
  REJECTED_BY_USER_CODE: 4001,
  DONATION_COMPLETE: 'Complete',
  THANK_YOU_MESSAGE: 'Thank you for donating!',
  START_OVER: 'START OVER',
  REJECTED: 'Rejected',
  REJECTED_MESSAGE: 'Transaction Rejected in MetaMask',
  SUCCESS_ANIMATION_DURATION: 2500,
  PREVIOUS: 'PREVIOUS',
  CLICK_WHEN_READY: 'Click completed once your donation is sent',
  COMPLETED_BUTTON_TEXT: 'COMPLETED',
};

export default KEYS;
