import React from 'react';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, CircularProgress } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import OptionallyVisible from 'components/optionallyVisible';

import useStyles from './styles';

const ColorButton = withStyles(Button, {
  root: {
    flex: 1,
    fontSize: '13px',
    color: 'red',
    backgroundColor: '#291B4F',
    paddingLeft: 6,
    paddingRight: 6,
    '&:hover': {
      '& svg': {
        color: '#FCD42B',
      },
    },
    borderBottomRightRadius: 5,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 5,
  },
});

const LeftButton = withStyles(Button, {
  root: {
    flex: 1,
    fontSize: '13px',
    paddingLeft: 6,
    paddingRight: 6,
  },
});

export interface BottomButtonsProps {
  isDisabledSubmit?: boolean;
  rightButtonText?: string;
  leftButtonText: string;
  onClickRight?: () => void;
  onClickLeft: () => void;
  rightIcon?: [IconPrefix, IconName];
  rightButtonLoading?: boolean;
}

export const BottomButtons = ({
  rightButtonText,
  leftButtonText,
  onClickLeft,
  onClickRight,
  isDisabledSubmit,
  rightIcon,
  rightButtonLoading = false,
}: BottomButtonsProps) => {
  const { classes, cx } = useStyles();
  return (
    <div className={classes.container}>
      <ButtonGroup
        className={classes.ButtonGroup}
        fullWidth
        variant="contained"
        size="large"
        aria-label="full width large contained button group"
      >
        <OptionallyVisible visible={Boolean(rightButtonText)}>
          <ColorButton
            type="submit"
            disabled={isDisabledSubmit || rightButtonLoading}
            onClick={onClickRight}
            className={classes.rightButton}
          >
            <OptionallyVisible visible={rightButtonLoading}>
              <CircularProgress size={22} color="inherit" />
            </OptionallyVisible>
            <OptionallyVisible visible={!rightButtonLoading}>
              <>
                {rightButtonText}
                <span className={classes.iconWrapRight}>
                  <FontAwesomeIcon
                    icon={rightIcon || ['fas', 'chevron-circle-right']}
                    className={cx({
                      [classes.iconDisabled]: isDisabledSubmit || rightButtonLoading,
                    })}
                  />
                </span>
              </>
            </OptionallyVisible>
          </ColorButton>
        </OptionallyVisible>
        <LeftButton
          onClick={onClickLeft}
          className={cx({
            [classes.leftButton]: true,
            [classes.leftButtonOnly]: !rightButtonText,
          })}
        >
          <span className={classes.iconWrapLeft}>
            <FontAwesomeIcon icon={['fas', 'chevron-circle-left']} />
          </span>
          {leftButtonText}
        </LeftButton>
      </ButtonGroup>
    </div>
  );
};

BottomButtons.defaultProps = {
  isDisabledSubmit: false,
  rightIcon: '',
};

export default BottomButtons;
