import React from 'react';
import { Box, Button } from '@mui/material';

import IconCross from 'components/icon/cross';
import { Logo } from 'components/logo';
import OptionallyVisible from 'components/optionallyVisible';
import { OrganizationInfo } from 'types/organization';

import useStyles from './styles';

interface ErrorScreenProps {
  error: string;
  hasBackButton: boolean;
  goBack: () => void;
  title?: string;
  organization: OrganizationInfo;
}

const KEYS = {
  BACK_BUTTON_TEXT: 'Go Back',
  TITLE: 'Error!',
};

const ErrorScreen = ({
  title,
  error,
  hasBackButton,
  goBack,
  organization,
}: ErrorScreenProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <OptionallyVisible visible={!organization.isPCDEnabled}>
        <Logo />
      </OptionallyVisible>
      <OptionallyVisible visible={organization.isPCDEnabled}>
        <IconCross />
      </OptionallyVisible>
      <h3 className={classes.title}>{title || KEYS.TITLE}</h3>
      <h5 className={classes.label}>{error}</h5>
      <OptionallyVisible visible={hasBackButton}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <Button
            onClick={goBack}
            className={classes.backButton}
          >
            {KEYS.BACK_BUTTON_TEXT}
          </Button>
        </Box>
      </OptionallyVisible>
    </div>
  );
};

export default ErrorScreen;
