import React, { ChangeEvent } from 'react';
import { TextField } from '@mui/material';

import { DonationPledge } from 'types/pledge';

import { KEYS, LABELS, PLEDGE_KEYS } from './notes.keys';
import useStyles from './notes.style';

export interface NoteProps {
  notes: string;
  onChange: (fieldName: keyof DonationPledge) => (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

export const Note = ({
  notes,
  onChange,
}: NoteProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.form}>
      <div>
        <h6 className={classes.inputTitle}>
          {LABELS.NOTE.INPUT_TITLE}
        </h6>
        <TextField
          value={notes}
          onChange={onChange(PLEDGE_KEYS.NOTES)}
          placeholder={LABELS.NOTE.INPUT_PLACEHOLDER}
          type="text"
          name={LABELS.NOTE.INPUT_NAME}
          variant="outlined"
          className={classes.textInput}
          multiline
          maxRows={KEYS.NOTE.MAX_INPUT_ROWS}
          minRows={KEYS.NOTE.INPUT_ROWS}
          inputProps={{
            maxLength: KEYS.MAX_NOTE_LENGTH,
          }}
        />
      </div>
    </div>
  );
};

export default Note;
