import { AnyAction } from '../state.types';
import { DonationReduxState } from './state';

export const actionTypeName = 'DONATIONS__SET_3DS_ERROR';

export type ActionSetError = AnyAction & {
  error: Record<string, string> | undefined;
}

export const createAction = (error: Record<string, string> | undefined) => ({
  type: actionTypeName,
  error,
});

export const reducer = (
  state: DonationReduxState,
  { error }: ActionSetError,
): DonationReduxState => ({
  ...state,
  card: {
    ...state.card,
    threeDSecure: {
      ...state.card.threeDSecure,
      isLoading: state.card.threeDSecure?.isLoading || false,
      token: state.card.threeDSecure?.token || null,
      error,
    },
  },
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
