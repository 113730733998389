import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

const useStyles = makeStyles()({
  wrapper: {
    position: 'relative',
  },
  button: {
    width: '100%',
    height: 50,
    padding: 10,
    background: COLORS.BLACK,
    border: 'none',
    borderRadius: 5,
    outline: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: COLORS.WHITE,
    fontSize: 16,
    fontWeight: 500,
    fontFamily: '-apple-system',
  },
  icon: {
    marginRight: 3.5,
  },
});

export default useStyles;
