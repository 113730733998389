import React from 'react';

import { COLORS } from 'theme/colors';

import { IconProps } from './icon.types';

const path = [
  [
    'M11.7212 1.9613C11.3499 1.58952 10.747 1.58976 10.3752 1.9613L4.31744 8.0193L1.62503',
    '5.32691C1.25325 4.95513 0.650609 4.95513 0.278832 5.32691C-0.0929441 5.69868 -0.0929441',
    '6.30133 0.278832 6.6731L3.6442 10.0385C3.82997 10.2242 4.07357 10.3174 4.31718 10.3174C4.5608',
    '10.3174 4.80463 10.2245 4.9904 10.0385L11.7212 3.30747C12.0929 2.93595 12.0929 2.33305 11.7212 1.9613Z',
  ].join(' '),
];

export const IconCheck = ({
  width = 24,
  height = 24,
  color = COLORS.GREY,
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="-3 -3 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {path.map(item => <path d={item} fill={color} />)}
  </svg>
);

export default IconCheck;
