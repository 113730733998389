import React, { useContext, useEffect } from 'react';
import { Container, Typography } from '@mui/material';

import loadingLogo from 'assets/images/simple-main-logo.png';
import { BottomButtonsNext } from 'components';
import { CaptchaContext } from 'components/captcha/captchaContext';
import Loading from 'components/loading';
import { FiatPaymentMethod } from 'types/card';

import { KEYS } from './keys';
import { useStyles } from './styles';

interface CardDonationProps {
  isSubmitting: boolean;
  method: FiatPaymentMethod;
  pay: (captchaToken: string | null) => void;
  resetWidget: () => void;
}

export const CardDonation = ({
  isSubmitting,
  method,
  pay,
  resetWidget,
}: CardDonationProps) => {
  const { classes } = useStyles();
  const { execute } = useContext(CaptchaContext);

  useEffect(() => {
    verifyCaptchaAndProceed();
  }, []);

  const verifyCaptchaAndProceed = async () => {
    if (method === FiatPaymentMethod.ApplePay) {
      pay(null);
      return;
    }

    const captchaToken = await execute();

    pay(captchaToken);
  };

  if (isSubmitting) {
    return <Loading text={KEYS.LOADING_ADDRESS} />;
  }

  return (
    <>
      <Container className={classes.donationContainerWrapper}>
        <div className={classes.loadingContainer}>
          <div className={classes.imageLoadingContainer}>
            <img src={loadingLogo} alt="processing" className={classes.loadingImage} />
          </div>
          <Typography variant="h6" className={classes.loadingText}>
            {KEYS.PROCESSING}
          </Typography>
        </div>
      </Container>
      <BottomButtonsNext
        rightIcon={['fas', 'sync']}
        isDisabledSubmit={false}
        onClickRight={resetWidget}
        rightButtonText={KEYS.RIGHT_BUTTON_TEXT}
      />
    </>
  );
};

export default CardDonation;
