import React from 'react';

import DonationSuccessLayout from 'pages/common/donationSuccess/components/donationSuccessLayout';

export interface DafDonationSuccessProps {
  resetFlowWithDesiredDonationType: () => void;
  organizationName: string;
  onShare: (platform: string) => void;
}

export const DafDonationSuccess = ({
  resetFlowWithDesiredDonationType,
  organizationName,
  onShare,
}: DafDonationSuccessProps) => (
  <DonationSuccessLayout
    restart={resetFlowWithDesiredDonationType}
    onShare={onShare}
    organizationName={organizationName}
    additionalContent={null}
    withSocialLinks
  />
);

export default DafDonationSuccess;
