import { AnyAction } from '../state.types';
import { DonationReduxState } from './state';

export const actionTypeName = 'WORKFLOW__SUBMIT_STOCKS_DONATION_PLEDGE__SUCCESS';

export type ActionSubmitStockDonationPledgeSuccess = AnyAction & {
  donationUuid: string;
}

export const createAction = ({ donationUuid }) => ({
  type: actionTypeName,
  donationUuid,
});

export const reducer = (
  state: DonationReduxState,
  action: ActionSubmitStockDonationPledgeSuccess,
): DonationReduxState => ({
  ...state,
  isSubmitting: false,
  stocks: {
    donationUuid: action.donationUuid,
  },
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
