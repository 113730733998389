import { FundraiserId } from 'types/fundraiser';

import { AnyReduxState } from '../state.types';

export type FundraiserReduxState = AnyReduxState & {
  fundraiserId: FundraiserId;
}

export const initialState = (): FundraiserReduxState => ({
  fundraiserId: null,
});
