import { environmentConfig } from 'environment/environment.config';

export const RUNTIME_LOCAL_STORAGE_KEYS = {
  IS_PCD_ENABLED: 'isPCDEnabled',
  COUNTRY: 'country',
  COUNTRY_ID: 'countryId',
};

export const runtimeLocalStorage = new Map();
runtimeLocalStorage.set(RUNTIME_LOCAL_STORAGE_KEYS.IS_PCD_ENABLED, environmentConfig.isPCDEnabled);
runtimeLocalStorage.set(RUNTIME_LOCAL_STORAGE_KEYS.COUNTRY, '');
runtimeLocalStorage.set(RUNTIME_LOCAL_STORAGE_KEYS.COUNTRY_ID, '');

export default runtimeLocalStorage;
