import { makeStyles } from 'tss-react/mui';

import { breatheAnimation } from 'components/loading/styles';
import COLORS from 'theme/colors';

export const useStyles = makeStyles()({
  scrollContainer: {
    padding: '30px 30px 45px 30px',
  },
  nextButton: {
    flex: 1,
    height: 50,
    fontSize: 16,
    borderRadius: '5px !important',
    color: COLORS.PRIMARY,
    lineHeight: '24px',
    background: '#FCD42B !important',
    boxShadow: 'none !important',
    textTransform: 'none !important' as 'none',
    '&:disabled, &.Mui-disabled': {
      border: 0,
      background: '#F0F4F8 !important',
      color: '#928E9C !important',
    },
  },
  inputFormControl: {
    marginBottom: '20px !important',
    '& .MuiFormHelperText-root': {
      fontFamily: 'Poppins',
      fontWeight: 600,
      color: COLORS.PRIMARY,
      margin: 0,
      marginTop: 5,
      fontSize: 14,
      lineHeight: '18px',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: COLORS.PRIMARY,
    },
  },
  defaultInput: {
    width: '100%',
    marginTop: '0px !important',
    '& .MuiInputBase-input': {
      height: 25,
      fontSize: 16,
      lineHeight: '23px',
      color: COLORS.PRIMARY,
      fontFamily: 'Noto Sans',
      padding: '13px 20px !important',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '&.Mui-disabled': {
        background: COLORS.INPUT.DISABLED.BACKGROUND,
      },
    },
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${COLORS.INPUT.BORDER}`,
      borderColor: `${COLORS.INPUT.BORDER} !important`,
    },
    [`& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
    & .MuiOutlinedInput-root:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline,
    & .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline`]: {
      border: `2px solid ${COLORS.SECONDARY}`,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 9px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& fieldset': {
      borderRadius: 5,
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: 16,
    },
    '& .MuiFormControl-marginDense': {
      marginTop: 1,
      marginBottom: 1,
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '20px !important',
    },
  },
  customSelectWrapper: {
    '&.MuiFormControl-root': {
      display: 'flex',
      margin: '0 auto 20px',
      '& .MuiInputBase-input': {
        fontSize: '16px',
        lineHeight: '23px',
        padding: '13px 20px',
        fontFamily: 'Noto Sans',
        fontWeight: 400,
        color: COLORS.PRIMARY,
        '&::focused': {
          background: 'transparent',
        },
      },
    },
    '& .MuiFormHelperText-root': {
      fontFamily: 'Poppins',
      fontWeight: 600,
      color: COLORS.PRIMARY,
      margin: 0,
      marginTop: 5,
      fontSize: 14,
      lineHeight: '18px',
    },
  },
  root: {
    [`&:hover .MuiOutlinedInput-notchedOutline,
    &.Mui-focused .MuiOutlinedInput-notchedOutline`]: {
      borderColor: '#FCD42B !important',
      borderRadius: 5,
      paddingLeft: 5,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D5DDE3',
      borderRadius: 5,
      paddingLeft: 5,
    },
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 300,
  },
  loadingText: {
    marginTop: '10px !important',
  },
  imageLoadingContainer: {
    height: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingImage: {
    animation: `${breatheAnimation} 1.5s linear infinite`,
  },
  '@keyframes breathe': {
    '20%, 80%': {
      transform: 'scale(1)',
      opacity: 1,
    },
    '30%, 70%': {
      transform: 'scale(1.1)',
      opacity: 0.5,
    },
  },
  container: {
    width: 100,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80%',
    '&.MuiContainer-root': {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 6,
    },
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
    },
  },
  fieldHeader: {
    fontSize: 13,
    fontWeight: 500,
    marginBottom: 4,
    marginTop: 10,
    width: '100%',
    textAlign: 'left',
    color: '#aaa',
  },
  mainInput: {
    width: '100%',
    fontSize: 13,
    '& .MuiOutlinedInput-input': {
      paddingLeft: '20px !important',
    },
  },
  inputWrapper: {
    display: 'flex',
    width: 220,
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  input: {
    textAlign: 'left',
    width: '100%',
    margin: '0px !important',
  },
  select: {
    '& .MuiSelect-root': {
      fontSize: '13px !important',
    },
    '& .MuiOutlinedInput-input': {
      paddingLeft: '20px !important',
    },
    textAlign: 'left',
    width: '100%',
    margin: '0px !important',
  },
  selectPlaceholder: {
    fontSize: '13px !important',
    marginLeft: 9,
    marginTop: -9,
    opacity: 0.9,
  },
  menuItem: {
    fontSize: '13px !important',
  },
  errorHelperText: {
    color: COLORS.PRIMARY,
  },
});

export default useStyles;
