import React from 'react';

import backgroundPattern from 'assets/images/background-pattern.svg';
import heartsSrc from 'assets/images/filled-hearts.svg';
import { Button } from 'components';
import IconStars from 'components/icon/starsIcon';
import OptionallyVisible from 'components/optionallyVisible';
import COLORS from 'theme/colors';

import {
  getLinkButtons,
  KEYS,
  LABELS,
} from '../keys';
import { DonationSuccessLayoutProps } from '../types';
import useStyles from './style';

export const DonationSuccessLayout = ({
  restart,
  additionalContent,
  withSocialLinks = false,
  organizationName,
  onShare,
}: DonationSuccessLayoutProps) => {
  const { classes, cx } = useStyles();

  const links = getLinkButtons(organizationName);

  const handleLinkClick = link => () => {
    window.open(link.link, '_blank');
    onShare?.(link.key);
  };

  return (
    <div className={classes.scrollContainer}>
      <div className={classes.mainContainer}>
        <section className={cx(classes.section, classes.headerSection)}>
          <img src={heartsSrc} className={classes.headerImage} alt={LABELS.ALT.HEADER} />
          <div className={classes.titleContainer}>
            <h2 className={classes.title}>
              {LABELS.PAGE_HEADER_TEXT}
            </h2>
            <h3 className={classes.subtitle}>
              {LABELS.PAGE_SUB_HEADER_TEXT}
            </h3>
          </div>
        </section>
        <div className={classes.contentBackgroundOuterContainer}>
          <div className={classes.contentBackgroundContainer}>
            <div className={classes.contentBackgroundOverlay} />
            <img
              src={backgroundPattern}
              className={classes.contentBackground}
              alt={KEYS.BACKGROUND_ALT}
            />
          </div>
        </div>
        <section className={cx(classes.section, classes.contentSection)}>
          {additionalContent}
        </section>
        <OptionallyVisible visible={withSocialLinks}>
          <section className={classes.socialSection}>
            <div className={classes.shareHeader}>{LABELS.SHARE_HEADER}</div>
            <div className={classes.shareMessage}>
              <span>
                {LABELS.SHARE_MESSAGE}
                <IconStars className={classes.iconStars} color={COLORS.PRIMARY} />
              </span>
            </div>
            <div className={classes.socialButtonsRow}>
              {links.map(link => (
                <button
                  onClick={handleLinkClick(link)}
                  className={classes.socialButton}
                  type="button"
                  key={link.key}
                  style={{
                    backgroundColor: link.backgroundColor,
                  }}
                  title={link.linkDescription}
                >
                  <img
                    src={link.icon}
                    alt={link.key}
                    className={classes.icon}
                    style={{
                      width: link?.iconWidth,
                    }}
                  />
                </button>
              ))}
            </div>
          </section>
        </OptionallyVisible>
        <Button
          onClick={restart}
          classes={{
            root: classes.proceedButton,
          }}
        >
          {LABELS.PROCEED_BUTTON_TEXT}
        </Button>
      </div>
    </div>
  );
};

export default DonationSuccessLayout;
