import React from 'react';
import {
  Error as ErrorIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { useField } from 'formik';

import OptionallyVisible from 'components/optionallyVisible';

const InvalidStateIcons = {
  Required: <ErrorIcon color="error" fontSize="small" />,
  Invalid: <WarningIcon color="error" fontSize="small" />,
};

interface EmailFieldProps {
  label: string;
  name: string;
  className?: string;
  required?: boolean;
}

export const EmailField = (props: EmailFieldProps) => {
  const [field, meta] = useField(props);
  const hasError = Boolean(meta.touched && meta.error);
  const { error } = meta;
  const errorContent = error ? InvalidStateIcons[error] : '';

  return (
    <TextField
      {...field}
      {...props}
      margin="dense"
      type="email"
      error={hasError}
      fullWidth
      variant="outlined"
      InputProps={{
        endAdornment: (
          <OptionallyVisible visible={hasError}>
            <InputAdornment position="end">{errorContent}</InputAdornment>
          </OptionallyVisible>
        ),
      }}
    />
  );
};

EmailField.defaultProps = {
  required: false,
  className: '',
};

export default EmailField;
