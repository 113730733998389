import { AnyAction } from 'state/state.types';

import { ChariotReduxState } from './state';

export const actionTypeName = 'CHARIOT__FETCH_CONNECTION_ID__SUCCESS';

type ActionFetchConnectionIdSuccess = AnyAction & {
  id: string;
}

export const createAction = (id: string) => ({
  type: actionTypeName,
  id,
});

export const reducer = (
  state: ChariotReduxState,
  { id }: ActionFetchConnectionIdSuccess,
): ChariotReduxState => ({
  ...state,
  connection: {
    ...state.connection,
    id,
    isLoading: false,
  },
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
