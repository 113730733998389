import React, { useMemo } from 'react';
import { Box, Tooltip } from '@mui/material';

import { Checkbox } from 'components/checkbox';
import { OptionallyVisible } from 'components/optionallyVisible';
import OptionsCrypto from 'components/optionsCrypto/optionsCrypto';
import Currency, { FiatCurrency } from 'types/currency';
import Frequency from 'types/frequency';
import { DonationPledge } from 'types/pledge';
import { locators, QALocator } from 'utils/tests/QA';

import CryptoCurrencyAmountBox from './currencyAmountBox.crypto';
import FiatCurrencyAmountBox from './currencyAmountBox.fiat';
import FundsDesignations from './fundsDesignations';
import { SelectOption } from './fundsDesignations/types';
import { KEYS, LABELS } from './pledge.keys';
import { OrganizationWithFees } from './pledge.types';
import useStyles from './styles';

export interface PledgeFormProps {
  pledge: DonationPledge;
  onAmountChange: (value: number) => void;
  calculateFromFiat: boolean;
  swapCurrencies: () => void;
  onChange: (pledge: Partial<DonationPledge>) => void;
  currencies: Currency[];
  organization: OrganizationWithFees;
  cryptoDropdownTouched: boolean;
  setDropdownTouched: (value: boolean) => void;
  isFiatDonation: boolean;
  error: Record<string, string> | null;
  frequency: Frequency;
}

export const PledgeForm = ({
  pledge,
  onAmountChange,
  onChange,
  swapCurrencies,
  calculateFromFiat,
  currencies,
  organization,
  cryptoDropdownTouched,
  setDropdownTouched,
  isFiatDonation,
  error,
}: PledgeFormProps) => {
  const { classes } = useStyles();
  const shouldShowTributesControl = organization.areNotesEnabled && organization.areTributesEnabled;

  const fundsDesignationOptions = useMemo(() => {
    const fundsDesignations = organization.fundsDesignations || [];

    return fundsDesignations.map(({ value, uuid }) => ({
      label: value,
      id: uuid,
    }));
  }, [organization.fundsDesignations]);

  const handleChange = (field: string) => (value: string | number | boolean) => {
    onChange({ [field]: value });
  };

  const currencyAmountBox = isFiatDonation
    ? (
      <FiatCurrencyAmountBox
        showSubPrice={pledge.coverTransactionFees}
        onAmountChange={onAmountChange}
        fees={organization.fee}
        pledge={pledge}
        error={error?.amount || error?.pledgeAmount}
      />
    )
    : (
      <CryptoCurrencyAmountBox
        swapCurrencies={swapCurrencies}
        onAmountChange={onAmountChange}
        pledge={pledge}
        calculateFromFiat={calculateFromFiat}
        fiatCurrency={organization.payoutCurrency || FiatCurrency.USD}
        error={error?.amount || error?.pledgeAmount}
      />
    );

  const hasDesignationOptions = fundsDesignationOptions.length > 0;
  const areDesignationsEnabled = Boolean(organization.fundsDesignations)
    && hasDesignationOptions;

  return (
    <div className={classes.contentContainer} {...QALocator(locators.components.common.pledgeForm)}>
      <div className={classes.form}>
        <OptionallyVisible visible={!isFiatDonation}>
          <div className={classes.inputButtonsWrapper}>
            <h6 className={classes.fieldHeader}>{LABELS.SELECT_CRYPTO_TITLE}</h6>
            <OptionsCrypto
              handleChange={handleChange(KEYS.CURRENCY_FIELD)}
              selectedCurrency={pledge.currency}
              currencies={currencies}
              isOnlyInput
              isTouched={cryptoDropdownTouched}
              setTouched={setDropdownTouched}
            />
          </div>
        </OptionallyVisible>
        {currencyAmountBox}
        <OptionallyVisible visible={areDesignationsEnabled}>
          <div className={classes.inputButtonsWrapper}>
            <h6 className={classes.fieldHeader}>{LABELS.FUNDS_DESIGNATIONS_TITLE}</h6>
            <FundsDesignations
              options={fundsDesignationOptions as SelectOption[]}
              onChange={handleChange(KEYS.FUNDS_DESIGNATION)}
              value={pledge.fundsDesignation || ''}
            />
          </div>
        </OptionallyVisible>
        <OptionallyVisible visible={isFiatDonation}>
          <Box
            className={classes.checkboxContainer}
            {...QALocator(locators.components.fiat.feesCheckbox)}
          >
            <Checkbox
              onChange={handleChange(KEYS.COVER_TRANSACTION_FEES)}
              value={pledge.coverTransactionFees}
              name={KEYS.COVER_TRANSACTION_FEES}
              label={(
                <div
                  className={classes.checkboxLabel}
                >
                  {LABELS.COVER_TRANSACTION_FEES}
                  <Tooltip
                    classes={{
                      tooltip: classes.tooltip,
                    }}
                    title={LABELS.TRANSACTION_FEES_TOOLTIP}
                  >
                    <span className={classes.checkboxLabelTooltip}>{LABELS.TOOLTIP_QUESTION_MARK}</span>
                  </Tooltip>
                </div>
            )}
            />
          </Box>
        </OptionallyVisible>
        <OptionallyVisible visible={organization.areNotesEnabled}>
          <Box className={classes.checkboxContainer} {...QALocator(locators.page.common.components.notesCheckbox)}>
            <Checkbox
              onChange={handleChange(KEYS.ARE_NOTES_ENABLED)}
              value={pledge.areNotesEnabled}
              name={KEYS.ARE_NOTES_ENABLED}
              label={(
                <div
                  className={classes.checkboxLabel}
                >
                  {LABELS.NOTES_CHECKBOX_LABEL}
                </div>
            )}
            />
          </Box>
        </OptionallyVisible>
        <OptionallyVisible visible={shouldShowTributesControl}>
          <Box className={classes.checkboxContainer} {...QALocator(locators.page.common.components.tributesCheckbox)}>
            <Checkbox
              onChange={handleChange(KEYS.ARE_TRIBUTES_ENABLED)}
              value={pledge.areTributesEnabled}
              name={KEYS.ARE_TRIBUTES_ENABLED}
              label={(
                <div
                  className={classes.checkboxLabel}
                >
                  {LABELS.TRIBUTE_CHECKBOX_LABEL}
                </div>
            )}
            />
          </Box>
        </OptionallyVisible>
      </div>
    </div>
  );
};

export default PledgeForm;
