/* eslint-disable react/no-array-index-key */
import React from 'react';

import COLORS from 'theme/colors';

import { IconProps } from './icon.types';

const paths: string[] = [
  [
    'M33.6193 9.14517C33.0923 8.70204 32.1929 8.48047 30.9205 ',
    '8.48047H28.0507V17.3493H30.1966V14.5701H31.1578C32.3245 ',
    '14.5701 33.1801 14.3133 33.7245 13.7991C34.3383 13.2231 ',
    '34.6464 12.4561 34.6464 11.4989C34.6464 10.5063 34.3039 9.72175 ',
    '33.6193 9.14517ZM31.1045 12.5628H30.1966V10.4882H31.0122C32.0044 ',
    '10.4882 32.5005 10.8361 32.5005 11.5321C32.5005 12.2189 ',
    '32.0348 12.5628 31.1045 12.5628Z',
  ].join(''),
  'M38.3188 8.48047H36.0812V17.3493H40.9124V15.3416H38.3188V8.48047Z',
  [
    'M45.2961 8.48047L41.7811 17.3493H44.1903L44.651 16.0596H47.7053L48.1266 ',
    '17.3493H50.5626L47.0731 8.48047H45.2961ZM45.2697 14.2645L46.1916 11.233L47.0995 14.2645H45.2697Z',
  ].join(''),
  'M0.589737 24.7081H63.4108V1.03906H0.589737V24.7081Z',
  'M51.6811 17.3551H53.9192V8.48633H51.6811V17.3551Z',
  [
    'M62.6077 10.1486C62.3091 9.72309 61.9536 9.38218 61.5412 ',
    '9.12485C60.8657 8.69934 59.9438 8.48633 58.7766 ',
    '8.48633H55.828V17.3551H59.2772C60.5231 17.3551 61.5238 16.9478 ',
    '62.2786 16.132C63.0335 15.3167 63.4104 14.235 63.4104 12.8874C63.4104 ',
    '11.8154 63.1427 10.9024 62.6077 10.1486ZM59.0269 15.3474H58.0661V10.494H59.0404C59.7249 ',
    '10.494 60.2509 10.7081 60.6199 11.1355C60.9883 11.5631 61.1728 12.1694 61.1728 ',
    '12.954C61.1728 14.5497 60.4578 15.3474 59.0269 15.3474Z',
  ].join(''),
  [
    'M9.88198 1.03906L2.62864 2.93851L0.630338 10.2321L3.13021 ',
    '12.7988L0.589455 15.3232L2.47059 22.6491L9.69202 24.6668L12.2328 ',
    '22.1419L14.7327 24.7081L21.9855 22.8087L23.9838 15.514L21.4845 ',
    '12.9484L24.0247 10.424L22.1441 3.09814L14.9217 1.08035L12.3819 ',
    '3.60473L9.88198 1.03906ZM5.43066 4.34144L9.25127 3.34035L10.9221 ',
    '5.0555L8.48546 7.47664L5.43066 4.34144ZM13.8178 5.07866L15.5155 ',
    '3.39172L19.3196 4.45475L16.215 7.53959L13.8178 5.07866ZM2.91931 ',
    '9.63341L3.97181 5.79171L7.02561 8.92691L4.58955 11.3481L2.91931 ',
    '9.63341ZM17.6509 9.01352L20.7555 5.92818L21.7457 9.7875L20.0485 ',
    '11.4744L17.6509 9.01352ZM9.92186 8.95058L12.3585 6.52944L14.7551 ',
    '8.99036L12.3191 11.4115L9.92186 8.95058ZM6.02596 12.822L8.46202 ',
    '10.4008L10.8597 12.8618L8.42313 15.2829L6.02596 12.822ZM13.7555 ',
    '12.8854L16.1915 10.4643L18.5887 12.9252L16.1522 15.3464L13.7555 ',
    '12.8854ZM2.86846 15.9597L4.56662 14.2722L6.96329 16.7336L3.85963 ',
    '19.818L2.86846 15.9597ZM9.85904 16.7569L12.2956 14.3357L14.6928 ',
    '16.7967L12.2567 19.2178L9.85904 16.7569ZM17.5881 16.8203L20.0246 ',
    '14.3992L21.6954 16.1138L20.6429 19.9555L17.5881 16.8203ZM5.29504 ',
    '21.2924L8.3992 18.2071L10.7969 20.668L9.09921 22.3555L5.29504 ',
    '21.2924ZM13.6927 20.6917L16.1287 18.2706L19.183 21.4057L15.3629 ',
    '22.4064L13.6927 20.6917Z',
  ].join(''),
];

export const IconPlaid = ({
  width = 64,
  height = 26,
  color = COLORS.WHITE,
  className,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 64 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3339_6000)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={paths[0]}
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={paths[1]}
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={paths[2]}
        fill={color}
      />
      <g id="Mask group">
        <mask id="mask0_3339_6000" maskUnits="userSpaceOnUse" x="0" y="1" width="64" height="24">
          <g id="Group">
            <path d={paths[3]} fill={color} />
          </g>
        </mask>
        <g>
          <g id="Group_2">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d={paths[4]}
              fill={color}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d={paths[5]}
              fill={color}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d={paths[6]}
              fill={color}
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3339_6000">
        <rect width="63.8182" height="26" fill={color} transform="translate(0.0909119)" />
      </clipPath>
    </defs>
  </svg>

);

export default IconPlaid;
