import { AnyAction } from 'state/state.types';

import { ChariotReduxState } from './state';

export const actionTypeName = 'CHARIOT__FETCH_AMOUNT__FAIL';

type ActionFetchDonationAmountFail = AnyAction & {
  error: Record<string, any>;
}

export const createAction = (error: Record<string, any>) => ({
  type: actionTypeName,
  error,
});

export const reducer = (
  state: ChariotReduxState,
  { error }: ActionFetchDonationAmountFail,
): ChariotReduxState => ({
  ...state,
  details: {
    ...state.details,
    isLoading: false,
    error,
  },
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
