import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';
import FONT_FAMILIES from 'theme/fontFamilies';

export const useStyles = makeStyles()({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '28px 34px',
    boxSizing: 'border-box',
    height: 'calc(100% - 72px)',
    gap: 15,
  },
  section: {
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerSection: {
    gap: 15,
  },
  instructionsSection: {
    gap: 15,
  },
  headerImage: {
    width: 66,
    height: 33,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: COLORS.TEXT.PRIMARY,
    fontSize: 16,
  },
  panel: {
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 3,
    gap: 8,
  },
  instructionsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    color: COLORS.TEXT.PRIMARY,
    gap: 10,
  },
  instructionIcon: {
    minWidth: 24,
    minHeight: 24,
    backgroundColor: COLORS.SECONDARY,
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  instructionText: {
    fontFamily: FONT_FAMILIES.POPPINS,
    fontSize: 13,
    lineHeight: 'normal',
    fontWeight: 400,
    margin: 0,
    textIndent: 'unset',
  },
  textSemiBold: {
    fontWeight: 500,
  },
  textBold: {
    fontWeight: 600,
  },
  delimeter: {
    border: 0,
    margin: 0,
    backgroundColor: COLORS.INPUT.BORDER,
    width: '100%',
    height: 1,
    marginTop: -1,
  },
  gratitude: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    color: COLORS.TEXT.PRIMARY,
  },
  gratitudeImage: {
    marginTop: 5,
  },
  socialButton: {
    borderRadius: 5,
    width: 40,
    height: 40,
    borderWidth: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer !important',
  },
  socialSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 4,
  },
  socialButtonsRow: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  icon: {},
  shareHeader: {
    color: COLORS.PRIMARY,
    textAlign: 'center',
    fontFamily: FONT_FAMILIES.ROBOTO,
    fontSize: 14,
    fontWeight: 400,
  },
  shareMessage: {
    color: COLORS.PRIMARY,
    fontFamily: FONT_FAMILIES.ROBOTO,
    fontSize: 12,
    fontWeight: 400,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
    padding: '5px 10px',
    backgroundColor: COLORS.GREY_BACKGROUND,
    borderRadius: 5,
    maxWidth: 171,
  },
  starsIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    marginRight: 8,
  },
  iconStars: {},
});

export default useStyles;
