import Decimal from 'decimal.js';

import { CRYPTO_DECIMALS_NUMBER } from 'config/workflow';
import { FiatCurrency } from 'types/currency';

export const KEYS = {
  SHORT_USD_AMOUNT_DECIMALS: 0,
  FULL_USD_AMOUNT_DECIMALS: 2,
};

export const toDecimalFormat = (value, decimalPlace = CRYPTO_DECIMALS_NUMBER) => {
  if (Number(value) === 0) {
    return value;
  }

  return Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: decimalPlace,
  }).format(value);
};

export const USDFormatter = (minimumFractionDigits: number = 0) => new Intl.NumberFormat(
  'en-US',
  {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits,
    maximumFractionDigits: 2,
  },
);

export const GBPFormatter = (minimumFractionDigits: number = 0) => new Intl.NumberFormat(
  'en-GB',
  {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits,
    maximumFractionDigits: 2,
  },
);

export const fiatFormatter = (fiatCurrency: FiatCurrency | null, minimumFractionDigits: number = 0) => {
  if (fiatCurrency === FiatCurrency.GBP) {
    return GBPFormatter(minimumFractionDigits);
  }

  return USDFormatter(minimumFractionDigits);
};

export const shortUSDFormatter = USDFormatter(KEYS.SHORT_USD_AMOUNT_DECIMALS);
export const fullUSDFormatter = USDFormatter(KEYS.FULL_USD_AMOUNT_DECIMALS);
export const adaptiveUSDFormatter = {
  format: (value: number) => {
    const hasDecimals = Boolean(value % 1);
    const formatter = hasDecimals ? fullUSDFormatter : shortUSDFormatter;
    return formatter.format(value);
  },
};

const INCOMPATIBLE_TYPE_ERROR_MESSAGE = 'Incompatible parameter types. Fees.percent and Fees.usd should be numbers';

// calculating gross amount by reverting the fee extraction process
// netAmount = grossAmount - fees.percent% - fees.usd =>
// grossAmount = (netAmount + fees.usd) / ((100 - fees.percent) / 100)
// and rounding it to higher value
export const calculateWithFeesBeforeGross = (amount: number, fees?: { percent?: number; usd?: number} | null): number => {
  if (!fees) {
    return amount;
  }

  const {
    percent = 0,
    usd = 0,
  } = fees;

  const incompatibleTypes = typeof percent !== 'number' || typeof usd !== 'number';
  if (incompatibleTypes) {
    throw new Error(INCOMPATIBLE_TYPE_ERROR_MESSAGE);
  }

  const nextAmount = Decimal.div(
    Decimal.ceil(
      Decimal.mul(
        Decimal.div(
          Decimal.add(amount, usd),
          Decimal.sub(100, percent),
        ),
        10000,
      ),
    ),
    100,
  );

  return nextAmount.toNumber();
};

export const calculateWithFeesAfterGross = (amount: number, fees?: { percent?: number; usd?: number} | null): number => {
  if (!fees) {
    return amount;
  }

  const {
    percent = 0,
    usd = 0,
  } = fees;

  const incompatibleTypes = typeof percent !== 'number' || typeof usd !== 'number';
  if (incompatibleTypes) {
    throw new Error(INCOMPATIBLE_TYPE_ERROR_MESSAGE);
  }

  const nextAmount = Math.ceil(amount * ((100 + percent) / 100) + usd);
  return nextAmount;
};

export const getFeeString = (fees?: { percent?: number; usd?: number} | null): string => {
  if (!fees) {
    return '';
  }

  return [
    (fees.percent ? `${fees.percent}%` : ''),
    (fees.usd ? adaptiveUSDFormatter.format(fees.usd) : ''),
  ].filter(Boolean).join(' + ');
};

export const getCurrencySymbol = (fiatCurrency: FiatCurrency | null): string => {
  if (fiatCurrency === FiatCurrency.GBP) {
    return '£';
  }

  return '$';
};
