export const LABELS = {
  DONATE: 'DONATE',
  PREVIOUS: 'PREVIOUS',
  ASSET_SYMBOL_LABEL: 'Stock Ticker Symbol',
  ASSET_DESCRIPTION_LABEL: 'Stock Name',
  QUANTITY_LABEL: 'Quantity',
  SHARES: 'Shares',
  SHARE: 'Share',
  FUNDS_DESIGNATIONS_TITLE: 'Select Impact Area',
  STOCK_DONATION: 'Stock Donation variant',
  PAGE_TITLE: 'Make a Donation',
  NEXT: 'Next',
  PROCEED_BUTTON_TEXT: 'Donate',
  APPROXIMATE_USD_VALUE_PREFIX: '≈',
  USD_INPUT_PLACEHOLDER: '$ Value',
  NOTES_CHECKBOX_LABEL: 'Add a note to your donation',
  TRIBUTE_CHECKBOX_LABEL: 'Dedicate this donation to a friend or loved one',
  EXCHANGE_RATE_UNAVAILABLE: [
    'Price unavailable for approximate donation value. ',
    'You can continue to make your donation using quantity, please note minimum ',
    'value for stock donations is $500.',
  ].join(''),
  USD_VALUE_PREFIX: '$',
};

export const KEYS = {
  QUANTITY: 'quantity',
  NOTES_FIELD: 'notes',
  FUNDS_DESIGNATION: 'fundsDesignation',
  TOOLTIP_TOUCH_DELAY: 50,
};

export default LABELS;
