import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';
import FONT_FAMILIES from 'theme/fontFamilies';

export const useStyles = makeStyles()({
  scrollContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: 400,
  },
  processingLabel: {
    color: COLORS.PRIMARY,
    textAlign: 'center',
    fontFamily: FONT_FAMILIES.POPPINS,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    marginTop: 15,
  },
  loadingIcon: {
    width: 50,
  },
  plaidButton: {
    width: 264,
    flex: 1,
    height: 50,
    fontSize: 18,
    borderRadius: '5px !important',
    color: `${COLORS.WHITE} !important`,
    lineHeight: '24px',
    textTransform: 'none !important' as 'none',
    background: `${COLORS.PLAID_BLACK} !important`,
    boxShadow: 'none !important',
    fontFamily: FONT_FAMILIES.POPPINS,
    fontWeight: 500,
    '&:disabled, &.Mui-disabled': {
      border: 0,
      background: '#F0F4F8 !important',
      color: '#928E9C !important',
    },
  },
  plaidLogo: {
    marginLeft: 8,
  },
  mandateScreen: {
    display: 'flex',
    flexDirection: 'column',
  },
  mandateButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  overlayOpenButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
    borderRadius: 10,
    background: COLORS.GREY_BACKGROUND_SECOND,
    borderWidth: 0,
    textTransform: 'none',
    height: 30,
    marginBottom: 32,
    width: '100px !important',
    '&:hover': {
      borderWidth: 0,
    },
  },
  overlayOpenButtonLabel: {
    color: '#26242A',
    fontFamily: FONT_FAMILIES.ROBOTO,
    fontSize: 13,
    fontWeight: 400,
    whiteSpace: 'nowrap',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    lineHeight: '24px',
  },
  chevronIcon: {
    marginLeft: 8,
  },
  header: {
    color: COLORS.PRIMARY,
    textAlign: 'center',
    fontFamily: FONT_FAMILIES.ROBOTO,
    fontSize: 16,
    fontWeight: 500,
    marginTop: 34,
  },
  noMandateHeader: {
    marginTop: 110,
    fontSize: 20,
  },
  mandateShort: {
    color: COLORS.PRIMARY,
    textAlign: 'center',
    fontFamily: FONT_FAMILIES.ROBOTO,
    fontSize: 13,
    fontWeight: 400,
    marginTop: 16,
    marginBottom: 16,
    marginLeft: 34,
    marginRight: 34,
  },
  pressPlaidLabel: {
    marginBottom: 32,
  },
});

export default useStyles;
