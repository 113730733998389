import React, {
  ChangeEvent,
  ReactNode, useState,
} from 'react';
import {
  Autocomplete,
  AutocompleteProps,
} from '@mui/material';

import IconChevron from 'components/icon/chevron';
import IconClear from 'components/icon/clear';
import IconButton from 'components/iconButton';
import COLORS from 'theme/colors';
import { locators, QALocator } from 'utils/tests/QA';

import { TextInput } from '../../input';
import useStyles from './styles';

export interface ComboboxProps<T> extends Omit<AutocompleteProps<T, any, any, any>, 'renderInput'>{
  options: T[];
  placeholder?: string;
  value: string | T | (string | T)[] | null;
  onChange: (event: ChangeEvent<{}>, value: string | T | (string | T)[] | null) => void;
  renderItem: (item: T) => ReactNode;
  error?: boolean;
  helperText?: string;
  onSearch?: (value: string) => void;
}

export const Combobox = <T extends object>({
  onChange,
  placeholder = '',
  renderItem,
  className,
  value,
  error,
  helperText,
  onSearch,
  ...props
}: ComboboxProps<T>) => {
  const { classes } = useStyles();
  const [inputValue, setInputValue] = useState<string>('');

  const handleChange = (event: ChangeEvent<{}>, value: string | T | (string | T)[] | null) => {
    onChange(event, value as T[]);
  };

  const renderOption = (props: Object, item: T) => <div {...props}>{renderItem(item)}</div>;

  return (
    <Autocomplete
      {...props}
      value={value as NonNullable<T>}
      autoHighlight
      onChange={handleChange}
      renderOption={renderOption}
      className={classes.combobox}
      popupIcon={<IconChevron width={12} height={7} color={COLORS.ICON.SECONDARY} />}
      clearIcon={<IconClear width={16} height={16} color={COLORS.ICON.PRIMARY} />}
      ChipProps={{
        deleteIcon: (
          <IconButton className={classes.removeItemButton}>
            <IconClear width={16} height={16} color={COLORS.ICON.PRIMARY} />
          </IconButton>),
      }}
      classes={{
        popper: classes.dropdown,
      }}
      {...QALocator(locators.components.common.combobox)}
      renderInput={params => (
        <TextInput
          {...params}
          placeholder={placeholder}
          className={classes.input}
          classes={{
            root: props.classes?.input,
          }}
          error={error}
          helperText={helperText}
          InputProps={{
            ...QALocator(locators.components.common.comboboxInput),
            ...params.InputProps,
            value: inputValue,
            onChange: (event) => {
              const { value } = event.target;
              setInputValue(value);
              onSearch?.(value);
            },
          }}
        />
      )}
    />
  );
};
