import { makeStyles } from 'tss-react/mui';

import { breatheAnimation } from 'components/loading/styles';

export const useStyles = makeStyles()({
  donationContainerWrapper: {
    padding: 30,
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  loadingText: {
    marginTop: '10px !important',
  },
  imageLoadingContainer: {
    height: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingImage: {
    animation: `${breatheAnimation} 1.5s linear infinite`,
  },
  '@keyframes breathe': {
    '20%, 80%': {
      transform: 'scale(1)',
      opacity: 1,
    },
    '30%, 70%': {
      transform: 'scale(1.1)',
      opacity: 0.5,
    },
  },
});

export default useStyles;
