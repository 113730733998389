import React, { useContext, useEffect } from 'react';
import { Button, Grid, Typography } from '@mui/material';

import IconAch from 'components/icon/ach';
import CreditCardIcon from 'components/icon/card';
import IconChariot from 'components/icon/chariot';
import CryptoIcon from 'components/icon/crypto';
import StocksIcon from 'components/icon/stocks';
import Loading from 'components/loading';
import { Shift4UtilsContext } from 'components/shift4Utils/context';
import { OrganizationInfo } from 'types/organization';
import { DonationWorkflowType } from 'types/workflow';
import shouldInitializeShift4Utils from 'utils/shift4';
import { locators, QALocator } from 'utils/tests/QA';

import { gridLayout, LABELS } from './start.keys';
import { useStyles } from './start.styles';

interface StartScreenProps {
  initialize: (id: string) => Promise<any>;
  setType: (type: DonationWorkflowType | null) => void;
  id: string;
  isFetching: boolean;
  organization: OrganizationInfo;
  availableFlows: DonationWorkflowType[];
}

export const StartScreen = ({
  initialize,
  organization,
  id,
  isFetching,
  setType,
  availableFlows,
}: StartScreenProps) => {
  const { classes, cx } = useStyles();
  const shift4UtilsCtx = useContext(Shift4UtilsContext);

  const flowSettings = {
    [DonationWorkflowType.Crypto]: {
      key: DonationWorkflowType.Crypto,
      label: LABELS.DONATE_CRYPTO,
      icon: CryptoIcon,
    },
    [DonationWorkflowType.CreditCard]: {
      key: DonationWorkflowType.CreditCard,
      label: LABELS.CREDIT_CARD_DONATION,
      icon: CreditCardIcon,
    },
    [DonationWorkflowType.Stock]: {
      key: DonationWorkflowType.Stock,
      label: LABELS.STOCK_DONATION,
      icon: StocksIcon,
    },
    [DonationWorkflowType.Daf]: {
      key: DonationWorkflowType.Daf,
      label: LABELS.DAF_DONATION,
      icon: IconChariot,
    },
    [DonationWorkflowType.Ach]: {
      key: DonationWorkflowType.Ach,
      label: LABELS.ACH_DONATION,
      icon: IconAch,
    },
  };

  const availableButtons = availableFlows
    .map((flow, index, array) => ({
      ...flowSettings[flow],
      gridSize: gridLayout[array.length][index],
    }));

  useEffect(() => {
    if (!organization.name) {
      initialize(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, organization]);

  useEffect(() => {
    const shouldInitializeS4 = shouldInitializeShift4Utils(shift4UtilsCtx, organization);

    if (shouldInitializeS4) {
      shift4UtilsCtx.shift4Utils = window.Shift4!(organization.shift4PublicKey!, { merchantId: organization.shift4MerchantId });
    }
  }, [id, organization, window.Shift4]);

  const handleSetDonationType = (type: DonationWorkflowType) => () => {
    setType(type);
  };

  if (isFetching) {
    return (
      <Loading text={LABELS.LOADING_TEXT} />
    );
  }

  return (
    <div className={classes.contentContainer} {...QALocator(locators.page.common.start)}>
      <Typography variant="h6" className={classes.header}>
        {LABELS.SELECTION_TITLE}
      </Typography>
      <Grid container className={classes.gridContainer} spacing={1}>
        {availableButtons.map((flow, i) => (
          <Grid
            item
            key={flow.key}
            md={flow.gridSize}
            xs={flow.gridSize}
          >
            <Button
              fullWidth
              onClick={handleSetDonationType(flow.key)}
              className={cx(classes.flowButton, {
                [classes.outstandingFlowButton]: i === 0,
              })}
              {...QALocator(locators.page.common.components.donationTypeButton(flow.key))}
            >
              {flow.icon({ className: classes.icon })}
              {flow.label}
            </Button>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default StartScreen;
