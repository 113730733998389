import { createReducer } from '../createReducer';
import { buildReducerItem } from '../reducerItem';
import { Reducer } from '../state.types';
import fetchCurrenciesFail from './fetchCurrencies.fail';
import fetchCurrenciesStart from './fetchCurrencies.start';
import fetchCurrenciesSuccess from './fetchCurrencies.success';
import { initialState } from './state';

const reducers = new Map([
  buildReducerItem(fetchCurrenciesStart),
  buildReducerItem(fetchCurrenciesSuccess),
  buildReducerItem(fetchCurrenciesFail),
]) as Map<string, Reducer<any, any>>;

export const cryptoCurrenciesReducer = createReducer(initialState, reducers);

export default cryptoCurrenciesReducer;
