import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

const useStyles = makeStyles()({
  optionsWrapper: {
    display: 'flex',
    width: '100%',
  },
  option: {
    height: 40,
    width: '100%',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '23px',
    color: '#291B4F',
    padding: 0,
    transition: 'unset !important',
    borderRadius: 'unset !important',
    '&:first-of-type': {
      borderRadius: '4px 0 0 4px !important',
    },
    '&:last-of-type': {
      borderRadius: '0 4px 4px 0 !important',
    },
  },
  selectedOption: {
    border: '2px solid #FCD42B !important',
    background: `${COLORS.WHITE} !important`,
    marginLeft: -2,
    marginRight: -2,
    zIndex: 1,
    borderRadius: '4px !important',
    '&:first-of-type, &:last-of-type': {
      borderRadius: '4px !important',
    },
  },
});

export default useStyles;
