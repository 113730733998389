export const LABELS = {
  FUNDS_DESIGNATIONS_PLACEHOLER: 'Funds designation',
  EMPTY_DESIGNATION_LABEL: 'Select Area',
};

export const defaultDesignationOption = {
  id: '',
  label: LABELS.EMPTY_DESIGNATION_LABEL,
};

export default LABELS;
