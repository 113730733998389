import { FormFieldDescription } from 'pages/creditCard/cardDetails/fields/fields.types';

export const LABELS = {
  FULL_NAME: 'Full Name',
  CARDHOLDER_NAME: 'Cardholder Name',
  RIGHT_BUTTON_TEXT: 'CONTINUE',
  LEFT_BUTTON_TEXT: 'PREVIOUS',
  REQURED: 'Required',
  OR: 'Or',
};

export const KEYS = {
  CARD_NUMBER: 'cardNumber',
  CARD_HOLDER_NAME: 'cardHolderName',
  CARD_EXPIRATION_DATE: 'cardExpirationDate',
  CARD_CVV: 'cardCVV',
  CARD_HOLDER_ZIP_CODE: 'cardHolderZipCode',
  DATE_SEPARATOR: '/',
  NUMBER_TYPE: 'number',
  SHIFT4: {
    FORM_IDENTIFIER: 'ccForm',
    CARD_NUMBER: 'number',
    CARD_EXPIRY_DATE: 'expiry',
    CARD_CVC: 'cvc',
    DATA_ATTRIBUTE: 'data-shift4',
  },
};

export const creditCardInfoFormFields = (isV2Api: boolean): FormFieldDescription[] => [
  {
    label: 'Full Name',
    placeholder: 'Cardholder Name',
    name: KEYS.CARD_HOLDER_NAME,
    isRequired: true,
  },
  {
    label: 'Card Number',
    placeholder: 'Enter Card Number',
    type: KEYS.NUMBER_TYPE,
    isRequired: !isV2Api,
    name: KEYS.CARD_NUMBER,
    dataAttributes: isV2Api ? {
      [KEYS.SHIFT4.DATA_ATTRIBUTE]: KEYS.SHIFT4.CARD_NUMBER,
    } : {},
  },
  {
    label: 'Expiration Date',
    placeholder: 'MM / YY',
    view: 'short',
    type: KEYS.NUMBER_TYPE,
    isRequired: !isV2Api,
    name: KEYS.CARD_EXPIRATION_DATE,
    mask: '99/99',
    formatChars: {
      9: '[0-9]',
    },
    dataAttributes: isV2Api ? {
      [KEYS.SHIFT4.DATA_ATTRIBUTE]: KEYS.SHIFT4.CARD_EXPIRY_DATE,
    } : {},
  },
  {
    label: 'CVV',
    placeholder: 'CVV Code',
    view: 'short',
    isRequired: !isV2Api,
    type: KEYS.NUMBER_TYPE,
    name: KEYS.CARD_CVV,
    mask: '9999',
    formatChars: {
      9: '[0-9]',
    },
    dataAttributes: isV2Api ? {
      [KEYS.SHIFT4.DATA_ATTRIBUTE]: KEYS.SHIFT4.CARD_CVC,
    } : {},
  },
  {
    label: 'Postal Code',
    placeholder: 'Billing Postal Code',
    name: KEYS.CARD_HOLDER_ZIP_CODE,
    isRequired: true,
  },
];

export default KEYS;
