import { connect, MapDispatchToPropsParam, MapStateToPropsParam } from 'react-redux';
import { Dispatch } from 'redux';

import { CreditCardReduxState } from 'state/creditCard/state';
import { CardDonationReduxState } from 'state/donation/state';
import { AppReduxState } from 'state/state';
import changeStep from 'state/workflow/changeStep';
import { pay } from 'state/workflow/thunk/pay.thunk';
import { FiatPaymentMethod } from 'types/card';
import Frequency from 'types/frequency';
import { CryptoDonationWorkflowStep } from 'types/workflow';
import { getExpirationDate } from 'utils/card';
import componentSwitch from 'utils/componentSwitch';

import { CardDonation } from './cardDonation';
import { CardDonation as OverlayCardDonation } from './overlayVariant/cardDonation';

const mapStateToProps: MapStateToPropsParam<any, any, AppReduxState> = (state) => {
  const {
    isSubmitting,
    card,
  } = state.donation;

  const {
    isSubmitting: isProcessing,
  } = state.workflow;

  const {
    cardToken,
    method,
  } = card;

  const {
    cardExpirationDate,
    cardNumber,
  } = state.card;

  const {
    currencies,
  } = state.currencies;

  const {
    frequency,
  } = state.frequency;

  const {
    widgetId,
  } = state.widget;

  const { expirationMonth, cardTypeYear } = getExpirationDate(cardExpirationDate);

  return {
    isSubmitting,
    isProcessing,
    donation: {
      cardToken,
      cardType: '',
      expirationMonth,
      cardTypeYear,
      cardLast4Numbers: cardNumber.substring(cardNumber.length - 4, cardNumber.length),
    },
    currencies,
    creditCardInformation: state.card,
    serverInfo: card,
    frequency,
    method,
    widgetId,
  };
};

const mapDispatchToProps: MapDispatchToPropsParam<any, any> = (dispatch: Dispatch<any>) => ({
  goBack: () => dispatch(changeStep.createAction(CryptoDonationWorkflowStep.FillDonorInfo)),
  resetWidget: () => window.location.reload(),
  pay: (
    serverInfo: CardDonationReduxState,
    creditCardInfo: CreditCardReduxState,
    captchaToken: string | null,
    frequency?: Frequency,
    widgetId?: string,
  ) => pay({
    serverInfo,
    creditCardInfo,
    captchaToken,
    frequency,
    widgetId,
  })(dispatch),
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  pay: (captchaToken: string | null) => {
    const tokenForCardDonationOnly = stateProps.serverInfo.method === FiatPaymentMethod.Card
      ? captchaToken
      : null;
    dispatchProps.pay(
      stateProps.serverInfo,
      stateProps.creditCardInformation,
      tokenForCardDonationOnly,
      stateProps.frequency,
      stateProps.widgetId,
    );
  },
});

export const CardDonationConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(componentSwitch(CardDonation, OverlayCardDonation));

export default CardDonationConnect;
