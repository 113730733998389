import React, { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Typography } from '@mui/material';

import OptionallyVisible from 'components/optionallyVisible';

import { IMAGE_FORMAT, LABELS } from './keys';
import { useStyles } from './styles';

interface SignatureProps {
  width?: number;
  height?: number;
  className?: string;
  onChange?: (value: string) => void;
  onClear?: () => void;
  placeholder?: string;
  label?: string;
  inputRef?: (ref: SignatureCanvas) => void;
  hideClearButton?: boolean;
  hidePlaceholder?: boolean;
  compact?: boolean;
}

export const SignatureInput = ({
  width = 300,
  height = 150,
  className = '',
  onChange,
  onClear,
  placeholder = LABELS.PLACEHOLDER,
  label,
  inputRef,
  hideClearButton = false,
  hidePlaceholder = false,
  compact = false,
}: SignatureProps) => {
  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(true);
  const { classes, cx } = useStyles({ width });
  const canvasRef = useRef<SignatureCanvas | null>(null);

  const handleDrawEnd = () => {
    const value = canvasRef.current?.toDataURL(IMAGE_FORMAT) || '';
    onChange?.(value);
  };

  const handleRef = (ref: SignatureCanvas | null) => {
    if (ref === null) {
      return;
    }

    canvasRef.current = ref;
    inputRef?.(ref);
  };

  const handleDrawStart = () => {
    setShowPlaceholder(false);
  };

  const handleClear = () => {
    canvasRef.current?.clear();
    setShowPlaceholder(true);
    onClear?.();
  };

  useEffect(() => {
    setShowPlaceholder(!hidePlaceholder);
  }, [hidePlaceholder]);

  useEffect(() => {
    handleClear();
  }, [width, height]);

  const placeholderVisible = !hidePlaceholder && showPlaceholder;
  const placeholderPosition = (hideClearButton || compact)
    ? (height / 2 - 5)
    : (height / 2 + 5);

  const isLabelVisible = Boolean(!compact && label);
  return (
    <div className={classes.container}>
      <OptionallyVisible visible={isLabelVisible}>
        <Typography
          variant="h6"
          className={classes.label}
          style={{
            width,
          }}
        >
          {label}
        </Typography>
      </OptionallyVisible>
      <OptionallyVisible visible={placeholderVisible}>
        <Typography
          className={classes.placeholder}
          style={{
            top: placeholderPosition,
          }}
        >
          {placeholder}
        </Typography>
      </OptionallyVisible>
      <SignatureCanvas
        ref={handleRef}
        onEnd={handleDrawEnd}
        onBegin={handleDrawStart}
        canvasProps={{
          width,
          height,
          className: cx([classes.signatureField, className]),
        }}
      />
      <OptionallyVisible visible={!hideClearButton}>
        <Button
          variant="outlined"
          onClick={handleClear}
          className={cx({
            [classes.clearButton]: true,
            [classes.compactButton]: compact,
          })}
        >
          {LABELS.CLEAR_BUTTON_LABEL}
        </Button>
      </OptionallyVisible>
    </div>
  );
};

export default SignatureInput;
export { default as SignatureCanvas } from 'react-signature-canvas';
