import omit from 'lodash/omit';
import { Dispatch } from 'redux';

import { API, Endpoints } from 'api';
import changeStep from 'state/workflow/changeStep';
import setError from 'state/workflow/setError';
import { BrokerInfo } from 'types/broker';
import { StockDonationWorkflowStep } from 'types/workflow';
import { brokerNameIsOther } from 'utils/broker';

import submitBrokerInfoFail from '../submitBrokerInfo.fail';
import submitBrokerInfoStart from '../submitBrokerInfo.start';
import submitBrokerInfoSuccess from '../submitBrokerInfo.success';

export const DEFAULT_ERROR_MESSGAE = 'Something went wrong. Please try again later.';
export const CUSTOM_BROKER_NAME_PROPERTY = 'customBrokerName';

export const prepareBody = (
  donationUuid: string,
  brokerInfo: BrokerInfo,
) => {
  const shouldExcludeCustomBrokerName = !brokerNameIsOther(brokerInfo);
  const brokerInfoProperties = shouldExcludeCustomBrokerName ? omit(brokerInfo, [CUSTOM_BROKER_NAME_PROPERTY]) : brokerInfo;
  return {
    donationUuid,
    ...brokerInfoProperties,
  };
};

export const submitBrokerInfo = (
  donationUuid: string,
  brokerInfo: BrokerInfo,
) => async (dispatch: Dispatch<any>) => {
  dispatch(submitBrokerInfoStart.createAction());

  try {
    const body = prepareBody(donationUuid, brokerInfo);
    const response = await API.post(Endpoints.stockDonationSubmit, body);

    if (response?.data?.isSuccess) {
      dispatch(submitBrokerInfoSuccess.createAction());
      dispatch(changeStep.createAction(StockDonationWorkflowStep.SignPledge));
      return;
    }

    dispatch(submitBrokerInfoFail.createAction({
      errorMessage: DEFAULT_ERROR_MESSGAE,
    }));
    dispatch(setError.createAction(DEFAULT_ERROR_MESSGAE));
  } catch (error: any) {
    const { message } = error;
    const errorMessage = message || DEFAULT_ERROR_MESSGAE;
    const failedAttributes = error?.details?.meta?.failedAttributes;

    const details = failedAttributes ? failedAttributes?.reduce((acc, {
      attributeName,
      message,
    }) => ({
      ...acc,
      [attributeName]: message,
    }), {}) : null;

    const failParams = details || { errorMessage };
    dispatch(submitBrokerInfoFail.createAction(failParams));
  }
};

export default submitBrokerInfo;
