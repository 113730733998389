export const Endpoints = {
  exchangeRate: 'v1/exchange-rate',
  initialize: 'v1/initialize',
  stockBrokers: 'v1/donation/stocks/brokers',
  currencyList: 'v1/currencies/list',
  crypto: {
    cryptoDonationStatus: (donationUuid: string) => `v1/donation/crypto/${donationUuid}/status`,
    donationCrypto: 'v1/donation/crypto',
  },
  donationFiat: 'v1/donation/fiat',
  donationAch: 'v1/donation/ach',
  donationAchFinalize: 'v1/donation/ach/finalize',
  donationStocks: 'v1/donation/stocks',
  donationFiatCharge: 'v1/donation/fiat/charge',
  stockDonationSubmit: 'v1/donation/stocks/submit',
  assetExchangeRate: 'v1/stocks/ticker-cost',
  stockAssets: 'v1/stocks/tickers',
  signStockDonation: 'v1/donation/stocks/sign',
  chariot: {
    byOrganizationId: (organizationId: number) => `v1/donation/daf/${organizationId}/chariot`,
    donationAmount: (organizationId: number) => `v1/donation/daf/${organizationId}/chariot/calculate-donation-amount`,
  },
};

export default Endpoints;
