import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { DEFAULT_USD_VALUES } from 'config/workflow';
import { BASIC_CURRENCIES } from 'constants/currencies';
import setConnectionId from 'state/chariot/setConnectionId';
import setSession from 'state/chariot/setSession';
import fetchConnectionId from 'state/chariot/thunk/fetchConnectionId.thunk';
import setAnonymous from 'state/donor/setAnonymous';
import { initialState as getInitialDonorState } from 'state/donor/state';
import updateDonorInfoValue from 'state/donor/updateDonorInfoValue';
import changeFiatAmount from 'state/pledge/changeFiatAmount';
import fetchCurrencyExchangeRateSuccess from 'state/pledge/fetchCurrencyExchangeRate.success';
import updatePledge from 'state/pledge/updatePledge';
import { AppReduxState } from 'state/state';
import changeStep from 'state/workflow/changeStep';
import setType from 'state/workflow/setType';
import { ChariotSession } from 'types/chariot';
import DonationWorkflowType, { DafDonationWorkflowStep } from 'types/workflow';
import componentSwitch from 'utils/componentSwitch';
import { pushDataLayerEvent } from 'utils/gtm';
import GTMEventType from 'utils/gtm/types';
import eventDataFactory from 'utils/gtm/util';
import hasMultipleFlowsEnabled, { getAvailableFlows } from 'utils/organization';
import checkIsFiatDonationType from 'utils/workflow';

import OverlayDAFPledgeScreen from './overlayVariant/pledge';
import DAFPledgeScreen from './pledge';

interface DefaultStateParams {
  defaultAmount: number | null;
  defaultCryptocurrency: string | null;
}

const mapStateToProps = (state: AppReduxState) => {
  const {
    type: workflowType,
  } = state.workflow;

  const { defaultAmount, defaultCryptocurrency } = state.donationData;

  const {
    organization,
  } = state.organization;

  const {
    connection,
    donation,
  } = state.chariot;

  const {
    fundraiserId,
  } = state.fundraiser;

  const { pledge } = state;

  const defaultEmptyState = getInitialDonorState();
  const availableFlows = getAvailableFlows(organization);
  const donationSentEventData = eventDataFactory(GTMEventType.DonationSent, workflowType!, { state });
  const donationStartEventData = eventDataFactory(GTMEventType.DonationStart, workflowType!, { state });

  return {
    donationSentEventData,
    donationStartEventData,
    connection,
    donation,
    donor: state.donor,
    pledge,
    defaultEmptyState,
    organization,
    workflowType,
    availableFlows,
    fundraiserId,
    isPreviousStepAvailable: hasMultipleFlowsEnabled(organization),
    defaultAmount,
    defaultCryptocurrency,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  resetConnectionId: () => dispatch(setConnectionId.createAction(null)),
  updateValue: (field: string, value: any) => dispatch(updateDonorInfoValue.createAction(field, value)),
  setType: (type: DonationWorkflowType | null, {
    defaultAmount,
    defaultCryptocurrency,
  }: DefaultStateParams) => {
    dispatch(setType.createAction(type));
    dispatch(setAnonymous.createAction(false));
    const isFiatDonationType = checkIsFiatDonationType(type);
    if (isFiatDonationType) {
      dispatch(fetchCurrencyExchangeRateSuccess.createAction(1));
      dispatch(updatePledge.createAction({ currency: BASIC_CURRENCIES.USD }));
      dispatch(changeFiatAmount.createAction(DEFAULT_USD_VALUES.FIAT));
    } else {
      dispatch(updatePledge.createAction({ currency: defaultCryptocurrency ?? BASIC_CURRENCIES.BTC }));
      dispatch(changeFiatAmount.createAction(defaultAmount ?? DEFAULT_USD_VALUES.CRYPTO));
    }
  },
  fetchConnectionId: (organizationId: number) => dispatch(fetchConnectionId(organizationId)),
  resetFlow: () => {
    dispatch(setType.createAction(null));
    dispatch(setAnonymous.createAction(false));
  },
  changeStep: (step: DafDonationWorkflowStep) => dispatch(changeStep.createAction(step)),
  setSession: (session: ChariotSession) => dispatch(setSession.createAction(session)),
});

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
) => ({
  ...stateProps,
  ...dispatchProps,
  fetchConnectionId: () => dispatchProps.fetchConnectionId(stateProps.organization.id),
  onDonationStart: () => {
    pushDataLayerEvent(stateProps.donationStartEventData);
  },
  onDonationSuccess: (data: ChariotSession) => {
    dispatchProps.setSession(data);
    pushDataLayerEvent({
      ...stateProps.donationSentEventData,
      selectionType: data.grantIntent.fundId,
      usdAmount: data.grantIntent.amount / 100,
      transactionId: data.grantIntent.userFriendlyId,
    });
    dispatchProps.changeStep(DafDonationWorkflowStep.FillDonorInfo);
  },
  setType: (type: DonationWorkflowType | null) => (
    dispatchProps.setType(type, {
      defaultAmount: stateProps.defaultAmount,
      defaultCryptocurrency: stateProps.defaultCryptocurrency,
    })
  ),
});

export const DAFPledgeConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(componentSwitch(DAFPledgeScreen, OverlayDAFPledgeScreen));

export default DAFPledgeConnect;
