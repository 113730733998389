import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';
import FONT_FAMILIES from 'theme/fontFamilies';

const useStyles = makeStyles()({
  section: {
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleCaption: {
    color: COLORS.TEXT.PRIMARY,
    fontFamily: FONT_FAMILIES.POPPINS,
    fontSize: 14,
    lineHeight: '24px',
    marginTop: 4,
  },
  headerSection: {
    '& > div:nth-of-type(1)': {
      margin: 0,
    },
    marginBottom: 30,
  },
  dialogContainer: {
    gap: 8,
    backgroundColor: COLORS.GREY_BACKGROUND,
    padding: '10px 0px 10px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    marginBottom: 40,
  },
  dialogHeaderRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  closeButtonContainer: {
    width: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 20,
  },
  dialogHeader: {
    color: COLORS.DARK_PRIMARY,
    fontFamily: FONT_FAMILIES.INTER,
    fontSize: 14,
    fontWeight: 400,
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: 50,
    width: 'calc(100% - 100px)',
    textAlign: 'center',
  },
  closeButton: {
    width: 30,
    minWidth: 30,
    height: 30,
    minHeight: 30,
    padding: 0,
    flex: 0,
  },
  dialogContent: {
    color: COLORS.DARK_PRIMARY,
    fontFamily: FONT_FAMILIES.INTER,
    fontSize: 14,
    fontWeight: 400,
    padding: 10,
  },
  link: {
    color: COLORS.BRIGHT_BLUE,
    fontFamily: FONT_FAMILIES.INTER,
    fontSize: 14,
    fontWeight: 400,
  },
});

export default useStyles;
