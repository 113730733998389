import buildReducerItem from 'state/reducerItem';

import { createReducer } from '../createReducer';
import { Reducer } from '../state.types';
import clearCreditCardInfoFields from './clearCreditCardInfoFields';
import setError from './setError';
import { initialState } from './state';
import updateCreditCardInfoValue from './updateCreditCardInfoValue';

const reducers = new Map([
  buildReducerItem(updateCreditCardInfoValue),
  buildReducerItem(clearCreditCardInfoFields),
  buildReducerItem(setError),
]) as Map<string, Reducer<any, any>>;

export const creditCardReducer = createReducer(initialState, reducers);

export default creditCardReducer;
