import { createReducer } from '../createReducer';
import { buildReducerItem } from '../reducerItem';
import { Reducer } from '../state.types';
import setFrequency from './setFrequency';
import { FrequencyReduxState, initialState } from './state';

const reducers: Map<string, Reducer<FrequencyReduxState, any>> = new Map([
  buildReducerItem(setFrequency),
]);

export const frequencyReducer = createReducer(initialState, reducers);

export default frequencyReducer;
