import React, { useMemo } from 'react';

import { Combobox, ComboboxProps } from 'components/inputs/combobox';

export type SelectorValue = number | string;

export interface SearchableDropdownProps<T> {
  disabled?: boolean;
  items: T[];
  onChange: (values: T[], name?: string) => void;
  selected?: T | T[] | null;
  label?: string;
  error?: boolean;
  helperText?: string;
  ComboboxProps?: Partial<ComboboxProps<T>>;
  getOptionLabel: (option: T) => string;
}

export const SearchableDropdown = <T extends object>({
  disabled = false,
  items,
  onChange,
  selected = null,
  error = false,
  helperText,
  getOptionLabel,
  ComboboxProps = {},
}: SearchableDropdownProps<T>) => {
  const availableValues = useMemo(
    () => items,
    [items],
  );

  const getOptionSelected = (
    option: T,
    selected: T,
  ) => (option === selected);

  const onSelect = (
    _event: React.ChangeEvent<HTMLLIElement>,
    values: T | T[],
  ) => {
    const optionName = _event.target.innerText || '';
    const valuesArray = (Array.isArray(values) ? values : [values]) as T[];
    onChange(valuesArray, optionName);
  };

  return (
    <Combobox
      disabled={disabled}
      fullWidth
      openOnFocus
      value={selected}
      options={availableValues}
      // @ts-ignore #some weird TS type handling
      onChange={onSelect}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      error={error}
      helperText={helperText}
      {...ComboboxProps}
    />
  );
};

export default SearchableDropdown;
