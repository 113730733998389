import React, { useMemo } from 'react';

import IconDollar from 'components/icon/dollar';
import IconMail from 'components/icon/mail';
import DonationSuccessLayout from 'pages/common/donationSuccess/components/donationSuccessLayout';

import { LABELS } from './stockDonationSuccess.keys';
import { useStyles } from './stockDonationSuccess.styles';

export interface StockDonationSuccessProps {
  resetFlowWithDesiredDonationType: () => void;
  isOtherBrokerSelected: boolean;
  organizationName: string;
}

export const StockDonationSuccess = ({
  resetFlowWithDesiredDonationType,
  isOtherBrokerSelected,
  organizationName,
}: StockDonationSuccessProps) => {
  const { classes } = useStyles();

  const content = useMemo(() => {
    if (isOtherBrokerSelected) {
      return (
        <div className={classes.panel}>
          <div className={classes.instructionsRow}>
            <div className={classes.instructionIcon}>
              <IconMail />
            </div>
            <p className={classes.instructionText}>
              <span className={classes.textBold}>
                {LABELS.DOLLAR_HEADER_OTHER[0]}
              </span>
              {LABELS.DOLLAR_HEADER_OTHER[1]}
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className={classes.panel}>
        <div className={classes.instructionsRow}>
          <div className={classes.instructionIcon}>
            <IconDollar />
          </div>
          <p className={classes.instructionText}>
            <span className={classes.textBold}>
              {LABELS.DOLLAR_HEADER}
            </span>
          </p>
        </div>
        <ul className={classes.list}>
          <h5 className={classes.listTitle}>
            {LABELS.WHAT_IS_NEXT}
          </h5>
          <li className={classes.listItem}>
            <span className={classes.textBold}>
              {LABELS.INSTRUCTIONS_1[0]}
            </span>
            {LABELS.INSTRUCTIONS_1[1]}
          </li>
        </ul>
      </div>
    );
  }, [isOtherBrokerSelected]);

  return (
    <DonationSuccessLayout
      restart={resetFlowWithDesiredDonationType}
      withSocialLinks
      additionalContent={content}
      organizationName={organizationName}
    />
  );
};

export default StockDonationSuccess;
