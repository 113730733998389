import { AppReduxState } from 'state/state';
import { DonationWorkflowType } from 'types/workflow';

import GTMEventType from './types';

export const brokerInfoPayloadFactory = (
  flow: DonationWorkflowType,
  { state }: { state: AppReduxState },
) => {
  if (flow !== DonationWorkflowType.Stock) {
    return {};
  }

  return {
    event: GTMEventType.BrokerInfo,
    donationType: flow,
    brokerFirm: state.stock.brokerName,
  };
};

export default brokerInfoPayloadFactory;
