import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

export const useStyles = makeStyles()({
  input: {
    '& .MuiInputBase-root': {
      padding: '0!important',
      minHeight: 50,
    },
    '& .MuiChip-root,': {
      fontFamily: 'Noto Sans',
      fontSize: '14px!important',
      lineHeight: '20px',
      fontWeight: 500,
      color: COLORS.TEXT.PRIMARY,
      '& .MuiChip-deleteIcon': {
        width: 16,
        height: 16,
      },
    },
    '& .MuiAutocomplete-input': {
      fontFamily: 'Noto Sans',
      fontSize: '16px!important',
      lineHeight: '23px',
      height: 23,
      fontWeight: 500,
      color: COLORS.TEXT.PRIMARY,
      padding: '13px 30px 14px 20px!important',
    },
    '& .MuiInputLabel-outlined': {
      outline: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.INPUT.BORDER,
      borderRadius: 5,
      paddingLeft: 5,
      outline: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.INPUT.BORDER,
      borderRadius: 5,
      paddingLeft: 5,
      outline: 'none',
    },
  },
  combobox: {
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${COLORS.INPUT.BORDER} !important`,
    },
    borderRadius: 5,
    [`
      &:hover .MuiOutlinedInput-notchedOutline,
      &.Mui-focused .MuiOutlinedInput-notchedOutline
    `]: {
      border: `2px solid ${COLORS.SECONDARY} !important`,
      borderRadius: 5,
    },
    '&.Mui-focused[aria-expanded=true] .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${COLORS.SECONDARY} !important`,
      paddingLeft: 5,
    },
    '& .MuiAutocomplete-popupIndicator': {
      width: 24,
      height: 24,
    },
  },
  dropdown: {
    '& .MuiPaper-root': {
      borderRadius: 5,
    },
    '& .MuiAutocomplete-listbox': {
      border: `2px solid ${COLORS.SECONDARY} !important`,
      padding: 0,
    },
    '& .MuiAutocomplete-option': {
      fontFamily: 'Noto Sans',
      fontSize: '16px!important',
      lineHeight: '23px',
      fontWeight: 500,
      color: COLORS.PRIMARY,
      padding: '8px 20px 9px!important',
      '&.Mui-focused': {
        background: 'linear-gradient(90deg, #F0F4F8 0%, rgba(240, 244, 248, 0) 100%)',
      },
    },
  },
  removeItemButton: {
    padding: 0,
  },
});

export default useStyles;
