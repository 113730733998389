import React from 'react';
import {
  FormControl,
  FormHelperText,
  TextField,
} from '@mui/material';
import { useField } from 'formik';

interface EmailFieldProps {
  placeholder: string;
  name: string;
  className?: string;
  required?: boolean;
}

export const EmailField = (props: EmailFieldProps) => {
  const [field, meta] = useField(props);
  const hasError = Boolean(meta.touched && meta.error);
  const { error } = meta;

  return (
    <FormControl fullWidth>
      <TextField
        {...field}
        {...props}
        margin="dense"
        type="email"
        error={hasError}
        fullWidth
        variant="outlined"
      />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

EmailField.defaultProps = {
  required: false,
  className: '',
};

export default EmailField;
