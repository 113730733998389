import React, { useMemo } from 'react';

import { DonationSuccessConnect } from 'pages/common/donationSuccess/donationSuccess.connect';
import { DonorInfoConnect } from 'pages/common/donorInfo/donorInfo.connect';
import { ErrorConnect } from 'pages/common/error/error.connect';
import NotesConnect from 'pages/common/notes/notes.connect';
import { BrokerInfoConnect } from 'pages/stock/brokerInfo/brokerInfo.connect';
import { StockDonationPledgeConnect } from 'pages/stock/pledge/stockDonationPledge.connect';
import { SigningPledgeConnect } from 'pages/stock/signing/signing.connect';
import { StockDonationWorkflowStep } from 'types/workflow';

const componentMap = {
  [StockDonationWorkflowStep.Pledge]: StockDonationPledgeConnect,
  [StockDonationWorkflowStep.Notes]: NotesConnect,
  [StockDonationWorkflowStep.FillDonorInfo]: DonorInfoConnect,
  [StockDonationWorkflowStep.FillBrokerInfo]: BrokerInfoConnect,
  [StockDonationWorkflowStep.SignPledge]: SigningPledgeConnect,
  [StockDonationWorkflowStep.Success]: DonationSuccessConnect,
  [StockDonationWorkflowStep.Error]: ErrorConnect,
};

interface StocksRouterProps {
  step: StockDonationWorkflowStep;
}

export const StocksRouter = ({ step }: StocksRouterProps) => {
  const Component = useMemo(() => componentMap[step], [step]);
  return (
    <Component />
  );
};

export default StocksRouter;
