import React, { ReactNode } from 'react';
import { IconButton, Typography } from '@mui/material';

import IconBackArrow from '../icon/backArrow';
import OptionallyVisible from '../optionallyVisible';
import useStyles from './styles';

interface PageHeaderProps {
  label?: ReactNode | string;
  withBackButton?: boolean;
  onGoBack?: () => void;
}

export const PageHeader = ({
  label,
  onGoBack,
  withBackButton,
}: PageHeaderProps) => {
  const { classes, cx, theme } = useStyles();
  return (
    <div className={classes.container}>
      <OptionallyVisible visible={Boolean(withBackButton)}>
        <IconButton
          onClick={onGoBack}
          className={cx(classes.backButton, {
            [classes.absolute]: withBackButton,
          })}
        >
          <IconBackArrow color={theme.palette.text.primary} />
        </IconButton>
      </OptionallyVisible>
      <OptionallyVisible visible={Boolean(label)}>
        <Typography
          variant="h3"
          className={cx({
            [classes.pageTitle]: true,
            [classes.pageTitleShifted]: withBackButton,
          })}
        >
          {label}
        </Typography>
      </OptionallyVisible>
    </div>
  );
};

export default PageHeader;
