import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  Box,
  Container,
  Tooltip,
} from '@mui/material';
import qrCode from 'qrcode-generator';

import { Button, Icon } from 'components';
import { IconCopy } from 'components/icon/copy';
import GeminiIcon from 'components/icon/gemini';
import { IconInfo } from 'components/icon/info';
import IconMetamask from 'components/icon/metamask';
import Loading from 'components/loading';
import OptionallyVisible from 'components/optionallyVisible';
import PageHeader from 'components/pageHeader/pageHeader';
import COLORS from 'theme/colors';
import Currency from 'types/currency';
import { DonationCredentials } from 'types/donation';
import { DonationPledge } from 'types/pledge';
import useReturnUrl from 'utils/hooks/useReturnUrl';
import { getIsCurrencySuppored } from 'utils/metamask';
import runtimeLocalStorage, { RUNTIME_LOCAL_STORAGE_KEYS } from 'utils/runtimeLocalStore';

import { MetamaskConnect } from '../metamask.connect';
import {
  DONATION_NOTES,
  KEYS,
  LABELS,
  PLACEHOLDERS,
} from './keys';
import useStyles from './styles';

interface DonateScreenProps {
  pledge: DonationPledge;
  donation: DonationCredentials;
  isSubmitting: boolean;
  currencies: Currency[];
  metamaskInstalled: boolean;
  isRipple: boolean;
  goBack: () => void;
  resetWidget: () => void;
  onInit: () => void;
  returnUrl: string | null;
  fetchCryptoDonationStatus: () => Promise<Boolean>;
}

const prepareDonationAddress = (address: string, containerRef: HTMLSpanElement): string => {
  if (!containerRef) {
    return '';
  }

  let formattedAddress = address;
  let partsSize = address.length % 2 ? (address.length - 1) / 2 : (address.length - 2) / 2;
  // eslint-disable-next-line no-param-reassign
  containerRef.innerText = formattedAddress;
  while (containerRef.scrollWidth > containerRef.offsetWidth) {
    formattedAddress = [
      address.slice(0, partsSize),
      KEYS.LONG_ADDRESS_JOIN_SYMBOL,
      address.slice(-partsSize),
    ].join('');
    partsSize -= 1;
    // eslint-disable-next-line no-param-reassign
    containerRef.innerText = formattedAddress;
  }

  return formattedAddress;
};

const createIconSourceMapFactory = (currencies: Currency[]) => () => (
  currencies.reduce((result, currency) => ({
    ...result,
    [currency.code.toLowerCase()]: currency.imageUrl,
  }), [])
);

export const DonateScreen = ({
  pledge,
  donation,
  isSubmitting,
  metamaskInstalled,
  isRipple,
  goBack,
  resetWidget,
  currencies,
  onInit,
  returnUrl,
  fetchCryptoDonationStatus,
}: DonateScreenProps) => {
  const { classes } = useStyles();
  const [metamaskScreenActive, setMetamaskScreenActive] = useState(false);
  const [formattedDonationAddress, setFormattedDonationAddress] = useState<string>('');
  const [copied, setCopied] = useState(false);
  const [tagCopied, setTagCopied] = useState(false);
  const iconSourceMap = useMemo(createIconSourceMapFactory(currencies), [currencies]);
  const isPCDEnabled = runtimeLocalStorage.get(RUNTIME_LOCAL_STORAGE_KEYS.IS_PCD_ENABLED);
  const addressRef = useRef<HTMLSpanElement>(null);
  const navigateToReturnUrl = useReturnUrl({
    returnUrl,
    fetchCryptoDonationStatus,
  });

  useEffect(() => {
    if (donation.donationAddress) {
      onInit();
    }
  }, [donation.donationAddress]);

  const [pledgedCurrency, isERC20] = useMemo(() => {
    const pledgedCurrency = currencies.find(({ code }) => code.toLowerCase() === pledge.currency);
    return [
      pledgedCurrency,
      Boolean(pledgedCurrency?.isErc20),
    ];
  }, [currencies, pledge]);

  const metamaskButtonVisible = useMemo(() => metamaskInstalled && getIsCurrencySuppored(pledge.currency, isERC20), []);

  const toggleMetamaskScreen = (value: boolean) => () => {
    setMetamaskScreenActive(value);
  };

  const getQRCenterImage = () => {
    if (isPCDEnabled) {
      return (
        <GeminiIcon
          className={classes.qrGeminiIcon}
        />
      );
    }

    return (
      <img
        src={iconSourceMap[pledge.currency]}
        alt={pledge.currency}
        className={classes.qrCryptoIcon}
      />
    );
  };

  const getQR = (string, className) => {
    if (string) {
      const qrImage = qrCode(4, 'M');
      qrImage.addData(string);
      qrImage.make();

      return (
        <div className={classes.qrWrapper}>
          {getQRCenterImage()}
          <img className={className} src={qrImage.createDataURL(4)} alt={KEYS.QR_ALT} />
        </div>
      );
    }

    return <Icon className={className} name="mainLogo" />;
  };

  const fifteenMinutesLater = new Date();
  fifteenMinutesLater.setMinutes(fifteenMinutesLater.getMinutes() + 15);

  const handleCopy = handlerFunc => () => {
    handlerFunc(true);
    setTimeout(() => {
      handlerFunc(false);
    }, 2000);
  };

  const valueToGenerateQrCode = donation.qrValue ?? donation.donationAddress;
  const paymentQR = useMemo(() => getQR(valueToGenerateQrCode, classes.qr), [valueToGenerateQrCode]);

  if (isSubmitting) {
    return <Loading text={LABELS.LOADING_ADDRESS} />;
  }

  const handleAddressRef = (element: HTMLSpanElement) => {
    // @ts-ignore
    addressRef.current = element;
    setFormattedDonationAddress(prepareDonationAddress(donation.donationAddress, addressRef.current));
  };

  const isSPL = currencies.some(({ code, network }) => (
    code.toLowerCase() === pledge.currency
    && network === KEYS.SPL_NETWORK
  ));

  const pledgedCrypto = [
    pledge.currency.toUpperCase(),
    isERC20 && KEYS.ERC_20,
    isSPL && KEYS.SPL_TOKEN,
  ].filter(Boolean).join(' ');

  const cryptoAmountString = [
    pledge.amount,
    pledgedCrypto,
  ].filter(Boolean).join(' ');

  const donationNotes = DONATION_NOTES[pledgedCrypto] || DONATION_NOTES.DEFAULT
    .replace(PLACEHOLDERS.PLEDGED_CRYPTO, pledgedCrypto);

  if (metamaskScreenActive) {
    return <MetamaskConnect goBack={toggleMetamaskScreen(false)} currency={pledgedCurrency} />;
  }

  const hasReturnUrl = Boolean(returnUrl);

  return (
    <Container className={classes.donationContainerWrapper}>
      <PageHeader
        label={(
          <>
            <span className={classes.cryptoAmount}>
              {cryptoAmountString}
            </span>
            <Tooltip
              title={LABELS.DONATION_NOTE}
              enterTouchDelay={50}
              classes={{
                tooltip: classes.donationNoteTooltip,
              }}
            >
              <span className={classes.iconInfoWrapper}>
                <IconInfo width={16} height={16} color={COLORS.GREY} />
              </span>
            </Tooltip>
          </>
        )}
        withBackButton
        onGoBack={goBack}
      />
      <div className={classes.topContainer}>
        <div className={classes.topMessageContainer}>
          <span>
            {LABELS.DONATE_PROPMT}
          </span>
        </div>
        {paymentQR}
      </div>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        gap="15px"
      >
        <CopyToClipboard
          text={donation.donationAddress}
          onCopy={handleCopy(setCopied)}
        >
          <button type="button" className={classes.addressCopyButton}>
            <OptionallyVisible visible={copied}>
              <span>{LABELS.COPIED}</span>
            </OptionallyVisible>
            <OptionallyVisible visible={!copied}>
              <span ref={handleAddressRef} className={classes.address}>{formattedDonationAddress}</span>
            </OptionallyVisible>
            <span className={classes.iconCopyWrapper}>
              <IconCopy width={20} height={20} color={COLORS.PRIMARY} />
            </span>
          </button>
        </CopyToClipboard>
        <OptionallyVisible visible={isRipple}>
          <CopyToClipboard
            text={donation.destinationTag}
            onCopy={handleCopy(setTagCopied)}
          >
            <button type="button" className={classes.addressCopyButton}>
              <div className={classes.destinationTagLabel}>{LABELS.DESTINATION_TAG}</div>
              <OptionallyVisible visible={tagCopied}>
                <span>{LABELS.COPIED}</span>
              </OptionallyVisible>
              <OptionallyVisible visible={!tagCopied}>
                <span>{donation.destinationTag}</span>
              </OptionallyVisible>
              <span className={classes.iconCopyWrapper}>
                <IconCopy width={20} height={20} color={COLORS.PRIMARY} />
              </span>
            </button>
          </CopyToClipboard>
        </OptionallyVisible>
      </Box>
      <OptionallyVisible visible={metamaskButtonVisible}>
        <Button className={classes.metamaskButton} onClick={toggleMetamaskScreen(true)}>
          <IconMetamask className={classes.walletButtonIcon} />
          {LABELS.DONATE_WITH_METAMASK}
        </Button>
      </OptionallyVisible>
      <div>
        <p className={classes.donationNotes}>
          <OptionallyVisible visible={isRipple}>
            <>
              {LABELS.XRP_DONATION_NOTE}
              <br />
            </>
          </OptionallyVisible>
          {donationNotes}
        </p>
      </div>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <OptionallyVisible visible={!hasReturnUrl}>
          <Button
            onClick={resetWidget}
            className={classes.resetButton}
          >
            {LABELS.RESET_BUTTON_TEXT}
          </Button>
        </OptionallyVisible>
        <OptionallyVisible visible={hasReturnUrl}>
          <Button
            onClick={navigateToReturnUrl}
            className={classes.readyButton}
          >
            {LABELS.CLICK_WHEN_READY}
          </Button>
        </OptionallyVisible>
      </Box>
    </Container>
  );
};

export default DonateScreen;
