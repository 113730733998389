import { initialLoadingAndErrorState, LoadingAndErrorState } from 'state/loadingAndError';
import { ChariotSession } from 'types/chariot';

import { AnyReduxState } from '../state.types';

export type ChariotReduxState = AnyReduxState & {
  connection: LoadingAndErrorState & {
    id: string | null;
  };
  donation: LoadingAndErrorState & {
    session: ChariotSession | null;
  };
  details: LoadingAndErrorState & {
    amount: number | null;
  };
}

export const initialState = (): ChariotReduxState => ({
  connection: {
    ...initialLoadingAndErrorState,
    id: null,
  },
  donation: {
    ...initialLoadingAndErrorState,
    session: null,
  },
  details: {
    ...initialLoadingAndErrorState,
    amount: null,
  },
});
