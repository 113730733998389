import React, {
  ChangeEvent,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import {
  Autocomplete,
  AutocompleteProps,
  TextField,
} from '@mui/material';

import { locators, QALocator } from 'utils/tests/QA';

import useStyles from './styles';

export interface ComboboxProps<T> extends Omit<AutocompleteProps<T, any, any, any>, 'renderInput'>{
  options: T[];
  placeholder?: string;
  value: string | T | (string | T)[] | null;
  onChange: (event: ChangeEvent<{}>, value: string | T | (string | T)[] | null) => void;
  renderItem: (item: T) => ReactNode;
  error?: boolean;
  helperText?: string;
  onSearch?: (value: string) => void;
  getOptionSelected: (option: T, selected: T) => boolean;
}

export const Combobox = <T extends object>({
  onChange,
  placeholder = '',
  renderItem,
  className,
  value,
  error,
  helperText,
  onSearch,
  ...props
}: ComboboxProps<T>) => {
  const { cx, classes } = useStyles();
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    onSearch?.(inputValue);
  }, [inputValue]);

  const handleChange = (event: ChangeEvent<{}>, value: string | T | (string | T)[] | null) => {
    onChange(event, value as T[]);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputValue(value);
  };

  const renderOption = (props: Object, item: T) => <div {...props}>{renderItem(item)}</div>;

  return (
    <Autocomplete
      {...props}
      value={value as NonNullable<T>}
      autoHighlight
      onChange={handleChange}
      renderOption={renderOption}
      className={cx(classes.combobox, props.classes?.root)}
      classes={{
        popper: classes.dropdown,
      }}
      {...QALocator(locators.components.common.combobox)}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant="outlined"
          className={classes.input}
          error={error}
          helperText={helperText}
          classes={{
            root: props.classes?.input,
          }}
          {...QALocator(locators.components.common.comboboxInput)}
          InputProps={{
            ...params.InputProps,
            value: inputValue,
            onChange: handleInputChange,
          }}
        />
      )}
    />
  );
};
