import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

export const useStyles = makeStyles()({
  fundsDesignationWrapper: {
    '&.MuiFormControl-root': {
      display: 'flex',
      margin: '0 auto 0',
      '& .MuiInputBase-input': {
        fontSize: '16px',
        lineHeight: '23px',
        padding: '13px 20px',
        fontFamily: 'Noto Sans',
        fontWeight: 400,
        color: COLORS.PRIMARY,
        '&::focused': {
          background: 'transparent',
        },
      },
    },
  },
  root: {
    [`&:hover .MuiOutlinedInput-notchedOutline,
    &.Mui-focused .MuiOutlinedInput-notchedOutline`]: {
      borderColor: '#FCD42B !important',
      borderRadius: 5,
      paddingLeft: 5,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D5DDE3',
      borderRadius: 5,
      paddingLeft: 5,
    },
  },
  designationMenuItemContent: {
    fontFamily: 'Noto Sans',
    fontSize: '16px',
    lineHeight: '23px',
    fontWeight: 400,
    display: 'block !important',
    whiteSpace: 'nowrap !important' as 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
  },
});

export default useStyles;
