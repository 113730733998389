import React from 'react';
import { Typography } from '@mui/material';

import { Icon } from 'components';
import OptionallyVisible from 'components/optionallyVisible';
import { OrganizationInfo } from 'types/organization';

import useStyles from './styles';

interface HeaderProps {
  organization: OrganizationInfo;
}

const MAX_NAME_LENGTH = 115;

export const Header = ({ organization }: HeaderProps) => {
  const { classes, cx } = useStyles({});
  const { name } = organization;
  const isLongName = name?.length > MAX_NAME_LENGTH;

  return (
    <div className={classes.topBarContainer}>
      <div className={classes.charityNameContainer}>
        <div className={classes.imageContainer}>
          <OptionallyVisible visible={Boolean(organization.logo)}>
            <img className={classes.image} src={organization.logo} alt={organization.name} />
          </OptionallyVisible>
          <OptionallyVisible visible={!organization.logo}>
            <Icon className={classes.image} name="charityLogo" />
          </OptionallyVisible>
        </div>
        <Typography
          className={cx({
            [classes.charityName]: true,
            [classes.charityNameLong]: isLongName,
          })}
          variant="h6"
        >
          {name}
        </Typography>
      </div>
    </div>
  );
};

export default Header;
