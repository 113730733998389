export const KEYS = {
  CONTAINER_IDENTIFIER: 'connectContainer',
  CHARIOT_NODE_NAME: 'chariot-connect',
  CHARIOT_SCRIPT_IDENTIFIER: 'chariot-script',
  DISABLED_ATTRIBUTE: 'disabled',
};

export enum Event {
  Init = 'CHARIOT_INIT',
  Success = 'CHARIOT_SUCCESS',
  Exit = 'CHARIOT_EXIT'
}
