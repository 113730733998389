import React from 'react';
import { Paper, Typography } from '@mui/material';

import mainLogo from 'assets/images/main-logo.png';
import OptionallyVisible from 'components/optionallyVisible';
import { OrganizationInfo } from 'types/organization';

import { useStyles } from './disabled.styles';

const KEYS = {
  DISABLED_PROMPT: 'Donations for {org} are unavailable at this moment.',
  COVER_LOGO_ALT: 'Disabled cover logo',
};

export interface DisabledPageProps {
  organization: OrganizationInfo;
}

export const DisabledPage = ({ organization }: DisabledPageProps) => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <Paper elevation={3}>
        <div className={classes.contentContainer}>
          <div className={classes.header} />
          <div className={classes.content}>
            <OptionallyVisible visible={!organization?.isPCDEnabled}>
              <img src={mainLogo} className={classes.logo} alt={KEYS.COVER_LOGO_ALT} />
            </OptionallyVisible>
            <Typography className={classes.prompt}>
              {KEYS.DISABLED_PROMPT.replace('{org}', organization?.name)}
            </Typography>
          </div>
          <div className={classes.footer} />
        </div>
      </Paper>
    </div>
  );
};

export default DisabledPage;
