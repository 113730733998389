import { Dispatch } from 'redux';

import { API, Endpoints } from 'api';
import DonationWorkflowType from 'types/workflow';

import { AppReduxState } from '../../state';
import fetchAssetExchangeRateFail from '../fetchAssetExchangeRate.fail';
import fetchAssetExchangeRateStart from '../fetchAssetExchangeRate.start';
import fetchAssetExchangeRateSuccess from '../fetchAssetExchangeRate.success';

interface FetchCurrencyResponse {
  data: {
    rate: number;
  };
  status: string;
}

const DEFAULT_ERROR = {
  errorCode: 'UNKNOWN_ERROR',
};

export const fetchAssetExchangeRate = (ticker: string) => async (dispatch: Dispatch, getState: () => AppReduxState) => {
  dispatch(fetchAssetExchangeRateStart.createAction());
  if (!ticker) {
    return null;
  }

  try {
    const response: FetchCurrencyResponse = await API.get(Endpoints.assetExchangeRate, { ticker });
    if (response?.data?.rate) {
      const currentState = getState();
      if (currentState.workflow.type === DonationWorkflowType.Stock) {
        dispatch(fetchAssetExchangeRateSuccess.createAction(response.data.rate));
      }

      return response.data;
    }

    dispatch(fetchAssetExchangeRateFail.createAction(DEFAULT_ERROR));
    return DEFAULT_ERROR;
  } catch (e: any) {
    dispatch(fetchAssetExchangeRateFail.createAction(e));
    return e;
  }
};

export default fetchAssetExchangeRate;
