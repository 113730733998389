import React from 'react';
import {
  IconButton as MaterialIconButton,
  IconButtonProps,
} from '@mui/material';

import { KEYS } from './iconButton.keys';
import useStyles from './styles';

export const IconButton = (props: IconButtonProps) => {
  const { classes, cx } = useStyles();
  const { color, className, disabled } = props;
  const isPrimary = !disabled && color === KEYS.PRIMARY;
  const isSecondary = !disabled && color === KEYS.SECONDARY;
  const isDefault = !disabled && color === KEYS.DEFAULT;

  return (
    <MaterialIconButton
      {...props}
      className={cx(classes.iconButton, {
        [className as string]: Boolean(className),
        [classes.disabledButton]: disabled,
        [classes.primaryButton]: isPrimary,
        [classes.secondaryButton]: isSecondary,
        [classes.defaultButton]: isDefault,
      })}
    />
  );
};

export default IconButton;
