import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';
import FONT_FAMILIES from 'theme/fontFamilies';

const useStyles = makeStyles<{isFocused: boolean}>()((theme, { isFocused }) => ({
  amountBoxWrapper: {
    width: '100%',
    marginBottom: 20,
  },
  amountBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '13px 20px',
    border: isFocused
      ? '2px solid #FCD42B'
      : '1px solid #D5DDE3',
    boxShadow: '0, 3, 12, rgba(41, 27, 79, 0.05)',
    borderRadius: 5,
  },
  error: {
    border: '2px solid #FCD42B !important',
  },
  errorText: {
    fontFamily: 'Noto Sans!important',
    fontSize: '14px!important',
    lineHeight: '20px!important',
    fontWeight: '700!important',
    color: `${COLORS.TEXT.PRIMARY}!important`,
  },
  mainInputContainer: {
    flex: 3,
  },
  mainCurrencyInput: {
    fontFamily: 'Noto Sans !important',
    fontWeight: 400,
    fontSize: 16,
    height: '23px',
    lineHeight: '23px',
    paddingBottom: 0,
    paddingTop: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  secondaryInputContainer: {
    flex: 2,
    display: 'flex',
    flexDirection: 'row',
  },
  secondaryInputContainerExtended: {
    flex: 3,
    display: 'flex',
    flexDirection: 'row',
  },
  secondaryCurrencyInput: {
    fontFamily: 'Noto Sans !important',
    fontWeight: 400,
    fontSize: 16,
    height: '23px',
    lineHeight: '23px',
    paddingBottom: 0,
    paddingTop: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  inputDecoration: {
    whiteSpace: 'nowrap',
    wordWrap: 'normal',
    fontFamily: 'Noto Sans !important',
    fontWeight: 400,
    fontSize: 16,
    height: '23px',
    lineHeight: '23px',
    color: '#928E9C',
    marginRight: 4,
  },
  donationTooltip: {
    width: 300,
    border: 5,
    fontFamily: `${FONT_FAMILIES.NOTO_SANS} !important`,
    color: COLORS.WHITE,
    backgroundColor: COLORS.GREY,
    textAlign: 'center',
    padding: '10px 15px !important',
    fontSize: 14,
    borderRadius: 5,
    fontWeight: 400,
  },
  iconEyeTooltip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: -8,
    cursor: 'pointer',
  },
  icon: {
    width: 16,
  },
}));

export default useStyles;
