import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';
import FONT_FAMILIES from 'theme/fontFamilies';

export const useStyles = makeStyles()({
  scrollContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: 400,
  },
  processingLabel: {
    color: COLORS.PRIMARY,
    textAlign: 'center',
    fontFamily: FONT_FAMILIES.POPPINS,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    marginTop: 15,
  },
  loadingIcon: {
    width: 50,
  },
  plaidLogo: {
    marginLeft: 8,
  },
  mandateScreen: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export default useStyles;
