import React, { ChangeEvent } from 'react';
import { Checkbox as MUICheckbox, FormControlLabel } from '@mui/material';

import { locators, QALocator } from 'utils/tests/QA';

import { CheckboxProps } from './checkbox.types';

export const Checkbox = ({
  label,
  name,
  value,
  onChange,
  disabled = false,
}: CheckboxProps) => {
  const handleChange = (event: ChangeEvent<{}>, checked: boolean) => {
    onChange(checked);
  };

  return (
    <FormControlLabel
      disabled={disabled}
      control={<MUICheckbox checked={value} {...QALocator(locators.components.common.checkbox)} />}
      label={label}
      name={name}
      onChange={handleChange}
      value={value}
    />
  );
};

export default Checkbox;
