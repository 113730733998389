import React from 'react';

import IconApple from 'components/icon/apple';

import { KEYS, LABELS } from './keys';
import useStyles from './styles';

export interface ApplePayProps {
  onPay: () => void;
}

export const ApplePay = ({ onPay }: ApplePayProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <button
        className={classes.button}
        type="button"
        onClick={onPay}
        id={KEYS.BUTTON_ID}
      >
        <IconApple className={classes.icon} />
        {LABELS.PAY}
      </button>
    </div>
  );
};
