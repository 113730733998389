export enum GTMEventType {
  DonationStart = 'donationStart',
  PersonalInfo = 'donationPersonalInfo',
  TaxReceipt = 'donationTaxReceipt',
  BrokerInfo = 'donationBrokerInfo',
  DonationSent = 'donationSent',
  DonationShare = 'donationShare',
}

export default GTMEventType;
