import React, {
  useEffect,
  useMemo,
} from 'react';
import {
  Box,
  Container,
} from '@mui/material';

import Checkbox from 'components/checkbox';
import { OptionallyVisible } from 'components/optionallyVisible';
import { SelectOption } from 'pages/common/pledge/fundsDesignations/types';
import FundsDesignations from 'pages/common/pledge/overlayVariant/fundsDesignations';
import { OrganizationInfo } from 'types/organization';
import { StocksDonationPledge, StockTickerSymbol } from 'types/pledge';
import { locators, QALocator } from 'utils/tests/QA';

import { KEYS } from '../stockDonationPledge.keys';
import { StockCurrencyAmountBox } from './amountBox/currencyAmountBox.stock';
import LABELS from './stockDonationPledge.keys';
import StockNameInput from './stockTickerInput/stockNameInput';
import StockTickerInput from './stockTickerInput/stockTickerInput';
import useStyles from './styles';

export interface PledgeFormProps {
  pledge: StocksDonationPledge;
  onChange: (pledge: Partial<StocksDonationPledge>) => void;
  organization: OrganizationInfo;
  updateExchangeRate: () => void;
  error: Record<string, string> | null;
  isExchangeRateUnavailable: boolean;
}

export const PledgeForm = ({
  pledge,
  onChange,
  organization,
  updateExchangeRate,
  error,
  isExchangeRateUnavailable,
}: PledgeFormProps) => {
  const { classes, cx } = useStyles();
  const shouldShowTributesControl = organization.areNotesEnabled && organization.areTributesEnabled;

  useEffect(() => {
    updateExchangeRate();
  }, [pledge.asset]);

  const fundsDesignationOptions = useMemo(() => {
    const fundsDesignations = organization.fundsDesignations || [];

    return fundsDesignations.map(({ value, uuid }) => ({
      label: value,
      id: uuid,
    }));
  }, [organization.fundsDesignations]);

  const containerClasses = cx([
    classes.contentContainer,
    !organization.areNotesEnabled ? classes.contentContainerPadded : null,
  ]);

  const handleChange = (field: string) => (value: any) => {
    onChange({ [field]: value });
  };

  const handleAssetChange = (assets: StockTickerSymbol[]) => {
    const [asset] = assets;
    handleChange(KEYS.ASSET)(asset);
  };

  const handleQuantityChange = (value: number) => {
    handleChange(KEYS.QUANTITY)(value);
  };

  const hasDesignationOptions = fundsDesignationOptions.length > 0;
  const areDesignationsEnabled = Boolean(organization.fundsDesignations) && hasDesignationOptions;

  return (
    <Container className={containerClasses}>
      <StockTickerInput
        selected={pledge.asset}
        onChange={handleAssetChange}
      />
      <StockNameInput
        selected={pledge.asset}
        onChange={handleAssetChange}
      />
      <StockCurrencyAmountBox
        onMainAmountChange={handleQuantityChange}
        pledge={pledge}
        error={error?.quantity}
        isExchangeRateUnavailable={isExchangeRateUnavailable}
      />
      <OptionallyVisible visible={areDesignationsEnabled}>
        <div className={classes.inputButtonsWrapper}>
          <FundsDesignations
            options={fundsDesignationOptions as SelectOption[]}
            onChange={handleChange(KEYS.FUNDS_DESIGNATION)}
            value={pledge.fundsDesignation || ''}
          />
        </div>
      </OptionallyVisible>
      <OptionallyVisible visible={organization.areNotesEnabled}>
        <Box
          className={classes.checkboxContainer}
          {...QALocator(locators.page.common.components.notesCheckbox)}
        >
          <Checkbox
            onChange={handleChange(KEYS.ARE_NOTES_ENABLED)}
            value={pledge.areNotesEnabled}
            name={KEYS.ARE_NOTES_ENABLED}
            label={(
              <div
                className={classes.checkboxLabel}
              >
                {LABELS.NOTES_CHECKBOX_LABEL}
              </div>
            )}
          />
        </Box>
      </OptionallyVisible>
      <OptionallyVisible visible={shouldShowTributesControl}>
        <Box
          className={classes.checkboxContainer}
          {...QALocator(locators.page.common.components.tributesCheckbox)}
        >
          <Checkbox
            onChange={handleChange(KEYS.ARE_TRIBUTES_ENABLED)}
            value={pledge.areTributesEnabled}
            name={KEYS.ARE_TRIBUTES_ENABLED}
            label={(
              <div
                className={classes.checkboxLabel}
              >
                {LABELS.TRIBUTE_CHECKBOX_LABEL}
              </div>
            )}
          />
        </Box>
      </OptionallyVisible>
    </Container>
  );
};

export default PledgeForm;
