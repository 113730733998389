import React, { useMemo } from 'react';

import { DonateConnect } from 'pages/common/donate/donate.connect';
import { DonorInfoConnect } from 'pages/common/donorInfo/donorInfo.connect';
import { ErrorConnect } from 'pages/common/error/error.connect';
import { NotesConnect } from 'pages/common/notes/notes.connect';
import { PledgeConnect } from 'pages/common/pledge/pledge.connect';
import { CryptoDonationWorkflowStep } from 'types/workflow';

const componentMap = {
  [CryptoDonationWorkflowStep.Pledge]: PledgeConnect,
  [CryptoDonationWorkflowStep.Notes]: NotesConnect,
  [CryptoDonationWorkflowStep.FillDonorInfo]: DonorInfoConnect,
  [CryptoDonationWorkflowStep.Error]: ErrorConnect,
  [CryptoDonationWorkflowStep.Donate]: DonateConnect,
};

interface CryptoRouterProps {
  step: CryptoDonationWorkflowStep;
}

export const CryptoRouter = ({ step }: CryptoRouterProps) => {
  const Component = useMemo(() => componentMap[step], [step]);
  return (
    <Component />
  );
};

export default CryptoRouter;
