import React from 'react';

import ICONS from 'constants/icons';

interface IconProps {
  name: string;
  className: string;
}

const Icon = ({
  name,
  className = '',
}: IconProps) => {
  if (ICONS[name] !== undefined) {
    if (ICONS[name].type === 'image') {
      return <img alt={name} className={className} src={ICONS[name].src} />;
    }
    if (ICONS[name].type === 'muiIcon') {
      return <div className={className}>{ICONS[name].component}</div>;
    }
  }
  return <img alt={name} src={ICONS.mainLogo.src} className={className} />;
};

export default Icon;
