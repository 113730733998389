import { AnyAction } from 'state/state.types';

import { ChariotReduxState } from './state';

export const actionTypeName = 'CHARIOT__FETCH_AMOUNT__SUCCESS';

type ActionFetchDonationAmountSuccess = AnyAction & {
  amount: number;
}

export const createAction = (amount: number) => ({
  type: actionTypeName,
  amount,
});

export const reducer = (
  state: ChariotReduxState,
  { amount }: ActionFetchDonationAmountSuccess,
): ChariotReduxState => ({
  ...state,
  details: {
    ...state.details,
    amount,
    isLoading: false,
  },
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
