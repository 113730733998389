/* eslint-disable no-console */
export class Logger {
  static isEnabled = window?.donationFormConfig?.enableLogs;

  static log(...args) {
    if (Logger.isEnabled) {
      console.log(...args);
    }
  }

  static error(...args) {
    if (Logger.isEnabled) {
      console.error(...args);
    }
  }

  static warn(...args) {
    if (Logger.isEnabled) {
      console.warn(...args);
    }
  }

  static trace(...args) {
    if (Logger.isEnabled) {
      console.trace(...args);
    }
  }
}

export default Logger;
