import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useField } from 'formik';

import { DonationWorkflowType } from 'types/workflow';

import { AUTOCOMPLETE_ACTIONS } from './fields.keys';
import { InputViewType, SelectOption } from './fields.types';
import { useStyles } from './styles';
import { SetFieldValueFunction } from './types';

interface AutocompleteFieldProps {
  item: any;
  values: any;
  disabled: boolean;
  isSubmitting: boolean;
  setFieldValue: SetFieldValueFunction;
  workflowType: DonationWorkflowType;
  setFieldTouched: (name: string, touched: boolean) => void;
}

export const AutocompleteField = ({
  item,
  values,
  isSubmitting,
  setFieldValue,
  setFieldTouched,
  workflowType,
}: AutocompleteFieldProps) => {
  const [error, setError] = useState(false);
  const [, meta] = useField({ name: item.name });
  const validationError = !item.disabled && !!((isSubmitting || meta.touched) && meta.error);
  const { classes, cx } = useStyles();
  const fieldName = item?.name;
  const idFieldName = `${fieldName}Id`;
  const currentValue = values[fieldName] || '';
  const canValidate = !item.isDisabled || isSubmitting;
  const shouldValidate = canValidate && item.isRequired;
  const hasShortDisplayType = item.view.display === InputViewType.Short;
  const isViewOptionApplicable = item.view?.appliesTo.includes(workflowType);
  const isShortField = hasShortDisplayType && isViewOptionApplicable;

  const getOptionLabel = (option: SelectOption | string) => (option as SelectOption)?.label || (option as string) || '';

  const onChangeOption = (_, option, action) => {
    setFieldTouched(fieldName, true);
    const optionId = option?.id;
    const optionLabel = option?.label;
    setFieldValue(fieldName, optionLabel, true);
    setFieldValue(idFieldName, optionId, false);

    const isInvalid = shouldValidate && action === AUTOCOMPLETE_ACTIONS.CLEAR;
    setError(isInvalid);
  };

  const onTypeOption = (event, value: string) => {
    const eventIsNull = event === null;
    const valueIsUnchanged = value === values[fieldName];

    if (valueIsUnchanged || eventIsNull) {
      return;
    }

    const isInvalid = shouldValidate && value.length === 0;
    setFieldValue(fieldName, value, true);
    setFieldTouched(fieldName, true);
    setError(isInvalid);
  };

  const renderInput = (params) => {
    const hasError = !params.disabled
      && (error || validationError);

    return (
      <TextField
        {...params}
        classes={{
          root: classes.autocompleteInput,
        }}
        margin="dense"
        required={item.isRequired}
        label={item.label}
        error={hasError}
      />
    );
  };

  useEffect(() => {
    if (!item.isDisabled) {
      setError(false);
    }
  }, [values, item.isDisabled]);

  useEffect(() => {
    setError(item.error);
  }, [item.error]);

  return (
    <div className={cx(classes.formItem, {
      [classes.longView]: !isShortField,
    })}
    >
      <Autocomplete
        disabled={item.isDisabled}
        freeSolo
        openOnFocus
        blurOnSelect
        options={item.options}
        getOptionLabel={getOptionLabel}
        onChange={onChangeOption}
        onInputChange={onTypeOption}
        renderInput={renderInput}
        className={classes.autocompleteInput}
        inputValue={currentValue}
      />
    </div>
  );
};

export default AutocompleteField;
