import React from 'react';

import { Button, ButtonDisplayVariant } from 'components/button/button';

import { KEYS, LABELS } from './keys';
import useStyles from './styles';
import { PoweredByBrand, PoweredByBrandType } from './types';

interface FooterProps {
  brand: PoweredByBrandType;
  openSupportDialog: () => void;
}

export const Footer = ({
  brand = PoweredByBrand.Crypto,
  openSupportDialog,
}: FooterProps) => {
  const { classes } = useStyles();
  const alt = LABELS.ALT[brand];
  const imageSrc = KEYS.LOGO[brand];
  const link = KEYS.LINK[brand];

  const supportButtonClicked = (event) => {
    event.preventDefault();
    event.stopPropagation();
    openSupportDialog();
  };

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={link}
      className={classes.poweredContainer}
    >
      <div className={classes.powerByContainer}>
        {LABELS.POWERED_BY}
        <img className={classes.poweredLogo} src={imageSrc} alt={alt} />
      </div>
      <Button
        display={ButtonDisplayVariant.text}
        className={classes.helpButton}
        onClick={supportButtonClicked}
      >
        {LABELS.HELP}
      </Button>
    </a>
  );
};

export default Footer;
