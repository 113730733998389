import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

const useStyles = makeStyles()({
  button: {
    margin: 10,
    padding: 5,
  },
  topContainer: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    marginTop: 0,
  },
  topLeftContainer: {
    marginTop: 5,
    width: '100%',
    alignItems: 'center',
  },
  topMessageContainer: {
    width: '270px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  topLeftContainerLong: {
    marginTop: 10,
    width: 280,
    alignItems: 'center',
  },
  timer: {
    paddingLeft: 37,
  },
  amountText: {
    marginTop: 8,
    paddingTop: 2,
    fontSize: 12,
    lineHeight: '16px',
    textAlign: 'center',
  },
  qr: {
    width: 130,
    height: 130,
    display: 'block',
  },
  separated: {
    marginRight: 1,
  },
  qrWrapper: {
    position: 'relative',
    marginTop: 0,
  },
  iconWrapQR: {
    width: 24,
    height: 24,
    top: 53,
    left: 53,
    position: 'absolute',
    color: '#000',
    backgroundColor: '#FFF',
    fontSize: '12px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addressInformationContainer: {
    marginTop: 11,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  adresses: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  addressContainer: {
    wordWrap: 'break-word',
    textAlign: 'center',
    color: '#2D2E31',
    fontSize: '10px',
    lineHeight: '16px',
  },
  smallFontSizeAddressContainer: {
    fontSize: '8px !important',
  },
  tagLabel: {
    color: COLORS.TEXT.INSUBSTANTIAL_GREY,
    fontSize: 13,
    fontWeight: 500,
    marginBottom: 3,
  },
  donationContainerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100% - 72px)',
    overflow: 'auto',
    '&.MuiContainer-root': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  addressCopyButton: {
    color: '#2D2E32 !important',
    fontWeight: 500,
    lineHeight: '28px',
    background: 'transparent',
    outline: 0,
    border: '1px solid #DADFE5',
    display: 'flex',
    margin: '0 auto',
    width: 252,
    cursor: 'pointer',
    fontSize: 12,
    fontFamily: 'Roboto !important',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    height: 35,
  },
  donationTooltip: {
    maxWidth: '90vw',
  },
  iconEyeTooltip: {
    marginLeft: 5,
    cursor: 'pointer',
    color: '#86909F',
  },
  iconWrapCopy: {
    marginLeft: 5,
    color: '#86909F',
  },
  donationNotes: {
    color: '#E4464C',
    fontSize: '10px',
    lineHeight: '11px',
    maxWidth: 243,
    textAlign: 'center',
    margin: '5px auto',
  },
  dialogPaper: {
    width: 252,
    display: 'flex',
    flexDirection: 'column',
  },
  addressText: {
    display: 'block',
    marginTop: -5,
    color: 'grey',
    alignText: 'center',
    fontSize: '0.6rem',
  },
  payByLink: {
    display: 'block',
    marginTop: -5,
    color: '#281b4f',
    textShadow: '2px 2px 4px #FFFFFF',
    textAlign: 'center',
    fontSize: 15,
  },
  learnMore: {
    display: 'block',
    textAlign: 'center',
    fontSize: 12,
    marginTop: 80,
  },
  contentContainer: {
    overflowY: 'visible !important' as 'visible',
    padding: '8px 23px',
  },
  bottomContainer: {
    marginTop: 0,
    justifyContent: 'center',
    display: 'flex',
    '& .MuiFormControl-marginDense': {
      marginTop: 0,
    },
  },
  checkbox: {
    height: 5,
    color: 'rgba(42, 35, 78)',
  },
  checkboxLabel: {
    fontSize: 12,
    color: '#86909F',
  },
  tooltipIcon: {
    marginLeft: 5,
    cursor: 'pointer',
    color: '#86909F',
  },
  checkboxRow: {
    marginTop: 8,
    marginBottom: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  emailPromptText: { color: '#867486', lineHeight: 0.5 },
  subscriptionText: {
    display: 'inline-block',
    color: 'grey',
    lineHeight: 0.5,
    fontSize: 12,
  },
  iframeWrapper: {
    height: 480,
  },
  checkboxContainer: {
    display: 'flex',
  },
  copyButtonWrapper: {
    display: 'flex',
    textAlign: 'center',
  },
  copyButton: {
    fontSize: '0.66rem !important',
    margin: '0 auto !important',
  },
  successText: {
    margin: '0 auto',
  },
  dialogTitle: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '32px',
    color: '#2D2E31',
    paddingBottom: 0,
  },
  modalContainer: {
    width: '100%',
    '& p.MuiTypography-colorTextSecondary': {
      display: 'block',
      marginBottom: 0,
    },
  },
  defaultInput: {
    marginTop: 0,
    '& label': {
      fontSize: '12px',
    },
    '& .MuiInputBase-input': {
      paddingTop: '6.5px',
      paddingBottom: '6.5px',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 9px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& fieldset': {
      borderColor: '#DADFE5',
      borderRadius: '3px',
    },
  },
  metamaskButton: {
    width: 252,
    height: '35px !important',
    color: `${COLORS.PRIMARY} !important`,
    backgroundColor: `${COLORS.WHITE} !important`,
    border: `1px solid ${COLORS.LIGHT_GREY} !important`,
    boxSizing: 'border-box',
    borderRadius: 5,
    margin: '10px auto 0px auto !important',
    display: 'flex !important',
    fontSize: 13,
    fontWeight: 500,
    fontFamily: 'Roboto !important',
  },
  walletButtonIcon: {
    marginRight: 10,
  },
  iconCopyWrapper: {
    marginLeft: 5,
    color: '#86909F',
    height: 20,
    width: 20,
  },
  dialogText: {
    width: 212,
    margin: '0 auto',
    fontSize: 12,
    padding: 0,
    lineHeight: '16px',
    textAlign: 'center',
    color: '#86909F',
    overflow: 'hidden',
  },
  dialogContent: {
    padding: '8px 23px',
    marginTop: 8,
    overflow: 'hidden',
  },
  returnUrlMessage: {
    marginTop: 0,
    marginBottom: 10,
    fontSize: 12,
    lineHeight: '16px',
    textAlign: 'center',
  },
  additionalMarginReturnUrlMessage: {
    marginTop: 20,
  },
});

export const styles = {
  absoluteContainer: {
    position: 'absolute',
    width: '100%',
  },
  dialogActions: {
    width: '100%',
    padding: 0,
    margin: 0,
    height: 48,
  },
};

export default useStyles;
