import { makeStyles } from 'tss-react/mui';

export const SHIFT4_COMPONENTS_STYLES = {
  style: {
    base: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: '14px',
      fontFamily: 'Roboto',
      '::placeholder': {
        color: '#a6a6a6',
      },
    },
    invalid: {
      color: '#F44336',
      '::placeholder': {
        color: '#a6a6a6',
      },
    },
  },
  fonts: [{
    family: 'Roboto',
    style: 'normal',
    weight: 400,
    display: 'swap',
    src: "url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2');",
  }],
};

export const useStyles = makeStyles()({
  '@global': {
    '.Shift4Component--focus': {
      border: '2px solid #000 !important',
      margin: -1,
      marginTop: 4,
    },
    '.Shift4Component--invalid': {
      border: '1px solid #f44336 !important',
    },
    '.Shift4Component--invalid .inputAdornmentError': {
      display: 'block !important',
    },
  },
  formContainer: {
    overflowY: 'auto',
    height: 'calc(100% - 70px)',
  },
  container: {
    paddingLeft: 34,
    paddingRight: 34,
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: 'repeat(2, minmax(0,1fr))',
    gap: '0px 4px',
    '&$anonDisabled': {
      paddingTop: 16,
    },
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  creditCard: {
    padding: 5,
  },
  longView: {
    gridColumn: '1 / span 2',
  },
  anonDisabled: {},
  shortInput: {
    width: '50%',
    '&:first-of-type': {
      width: 'calc(50% - 5px)',
      marginRight: 5,
    },
  },
  defaultInput: {
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 9px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& fieldset': {
      borderColor: '#DADFE5',
      borderRadius: '3px',
    },
  },
  sectionHeader: {
    textAlign: 'left',
    marginTop: '10px',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#2D2E31',
  },
  donationAmount: {
    fontSize: '26px !important',
    textAlign: 'center',
    fontWeight: '700 !important',
    margin: '10px !important',
  },
  withLessEmptySpace: {
    marginBottom: '0 !important',
  },
  thirdPartyPaymentMethodsContainer: {
    display: 'grid',
    gridColumn: '1 / span 2',
    gridTemplateColumns: '1fr',
    gap: 10,
  },
  dividerContainer: {
    gridColumn: '1 / span 2',
    marginTop: '13px',
    marginBottom: '8px',
  },
  divider: {
    background: '#D5DDE3',
    width: '100%',
    height: '1px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  dividerText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '12px',
    color: '#291B4F',
    padding: '0 17px',
  },
});

export default useStyles;
