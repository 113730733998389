import { AnyAction } from '../state.types';
import { CreditCardReduxState } from './state';

export const actionTypeName = 'CREDIT_CARD__UPDATE_CREDIT_CARD_INFO_VALUE';

export type ActionUpdateCreditCardInfoValue = AnyAction & {
  field: string;
  value: string;
}

export const createAction = (field: string, value: string) => ({
  type: actionTypeName,
  field,
  value,
});

export const reducer = (
  state: CreditCardReduxState,
  action: ActionUpdateCreditCardInfoValue,
): CreditCardReduxState => {
  const { field, value } = action;
  if (!field) {
    return state;
  }

  const nextErrorState = Object.entries(state.error).reduce((acc, [key, value]) => {
    if (key === field) {
      return acc;
    }

    return { ...acc, [key]: value };
  }, {});

  return {
    ...state,
    [field]: value,
    error: nextErrorState,
  };
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
