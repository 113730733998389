import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  header: {
    backgroundColor: theme.palette.text.primary,
    height: 88,
    borderRadius: `${theme.shape.borderRadius} ${theme.shape.borderRadius} 0 0`,
  },
  footer: {
    backgroundColor: theme.palette.text.primary,
    height: 48,
    borderRadius: `0 0 ${theme.shape.borderRadius} ${theme.shape.borderRadius}`,
  },
  content: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    padding: 6,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 512,
    justifyContent: 'space-between',
    paddingTop: 0,
    paddingBottom: 0,
  },
  logo: {
    width: 276,
    marginBottom: 32,
  },
  prompt: {
    fontSize: 20,
    textAlign: 'center',
  },
}));

export default useStyles;
