/* eslint-disable react/no-array-index-key */
import React from 'react';

import { COLORS } from 'theme/colors';

import { IconProps } from './icon.types';

const path = [
  [
    'M4.41173 25.5H2.2431C1.2817 25.5 0.500488 24.7105 0.500488 23.7407V15.8026C0.500488 14.8318',
    '1.2817 14.0423 2.2431 14.0423H5.00753C5.48667 14.0423 5.95019 14.2444 6.27829 14.5985C6.61161',
    '14.9568 6.78139 15.4443 6.74493 15.9339L6.14913 23.873C6.08039 24.7844 5.31793 25.5 4.41173',
    '25.5ZM2.58371 23.4168H4.09404L4.64193 16.1255H2.58371V23.4168ZM14.5278 25.5C12.0009 25.5',
    '9.2323 24.9584 7.12095 24.0532L5.1794 23.221C4.65026 22.9949 4.40548 22.3825 4.63151',
    '21.8544C4.85754 21.3242 5.46896 21.0763 5.99914 21.3075L7.94174 22.1398C9.80726 22.9387',
    '12.2686 23.4168 14.5278 23.4168C17.4141 23.4168 19.7328 22.4293 22.693 21.0336C23.2638',
    '20.7638 23.4159 20.4888 23.4159 20.369C23.4159 20.2399 23.369 20.193 23.3534 20.1774C23.2544',
    '20.1388 22.7243 20.169 22.0191 20.4305C21.817 20.5055 21.6191 20.5701 21.4129 20.6232C18.8057',
    '21.2961 15.207 21.4377 12.453 20.9794C11.8946 20.8857 11.5134 20.3638 11.5936 19.8045C11.6738',
    '19.2441 12.1759 18.8462 12.7488 18.917C14.2133 19.0941 15.3965 19.067 16.3725 18.8379C16.6131',
    '18.7993 16.7267 18.6691 16.7267 18.5181C16.7267 18.3608 16.6131 18.2358 16.4506 18.215C14.9747',
    '18.0327 13.0998 17.7733 11.252 17.3307C10.876 17.24 9.98017 17.0963 9.18022 17.0963C8.25631',
    '17.0963 7.68967 17.2932 6.5262 17.7796C5.99498 18.0035 5.38563 17.7494 5.16377 17.2192C4.94191',
    '16.688 5.19294 16.0786 5.72416 15.8568C6.98346 15.3318 7.84487 15.012 9.18126 15.012C10.1062',
    '15.012 11.1561 15.1631 11.7394 15.3026C13.4852 15.7214 15.2861 15.9703 16.659 16.1401C17.9068',
    '16.2943 18.8099 17.314 18.8099 18.516C18.8099 18.6785 18.7932 18.8389 18.7609 18.992C19.5224',
    '18.9004 20.2463 18.7702 20.891 18.6035L21.2962 18.4754C22.1951 18.141 23.8534 17.7254 24.8273',
    '18.7025C25.2606 19.1389 25.4991 19.7295 25.4991 20.369C25.4991 20.8638 25.3116 22.1012 23.5815',
    '22.9168C21.0139 24.1282 18.1037 25.5 14.5278 25.5ZM17.1631 12.3955C16.5277 12.3955 15.8923 12.157',
    '15.409 11.681L11.0812 7.41663C10.2114 6.55938 9.77914 5.37507 9.89268 4.16472C10.0073 2.95125',
    '10.6666 1.85964 11.6999 1.16697C13.306 0.0899501 15.5174 0.338895 16.9548 1.75757L17.1891',
    '1.98776L17.4964 1.68465C18.286 0.907613 19.3807 0.481595 20.4879 0.508677C21.6149 0.538884',
    '22.6524 1.01282 23.4107 1.84402C24.8418 3.41477 24.7221 5.95942 23.1409 7.51662L18.9151',
    '11.681C18.4328 12.158 17.7974 12.3955 17.1631 12.3955ZM13.9247 2.58148C13.5477 2.58148 13.1779',
    '2.68564 12.8592 2.89917C12.3394 3.24603 12.0228 3.76579 11.9665 4.36159C11.9113 4.94489',
    '12.1217 5.51777 12.5436 5.93338L16.8715 10.1977C17.0329 10.356 17.2923 10.355 17.4537',
    '10.1977L21.6795 6.03337C22.4732 5.25216 22.5597 4.00223 21.8722 3.24811C21.5004 2.8398',
    '20.9889 2.60648 20.4338 2.59189C19.8817 2.59085 19.3547 2.78147 18.9609 3.16895L17.9224',
    '4.19181C17.5173 4.59178 16.8662 4.59178 16.46 4.19181L15.4944 3.24082C15.0538 2.80751',
    '14.482 2.58148 13.9247 2.58148Z',
  ].join(' '),
];

export const IconLoveHands = ({
  width = 14,
  height = 14,
  color = COLORS.PRIMARY,
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {path.map((item, index) => <path d={item} fill={color} key={index} />)}
  </svg>
);

export default IconLoveHands;
