import { connect, MapDispatchToPropsParam } from 'react-redux';
import { Dispatch } from 'redux';

import { DEFAULT_USD_VALUES, MINIMAL_STOCKS_QUANTITY, MINIMAL_USD_VALUES } from 'config/workflow';
import { BASIC_CURRENCIES } from 'constants/currencies';
import setAnonymous from 'state/donor/setAnonymous';
import changeFiatAmount from 'state/pledge/changeFiatAmount';
import fetchCurrencyExchangeRateSuccess from 'state/pledge/fetchCurrencyExchangeRate.success';
import setError from 'state/pledge/setError';
import fetchAssetExchangeRate from 'state/pledge/thunk/fetchAssetExchangeRate.thunk';
import updatePledge from 'state/pledge/updatePledge';
import { AppReduxState } from 'state/state';
import changeStep from 'state/workflow/changeStep';
import setType from 'state/workflow/setType';
import { StocksDonationPledge } from 'types/pledge';
import { DonationWorkflowType, StockDonationWorkflowStep } from 'types/workflow';
import componentSwitch from 'utils/componentSwitch';
import { pushDataLayerEvent } from 'utils/gtm';
import GTMEventType from 'utils/gtm/types';
import eventDataFactory from 'utils/gtm/util';
import hasMultipleFlowsEnabled, { getAvailableFlows } from 'utils/organization';
import checkIsFiatDonationType from 'utils/workflow';

import OverlayStockDonationPledgeScreen from './overlayVariant/stockDonationPledge';
import StockDonationPledgeScreen from './stockDonationPledge';
import { LABELS } from './stockDonationPledge.keys';

interface DefaultStateParams {
  defaultAmount: number | null;
  defaultCryptocurrency: string | null;
}

const mapStateToProps = (state: AppReduxState) => {
  const {
    notes,
    error,
    fundsDesignation,
    asset,
    quantity,
    usdAmount,
    exchangeRate,
    areNotesEnabled,
    areTributesEnabled,
    exchangeRateLoading,
  } = state.pledge;

  const { defaultAmount, defaultCryptocurrency } = state.donationData;

  const {
    isSubmitting,
  } = state.donation;

  const {
    organization,
  } = state.organization;

  const {
    type,
  } = state.workflow;

  const GTMEventData = eventDataFactory(GTMEventType.DonationStart, DonationWorkflowType.Stock, { state });

  const pledge: StocksDonationPledge = {
    notes,
    fundsDesignation,
    asset,
    quantity,
    usdAmount,
    exchangeRate,
    areNotesEnabled,
    areTributesEnabled,
  };

  const notEnoughStockQuantity = (quantity || 0) <= MINIMAL_STOCKS_QUANTITY;
  const hasSelectedAsset = Boolean(asset);

  const shouldCheckUsdValueAmount = hasSelectedAsset && exchangeRate !== 0;

  const isExchangeRateUnavailable = hasSelectedAsset && exchangeRate === 0 && !exchangeRateLoading;

  const notEnoughUsdValue = shouldCheckUsdValueAmount ? (usdAmount || 0) < MINIMAL_USD_VALUES.STOCK : false;
  const notEnoughQuantity = notEnoughStockQuantity || notEnoughUsdValue;
  const isDisabledSubmit = notEnoughQuantity || !asset;
  const availableFlows = getAvailableFlows(organization);
  let passedError = error;
  if (notEnoughQuantity && usdAmount) {
    passedError = error || {};
    passedError.quantity = LABELS.MINIMAL_USD_VALUE_ERROR;
  }

  return {
    GTMEventData,
    pledge,
    organization,
    error: passedError,
    isDisabledSubmit,
    type,
    isSubmitting,
    isPreviousStepAvailable: hasMultipleFlowsEnabled(organization),
    availableFlows,
    areNotesEnabled,
    areTributesEnabled,
    isExchangeRateUnavailable,
    defaultAmount,
    defaultCryptocurrency,
  };
};

const mapDispatchToProps: MapDispatchToPropsParam<any, any> = (dispatch: Dispatch<any>) => ({
  updatePledge: (changes: Partial<StocksDonationPledge>) => dispatch(updatePledge.createAction(changes)),
  proceed: (shouldShowNotesStep: boolean) => {
    const nextStep = shouldShowNotesStep
      ? StockDonationWorkflowStep.Notes : StockDonationWorkflowStep.FillDonorInfo;

    dispatch(setAnonymous.createAction(false));
    dispatch(changeStep.createAction(nextStep));
  },
  fetchAssetExchangeRate: (assetSymbol: string) => dispatch(fetchAssetExchangeRate(assetSymbol)),
  goBackToStart: () => dispatch(setType.createAction(null)),
  clearErrors: () => dispatch(setError.createAction(null)),
  setType: (type: DonationWorkflowType | null, {
    defaultAmount,
    defaultCryptocurrency,
  }: DefaultStateParams) => {
    dispatch(setType.createAction(type));
    const isFiatDonationType = checkIsFiatDonationType(type);
    if (isFiatDonationType) {
      dispatch(fetchCurrencyExchangeRateSuccess.createAction(1));
      dispatch(updatePledge.createAction({ currency: BASIC_CURRENCIES.USD }));
      dispatch(changeFiatAmount.createAction(DEFAULT_USD_VALUES.FIAT));
    } else {
      dispatch(updatePledge.createAction({ currency: defaultCryptocurrency ?? BASIC_CURRENCIES.BTC }));
      dispatch(changeFiatAmount.createAction(defaultAmount ?? DEFAULT_USD_VALUES.CRYPTO));
    }
  },
});

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
) => ({
  ...stateProps,
  ...dispatchProps,
  proceed: () => {
    const shouldShowNotesStep = stateProps.areNotesEnabled || stateProps.areTributesEnabled;
    pushDataLayerEvent(stateProps.GTMEventData);
    return dispatchProps.proceed(shouldShowNotesStep);
  },
  updateExchangeRate: () => {
    const { ticker } = stateProps.pledge.asset || {};
    return dispatchProps.fetchAssetExchangeRate(ticker);
  },
  setType: (type: DonationWorkflowType | null) => dispatchProps.setType(type, {
    defaultAmount: stateProps.defaultAmount,
    defaultCryptocurrency: stateProps.defaultCryptocurrency,
  }),
});

export const StockDonationPledgeConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(componentSwitch(StockDonationPledgeScreen, OverlayStockDonationPledgeScreen));

export default StockDonationPledgeConnect;
