import { AnyAction, Reducer } from '../state.types';
import { DonationReduxState } from './state';

export const actionTypeName = 'DONATION__SUBMIT_ACH_DONATION_PLEDGE__SUCCESS';

export type ActionSubmitAchDonationPledgeSuccess = AnyAction & {
  payload: {
    donationUuid: string;
    clientObjectId: string;
  };
}

export const createAction = (payload): ActionSubmitAchDonationPledgeSuccess => ({
  type: actionTypeName,
  payload,
});

export const reducer: Reducer<DonationReduxState, ActionSubmitAchDonationPledgeSuccess> = (
  state: DonationReduxState,
  action: ActionSubmitAchDonationPledgeSuccess,
): DonationReduxState => {
  const nextState = {
    ...state,
    isSubmitting: false,
    ach: {
      ...state.ach,
      donationUuid: action.payload.donationUuid,
      clientObjectId: action.payload.clientObjectId,
    },
  };

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
