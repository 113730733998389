import { FundraiserId } from 'types/fundraiser';

import { AnyAction } from '../state.types';
import { FundraiserReduxState } from './state';

export const actionTypeName = 'FUNDRAISER__SET_ID';

export type ActionSetFundraiserId = AnyAction & {
  fundraiserId: FundraiserId;
}

export const createAction = (fundraiserId: FundraiserId) => ({
  type: actionTypeName,
  fundraiserId,
});

export const reducer = (
  state: FundraiserReduxState,
  { fundraiserId }: ActionSetFundraiserId,
): FundraiserReduxState => ({
  ...state,
  fundraiserId,
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
