import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { EmailField } from '../emailField';
import useStyles, { styles } from '../styles';
import { ColorButtonLeft, ColorButtonRight } from './emailDialog.buttons';
import { KEYS } from './taxReceiptInquiryDialog.keys';
import { EmailDialogProps } from './types';

const PaperComponent = (props) => {
  const { classes } = useStyles();
  return <Paper {...props} className={classes.dialogPaper} />;
};

const EmailSchema = Yup.object().shape({
  [KEYS.EMAIL_FIELD_KEY]: Yup.string()
    .email('Invalid')
    .required('Required'),
});

const defaultFormValue = {
  [KEYS.EMAIL_FIELD_KEY]: '',
};

export const TaxReceiptInquiryDialog = ({
  isModalSubmitOpen,
  toggleModalSubmitOpen,
  onSubmit,
  disable,
}: EmailDialogProps) => {
  const { classes } = useStyles();

  if (disable) {
    return null;
  }

  return (
    <div className={classes.modalContainer} id={KEYS.MODAL_CONTAINER_ID}>
      <Dialog
        PaperComponent={PaperComponent}
        container={() => document.getElementById(KEYS.MODAL_CONTAINER_ID)}
        open={isModalSubmitOpen}
        onClose={toggleModalSubmitOpen}
        style={styles.absoluteContainer as React.CSSProperties}
        BackdropProps={{
          style: styles.absoluteContainer as React.CSSProperties,
        }}
        aria-labelledby="alert-dialog-title"
      >
        <Formik
          initialValues={defaultFormValue}
          validationSchema={EmailSchema}
          onSubmit={onSubmit}
        >
          <Form placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
              {KEYS.DIALOG_TITLE}
            </DialogTitle>
            <DialogContent classes={{ root: classes.dialogContent }}>
              <DialogContentText id="alert-dialog-description" className={classes.dialogText}>
                {KEYS.DIALOG_TEXT}
              </DialogContentText>
            </DialogContent>
            <DialogContent classes={{ root: classes.contentContainer }}>
              <div className={classes.bottomContainer}>
                <EmailField
                  label={KEYS.EMAIL_FIELD_LABEL}
                  className={classes.defaultInput}
                  name={KEYS.EMAIL_FIELD_KEY}
                />
              </div>
            </DialogContent>
            <DialogActions style={styles.dialogActions}>
              <ColorButtonRight
                type="button"
                className={classes.separated}
                onClick={toggleModalSubmitOpen}
              >
                {KEYS.SKIP}
              </ColorButtonRight>
              <ColorButtonLeft type="submit" variant="text" color="primary" autoFocus>
                {KEYS.GET_RECEIPT}
              </ColorButtonLeft>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </div>
  );
};

export default React.memo(TaxReceiptInquiryDialog);
