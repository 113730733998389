import React, { useCallback } from 'react';
import {
  FormControl,
  FormHelperText,
  TextField,
} from '@mui/material';
import { useField } from 'formik';

import { Broker } from 'types/broker';

import { FormFieldDescription } from '../../fields/field.types';
import { KEYS } from '../brokerInfo.keys';
import { useStyles } from '../styles';

export interface CustomTextFieldProps {
  brokers: Broker[];
  item: FormFieldDescription;
  required: boolean;
  disabled: boolean;
  isSubmitting: boolean;
  name: string;
  setFieldValue: (field: string, value: any) => void;
  errorText?: string;
}

export const CustomTextField = ({
  item,
  disabled,
  isSubmitting,
  setFieldValue,
  errorText,
  ...props
}: CustomTextFieldProps) => {
  const { classes } = useStyles();
  const [field, meta] = useField(props);
  const hasMetaError = Boolean((isSubmitting || meta.touched) && meta.error);
  const hasError = !disabled && (hasMetaError || Boolean(errorText));
  const errorCode: string = meta.error || '';

  const handleChange = useCallback(({ target }) => setFieldValue(item.name, target.value), [item]);

  const label = `${item.label}${item.isRequired ? KEYS.REQUIRED_ASTERISK : ''}`;

  return (
    <FormControl fullWidth className={classes.inputFormControl}>
      <TextField
        {...field}
        {...props}
        inputProps={{
          className: classes.mainInput,
          maxLength: item.maxLength,
        }}
        className={classes.defaultInput}
        onChange={handleChange}
        placeholder={label}
        name={item.name}
        title={item?.title}
        type={item.type}
        margin="dense"
        error={hasError}
        helperText={errorText}
        disabled={disabled}
      />
      <FormHelperText className={classes.errorHelperText}>{hasError && errorCode}</FormHelperText>
    </FormControl>
  );
};

export default CustomTextField;
