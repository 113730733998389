import { AnyAction } from '../state.types';
import { WidgetReduxState } from './state';

export const actionTypeName = 'WIDGET__SET_ID';

export type ActionSetWidgetId = AnyAction & {
  widgetId: string;
}

export const createAction = (widgetId: string) => ({
  type: actionTypeName,
  widgetId,
});

export const reducer = (
  state: WidgetReduxState,
  { widgetId }: ActionSetWidgetId,
): WidgetReduxState => ({
  ...state,
  widgetId,
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
