export const MAX_TYPES_PER_ROW = 3;

export const LABELS = {
  CARD: 'Card',
  CRYPTO: 'Crypto',
  STOCK: 'Stock',
  DAF: 'DAF',
  ACH: 'ACH',
};

export default LABELS;
