import { AnyAction } from '../state.types';
import { DonationReduxState } from './state';

export const actionTypeName = 'WORKFLOW__SUBMIT_STOCK_DONATION_PLEDGE__FAIL';

export type ActionSubmitStockDonationPledgeFail = AnyAction & {
  error: Record<string, string>;
}

export const createAction = (error: Record<string, string>): ActionSubmitStockDonationPledgeFail => ({
  type: actionTypeName,
  error,
});

export const reducer = (
  state: DonationReduxState,
  action: ActionSubmitStockDonationPledgeFail,
): DonationReduxState => ({
  ...state,
  isSubmitting: false,
  error: action.error,
});

export default {
  actionTypeName,
  createAction,
  reducer,
};
