import React, {
  ChangeEvent,
  FocusEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, Input, Tooltip } from '@mui/material';

import { FiatCurrency } from 'types/currency';
import { DonationPledge } from 'types/pledge';
import { fiatFormatter, getCurrencySymbol, toDecimalFormat } from 'utils/currency';
import { locators, QALocator } from 'utils/tests/QA';

import { FiatFormatCustom, NumberFormatCustom } from '../../numberFormatCustom';
import { getCurrencyFieldPlaceholder, LABELS } from '../pledge.keys';
import useStyles from './styles';

export interface CryptoCurrencyAmountBoxProps {
  onMainAmountChange: (value: number) => void;
  fiatCurrency: FiatCurrency | null;
  onSecondaryAmountChange: (value: number) => void;
  pledge: DonationPledge;
  error?: string;
}

export const CryptoCurrencyAmountBox = ({
  onMainAmountChange,
  fiatCurrency,
  onSecondaryAmountChange,
  pledge,
  error = '',
}: CryptoCurrencyAmountBoxProps) => {
  const [amountInputFocused, setAmountInputFocused] = useState<boolean>(false);
  const formattedUsdValue = useMemo(
    () => {
      if (pledge.usdAmount === 0) {
        return '';
      }

      return fiatFormatter(fiatCurrency, 2).format(pledge.usdAmount);
    },
    [pledge.usdAmount],
  );
  const [amountInputValue, setAmountInputValue] = useState<string>(toDecimalFormat(pledge.amount));
  const formattedCryptoValue = useMemo(() => {
    if (amountInputFocused) {
      return amountInputValue;
    }

    if (pledge.amount === 0) {
      return '';
    }

    return toDecimalFormat(pledge.amount);
  }, [pledge.amount, amountInputFocused, amountInputValue]);
  const [fiatInputValue, setFiatInputValue] = useState<string>(formattedUsdValue);
  const [USDInputFocused, setUSDInputFocused] = useState<boolean>(false);
  const { classes, cx } = useStyles({ isFocused: amountInputFocused || USDInputFocused });

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (amountInputFocused) {
      onMainAmountChange(Number(value));
    }

    setAmountInputValue(value);
  };

  const handleUSDAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (USDInputFocused) {
      onSecondaryAmountChange(Number(value));
    }
    setFiatInputValue(fiatFormatter(fiatCurrency, 2).format(Number(value)));
  };

  const handleAmountInputFocus = (event: FocusEvent<HTMLInputElement>) => {
    setAmountInputFocused(true);
  };

  const handleUSDInputFocus = (event: FocusEvent<HTMLInputElement>) => {
    setUSDInputFocused(true);
  };

  const handleInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onMainAmountChange(Number(value));
    setAmountInputValue(formattedCryptoValue);
    setAmountInputFocused(false);
  };

  const handleUSDInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onSecondaryAmountChange(Number(value));
    setFiatInputValue(fiatFormatter(fiatCurrency, 2).format(Number(value)));
    setUSDInputFocused(false);
  };

  useEffect(() => {
    setAmountInputValue(formattedCryptoValue);
  }, [pledge.amount]);

  useEffect(() => {
    setFiatInputValue(formattedUsdValue);
  }, [pledge.usdAmount]);

  const fiatCurrencyPrefix = getCurrencySymbol(fiatCurrency);
  const fiatCurrencyInputPlaceholder = getCurrencyFieldPlaceholder(fiatCurrency);

  return (
    <div className={classes.amountBoxWrapper}>
      <Tooltip
        open={Boolean(error)}
        arrow
        classes={{
          tooltip: classes.tooltip,
        }}
        disableTouchListener
        placement="bottom-start"
        title={error}
      >

        <Box
          className={cx({
            [classes.amountBox]: true,
            error: Boolean(error),
          })}
        >
          <Box className={classes.mainInputContainer}>
            <Input
              disableUnderline
              inputProps={{ className: classes.mainCurrencyInput }}
              value={amountInputValue}
              onChange={handleAmountChange}
              onFocus={handleAmountInputFocus}
              onBlur={handleInputBlur}
              placeholder={LABELS.CRYPTO_INPUT_PLACEHOLDER}
              inputComponent={NumberFormatCustom}
              {...QALocator(locators.components.crypto.amountInput)}
            />
          </Box>
          <Box className={classes.secondaryInputContainer}>
            <span className={classes.inputDecoration}>
              {LABELS.APPROXIMATE_FIAT_VALUE_PREFIX}
            </span>
            <Input
              disableUnderline
              inputProps={{
                className: classes.secondaryCurrencyInput,
                prefix: fiatCurrencyPrefix,
              }}
              value={fiatInputValue}
              onChange={handleUSDAmountChange}
              onFocus={handleUSDInputFocus}
              onBlur={handleUSDInputBlur}
              placeholder={fiatCurrencyInputPlaceholder}
              inputComponent={FiatFormatCustom}
              {...QALocator(locators.components.crypto.usdAmountInput)}
            />
          </Box>
        </Box>
      </Tooltip>
    </div>
  );
};

export default CryptoCurrencyAmountBox;
