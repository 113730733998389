import { MINIMAL_USD_VALUES } from 'config/workflow';
import { DonationWorkflowType } from 'types/workflow';
import { USDFormatter } from 'utils/currency';
import checkIsFiatDonationType from 'utils/workflow';

import { AnyAction } from '../state.types';
import { PledgeReduxState } from './state';

export const actionTypeName = 'PLEDGE__CHANGE_FIAT_AMOUNT';

export type ActionChangeFiatAmount = AnyAction & {
  value: number;
  workflowType: DonationWorkflowType;
}

export const createAction = (value: number, workflowType?: DonationWorkflowType) => ({
  type: actionTypeName,
  value,
  workflowType,
});

const VALIDATION_MESSAGES = {
  LESS_THAN_MINIMUM: `Minimum donation amount is ${USDFormatter().format(MINIMAL_USD_VALUES.FIAT)}.`,
};

export const reducer = (
  state: PledgeReduxState,
  action: ActionChangeFiatAmount,
): PledgeReduxState => {
  const { exchangeRate } = state;
  const { value, workflowType } = action;

  const amount = Number(value / exchangeRate);

  const nextState = {
    ...state,
    usdAmount: value,
    amount,
  };

  const isFiatDonationFlow = checkIsFiatDonationType(workflowType);
  if (isFiatDonationFlow) {
    if (value < MINIMAL_USD_VALUES.FIAT) {
      nextState.error = {
        amount: VALIDATION_MESSAGES.LESS_THAN_MINIMUM,
      };
    } else {
      nextState.error = null;
    }
  }

  return nextState;
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
