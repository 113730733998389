import React from 'react';

import { IconProps } from './icon.types';

const path = [
  [
    'M12.6667 2H3.33333C2.4496 2.00106 1.60237 2.35259 0.97748',
    '2.97748C0.352588 3.60237 0.00105857 4.44961 0 5.33334H16C15.9989',
    '4.44961 15.6474 3.60237 15.0225 2.97748C14.3976 2.35259 13.5504 2.00106 12.6667 2Z',
  ].join(' '),
  [
    'M0 10.6667C0.00105857 11.5504 0.352588 12.3976',
    '0.97748 13.0225C1.60237 13.6474 2.4496 13.999 3.33333 14H12.6667C13.5504',
    '13.999 14.3976 13.6474 15.0225 13.0225C15.6474 12.3976 15.9989 11.5504',
    '16 10.6667V6.66669H0V10.6667ZM4.66666 10.3334C4.66666 10.5311 4.60802 10.7245',
    '4.49813 10.8889C4.38825 11.0534 4.23207 11.1815 4.04935 11.2572C3.86662 11.3329',
    '3.66556 11.3527 3.47158 11.3141C3.27759 11.2756 3.09941 11.1803 2.95956 11.0405C2.81971',
    '10.9006 2.72447 10.7224 2.68588 10.5284C2.64729 10.3345 2.6671 10.1334 2.74279',
    '9.95067C2.81847 9.76794 2.94665 9.61177 3.1111 9.50188C3.27554 9.392 3.46888',
    '9.33335 3.66667 9.33335C3.93188 9.33335 4.18624 9.43871 4.37377 9.62625C4.56131',
    '9.81378 4.66666 10.0681 4.66666 10.3334Z',
  ].join(' '),
];

export const IconCard = ({
  width = 16,
  height = 16,
  color = 'black',
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {path.map(item => <path key={item} d={item} fill={color} />)}
  </svg>
);

export default IconCard;
