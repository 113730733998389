import React from 'react';

import LoadingIcon from './loadingIcon';
import useStyles from './styles';

interface LoadingProps {
  text: string;
}

export const Loading = ({ text }: LoadingProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <LoadingIcon />
      <h5 className={classes.label}>{text}</h5>
    </div>
  );
};

export default Loading;
