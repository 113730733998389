export enum APIRequestMethods {
  GET='GET',
  POST='POST',
  PUT='PUT',
  DELETE='DELETE',
}

export enum APIHeaders {
  ContentType = 'Content-Type',
  Authorization = 'Authorization',
}

export enum APIContentTypes {
  Form = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
  Csv = 'text/csv',
  Json = 'application/json',
  Pdf = 'application/pdf',
  OctetStream = 'application/octet-stream',
}

export enum APIErrorCodes {
  Unauthorized = 401,
  Ok = 200,
  Forbidden = 403,
}

export interface APIFetchParams {
  url: string;
  method: APIRequestMethods;
  body?: any;
  headers?: Headers;
  shouldBuildUrl?: boolean;
}

export enum APIErrorType {
  Generic = 'err.generic',
  EntityNotFound = 'err.entityNotFound',
  GeminiRequestError = 'err.geminiRequestError',
  Permission = 'err.permissions',
  UnprocessableEntity = 'err.unprocessableEntity',
  Validation = 'err.validation',
}

export type ValidationErrorMeta = Record<string, string> & {
  validationErrorMessage: string;
  failedAttributes: Array<{
    attributeName: string;
    message: string;
    path: string;
  }>;
};

export type GeminiRequestErrorMeta = {
  msg: string;
  url: string;
  payload: any;
  organizationName: string;
  organizationId: string;
  isGeminiSandboxEnabled: boolean;
  response: any;
  errorMessage?: string;
  reason?: string;
}
export interface APIErrorBase {
  details: {
    errorMessage: string;
    errorType: APIErrorType;
  };
  message: string;
  errorType: APIErrorType;
  meta: Record<string, any>;
  requestId: string;
}

export type APIError = APIErrorBase | {
  message: string;
  errorType: APIErrorType.Validation;
  meta: ValidationErrorMeta;
  requestId: string;
} | {
  message: string;
  errorType: APIErrorType.GeminiRequestError;
  meta: GeminiRequestErrorMeta;
  requestId: string;
};

export interface ValidationAPIErrorDetails extends APIErrorBase {
  errorType: APIErrorType.Validation;
  meta: ValidationErrorMeta;
}
