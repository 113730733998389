import React, {
  ChangeEvent,
  FocusEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Input, Tooltip } from '@mui/material';

import OptionallyVisible from 'components/optionallyVisible';
import { FiatCurrency } from 'types/currency';
import { DonationPledge } from 'types/pledge';
import { fiatFormatter, toDecimalFormat } from 'utils/currency';
import { locators, QALocator } from 'utils/tests/QA';

import NumberFormatCustom from './numberFormatCustom';
import { LABELS } from './pledge.keys';
import useStyles from './styles';

export interface CryptoCurrencyAmountBoxProps {
  swapCurrencies: () => void;
  fiatCurrency: FiatCurrency;
  onAmountChange: (value: number) => void;
  pledge: DonationPledge;
  calculateFromFiat: boolean;
  error?: string;
}

export const CryptoCurrencyAmountBox = ({
  swapCurrencies,
  fiatCurrency,
  onAmountChange,
  calculateFromFiat,
  pledge,
  error = '',
}: CryptoCurrencyAmountBoxProps) => {
  const { classes, cx } = useStyles();
  const formattedFiatValue = useMemo(() => fiatFormatter(
    fiatCurrency,
    calculateFromFiat ? 0 : 2,
  ).format(pledge.usdAmount), [pledge.usdAmount]);

  const formattedCryptoValue = useMemo(() => toDecimalFormat(pledge.amount), [pledge.amount]);
  const formattedValue = calculateFromFiat ? formattedFiatValue : formattedCryptoValue;
  const [inputValue, setInputValue] = useState<string>(formattedValue);
  const amountInputFocused = useRef<boolean>(false);

  const handleUpdateAmount = (value) => {
    const nextValue = calculateFromFiat
      ? fiatFormatter(fiatCurrency, 2).format(Number(value))
      : toDecimalFormat(value);

    setInputValue(nextValue);
  };

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onAmountChange(Number(value));
    handleUpdateAmount(value);
  };

  const handleInputFocus = () => {
    amountInputFocused.current = true;
  };

  const handleInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    amountInputFocused.current = false;
    const { value } = event.target;
    onAmountChange(Number(value));
    setInputValue(value);
  };

  useEffect(() => {
    const isUpdatingCryptoValue = !calculateFromFiat;
    const isInputFocused = amountInputFocused.current;
    const shouldSkipReevaluatingInputValue = isUpdatingCryptoValue && isInputFocused;
    if (shouldSkipReevaluatingInputValue) {
      return;
    }

    setInputValue(formattedValue);
  }, [pledge]);

  const changingCurrency = calculateFromFiat ? fiatCurrency : pledge.currency.toUpperCase();
  const calculatedCurrency = calculateFromFiat ? pledge.currency.toUpperCase() : fiatCurrency;

  const subPriceInputVisible = Boolean(pledge.exchangeRate);
  const subPrice = calculateFromFiat
    ? `~${toDecimalFormat(pledge.amount)}`
    : `~${fiatFormatter(fiatCurrency, 2).format(pledge.usdAmount)}`;

  return (
    <div className={classes.inputWrapper}>
      <h6 className={classes.fieldHeader}>{LABELS.ENTER_AMOUNT_TITLE}</h6>
      <Tooltip
        classes={{
          arrow: classes.tooltipArrow,
          tooltip: classes.tooltip,
        }}
        open={Boolean(error)}
        arrow
        disableTouchListener
        placement="bottom-start"
        title={error}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
          className={cx({
            [classes.amountBox]: true,
            error: Boolean(error),
          })}
        >
          <Box>
            <Input
              className={classes.input}
              disableUnderline
              inputProps={{ className: classes.mainCurrencyInput }}
              value={inputValue}
              onChange={handleAmountChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              inputComponent={NumberFormatCustom}
              {...QALocator(locators.components.crypto.amountInput)}
            />
            <OptionallyVisible
              visible={subPriceInputVisible}
            >
              <div>
                <div className={classes.subPrice}>
                  {subPrice}
                  &nbsp;
                </div>
              </div>
            </OptionallyVisible>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className={classes.swapBox}
            onClick={swapCurrencies}
          >
            <div className={classes.activeCurrency}>
              {changingCurrency}
            </div>
            <span
              role="button"
              tabIndex={0}
              className={classes.iconExchangeWrapper}
            >
              <FontAwesomeIcon icon={['fas', 'exchange-alt']} className={classes.iconExchange} />
            </span>
            <div className={classes.calculatedCurrency}>
              {calculatedCurrency}
            </div>
          </Box>
        </Box>
      </Tooltip>
    </div>
  );
};

export default CryptoCurrencyAmountBox;
