import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  amountButtonContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: 5,
    width: '100%',
  },
  amountButton: {
    backgroundColor: 'transparent !important',
    border: '1px solid #C4C4C4 !important',
    boxShadow: 'none !important',
    height: 40,
  },
  selectedAmountButton: {
    borderColor: '#FCD42B !important',
  },
});

export default useStyles;
