import { LOCATION_FIELD_NAMES } from 'components/formFields/overlayVariant/fields.keys';
import { FormFieldDescription } from 'components/formFields/overlayVariant/fields.types';

export const KEYS = {
  EMAIL_REGEX: /\S+@\S+\.\S+/,
  ANONYMOUS_FIELD_NAME: 'isAnonymous',
  MAX_LENGTH_PHONE_NUMBER: 20,
  PLAID_LOGO_ALT: 'Plaid',
  PLACEHOLDERS: {
    ORGANIZATION_NAME: '{{organizationName}}',
  },
  COMMUNICATION_ALLOWED_KEY: 'isCharityCommunicationAllowed',
  IS_RETIRED_FIELD_NAME: 'isDonorRetired',
  EMPLOYER_FIELD: 'employer',
  OCCUPATION_FIELD: 'occupation',
};

export const LABELS = {
  PLAID_BUTTON_TEXT: 'Continue with',
  LEFT_BUTTON_TEXT: 'Previous',
  INVALID_EMAIL: 'Please enter a valid email',
  REQUIRED: 'Required field',
  PAGE_TITLE: 'Personal Info',
  SUBMITTING_TEXT: 'Preparing Pledge',
  VIEW_LOADING: 'Processing...',
  MANDATE_SHORT: [
    'By continuing you hereby authorize each of ',
    KEYS.PLACEHOLDERS.ORGANIZATION_NAME,
    ' (the “Company”), its ',
    'processor, and any financial institution (the “Suppliers”) working in connection ',
    'with either of them, to debit your bank account up to the full amount of your purchase, ',
    'including for any applicable fees, taxes, and other costs, including shipping costs.',
  ].join(''),
};

export const RETIRED_FIELD_OPTIONAL_KEYS = [KEYS.EMPLOYER_FIELD, KEYS.OCCUPATION_FIELD];

export const occupationFields: FormFieldDescription[] = [
  {
    label: 'I\'m retired or currently unemployed',
    type: 'checkbox',
    name: KEYS.IS_RETIRED_FIELD_NAME,
  },
  {
    label: 'Employer',
    type: 'text',
    isRequired: true,
    view: 'short',
    name: KEYS.EMPLOYER_FIELD,
  },
  {
    label: 'Occupation',
    type: 'text',
    isRequired: true,
    view: 'short',
    name: KEYS.OCCUPATION_FIELD,
  },
];

export const donorFormFields: FormFieldDescription[] = [
  {
    label: 'First name',
    type: 'text',
    view: 'short',
    isRequired: true,
    name: 'firstName',
  },
  {
    label: 'Last name',
    type: 'text',
    view: 'short',
    isRequired: true,
    name: 'lastName',
  },
  {
    label: 'Email',
    type: 'email',
    isRequired: true,
    name: 'email',
  },
  {
    label: 'Phone',
    type: 'tel',
    name: 'phoneNumber',
    formatter: (value: string) => value.replace(/[^\d+]/g, '').substring(0, KEYS.MAX_LENGTH_PHONE_NUMBER),
  },
  {
    label: 'Address 1',
    type: 'text',
    isRequired: true,
    name: 'address1',
  },
  {
    label: 'Address 2',
    type: 'text',
    isRequired: false,
    name: 'address2',
  },
  {
    label: 'Country',
    type: 'autocomplete',
    isRequired: true,
    view: 'short',
    name: LOCATION_FIELD_NAMES.COUNTRY,
    options: [],
  },
  {
    label: 'State/Province/Region',
    type: 'autocomplete',
    isRequired: true,
    view: 'short',
    name: LOCATION_FIELD_NAMES.STATE,
    options: [],
  },
  {
    label: 'City',
    type: 'text',
    view: 'short',
    isRequired: true,
    name: 'city',
  },
  {
    label: 'ZIP/Postal Code',
    type: 'text',
    isRequired: true,
    view: 'short',
    name: 'zipCode',
  },
  {
    label: 'Join our mailing list for news and updates',
    type: 'checkbox',
    view: 'clean',
    name: KEYS.COMMUNICATION_ALLOWED_KEY,
  },
];
