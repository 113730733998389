import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';
import FONT_FAMILIES from 'theme/fontFamilies';

export const useStyles = makeStyles()({
  scrollContainer: {
    padding: '30px 30px 45px 30px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  captchaTerms: {
    marginTop: 20,
    padding: '0 !important',
    fontSize: '14px !important ',
  },
  infoPanel: {
    padding: 10,
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: COLORS.GREY_BACKGROUND,
    borderRadius: 5,
    width: '100%',
    boxSizing: 'border-box',
    marginTop: 8,
    marginBottom: 38,
  },
  infoItem: {
    color: COLORS.TEXT.PRIMARY,
    textAlign: 'center',
    fontFamily: FONT_FAMILIES.INTER,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '28px',
    width: 'auto',
  },
});

export default useStyles;
