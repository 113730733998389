import { connect } from 'react-redux';

import { AppReduxState } from 'state/state';
import { getUsedDesignVersion } from 'utils/componentSwitch';

import CustomThemeProvider from './themeProvider';

const mapStateToProps = (state: AppReduxState) => {
  const {
    isPCDEnabled,
  } = state.organization.organization;

  const designVersion = getUsedDesignVersion();

  return {
    isPCDEnabled,
    designVersion,
  };
};

export const ThemeProviderConnect = connect(
  mapStateToProps,
)(CustomThemeProvider);

export default ThemeProviderConnect;
